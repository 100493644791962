const messages = {
  list: {
    userId: 'Id',
    username: 'Username',
    responseStatus: 'Response Status',
    created_at: 'Created at',
    clientIp: 'IP',
    adminVersion: 'Admin Version'
  },
  filter: {
    userId: 'Id',
    username: 'Username',
    responseStatus: 'Response Status',
    created_at_from: 'Created at from',
    created_at_to: 'Created at to',
    clientIp: 'IP',
    search: 'Hľadať'
  }
}

export default messages
