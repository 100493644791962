const messages = {
  id: 'ID',
  login: 'Login',
  email: 'Email',
  yes: 'Yes',
  no: 'No',
  firstName: 'Name',
  lastName: 'Surname',
  name: 'Name',
  save: 'Save',
  localLogin: 'Local',
  showLocalUsers: 'Local users',
  settings: 'Settings',
  details: 'Details',
  searchPermissionGroups: 'Permission groups',
  userAuthorization: 'User authorization',
  jobTitle: 'Job title',
  employmentType: 'Employment type',
  defaultPermissionGroup: 'Permission groups',
  fullscreen: 'Fullscreen',
  disabledPermissionTooltip: 'The permission is part of the group s<b>"{groupName}"</b> and cannot be edited.',
  filter: {
    search: 'Search',
    reset_filter: 'Reset filter'
  },
  error: {
    required_fields: 'Please fill all required fields.',
    user_has_relations: 'Cannot delete, user still has relations.'
  },
  message: {
    updated_record: 'Record updated!',
    deleted_record: 'Record deleted!',
    password_reset: 'Password reset!',
    resetLinkCopied: 'Reset link copied!'
  }
}

export default messages
