const messages = {
  id: 'Id',
  title: 'Název',
  slug: 'Slug/Název pro URL',
  description: 'Popis',
  image: 'Obrázek',
  type: 'Typ tagu',
  tag_name_already_exists: "Tag s názvem '{name}' již existuje.",
  list: {
    id: 'Id',
    title: 'Název tagu',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil'
  },
  filter: {
    title: 'Titulek',
    id: 'Id',
    search: 'Hledat'
  },
  commonTags: {
    geneeaTags: 'Geneea tagy',
    editorialTags: 'Redakční tagy'
  },
  geneeaTag: {
    title: 'Název tagu',
    id: 'Id',
    geneeaId: 'Geneea Id',
    type: 'Typ tagu',
    url: 'URL',
    generalInfo: {
      title: 'Obecné informace o tagu',
      slug: 'Slug/Název pro URL',
      createdAt: 'Datum vytvoření',
      modifiedAt: 'Datum poslední změny'
    },
    person: {
      title: 'Osoba',
      familyName: 'Příjmení',
      birthDate: 'Datum narození',
      deathDate: 'Datum úmrtí',
      hasOccupation: 'Povolání',
      givenName: 'Jméno',
      birthPlace: 'Místo narození',
      deathPlace: 'Místo úmrtí'
    },
    organisation: {
      title: 'Organizace',
      name: 'Jméno',
      alternateName: 'Alternativní název',
      logo: 'Logo'
    },
    event: {
      title: 'Událost',
      name: 'Název události',
      startDate: 'Začátek',
      endDate: 'Konec'
    },
    product: {
      title: 'Produkt',
      name: 'Produkt',
      manufacturer: 'Výrobce',
      model: 'Model'
    },
    location: {
      title: 'Místo',
      name: 'Název lokality',
      gps: 'GPS',
      streetAddress: 'Adresa',
      addressLocation: 'Okres',
      addressRegion: 'Kraj',
      addressCountry: 'Země',
      population: 'Populace'
    },
    general: {
      title: 'Obecný'
    }
  }
}

export default messages
