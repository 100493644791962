import PermissionService from '@/services/PermissionService'
import Config from '@/config'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_AUTHOR_PERMISSIONS

const routes = [
  {
    path: 'author/new',
    component: () => Config.isVlm()
      ? import('../../views/author/vlm/AuthorNewViewVlm')
      : import('../../views/author/AuthorNewView'),
    name: 'author_new',
    meta: {
      description: 'Author new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'author/:id/edit',
    component: () => Config.isVlm()
      ? import('../../views/author/vlm/AuthorEditViewVlm')
      : import('../../views/author/AuthorEditView'),
    name: 'author_edit',
    meta: {
      description: 'Author edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'author/:id',
    component: () => Config.isVlm()
      ? import('../../views/author/vlm/AuthorViewVlm')
      : import('../../views/author/AuthorView'),
    name: 'author_detail',
    meta: {
      description: 'Author detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'author',
    component: () => Config.isVlm()
      ? import('../../views/author/vlm/AuthorListViewVlm')
      : import('../../views/author/AuthorListView'),
    name: 'author_list',
    meta: {
      description: 'Author list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
