const messages = {
  list: {
    id: 'Id',
    title: 'Titulek',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil'
  },
  filter: {
    title: 'Titulek',
    id: 'Id',
    search: 'Hledat'
  },
  id: 'Id',
  title: 'Titulek'
}

export default messages
