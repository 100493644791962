const messages = {
  filter: {
    created_at_from: 'Created since',
    created_at_to: 'Created until'
  },
  main_tab: 'Main info',
  instructions_tab: 'Instructions',
  ingredients_tab: 'Ingredients and allergens',
  gallery_tab: 'Gallery',
  title: 'Title',
  title_placeholder: 'Write title',
  published_since: 'Published since',
  published_since_placeholder: 'Choose date of publishing',
  photo_or_video: 'Select main image or video',
  enabled: 'Enabled',
  ingredient_title: 'Title',
  ingredient_title_placeholder: 'Write ingredient group title',
  ingredient_urls: 'Ingredients URLs',
  spentTime: 'Preparation time (min)',
  portions: 'Number of portions',
  price: 'Price',
  difficulty: 'Difficulty level',
  instructions: 'Instructions',
  step: 'Step',
  add_step: 'Add step',
  ingredient_groups: 'Ingredient groups',
  ingredient_group: 'Ingredient group',
  add_ingredient: 'Add ingredient',
  add_ingredient_group: 'Add ingredient group',
  ingredient: 'Ingredient',
  amount: 'Amount',
  unit: 'Unit',
  allergens: 'Allergens',
  shop: 'Shop online (URL)',
  discount: 'Discount flyer (URL)',
  headline_used_in_articles: 'Used in articles',
  article_not_found: 'Recipe article not found.',
  article_exists: 'Recipe is used in articles !',
  create_article: 'Create article',
  article_modal_header: 'Recipe needs to be saved before article creation. Save now?',
  article_created_error: 'Error. Problem during recipe article creation.',
  article_created: 'Article has been created'
}

export default messages
