const state = {
  articlesChartApiData: {}
}

const mutations = {
  addArticleChartApiData (state, { documentId, data }) {
    state.articlesChartApiData[documentId] = data
  },
  removeArticleChartApiData (state, documentId) {
    if (state.articlesChartApiData[documentId]) {
      delete state.articlesChartApiData[documentId]
    }
  },
  updateChartModes (state, { documentId, data }) {
    if (state.articlesChartApiData[documentId]) {
      state.articlesChartApiData[documentId].lineChartMode = data.lineChartMode
      state.articlesChartApiData[documentId].lineChartSubMode = data.lineChartSubMode
    }
  }
}

const getters = {
  getArticleChartApiData (state) {
    return documentId => state.articlesChartApiData[documentId]
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
