import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.NATIVE_CAMPAIGN_MANAGEMENT_PERMISSIONS

const routes = [
  {
    path: 'nativeCampaignManagement',
    component: () => import('../../views/donBox/NativeCampaignManagementListView'),
    name: 'native_campaign_list',
    meta: {
      description: 'Get a Native Campaign list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  },
  {
    path: 'nativeCampaignManagement/new',
    component: () => import('../../views/donBox/NativeCampaignManagementNewView'),
    name: 'native_campaign_new',
    meta: {
      description: 'Create a Native Campaign',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'nativeCampaignManagement/:id',
    component: () => import('../../views/donBox/NativeCampaignManagementView'),
    name: 'native_campaign_detail',
    meta: {
      description: 'View a Native Campaign',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'nativeCampaignManagement/:id/edit',
    component: () => import('../../views/donBox/NativeCampaignManagementEditView'),
    name: 'native_campaign_edit',
    meta: {
      description: 'Edit a Native Campaign',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'nativeCampaignManagement/:id/duplicate',
    component: () => import('../../views/donBox/NativeCampaignManagementDuplicateView'),
    name: 'native_campaign_duplicate',
    meta: {
      description: 'Duplicate a Native Campaign',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.duplicateRoute
    }
  },
  {
    path: 'nativeCampaignManagement/fromArticle/:id',
    component: () => import('../../views/donBox/NativeCampaignManagementFromArticleView'),
    name: 'native_campaign_from_article',
    meta: {
      description: 'Crate a Native Campaign from article',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newFromArticleRoute
    }
  }
]

export default routes
