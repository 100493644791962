export default {
  userId: 0,
  departmentId: 0,
  startDate: null,
  endDate: null,
  pageViews: 0.0,
  videoViews: 0.0,
  onlineArticles: 0.0,
  p1Articles: 0.0,
  p2Articles: 0.0,
  publishedVideos: 0.0,
  createdVideos: 0.0,
  imagesInArticles: 0.0
}
