const DEFAULT_POSITION = 'c'

export default {
  id: null,
  name: '',
  cropPosition: DEFAULT_POSITION,
  imageCaption: '',
  imageAttribution: '',
  imageUuid: '',
  imageId: '',
  position: 0,
  exportWoodWing: false
}
