import axios from 'axios'
import appConfig from '../config'

const eshopApi = function () {
  const _client = axios.create({
    baseURL: appConfig.eshop.apiUrl(),
    timeout: appConfig.eshop.apiTimeout * 1000,
    headers: {
      Authorization: localStorage.getItem('access_token'),
      'Content-Type': 'application/json'
    }
  })

  /*
   * Axios Request Interceptor.
   * Before each request, use the values in local storage to set new headers.
   */
  _client.interceptors.request.use(
    config => {
      if (config.baseURL === appConfig.eshop.apiUrl()) {
        config.headers.Authorization = localStorage.getItem('access_token')
      }
      return config
    },
    error => Promise.reject(error)
  )

  return _client
}

export default eshopApi
