import _ from 'lodash'
import CbsApi from '@/api/contentBlockService'
import ContentBlockModel from '../../model/ContentBlock.js'
import ContentBlockFilter from '../../model/ContentBlockFilter'
import ContentBlockFilterService from '../../services/contentBlock/ContentBlockFilterService'

const CBS_API_NAME = '/ContentBlocks'
const LIMIT = 600
const LOCALSTORAGE_FILTER = 'contentBlock/hpManagementFilter'

const state = {
  error: null,
  detail: ContentBlockModel,
  list: [],
  all: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(ContentBlockFilter),
  hpManagementFilter: {
    site: 1,
    contentBlocks: []
  },
  limit: 100
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeAll (state, responseData) {
    state.all = responseData.data
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setLimit (state, limit) {
    state.limit = limit
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(ContentBlockFilter)
  },
  setHpManagementFilter (state, hpManagementFilter) {
    state.hpManagementFilter = hpManagementFilter
    if (window.localStorage) {
      window.localStorage.setItem(LOCALSTORAGE_FILTER, JSON.stringify(hpManagementFilter))
    }
  }
}

const actions = {
  async fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * store.state.limit) - store.state.limit
    const url = CBS_API_NAME +
      '?limit=' + store.state.limit +
      '&offset=' + offset + ContentBlockFilterService.buildFilterQuery(store.state.filter)
    await CbsApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchOne (store, id) {
    await CbsApi().get(CBS_API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async create (store, record) {
    return await CbsApi().post(CBS_API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
          return response.data
        } else {
          store.commit('setError', 'Error')
          return undefined
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async update (store, record) {
    return await CbsApi().put(CBS_API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 204) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async deleteRecord (store, record) {
    return await CbsApi().delete(CBS_API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data)
        }
      })
  },
  async fetchAll ({ commit }) {
    return await CbsApi().get(CBS_API_NAME + '?limit=' + LIMIT)
      .then(res => {
        commit('storeAll', res.data)
        return res.data
      })
      .catch(error => console.log(error))
  },
  fetchForHpManagement (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * store.state.limit) - store.state.limit
    const url = CBS_API_NAME +
      '?limit=' + store.state.limit +
      '&offset=' + offset + ContentBlockFilterService.buildHpManagementFilterQuery(store.state.hpManagementFilter)
    CbsApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
        return res.data
      })
      .catch(error => console.log(error))
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  all (state) {
    return state.all
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  limit (state) {
    return state.limit
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  hpManagementFilter (state, getters, rootState, rootGetters) {
    const currentUser = rootGetters['user/currentUser']
    if (currentUser.sites.includes(parseInt(state.hpManagementFilter.site)) === false) {
      state.hpManagementFilter.site = currentUser.sites[0]
      state.hpManagementFilter.contentBlocks = []
    }
    return state.hpManagementFilter
  },
  getContentBlockById (state) {
    return id => state.all.find(contentBlock => {
      if (id === contentBlock.id) {
        return contentBlock
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
