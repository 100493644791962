import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.FRONTEND_USER_DELIVERY_ADDRESS_PERMISSIONS

const routes = [
  {
    path: 'feUserDeliveryAddress/:id/edit',
    component: () => import('../../../components/feUser/address/FeUserDeliveryAddressEdit'),
    name: 'feUserDeliveryAddress_edit',
    meta: {
      description: 'FeUser Delivery Address edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'feUserDeliveryAddress/new/:feUserId?',
    component: () => import('../../../components/feUser/address/FeUserDeliveryAddressNew'),
    name: 'feUserDeliveryAddress_new',
    meta: {
      description: 'FeUser Delivery Address new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'feUserDeliveryAddress',
    component: () => import('../../../components/feUser/address/FeUserDeliveryAddressList'),
    name: 'feUserDeliveryAddress_list',
    meta: {
      description: 'FeUser Delivery Address list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
