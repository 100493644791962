const messages = {
  id: 'ID',
  created_at: 'Vytvořeno',
  created_at_from: 'Vytvořeno od',
  created_at_to: 'Vytvořeno do',
  created_by: 'Vytvořil',
  modified_at: 'Změněno',
  modified_by: 'Upravil',
  last_login: 'Poslední přihlášení',
  last_login_from: 'Poslední přihlášení od',
  last_login_to: 'Poslední přihlášení do',
  main_settings: 'Hlavní nastavení',
  name: 'Jméno',
  surname: 'Příjmení',
  street: 'Ulice',
  city: 'Město',
  zip: 'PSČ',
  aboCountry: 'Země',
  phone: 'Telefon',
  user_email: 'Email uživatele',
  user_id: 'ID uživatele',
  save: 'Uložit',
  username: 'Uživatelské jméno',
  password: 'Heslo',
  firstName: 'Jméno',
  lastName: 'Příjmení',
  email: 'Email',
  deliveryAddresses: 'Doručovací adresy',
  billingAddresses: 'Fakturační adresy',
  systemInfo: 'Systémové informace',
  facebook_login: 'Facebook Login',
  google_login: 'Google Login',
  roles: 'Role',
  permissions: 'Povolení',
  role_no: 'Role no.',
  permission_no: 'Povolení č.',
  removeRole: 'Vymaž roli',
  removePermission: 'Vymaž povolení',
  yes: 'Ano',
  no: 'Ne',
  enabled: 'Zapnuto',
  ico: 'ICO',
  dic: 'DIC',
  icDph: 'IC DPH',
  addressType: 'Typ',
  company: 'Firma',
  type_person: 'fyzická osoba',
  type_company: 'právnická osoba',
  article_id: 'Document ID článku',
  article_title: 'Nadpis článku',
  article: 'Článek',
  articles: 'Články',
  polls: 'Ankety',
  poll: 'Anketa',
  poll_option: 'Možnost',
  masterSite: 'Hlavní stránka',
  otherSites: 'Ostatní stránky',
  detail: 'Detail',
  create_new: 'Vytvoř novou',
  consent: {
    gdpr: 'GDPR souhlas',
    marketing: 'Marketingový souhlas'
  },
  tab: {
    general: 'General',
    addresses: 'Addresses',
    articles: 'Articles'
  },
  filter: {
    search: 'Hledat',
    reset_filter: 'Zrušit filtr',
    export: 'Exportovat'
  },
  error: {
    required_fields: 'Prosím, vyplňte všechna povinná pole'
  },
  message: {
    updated_record: 'Záznam byl změněn!',
    deleted_record: 'Záznam byl vymazán!'
  }
}

export default messages
