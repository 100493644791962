const messages = {
  list: {
    id: 'Id',
    title: 'Titulok',
    enabled: 'Povolené',
    video_count: 'Počet videí',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril'
  },
  filter: {
    id: 'Id',
    title: 'Titulok',
    search: 'Hľadať'
  },
  id: 'Id',
  title: 'Titulok',
  slug: 'Slug',
  sub_title: 'Podtitulok',
  enabled: 'Povolené',
  created_at: 'Dátum vytvorenia',
  modified_at: 'Dátum poslednej zmeny',
  created_by: 'Vytvoril',
  image: 'Obrázok'
}

export default messages
