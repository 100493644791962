const messages = {
  id: 'ID',
  customers_at_start: 'Customers at start',
  customers_at_end: 'Customers at end',
  customers_at_end_Check: 'Customers at end (start + new - churn - newChurn - newDuplicity)',
  churn_customers: 'Churn customers',
  new_customers: 'New customers',
  new_churn_customers: 'New churn customers',
  new_customers_duplicity: 'New customers - duplicity',
  customers_with_other_products: 'Customers with other products',
  churn_rate: 'Churn Rate (%)',
  monthly_churn_rate: 'Churn Rate (%) (churn + newChurn - otherProduct) / start',
  total: 'Total',
  tab: {
    churn_rate: 'Churn Rate',
    payment: 'Payments'
  },
  filter: {
    churn_rate: {
      year: 'Year',
      product: 'Product'
    },
    subscription_payment_stats: {
      year: 'Year',
      payment_type: 'Payment type'
    },
    search: 'Search',
    reset_filter: 'Reset filter'
  },
  quarter: {
    q1: 'Q1',
    q2: 'Q2',
    q3: 'Q3',
    q4: 'Q4'
  },
  payment_type: {
    new: 'New',
    recurring: 'Recurring',
    total: 'Total'
  }
}

export default messages
