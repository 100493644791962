const messages = {
  list: {
    id: 'Id',
    title: 'Title',
    enabled: 'Enabled',
    video_count: 'Počet videí',
    created_at: 'Created at',
    modified_at: 'Modified at',
    created_by: 'Created by'
  },
  filter: {
    id: 'Id',
    title: 'Title',
    search: 'Search'
  },
  id: 'Id',
  title: 'Title',
  slug: 'Slug',
  sub_title: 'Subtitle',
  enabled: 'Enabled',
  created_at: 'Created at',
  modified_at: 'Modified at',
  created_by: 'Created by',
  image: 'Image'
}

export default messages
