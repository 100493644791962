import _ from 'lodash'
import EshopApi from '../../../api/eshop'
import config from '../../../config'
import PaymentFilterModel from '../../../model/eshop/SubscriptionPaymentFilter'
import PaymentFilterService from '../../../services/eshop/SubscriptionPaymentFilterService'
import ErrorHandlingService from '../../../services/ErrorHandlingService'
import SubscriptionPayment from '../../../model/eshop/SubscriptionPayment'

const API_NAME = '/payment/subscription'
const EXPORT_LIMIT = 10000

const state = {
  error: null,
  detail: SubscriptionPayment,
  list: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(PaymentFilterModel)
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * config.defaults.list.limit) - config.defaults.list.limit
    const url = API_NAME + '?view=full&limit=' + config.defaults.list.limit + '&offset=' + offset + '&order[id]=desc' +
      '&payment[id]=desc' + PaymentFilterService.buildFilterQuery(store.state.filter)
    EshopApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
      })
      .catch(error => {
        ErrorHandlingService.handleError(store, error)
      }).finally(() => {
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchOne (store, id) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    EshopApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
      .finally(() => {
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async export (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const url = API_NAME + '.csv?limit=' + EXPORT_LIMIT + '&payment[id]=desc' + PaymentFilterService.buildFilterQuery(store.state.filter)
    return await EshopApi().get(url)
      .finally(() => {
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  }
}

const getters = {
  detail (state, getters, rootState, rootGetters) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
