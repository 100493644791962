import { dateTimeNow } from '@/services/DateTimeService'

export default {
  siteIds: [],
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  publishedAt: dateTimeNow(),
  unpublishedAt: dateTimeNow(),
  id: 0,
  imageId: null,
  linkUrl: '',
  position: 1,
  siteId: 0,
  text: ''
}
