export const CAR_TYPE_PETROL_DIESEL = 'petrol_diesel'
export const CAR_TYPE_HYBRID = 'hybrid'
export const CAR_TYPE_ELECTRO = 'electro'

export const CAR_TYPE_DEFAULT = CAR_TYPE_PETROL_DIESEL

export default {
  computed: {
    carTypeValues () {
      return [
        {
          id: CAR_TYPE_PETROL_DIESEL,
          title: this.$t('car.car_type.petrol_diesel')
        },
        {
          id: CAR_TYPE_HYBRID,
          title: this.$t('car.car_type.hybrid')
        },
        {
          id: CAR_TYPE_ELECTRO,
          title: this.$t('car.car_type.electro')
        }
      ]
    }
  },
  methods: {
    carTypeIsHybrid (type) {
      return type === CAR_TYPE_HYBRID
    },
    carTypeIsElectro (type) {
      return type === CAR_TYPE_ELECTRO
    },
    carTypeIsPetrolDiesel (type) {
      return type === CAR_TYPE_PETROL_DIESEL
    }
  }
}
