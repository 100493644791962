import axios from 'axios'
import appConfig from '../config'
import Store from '../store/'
import NotifyService from '@/services/NotifyService'
import { isArticleError, CANNOT_DELETE_IN_USE } from '@/model/ValueObject/ErrorCodes'
import { i18n, getInstanceHash } from '@/main'
import { notifyServiceSetGeneralErrorMsg } from '@/services/NotifyServiceSetGeneralErrorMsg'

const coreApi = function (timeout = appConfig.core.apiTimeout) {
  Store.commit('SET_OFFLINE', false)
  const _client = axios.create({
    baseURL: appConfig.core.apiUrl(),
    timeout: timeout * 1000,
    headers: {
      Authorization: localStorage.getItem('access_token'),
      'Content-Type': 'application/json',
      'X-Admin-Instance-Hash': getInstanceHash()
    }
  })

  /*
   * Axios Request Interceptor.
   * Before each request, use the values in local storage to set new headers.
   */
  _client.interceptors.request.use(
    config => {
      if (config.baseURL === appConfig.core.apiUrl()) {
        config.headers.Authorization = localStorage.getItem('access_token')
      }
      return config
    },
    error => Promise.reject(error)
  )

  /*
  * Axios Response Interceptor.
  */
  _client.interceptors.response.use(
    response => {
      return response
    },
    error => {
      const errorData = error?.response?.data
      if (errorData?.status === 409 && errorData?.error === CANNOT_DELETE_IN_USE) {
        NotifyService.setErrorMessage(i18n.t('notify.record_in_use_cannot_be_deleted'))
      } else if (!isArticleError(error)) {
        notifyServiceSetGeneralErrorMsg(error)
      }
      return Promise.reject(error)
    }
  )

  return _client
}

export default coreApi
