const messages = {
  playability: 'Dohranosť videí',
  reach: 'Dosah videí',
  video_performance: 'Výkonnosť videí',
  ad_performance: 'Výkonnosť video reklamy',
  avgVideosPerUser: 'Priemer videí na používateľa',
  concurrentUsers: 'Unikátni použivatelia',
  playConversionRate: 'In view play conv. rate',
  adConversionRate: 'Ad conv. rate',
  videoStarts: 'Video sa začína',
  adStarts: 'Spustenie reklamy',
  adSkipRate: 'Preskočenie reklamy',
  adFinishRate: 'Dopozeranosť reklamy',
  vidFinishRate: 'Dopozeranosť videa',
  videoPlays: 'Spustenie videa',
  dailyAvg: 'Denný priemer',
  total: 'Celkovo',
  inViewDisplays: 'Zobrazenie playera',
  concurrent_users: 'Realtime počet sledujúcich v celom {company}',
  videoviews: 'Videoviews',
  detail: 'Detail',
  devices: 'Devices',
  desktop: 'Desktop',
  mobile: 'Mobile',
  tablet: 'Tablet',
  os: 'OS',
  windows: 'Windows',
  ios: 'iOS',
  android: 'Android',
  top_assets: 'Top videá',
  players_performance: 'Výkonnosť playerov podľa pozícií',
  sources: 'Sources',
  sources_domain: 'Zdroj doména',
  sources_url: 'Top články s videom',
  type: 'Type',
  video_id: 'Video ID',
  title: 'Title',
  displays: 'Displays',
  in_view_displays: 'In view displays',
  videoplays: 'Videoplays',
  videoplays_portfolio: 'Počet prehratí videa v celom {company}',
  all_sites: 'Celé {company} portfólio',
  ad_starts: 'AD starts',
  video_starts: 'Video starts',
  play_conv_rate: 'In view play conv rate %',
  conversion_rate_info: 'Conversion rate = Video plays / In view displays',
  replays: 'Replays',
  totalVideoViews: 'Video views',
  pass25Plays: 'Dohranosť 25%',
  pass50Plays: 'Dohranosť 50%',
  pass75Plays: 'Dohranosť 75%',
  pass100Plays: 'Dohranosť 100%',
  avgPassPerc: 'Priemerná dohranosť',
  unique_users_count: 'Unikátni používatelia',
  characterType: {
    title: 'Povaha videa',
    agencycontent: 'Agentúrne',
    editorialcontent: 'Redakčné',
    marketingcontent: 'Selfpromo (marketing)',
    nativecontent: 'Natívne'
  },
  load_data: 'Načítať dáta',
  displayed: 'zobrazených',
  filter: {
    site: 'Stránka',
    department: 'Oddelenie',
    date_from: 'Od',
    date_to: 'Do',
    dateFrom: 'Zobraziť od',
    dateTo: 'Zobraziť do',
    reset_filter: 'Zresetovať filter'
  },
  no_data_found: 'Neboli nájdené žiadne dáta.',
  playlist_performance_today: 'Playlist performance today',
  other: 'other',
  daily_report: {
    author: 'Autor videa',
    title: 'Titulok videa',
    play_request: 'Počet spustení',
    site: 'Stránka',
    unique_users: 'Unikátnych používateľov',
    average: 'Priemerná dopozeranosť',
    hero: 'Hero',
    article: 'Článok',
    gallery: 'Galéria',
    video_duration: 'Dĺžka videa',
    filter: {
      date: 'Dátum'
    }
  },
  header: {
    onRequest: 'On Request',
    shows: 'Relácie',
    totalVideos: 'Celkom videí',
    youtube: 'Youtube',
    publishedArticlesCount: 'Počet vypublikovaných článkov',
    videosArticlesRate: 'Pokrytie článkov videami'
  },
  list: {
    department: '',
    departmentStat: 'Spolu',
    socialNetworks: 'Sociálne siete',
    agencies: 'Agentúry',
    mobilePhone: 'Mobil',
    slideShow: 'SlideShow',
    onRequest: 'Realita',
    shows: 'Realita',
    videosCount: 'Realita'
  }
}

export default messages
