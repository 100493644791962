export default {
  documentId: '',
  title: '',
  orderDate: '',
  sharedUsers: {
    userId: 0,
    userName: ''
  },
  pageViewsCnt: 0,
  fbSharesCnt: 0,
  disqusCnt: 0,
  imagesCnt: 0,
  videosCnt: 0
}
