const messages = {
  list: {
    id: 'Id',
    year: 'Year',
    competitionName: 'Competition Name',
    fortunaSportName: 'Sport Name',
    nationName: 'Nation Name',
    fortunaCatName: 'League'
  },
  filter: {
    id: 'Id',
    year: 'Year',
    competitionName: 'Competition Name',
    fortunaSportName: 'Sport Name',
    nationName: 'Nation Name',
    fortunaCatName: 'League',
    search: 'Search'
  },
  games: 'Games',
  won: 'Won',
  drawn: 'Drawn',
  lost: 'Lost',
  won_2: 'Won PP',
  lost_2: 'Lost PP',
  points: 'Points'
}

export default messages
