const messages = {
  filter: {
    id: 'Id',
    title: 'Titulek',
    type: 'Typ',
    brand: 'Značka/výrobce',
    model: 'Model'
  },
  list: {
    id: 'Id',
    title: 'Titulek',
    type: 'Typ',
    brand: 'Značka/výrobce',
    model: 'Model'
  },
  car_type: {
    petrol_diesel: 'Nafta/Benzín',
    hybrid: 'Hybrid',
    electro: 'Electro'
  },
  id: 'Id',
  title: 'Titulek',
  brand: 'Značka/výrobce',
  model: 'Model',
  productionYear: 'Modelový rok',
  type: 'Typ',
  description: 'Popis',
  image: 'Obrázek',
  main_car: 'hlavní auto',
  petrol_motor: 'benzínový motor',
  diesel_motor: 'dieselový motor',
  electro_motor: 'elektromotor',
  petrol_plus_gas: 'benzin + plyn',
  save: 'Uložit',
  frontAxle: 'přední náprava',
  rearAxle: 'zadní náprava',
  allWheels: 'všechna kola',
  engine: {
    header: 'Motor a pohonný systém',
    fuel: 'Palivo',
    drive: 'Pohon',
    gearbox: 'Převodovka'
  },
  fuelEngine: {
    cylinderSetup: 'Uspořádání a počet válců',
    cylinderVolume: 'Zdvihový objem',
    maxPower: 'Max. výkon',
    maxPowerRotation: 'při otáčkách',
    maxTorque: 'Max. točivý moment',
    maxTorqueRotation: 'při otáčkách'
  },
  elEngine: {
    motorType: 'Typ elektromotoru',
    maxPower: 'Max. výkon',
    maxTorque: 'Max. točivý moment ',
    maxRotation: 'Max. otáčky'
  },
  measurements: {
    header: 'Rozměry, hmotnosti a objemy',
    length: 'Délka',
    width: 'Šířka',
    height: 'Výška',
    wheelBase: 'Rázvor náprav',
    curbWeight: 'Pohotovostní hmotnost',
    curbWeightEu: 'Pohotovostní hmotnost (EU)',
    capacityWeight: 'Užitečná hmotnost',
    totalWeight: 'Celková hmotnost',
    trunkCapacity: 'Objem zavazadlového prostoru',
    trunkDimensions: 'Rozměry nakládacího prostoru (DxŠxV)',
    tankVolume: 'Objem palivové nádrže',
    batteryCapacity: 'Kapacita baterie'
  },
  driving: {
    header: 'Jízdní výkony',
    maxSpeed: 'Max. rychlost',
    acceleration: 'Zrychlení 0-100 km/h'
  },
  consumption: {
    header: 'Spotřeba'
  },
  fuelConsumption: {
    extraHigh: 'Velmi vysoká zátěž - normovaná',
    high: 'Vysoká zátěž - normovaná',
    medium: 'Střední zátěž - normovaná',
    low: 'Nízká zátěž - normovaná',
    combined: 'Kombinovaná - normovaná',
    testedCity: 'Město - během testu',
    testedMotorway: 'Dálnice - během testu',
    testedCountryRoad: 'Venkov - během testu'
  },
  elConsumption: {
    extraHigh: 'Velmi vysoká zátěž - normovaná',
    high: 'Vysoká zátěž - normovaná',
    medium: 'Střední zátěž - normovaná',
    low: 'Nízká zátěž - normovaná',
    combined: 'Kombinovaná - normovaná',
    testedCity: 'Město - během testu',
    testedMotorway: 'Dálnice - během testu',
    testedCountryRoad: 'Venkov - během testu',
    range: 'Dojezd - norma WLTP'
  },
  emission: {
    header: 'Emise',
    value: 'Emise CO2',
    standard: 'Emisní norma'
  },
  price: {
    header: 'Aktuální ceníkové ceny s dph',
    modelPrice: 'Základní cena modelu',
    versionPrice: 'Základní cena verze',
    testedPrice: 'Cena testovaného exempláře'
  },
  rating: {
    header: 'Hodnocení',
    exterior: 'Exteriér',
    interior: 'Interiér',
    engine: 'Pohon',
    driving: 'Podvozek',
    price: 'Cena',
    total: 'Celkově'
  },
  prosCons: {
    header: 'Výhody a nevýhody',
    pros: 'Výhody',
    cons: 'Nevýhody'
  },
  unit: {
    percentage: '%',
    cylinderVolume: 'cm3',
    power: 'kW',
    power_horse: 'k',
    rotation: '/min',
    torque: 'Nm',
    dimensions: 'mm',
    weight: 'kg',
    volume: 'l',
    capacity: 'kWh',
    speed: 'km/h',
    acceleration: 's',
    fuel_consumption: 'l/100 km',
    el_consumption: 'kWh/100 km',
    emission: 'g/km',
    price: '€',
    range: 'km'
  }
}

export default messages
