const messages = {
  home: 'Home',
  article_list: 'Seznam článků',
  article_detail: 'Detail článku',
  article_edit: 'Editace článku',
  articleByDocumentId: 'Detail článku',
  author_list: 'Seznam autorů',
  author_detail: 'Detail autora',
  author_edit: 'Editace autora',
  author_new: 'Nový autor',
  breakingnews_edit: 'Editovat zprávu',
  breakingnews_list: 'Breaking news',
  breakingnews_new: 'Vytvořit novou zprávu',
  source_list: 'List zdrojů',
  source_detail: 'Detail zdroje',
  source_edit: 'Editace zdroje',
  source_new: 'Nový zdroj',
  otherFunction_list: 'List dalších funkcí',
  otherFunction_detail: 'Detail další funkce',
  otherFunction_edit: 'Editace další funkce',
  otherFunction_new: 'Nová další funkce',
  contentBlock_list: 'Content block list',
  contentBlock_detail: 'Content block detail',
  contentBlock_edit: 'Content block edit',
  contentBlock_new: 'Content block new',
  pollBlock_list: 'Poll block list',
  pollBlock_detail: 'Poll block detail',
  pollBlock_edit: 'Poll block edit',
  pollBlock_new: 'Poll block new',
  topic_list: 'Seznam témat',
  topic_detail: 'Detail témata',
  topic_edit: 'Editace tématu',
  topic_new: 'Nové téma',
  car_list: 'Seznam vozidel',
  car_detail: 'Detail vozidla',
  car_edit: 'Editace vozidla',
  car_new: 'Nové vozidlo',
  restaurant_list: 'Seznam restaurací',
  restaurant_detail: 'Detail restaurace',
  restaurant_edit: 'Editace restaurace',
  restaurant_new: 'Nová restaurace',
  hotel_list: 'Seznam hotelů',
  hotel_detail: 'Detail hotelu',
  hotel_edit: 'Editace hotelu',
  hotel_new: 'Nový hotel',
  contentBlockItem_list: 'HP management',
  contentBlockItem_list_old: 'HP management(old)',
  dam_list: 'DAM seznam fotek',
  dam_pdf_list: 'DAM seznam dokumentů',
  native_campaign_list: 'Přehled spuštěných kampaní',
  native_campaign_detail: 'Náhled kampaně',
  native_campaign_edit: 'Upravit kampaň',
  native_campaign_duplicate: 'Zduplikovat kampaň',
  native_campaign_from_article: 'Vytvořit kampaň z článku',
  native_campaign_new: 'Vytvořit kampaň',
  don_npa_list: 'Management kampaní',
  don_npa_predictions: 'Vývoj kampaní',
  don_npa_detail: 'Náhled kampaně',
  don_npa_edit: 'Upravit kampaň',
  don_npa_duplicate: 'Zduplikovat kampaň',
  don_npa_new: 'Vytvořit kampaň',
  don_npa_config: 'Konfigurace DON boxu',
  dashboard: 'Realtime statistiky',
  dashboard_old: 'Realtime statistiky (old)',
  welcome: 'Vítejte',
  infobox_list: 'Seznam infoboxů',
  infobox_detail: 'Detail infoboxu',
  infobox_edit: 'Editace infoboxu',
  infobox_new: 'Nový infobox',
  joke_list: 'Seznam vtipů',
  joke_detail: 'Detail vtipu',
  joke_edit: 'Editace vtipu',
  joke_new: 'Nový vtip',
  quote_list: 'Seznam myšlenek týdne',
  quote_detail: 'Detail myšlenky týdne',
  quote_edit: 'Editace myšlenky týdne',
  quote_new: 'Nová myšlenka týdne',
  poll_list: 'Seznam anket',
  poll_detail: 'Detail ankety',
  poll_edit: 'Editace ankety',
  poll_new: 'Nová anketa',
  poll_management: 'Ankety na Homepage',
  likeDislike_list: 'Seznam líbí nelíbí',
  likeDislike_detail: 'Detail líbí nelíbí',
  likeDislike_edit: 'Editace líbí nelíbí',
  likeDislike_new: 'Nová líbí nelíbí',
  quiz_list: 'Seznam kvízů',
  quiz_detail: 'Detail kvízu',
  quiz_edit: 'Editace kvízu',
  quiz_new: 'Nový kvíz',
  quizQuestion_new: 'Nová otázka kvízu',
  quizQuestion_edit: 'Editace otázky kvízu',
  oneQuestion_list: 'Seznam - jedna otázka',
  oneQuestion_detail: 'Detail - jedna otázka',
  oneQuestion_edit: 'Editace - jedna otázka',
  oneQuestion_new: 'Nový - jedna otázka',
  oneQuestionQuestion_new: 'Nová otázka jedné otázky',
  oneQuestionQuestion_edit: 'Editace otázky jedné otázky',
  historical_dashboard: 'Statistiky produktu',
  beUserKpi_list: 'KPI management',
  beUserKpi_edit: 'Uprav KPI',
  kpi_comparison: 'Srovnej KPI',
  performance_kpi_settings: 'Nastavení KPI',
  performance_product: 'Výkonnost produktu',
  performance_department: 'Výkonnost oddělení',
  historical_performance: 'Historická výkonnost',
  performance_coefficient: 'Nastavení koeficientů',
  performance_weights: 'Nastavení vah',
  edonProduct_list: 'Produkty',
  edonProduct_new: 'Nový produkt',
  edonProduct_edit: 'Uprav produkt',
  beUser_list: 'BE uživatelé',
  beUser_edit: 'Editace BE uživatele',
  beUser_new: 'Nový BE uživatel',
  feUser_list: 'Fe user list',
  feUser_edit: 'Fe user edit',
  feUser_new: 'Fe user new',
  feUserArticle_list: 'FeUser Article list',
  feUserArticle_edit: 'FeUser Article edit',
  feUserArticle_new: 'FeUser Article new',
  feUserBillingAddress_list: 'FeUser Billing Address list',
  feUserBillingAddress_edit: 'FeUser Billing Address edit',
  feUserBillingAddress_new: 'FeUser Billing Address new',
  feUserDeliveryAddress_list: 'FeUser Delivery Address list',
  feUserDeliveryAddress_edit: 'FeUser Delivery Address edit',
  feUserDeliveryAddress_new: 'FeUser Delivery Address new',
  feUserPollVote_list: 'Ankety',
  hottopics_edit: 'Hot Topics - editace panelu',
  hottopics_list: 'Hot Topics - seznam panelů',
  hottopics_new: 'Hot Topics - nový panel',
  site_list: 'Site list',
  site_edit: 'Site edit',
  rubric_list: 'Rubric list',
  rubric_edit: 'Rubric edit',
  rubric_new: 'Rubric new',
  category_list: 'Category list',
  category_edit: 'Category edit',
  category_new: 'Category new',
  tag_list: 'Tag list',
  tag_edit: 'Tag edit',
  tag_new: 'Tag new',
  commonTags_list: 'Tagy - seznam',
  geneeaTags_list: 'Tagy Geneea - seznam',
  geneeaTag_edit: 'Tagy Geneea - editace',
  geneeaTag_detail: 'Tagy Geneea - detail',
  editorialTags_list: 'Redakční tagy - seznam',
  editorialTag_edit: 'Redakční tagy - editace',
  editorialTag_detail: 'Redakční tagy - detail',
  editorialTag_new: 'Redakční tagy - nový',
  themeSpecialAndSeries_list: 'Témata, speciály a seriály - seznam',
  themeSpecialAndSeries_edit: 'Témata, speciály a seriály - editace',
  special_detail: 'Speciály - detail',
  theme_detail: 'Témata - detail',
  series_detail: 'Seriály - detail',
  themeSpecialAndSeries_detail: 'Témata, speciály a seriály - detail',
  themeSpecialAndSeries_new: 'Témata, speciály a seriály - nový',
  department_list: 'Oddělení',
  department_edit: 'Uprav oddělení',
  department_new: 'Nové oddělení',
  app_performance: 'App Performance',
  scale_list: 'Váhy',
  scale_edit: 'Uprav váhy',
  safetyTopic_list: 'SafetyTopic list',
  safetyTopic_edit: 'SafetyTopic edit',
  safetyTopic_new: 'SafetyTopic new',
  redirect_list: 'Seznam přesměrování',
  redirect_edit: 'Editace přesměrování',
  redirect_new: 'Nové přesměrování',
  redirect_detail: 'Detail přesměrování',
  feature_list: 'Feature list',
  feature_edit: 'Feature edit',
  articleHistory_list: 'Article history list',
  sport24_list: 'Sportovní tabulky',
  sport24_detail: 'Sportovní tabulky - detail',
  help_video_list: 'Seznam videí',
  userLoginAttempt_list: 'User Login Attempt list',
  tasrNews_list: 'Seznam TASR zpráv',
  tasrNews_detail: 'Detail TASR zprávy',
  tasrNewsCategory_list: 'Seznam TASR news category',
  tasrNewsCategory_edit: 'Edit TASR news category',
  tasrNewsCategory_new: 'New TASR news category',
  topicOffer_list: 'Seznam nabídky obsahu',
  topicOffer_detail: 'Detail nabídky obsahu',
  printTitle_list: 'List Print Titles',
  printTitle_detail: 'Detail Print Title',
  printTitle_edit: 'Edit Print Title',
  printTitle_new: 'New Print Title',
  printPublication_preview: 'Aktuální printová vydání na webu',
  printPublication_list: 'Seznam printových vydání',
  printPublication_detail: 'Detail printového vydání',
  printPublication_edit: 'Editace printového vydání',
  printPublication_new: 'Nové printové vydání',
  video_list: 'Seznam videí',
  video_list_old: 'Seznam videí(old)',
  video_detail: 'Detail videa',
  video_detail_old: 'Detail videa(old)',
  video_edit: 'Editace videa',
  video_edit_old: 'Editace videa(old)',
  video_new: 'Nové video',
  video_new_old: 'Nové video(old)',
  videoAnnotation_list: 'Seznam anotací',
  videoAnnotation_list_old: 'Seznam anotací(old)',
  videoAnnotation_detail: 'Detail anotace',
  videoAnnotation_detail_old: 'Detail anotace(old)',
  videoAnnotation_edit: 'Editace anotace',
  videoAnnotation_edit_old: 'Editace anotace(old)',
  videoAnnotation_new: 'Nová anotace',
  videoAnnotation_new_old: 'Nová anotace(old)',
  videoShow_list: 'Video show list',
  videoShow_detail: 'Video show detail',
  videoShow_edit: 'Video show edit',
  videoShow_new: 'Video show new',
  videoCategory_list: 'Video category list',
  videoCategory_detail: 'Video category detail',
  videoCategory_edit: 'Video category edit',
  videoCategory_new: 'Video category new',
  videoManagement: 'Video management',
  videoDashboard: 'Statistiky videí',
  videoKpiReports: 'Report uploadovaných videí',
  printArticle_list: 'Printové články',
  printArticle_edit: 'Uprav printový článek',
  printArticle_new: 'Nový printový článek',
  eshopSubscriptionProduct_list: 'Produkty',
  eshopSubscriptionProduct_new: 'Nový produkt',
  eshopSubscriptionProduct_edit: 'Uprav produkt',
  eshopSubscriptionOrder_list: 'Objednávky',
  eshopSubscriptionOrder_detail: 'Objednávka',
  eshopSubscriptionPayment_list: 'Platby',
  eshopSubscriptionPayment_detail: 'Platba',
  eshopSubscription_list: 'Předplatné',
  eshopSubscription_new: 'Nové předplatné',
  eshopSubscription_edit: 'Uprav předplatné',
  eshopSubscriber_list: 'Předplatitelé',
  eshopHistory_list: 'Historie',
  eshopFeature_list: 'Eshop feature list',
  eshopFeature_edit: 'Eshop feature edit',
  eshopDashboard: 'Dashboard',
  eshopTopProductSetting_edit: 'Uprav TOP Produkty',
  videoDailyReport: 'Report redakčních videí',
  videoPublishedReport: 'Report publikovaných videí',
  notification_detail: 'Detail serveru',
  notification_edit: 'Editace serveru',
  notification_list: 'Notifikace - servery',
  notification_new: 'Nový server',
  permissionGroup_list: 'Skupiny oprávnění',
  permissionGroup_new: 'Nová skupina oprávnění',
  permissionGroup_detail: 'Detail skupiny oprávnění',
  permissionGroup_edit: 'Editace skupiny oprávnění',
  recipe_detail: 'Detail receptu',
  recipe_edit: 'Editace receptu',
  recipe_list: 'Recepty',
  recipe_new: 'Nový recept',
  recipe_category_edit: 'Editace kategorie receptu',
  recipe_category_list: 'Kategorie receptu',
  recipe_category_new: 'Nová kategorie receptu',
  hp_topic_offer_list: 'Homepage nabídka článků',
  hp_topic_offer_list_old: 'Homepage nabídka článků(old)',
  woodWing_detail: 'Detail WoodWing článku',
  woodWing_list: 'Homepage WoodWing článků',
  kpi_nmh: 'NMH KPI',
  kpi_nmh_old: 'Historické NMH KPI',
  kpi_nmh_settings: 'NMH KPI nastavení',
  gallery_detail: 'Detail galerie',
  gallery_edit: 'Editace galerie',
  gallery_list: 'Galerie',
  gallery_new: 'Nová galerie',
  userLogout: 'Odhlášení uživatele'
}

export default messages
