const messages = {
  list: {
    id: 'Id',
    title: 'Titulek',
    type: 'Typ',
    created_at: 'Datum vytvoření',
    created_by: 'Vytvořil'
  },
  filter: {
    title: 'Titulek',
    id: 'Id',
    search: 'Hledat',
    discriminators: 'Typ'
  },
  discriminators: {
    car: 'Auto'
  },
  id: 'Id',
  title: 'Titulek'
}

export default messages
