<template>
  <div class="login">
    <div class="login__dialog-wr">
      <LoginLogo />
      <Preloader v-if="loading" />
      <p v-if="errorResponse" class="login__error">
        {{ errorResponse }}
        <span
          class="logout-button"
          data-test="logout_btn"
          @click="logout"
        >
          {{ $t('login.logout') }}
        </span>
        <router-link
          tag="a"
          :title="$t('login.log_in')"
          :to="{ name: 'login' }"
        >
          {{ $t('login.log_in') }}
        </router-link>
      </p>
      <p class="login__version">
        {{ $t('login.version') }}: {{ version }}
      </p>
    </div>
  </div>
</template>

<script>
import appConfig from '@/config'
import Preloader from '@/components/preloader/Preloader'
import SsoLoginService from '@/services/user/SsoLoginService'
import LoginLogo from '@/components/user/LoginLogo'

export default {
  name: 'LoginCallback',
  components: {
    LoginLogo,
    Preloader
  },
  data () {
    return {
      section: 'Login',
      loading: false,
      errorResponse: '',
      version: appConfig.appVersion()
    }
  },
  methods: {
    logout () {
      const manager = SsoLoginService.getOidcUserManager()
      manager.signoutPopup()
    },
    requestFailed (error) {
      console.error(error)
      this.errorResponse = this.$t('login.error_message')
      this.loading = false
    }
  },
  created () {
    this.loading = true
    const manager = SsoLoginService.getOidcUserManager()
    manager.signinRedirectCallback().then(async (user) => {
      if (user) {
        await this.$store.dispatch('user/login', user)
          .then(() => {
            const redirect = localStorage.getItem('redirect_path')
            if (redirect) {
              this.$router.push(redirect)
              localStorage.removeItem('redirect_path')
            } else {
              this.$router.push('/article')
            }
          }).catch(error => {
            this.requestFailed(error)
          })
      } else {
        this.requestFailed('User not logged')
      }
    }).catch(error => {
      this.requestFailed(error)
    })
  }
}
</script>

<style scoped lang="scss">
.logout-button {
  color: #1e88e5;
  cursor: pointer;
}
.login {
  padding-top: 1rem;
  &__version {
    text-align: center;
  }
}
</style>
