<template>
  <div class="notifications-wrapper">
    <notifications
      group="main"
      position="top center"
      :speed="500"
      :width="notificationWidth"
      :closeOnClick="true"
    >
      <template slot="body" slot-scope="{ item, close }">
        <div :class="notifyClass(item)" @click.stop.self="close">
          <div
            v-if="item.title"
            class="notification-title"
            v-html="item.title"
          >
          </div>
          <div
            class="notification-content"
            v-html="item.text"
          >
          </div>
          <button
            v-if="item.type === 'apiError'"
            type="button"
            class="notification-copy-btn"
            @click="copyToClipboard(item.title)"
            ref="notificationCopyBtn"
          >
            Copy text
          </button>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
export default {
  name: 'AppNotifications',
  data () {
    return {
      notificationWidth: 600
    }
  },
  methods: {
    notifyClass (item) {
      return [
        'vue-notification-template',
        'vue-notification',
        item.type
      ]
    },

    copyToClipboard (text) {
      const plainText = text.replace(/<[^>]+>/g, '')
      const textarea = document.createElement('textarea')
      textarea.value = plainText
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
      this.$refs.notificationCopyBtn.style.transform = 'scale(1.2)'
      setTimeout(() => {
        this.$refs.notificationCopyBtn.style.transform = 'scale(1)'
      }, 100)
    }
  }
}
</script>

<style lang="scss">
.vue-notification {
  &.apiError {
    pointer-events: none;
    position: relative;
    background: #dc5555;
    border-left: 5px solid #a83131;

    &::after {
      position: absolute;
      top: .5rem;
      right: 1rem;
      content: '\00D7';
      font-size: 2rem;
      line-height: 1rem;
      cursor: pointer;
      pointer-events: auto;
      font-weight: 100;
    }

    .notification-title {
      font-weight: 500;
      line-height: 1.3rem;
      margin-right: 2rem;
    }

    .notification-copy-btn {
      border: 1px solid #fff;
      font-size: .8rem;
      color: #e54d42;
      background: #fff;
      padding: 0.1rem 0.3rem;
      cursor: copy;
      pointer-events: auto;
      margin-top: .3rem;
    }
  }
}
</style>
