import CoreApi from '../../../api/core'
import DonNativePerfConfig from '../../../model/DonNativePerfConfig'

const API_NAME = '/don/nativePerf/config'

const state = {
  error: null,
  detail: DonNativePerfConfig
}

const mutations = {
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setError (state, message) {
    state.error = message
  }
}

const actions = {
  async fetchConfig (store) {
    await CoreApi().get(API_NAME)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  detail (state, getters, rootState, rootGetters) {
    return state.detail
  },
  error (state) {
    return state.error
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
