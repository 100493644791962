import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_SOURCE_PERMISSIONS

const routes = [
  {
    path: 'source/new',
    component: () => import('../../views/source/SourceNewView'),
    name: 'source_new',
    meta: {
      description: 'Source new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'source/:id/edit',
    component: () => import('../../views/source/SourceEditView'),
    name: 'source_edit',
    meta: {
      description: 'Source edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'source/:id',
    component: () => import('../../views/source/SourceView'),
    name: 'source_detail',
    meta: {
      description: 'Source detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'source',
    component: () => import('../../views/source/SourceListView'),
    name: 'source_list',
    meta: {
      description: 'Source list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
