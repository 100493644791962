import moment from 'moment'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.type !== 'none') {
    filterQuery += '&filter_eq[type]=' + filter.type
  }
  if (filter.payment) {
    filterQuery += '&filter_eq[payment]=' + filter.payment
  }
  if (filter.order) {
    filterQuery += '&filter_eq[order]=' + filter.order
  }
  if (filter.description) {
    filterQuery += '&filter_contains[description]=' + filter.description
  }
  if (filter.createdAtFrom) {
    const date = moment(filter.createdAtFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[createdAt]=' + date
  }
  if (filter.createdAtTo) {
    const date = moment(filter.createdAtTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[createdAt]=' + date
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
