import { dateTimeNow } from '@/services/DateTimeService'

export default {
  id: 0,
  entityUuid: '',
  title: '',
  text: '',
  linkUrl: '',
  siteIds: [],
  publishedFrom: dateTimeNow(),
  publishedTo: dateTimeNow(),
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  createdById: 0,
  modifiedById: 0,
  siteId: 0
}
