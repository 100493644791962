export default {
  id: null,
  site: null,
  title: '',
  createdBy: null,
  createdAtFrom: null,
  createdAtTo: null,
  tags: [],
  users: []
}
