const messages = {
  id: 'ID',
  customers_at_start: 'Zákazníci na začiatku',
  customers_at_end: 'Zákazníci na konci',
  customers_at_end_Check: 'Zákazníci na konci (start + new - churn - newChurn - newDuplicity)',
  churn_customers: 'Zákazníci, ktorí si neobnovili predplatné',
  new_customers: 'Noví zákazníci',
  new_churn_customers: 'Noví zákazníci, ktorí si neobnovili predplatné',
  new_customers_duplicity: 'Noví zákazníci - duplicity',
  customers_with_other_products: 'Prešli na iný balík',
  churn_rate: 'Churn Rate (%) (churn + newChurn - otherProduct) / (start + new)',
  monthly_churn_rate: 'Churn Rate (%) (churn + newChurn - otherProduct) / start',
  total: 'Spolu',
  tab: {
    churn_rate: 'Churn Rate',
    payment: 'Platby'
  },
  filter: {
    churn_rate: {
      year: 'Rok',
      product: 'Produkt'
    },
    subscription_payment_stats: {
      year: 'Rok',
      payment_type: 'Typ platby'
    },
    search: 'Hľadať',
    reset_filter: 'Zrušiť filter'
  },
  quarter: {
    q1: 'Q1',
    q2: 'Q2',
    q3: 'Q3',
    q4: 'Q4'
  },
  payment_type: {
    new: 'Nové',
    recurring: 'Opakované',
    total: 'Spolu'
  }
}

export default messages
