import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ARTICLE_OFFER_TOPIC_OFFER_PERMISSIONS

export const TOPIC_OFFER_DETAIL_ROUTE = 'topicOffer_detail'
export const TOPIC_OFFER_LIST_ROUTE = 'topicOffer_list'

const routes = [
  {
    path: 'topicOffer/:id',
    component: () => import('../../components/topic/TopicOffer'),
    name: TOPIC_OFFER_DETAIL_ROUTE,
    meta: {
      description: 'topicOffer detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'topicOffer',
    component: () => import('../../components/topic/TopicOfferList'),
    name: TOPIC_OFFER_LIST_ROUTE,
    meta: {
      description: 'TopicOffer list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
