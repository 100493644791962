import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'feUserPollVote',
    component: () => import('../../../components/feUser/pollVote/FeUserPollVoteList'),
    name: 'feUserPollVote_list',
    meta: {
      description: 'FeUser poll votes list',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .FRONTEND_USER_POLL_VOTE_PERMISSIONS
        .listRoute
    }
  }
]

export default routes
