import PermissionService from '@/services/PermissionService'

const videoRequiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.VIDEO_PERMISSIONS
const videoAnnotationRequiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.VIDEO_ANNOTATION_PERMISSIONS

const routes = [
  {
    path: 'video/new',
    component: () => import('../../views/video/VideoNewView'),
    name: 'video_new',
    meta: {
      description: 'Video new',
      requiresAuth: true,
      requiredPermissionModules: videoRequiredPermissions.newRoute
    }
  },
  {
    path: 'video/:id',
    component: () => import('../../views/video/VideoView'),
    name: 'video_detail',
    meta: {
      description: 'Video detail',
      requiresAuth: true,
      requiredPermissionModules: videoRequiredPermissions.detailRoute
    }
  },
  {
    path: 'video/:id/edit',
    component: () => import('../../views/video/VideoEditView'),
    name: 'video_edit',
    meta: {
      description: 'Video edit',
      requiresAuth: true,
      requiredPermissionModules: videoRequiredPermissions.editRoute
    }
  },
  {
    path: 'video/:id/annotation/new',
    component: () => import('../../views/video/VideoAnnotationNewView'),
    name: 'videoAnnotation_new',
    meta: {
      description: 'Video annotation new',
      requiresAuth: true,
      requiredPermissionModules: videoAnnotationRequiredPermissions.newRoute
    }
  },
  {
    path: 'video/:id/annotation/:annotationId/edit',
    component: () => import('../../views/video/VideoAnnotationEditView'),
    name: 'videoAnnotation_edit',
    meta: {
      description: 'Video annotation edit',
      requiresAuth: true,
      requiredPermissionModules: videoAnnotationRequiredPermissions.editRoute
    }
  },
  {
    path: 'video',
    component: () => import('../../views/video/VideoListView'),
    name: 'video_list',
    meta: {
      description: 'Video list',
      requiresAuth: true,
      requiredPermissionModules: videoRequiredPermissions.listRoute
    }
  },
  {
    path: 'videoOld',
    component: () => import('../../views/video/VideoListView'),
    name: 'video_list_old',
    meta: {
      description: 'Video list',
      requiresAuth: true,
      requiredPermissionModules: videoRequiredPermissions.listRoute
    }
  },
  {
    path: 'videoOld/:id/annotation/:annotationId/edit',
    component: () => import('../../views/video/VideoAnnotationEditView'),
    name: 'videoAnnotation_edit_old',
    meta: {
      description: 'Video annotation edit',
      requiresAuth: true,
      requiredPermissionModules: videoAnnotationRequiredPermissions.editRoute
    }
  },
  {
    path: 'videoOld/new',
    component: () => import('../../views/video/old/VideoNewViewOld'),
    name: 'video_new_old',
    meta: {
      description: 'Video new',
      requiresAuth: true,
      requiredPermissionModules: videoAnnotationRequiredPermissions.newRoute
    }
  },
  {
    path: 'videoOld/:id',
    component: () => import('../../views/video/old/VideoViewOld'),
    name: 'video_detail_old',
    meta: {
      description: 'Video detail',
      requiresAuth: true,
      requiredPermissionModules: videoRequiredPermissions.detailRoute
    }
  },
  {
    path: 'videoOld/:id/edit',
    component: () => import('../../views/video/old/VideoEditViewOld'),
    name: 'video_edit_old',
    meta: {
      description: 'Video edit',
      requiresAuth: true,
      requiredPermissionModules: videoRequiredPermissions.editRoute
    }
  },
  {
    path: 'videoOld/:id/annotation/new',
    component: () => import('../../views/video/VideoAnnotationNewView'),
    name: 'videoAnnotation_new_old',
    meta: {
      description: 'Video annotation new',
      requiresAuth: true,
      requiredPermissionModules: videoAnnotationRequiredPermissions.newRoute
    }
  }
]

export default routes
