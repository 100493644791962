export const TYPE_NONE = 'none'
export const TYPE_PER_HOUR = 'perHour'
export const TYPE_PER_DAY = 'perDay'
export const TYPE_PER_MONTH = 'perMonth'
export const TYPE_ONCE = 'once'

export const TYPE_MAP = {
  none: 'Žiadne',
  perHour: 'Raz za hodinu',
  perDay: 'Raz za deň',
  perMonth: 'Raz za mesiac',
  once: 'Raz'
}

export const AVAILABLE_VALUES = [
  {
    id: TYPE_NONE,
    title: 'Žiadne'
  },
  {
    id: TYPE_PER_HOUR,
    title: 'Raz za hodinu'
  },
  {
    id: TYPE_PER_DAY,
    title: 'Raz za deň'
  },
  {
    id: TYPE_PER_MONTH,
    title: 'Raz za mesiac'
  },
  {
    id: TYPE_ONCE,
    title: 'Raz'
  }
]
