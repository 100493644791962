export const CONTENT_BLOCK_TYPE_ARTICLE = 'Article'
export const CONTENT_BLOCK_TYPE_VIDEO = 'Video'

export const CONTENT_BLOCK_TYPE_DEFAULT = CONTENT_BLOCK_TYPE_ARTICLE

export default {
  computed: {
    contentBlockTypes () {
      return {
        article: CONTENT_BLOCK_TYPE_ARTICLE,
        video: CONTENT_BLOCK_TYPE_VIDEO
      }
    },
    contentBlockTypeValues () {
      return [
        {
          id: CONTENT_BLOCK_TYPE_ARTICLE,
          title: this.$t('contentBlock.content_block_type_value.article')
        },
        {
          id: CONTENT_BLOCK_TYPE_VIDEO,
          title: this.$t('contentBlock.content_block_type_value.video')
        }
      ]
    }
  }
}
