import moment from 'moment'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.username) {
    filterQuery += '&filter_contains[username]=' + filter.username
  }
  if (filter.firstName) {
    filterQuery += '&filter_contains[firstName]=' + filter.firstName
  }
  if (filter.lastName) {
    filterQuery += '&filter_contains[lastName]=' + filter.lastName
  }
  if (filter.email) {
    filterQuery += '&filter_contains[email]=' + filter.email
  }
  if (filter.enabled !== 'none') {
    filterQuery += '&filter_eq[enabled]=' + filter.enabled
  }
  if (filter.createdAtFrom) {
    const date = moment(filter.createdAtFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[createdAt]=' + date
  }
  if (filter.createdAtTo) {
    const date = moment(filter.createdAtTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[createdAt]=' + date
  }
  if (filter.lastLoginFrom) {
    const date = moment(filter.lastLoginFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[lastLogin]=' + date
  }
  if (filter.lastLoginTo) {
    const date = moment(filter.lastLoginTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[lastLogin]=' + date
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
