<template>
  <div
    :class="[
      'select-area',
      { 'form-group': !disableFormGroup },
      { 'select-area--has-danger': error },
      { 'select-area--compact-width': compactWidth },
      { 'select-area--compact-height': compactHeight }
    ]"
  >
    <label
      v-if="!noLabel"
      class="select-area__label"
      :for="id"
    >
      {{ label }}
      <span v-if="required" class="required">*</span>
    </label>
    <Tooltip
      v-if="toolTip.length"
      :title="toolTip"
      customInfoClass="seo"
    />
    <div class="select-area__select-wr">
      <select
        :id="id"
        class="select-area__select"
        :class="{
          [`has-status-${status}`]: status,
          'select-area__select--enabled': !disabled,
          'select-area__select--error': error
        }"
        :disabled="disabled"
        @input="$emit('input', getConvertedValue($event.target.value))"
        @change="$emit('change', getConvertedValue($event.target.value))"
        :data-test="dataAttr ? dataAttr : id"
      >
        <option class="select-area__option" value="0" v-if="noEmptyValue === false">{{ emptyValueTitle }}</option>
        <option
          class="select-area__option"
          :class="{ 'select-area__option--heading': option.heading }"
          v-for="(option, index) in options"
          :key="`item-${index}`"
          :value="getValueFromOption(option)"
          :selected="isOptionSelected(option)"
          :disabled="option.disabled"
        >
          <template v-if="Object.prototype.hasOwnProperty.call(option, 'lvl') && option.lvl === 1">
            &nbsp;&nbsp;&nbsp;&nbsp;
          </template>
          <template v-if="Object.prototype.hasOwnProperty.call(option, 'lvl') && option.lvl === 2">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </template>
          {{ getOptionTitle(option) }}
        </option>
      </select>
      <SvgIconArrowDown class="select-area__arrow-icon" />
    </div>
  </div>
</template>

<script>
import Tooltip from '../../tooltip/Tooltip'
import SvgIconArrowDown from '@/assets/img/svg/arrow-down.svg?inline'

export default {
  name: 'Select',
  components: {
    Tooltip,
    SvgIconArrowDown
  },
  props: {
    compactWidth: {
      type: Boolean,
      default: false
    },
    compactHeight: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    label: {
      type: String
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    status: {
      type: String
    },
    error: {
      type: Boolean,
      default: false
    },
    disableFormGroup: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    emptyValueTitle: {
      type: String,
      default: '---'
    },
    noEmptyValue: {
      type: Boolean,
      default: false
    },
    options: {},
    optionTitle: {
      type: String,
      default: 'title'
    },
    // based on MultiSelect customLabel
    customTitle: {
      type: Function,
      default (option, title) {
        if (this.isEmpty(option)) {
          return ''
        }
        return title ? option[title] : option
      }
    },
    /**
     * Pass 'null' if you want the whole object as the value
     */
    optionValue: {
      type: String,
      default: 'id'
    },
    value: {},
    required: {
      type: Boolean,
      default: false
    },
    convertValue: {
      type: Boolean,
      default: true
    },
    toolTip: {
      type: String,
      default: ''
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    isEmpty (opt) {
      if (opt === 0) {
        return false
      }
      if (Array.isArray(opt) && opt.length === 0) {
        return true
      }
      return !opt
    },
    getValueFromOption (option) {
      return this.optionValue === null ? JSON.stringify(option) : option[this.optionValue]
    },
    isOptionSelected (option) {
      return this._.isEqual(this.optionValue === null ? option : option[this.optionValue], this.value)
    },
    getConvertedValue (value) {
      if (this.convertValue === false) {
        return value
      }
      if (this.optionValue === null) {
        return JSON.parse(value)
      }
      if (value === 'true') {
        return true
      }
      if (value === 'false') {
        return false
      }
      // do NOT use 'parseInt(value)' instead of '+value'
      //   as for uuid strings it can create an integer out of the first digits of that uuid
      //   e.g. parseInt("2eaef4b7-c205-11eb-a6e5-960c93aeb2bc") will become number 2
      const integerValue = +value
      if (isNaN(integerValue)) {
        return value
      }
      return integerValue
    },
    getOptionTitle (option) {
      return this.customTitle(option, this.optionTitle)
    }
  }
}
</script>

<style scoped lang="scss">
  .select-area {
    &--compact-width {
      width: fit-content;
    }
    &__option {
      &--heading {
        color: #000000;
      }
    }
    &__label,
    &__select {
      font-family: "Roboto", sans-serif;
      font-size: rem(14px);
      font-weight: 400;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    &__label {
      color: #8A96AC;
      .select-area--has-danger & {
        color: #fc4b6c;
      }
    }
    &__select-wr {
      position: relative;
    }
    &__select {
      height: rem(40px) !important;
      border-radius: rem(6px);
      border: solid 1px #d1dbe4;
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
      color: #465674;
      padding: 0 rem(30px) 0 rem(10px);
      width: 100%;
      .select-area--has-danger & {
        border: solid 1px #fc4b6c;
      }
      .select-area--compact-height & {
        height: rem(30px) !important;
      }
      &--enabled {
        background: #fff;
      }
      &--error {
        border: 1px solid #FF3455;
      }
    }
    &__arrow-icon {
      position: absolute;
      top: calc(50% - 6px);
      right: rem(12px);
      width: rem(11px);
      fill: #9DAAC4;
      pointer-events: none;
    }
  }
</style>
