import {
  PLATFORM_TOTAL,
  PLATFORM_PC,
  PLATFORM_MOBILE_PHONES,
  PLATFORM_MOBILE_TABLETS
} from '../../model/HistoricalDashboard'

const messages = {
  product: 'Product',
  from: 'Since',
  to: 'Until',
  real_users: 'RU',
  users: 'RU',
  page_views: 'PVs',
  stream_views: 'VVs',
  visits: 'Visits',
  sessions: 'Visits',
  time_spent_visit: 'ATS',
  avg_session_duration: 'ATS',
  page_views_visits: 'PV / Visits',
  page_views_sessions: 'PV / Visits',
  competitors: 'Competition',
  channels: 'Channels',
  devices: 'Devices',
  products_perf: 'Product performance',
  article_types: 'Article types',
  departments: 'Departments',
  platform: {
    [PLATFORM_TOTAL]: 'All',
    [PLATFORM_PC]: 'Desktop',
    [PLATFORM_MOBILE_PHONES]: 'Mobil',
    [PLATFORM_MOBILE_TABLETS]: 'Tablet'
  },
  unit: {
    label: 'Data units',
    day: 'days',
    month: 'months'
  }
}

export default messages
