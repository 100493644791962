import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ARTICLE_OFFER_TASR_NEWS_PERMISSIONS

const routes = [
  {
    path: 'tasrNews/:id',
    component: () => import('../../components/tasr/TasrNews'),
    name: 'tasrNews_detail',
    meta: {
      description: 'TasrNews detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'tasrNews',
    component: () => import('../../components/tasr/TasrNewsList'),
    name: 'tasrNews_list',
    meta: {
      description: 'TasrNews list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
