import PermissionService from '@/services/PermissionService'
import Config from '@/config'

const quizRequiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.QUIZ_PERMISSIONS
const oneQuestionRequiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.QUIZ_ONE_QUESTION_PERMISSIONS

const routes = [
  {
    path: 'quiz/:id/edit',
    component: () => Config.isVlm()
      ? import('../../views/quiz/vlm/QuizEditViewVlm')
      : import('../../views/quiz/QuizEditView'),
    name: 'quiz_edit',
    meta: {
      description: 'Quiz edit',
      requiresAuth: true,
      requiredPermissionModules: quizRequiredPermissions.editRoute
    }
  },
  {
    path: 'quiz/:id',
    component: () => Config.isVlm()
      ? import('../../views/quiz/vlm/QuizViewVlm')
      : import('../../views/quiz/QuizView'),
    name: 'quiz_detail',
    meta: {
      description: 'Quiz detail',
      requiresAuth: true,
      requiredPermissionModules: quizRequiredPermissions.detailRoute
    }
  },
  {
    path: 'quiz/new',
    component: () => Config.isVlm()
      ? import('../../views/quiz/vlm/QuizNewViewVlm')
      : import('../../views/quiz/QuizNewView'),
    name: 'quiz_new',
    meta: {
      description: 'Quiz new',
      requiresAuth: true,
      requiredPermissionModules: quizRequiredPermissions.newRoute
    }
  },
  {
    path: 'quiz',
    component: () => Config.isVlm()
      ? import('../../views/quiz/vlm/QuizListViewVlm')
      : import('../../views/quiz/QuizListView'),
    name: 'quiz_list',
    meta: {
      description: 'Quiz list',
      requiresAuth: true,
      requiredPermissionModules: quizRequiredPermissions.listRoute
    }
  },
  {
    path: 'oneQuestion/:id/edit',
    component: () => import('../../views/quiz/QuizOneQuestionEditView'),
    name: 'oneQuestion_edit',
    meta: {
      description: 'One question edit',
      requiresAuth: true,
      requiredPermissionModules: oneQuestionRequiredPermissions.editRoute
    }
  },
  {
    path: 'oneQuestion/:id',
    component: () => import('../../views/quiz/QuizOneQuestionView'),
    name: 'oneQuestion_detail',
    meta: {
      description: 'One question detail',
      requiresAuth: true,
      requiredPermissionModules: oneQuestionRequiredPermissions.detailRoute
    }
  },
  {
    path: 'oneQuestion/new',
    component: () => import('../../views/quiz/QuizOneQuestionNewView'),
    name: 'oneQuestion_new',
    meta: {
      description: 'One question new',
      requiresAuth: true,
      requiredPermissionModules: oneQuestionRequiredPermissions.newRoute
    }
  },
  {
    path: 'oneQuestion',
    component: () => import('../../views/quiz/QuizOneQuestionListView'),
    name: 'oneQuestion_list',
    meta: {
      description: 'One question list',
      requiresAuth: true,
      requiredPermissionModules: oneQuestionRequiredPermissions.listRoute
    }
  }
]

export default routes
