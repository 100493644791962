const messages = {
  nativeCampaign: {
    status: 'Stav',
    agency: 'Agentura',
    client: 'Klient',
    title: 'Titulek',
    interval: 'Trvání',
    alternatives: 'Alter- nativy',
    days: 'Aktivní zobrazení (dny)',
    daysColumn: 'Aktivní dny',
    daysTooltip: 'Počet dní, kdy je kampaň aktivní',
    visits: 'Zhlédnutí (inview imprese)',
    visitsColumn: 'Zhlédnutí',
    visitsTooltip: 'Inview imprese',
    displays: 'Zobrazení (imprese)',
    clicks: 'Kliknutí',
    clicksTooltip: 'Počet kliknutí / Počet zaplacených kliknutí',
    sumPrice: 'Minutá suma',
    ctr: 'CTR In-View',
    ctrColumn: 'CTR',
    ctrTooltip: 'In-View',
    clicksLimit: 'Zaplacené kliknutí',
    budget: 'Rozpočet',
    clickPrice: 'Cena za klik (€)',
    clickPriceColumn: 'CPC',
    clickPriceTooltip: 'Cena za klik',
    budgetFulfillment: 'Plnění rozpočtu',
    fulfillment: 'Plnění',
    bonusFulfillment: 'Aktuální bonusová kliknutí',
    bonusFulfillmentColumn: 'Bonus',
    bonusFulfillmentTooltip: 'Aktuální bonusová kliknutí',
    rrk: 'RRK',
    rrkTooltip: 'Rovnoměrné rozložení kliků',
    dividedByDays: 'Rovnoměrné rozložení kliků', // RRK
    note: 'Poznámka',
    salesman: 'Obchodník',
    category: 'Kategorie kampaně',
    pageUrl: 'URL stránky',
    orderId: 'Číslo objednávky',
    addAlternative: 'Přidat alternativu',
    mainAlternative: 'Hlavní alternativa',
    alternativeName: 'Alternativa {name}',
    image: 'Obrázek',
    loadImageFromArticle: 'Načíst obrázek z článku',
    publishedFrom: 'Platnost od',
    publishedTo: 'Platnost do',
    segments: 'Segmenty',
    pause: 'Pozastavit kampaň',
    bonusClicks: 'Nárok na bonusová kliknutí',
    resetCampaign: 'Resetovat kampaň',
    resetCampaignDisabled: 'Kampaň je stále platná',
    publishedIsInPast: 'Platnost nemůže být v minulosti.',
    brands: 'Výběr webů pro zobrazení reklamy',
    allNMHBrandOption: 'Celé NMH',
    alternativesEnabled: 'Alternování kampaní',
    paidCampaign: 'Placená kampaň',
    createNewLabel: 'Nová kampaň',
    filter: {
      status: 'Stav',
      agency: 'Agentura',
      client: 'Klient',
      title: 'Titulek',
      from: 'Datum od',
      to: 'Datum do',
      url: 'URL',
      salesman: 'Obchodník',
      orderId: 'Objednávka č.'
    },
    alternative: {
      ctr: 'CTR',
      visitsCount: 'Počet zhlédnutí (inview imprese)',
      displaysCount: 'Počet zobrazení (imprese)',
      clickCount: 'Počet kliknutí'
    },
    brandsTable: {
      title: 'Vývoj kampaně podle brandu',
      brands: 'Brandy',
      visits: 'Vidění (inview imprese)',
      clicks: 'Kliky',
      ctr: 'CTR'
    }
  },
  prediction: {
    clicks: 'Realne kliknutí',
    clicksForecast: 'Předpověď kliknutí',
    clicksReserved: 'Rezervovaná kliknutí',
    sold: 'Vyprodanost',
    clicksForecastTooltip: 'Tento údaj/osa udává, kolik reklamního prostoru má NMH případě nativní reklamy na donboxech.',
    clicksReservedTooltip: 'Tento údaj / osa udává celkový počet rezervovaných kliknutí v kampaních v případě nativní reklamy v donboxech.' +
      'Tedy počet prokliků, které klienti v rámci kampaní zaplatili.',
    soldTooltip: '% podíl mezi „dostupnými kliknutími“ a „prodanými kliknutími“.',
    clicksTooltip: 'Tento údaj / osa udává skutečný počet kliknutí, která budou provedena v případě nativní reklamy donbox.'
  }
}

export default messages
