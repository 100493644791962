import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'kpi-nmh',
    component: () => import('../../components/kpiNmh/KpiNmh'),
    name: 'kpi_nmh',
    meta: {
      description: 'NMH KPI',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .EDITOR_PERFORMANCE_KPI_NMH_PERMISSIONS
        .listRoute
    }
  },
  {
    path: 'kpi-nmh-old',
    component: () => import('../../components/kpiNmh/KpiNmhOld'),
    name: 'kpi_nmh_old',
    meta: {
      description: 'NMH KPI old',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .EDITOR_PERFORMANCE_KPI_NMH_PERMISSIONS
        .listRoute
    }
  },
  {
    path: 'kpi-nmh-settings',
    component: () => import('../../components/kpiNmh/KpiNmhSettings'),
    name: 'kpi_nmh_settings',
    meta: {
      description: 'NMH KPI SETTINGS',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .EDITOR_PERFORMANCE_KPI_NMH_SETTINGS_PERMISSIONS
        .listRoute
    }
  }
]

export default routes
