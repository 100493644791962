const messages = {
  list: {
    id: 'Id',
    server: 'Server',
    title: 'Quiz title',
    type: 'Rules',
    loggedInUsersOnly: 'Logged in only'
  },
  filter: {
    id: 'Id',
    server: 'Server',
    title: 'Quiz title'
  },
  id: 'Id',
  server: 'Server',
  title: 'Quiz Title',
  description: 'Quiz text',
  type: 'Rules',
  image: 'Quiz Image',
  resultsHeader: 'Results',
  resultTitle: 'Result text',
  addResult: 'Add result',
  resultScore: 'Points',
  displayCorrectAnswersImmediatelyLabel: 'When to show correct answers?',
  displayCorrectAnswersImmediatelyLabelFalse: 'After completion',
  displayCorrectAnswersImmediatelyLabelTrue: 'Immediately',
  loggedInUsersOnlyLabel: 'For logged in users only',
  loggedInUsersOnlyLabelFalse: 'No',
  loggedInUsersOnlyLabelTrue: 'Yes',
  enabled: 'Enabled',
  questionsEmptyError: 'Quiz must have at least 1 question',
  quizType: {
    correctAnswer: 'Item may be correct or incorrect',
    points: 'Each item has a point value'
  },
  question: {
    add: 'Add question',
    header: 'Questions',
    title: 'Question text',
    image: 'Question Image',
    article: 'Article'
  },
  answer: {
    add: 'Add answer',
    header: 'Answers',
    title: 'Answer text',
    points: 'Points',
    pointsTrue: 'true',
    pointsFalse: 'false'
  },
  articleModal: {
    selectButton: 'Select article',
    header: 'Select article'
  }
}

export default messages
