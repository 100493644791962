const messages = {
  period_type: {
    none: 'None',
    week: 'Week',
    month: 'Month',
    three_months: '3 months',
    six_months: '6 months',
    one_year: '1 year',
    two_years: '2 years',
    one_article: '1 article'
  },
  visibility_type: {
    public: 'public',
    with_link: 'with_link'
  },
  payment_type: {
    free: 'Free',
    transfer: 'Transfer',
    cardpay: 'Online CardPay',
    tatrapay: 'Online TatraPay',
    comfortpay: 'Online ComfortPay',
    sms_recurring: 'SMS recurring',
    csob_cardpay: 'Online CSOB (card)',
    csob_googlepay: 'Online GooglePay',
    csob_mastercard_mob: 'Online Mastercard Mobile'
  },
  payment_status_type: {
    common: 'Common',
    first: 'First',
    recurring: 'Recurring'
  },
  payment_result_type: {
    success: 'Success',
    failed: 'Failed',
    unknown: 'Unknown'
  },
  order_address_type: {
    person: 'Person',
    company: 'Company'
  },
  history_type: {
    payment_created: 'Payment created',
    order_created: 'Order created',
    payment_gateway_redirect: 'Payment gateway redirect',
    payment_gateway_return: 'Payment gateway return',
    mail_sent: 'Mail sent',
    payment_abo_ready_to_export: 'Payment ready to export to ABO',
    payment_abo_exported: 'Payment to ABO exported',
    payment_abo_export_failed: 'ABO export failed',
    payment_abo_processed: 'Payment in ABO processed',
    payment_abo_not_processed: 'Payment in ABO not processed',
    payment_abo_periodik_ready_to_export: 'Payment ready to export to Periodik ABO',
    payment_abo_periodik_exported: 'Payment to Periodik ABO exported',
    payment_abo_periodik_export_failed: 'Periodik ABO export failed',
    payment_abo_periodik_processed: 'Payment in Periodik ABO processed',
    payment_abo_periodik_not_processed: 'Payment in Periodik ABO not processed',
    subscription_activated: 'Subscription activated',
    next_payment_set: 'Next payment set',
    sms_code_created: 'SMS code created',
    mo_sms_delivered: 'MO SMS delievered',
    mt_sms_created: 'MT SMS created',
    mt_sms_sent: 'MT SMS sent',
    mt_sms_sent_error: 'MT SMS sent ERROR',
    mt_sms_status_delivered: 'MT SMS status delivered',
    payment_cancelled: 'Payment cancelled',
    payment_result_set: 'Payment result',
    closed_subscription_mail_3_days: 'Notification sent after 3 days from the end of the subscription',
    closed_subscription_mail_next_day: 'Notification sent the day after the end of the subscription',
    next_recurring_payment_mail: 'Notification sent before next recurring payment'
  },
  subscription_type: {
    date_interval: 'Date interval',
    interval_in_days: 'Interval in days'
  },
  notify: {
    export_failed: 'Error encountered during export.'
  }
}

export default messages
