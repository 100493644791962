const messages = {
  list: {
    id: 'Id',
    name: 'Názov',
    external: 'Externá',
    external_group_mapping_tooltip: 'Ak je skupina uzamknutá, je priradená k externej systémovej skupine a nie je možné ju vymazať.'
  },
  name: 'Názov',
  capabilities: 'Oprávnenie skupiny'
}

export default messages
