import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_CATEGORY_PERMISSIONS

const routes = [
  {
    path: 'category/:id/edit',
    component: () => import('../../views/category/CategoryEditView'),
    name: 'category_edit',
    meta: {
      description: 'Category edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'category/new',
    component: () => import('../../views/category/CategoryNewView'),
    name: 'category_new',
    meta: {
      description: 'Category new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'category',
    component: () => import('../../views/category/CategoryListView'),
    name: 'category_list',
    meta: {
      description: 'Category list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
