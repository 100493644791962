const messages = {
  id: 'Id',
  name: 'Názov',
  site: 'Stránka',
  managers: 'Manažéri',
  search: 'Hľadaj',
  created_at: 'Vytvorené',
  modified_at: 'Upravené',
  created_by: 'Vytvoril'
}

export default messages
