const messages = {
  id: 'ID',
  login: 'Login',
  email: 'Email',
  yes: 'Áno',
  no: 'Nie',
  firstName: 'Meno',
  lastName: 'Priezvisko',
  name: 'Meno',
  save: 'Uložiť',
  localLogin: 'Lokálny',
  showLocalUsers: 'Lokálni užívatelia',
  settings: 'Nastavenia',
  details: 'Detaily',
  searchPermissionGroups: 'Skupina prístupov',
  userAuthorization: 'Autorizácia užívateľa',
  jobTitle: 'Pracovná pozícia',
  employmentType: 'Typ pracovného pomeru',
  defaultPermissionGroup: 'Skupiny oprávnení',
  fullscreen: 'Celá obrazovka',
  disabledPermissionTooltip: 'Oprávnenie je súčasťou skupiny <b>"{groupName}"</b> a nie je možné ho editovať.',
  filter: {
    search: 'Hľadať',
    reset_filter: 'Zrušiť filter'
  },
  error: {
    required_fields: 'Prosím, vyplňte všetky povinné polia',
    user_has_relations: 'Nedá sa zmazať, užívateľ má relácie.'
  },
  message: {
    updated_record: 'Záznam bol zmenený!',
    deleted_record: 'Záznam bol vymazaný!',
    password_reset: 'Heslo resetované!',
    resetLinkCopied: 'Reset linka skopírovaná!'
  }
}

export default messages
