import config from '@/config'
import NotificationServiceApi from '@/api/notificationService'
import NotifyService from '@/services/NotifyService'

const API_NAME = '/api/oneSignal/settings'

const callApi = async (store, callback) => {
  try {
    store.commit('TOGGLE_LOADING', null, { root: true })
    store.commit('setError', null)
    return callback()
  } catch (error) {
    console.error(error)
    store.commit('setError', error)
    NotifyService.setErrorMessage(error)
    return null
  } finally {
    store.commit('TOGGLE_LOADING', null, { root: true })
  }
}

const state = {
  list: [],
  detail: null,
  totalCount: 0,
  page: 1,
  error: null
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.results
    state.totalCount = responseData.count
    state.page = responseData.page
  },
  storeDetail (state, item) {
    state.detail = item
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, error) {
    state.error = error
  }
}

const actions = {
  async fetch (store) {
    return callApi(store, async () => {
      const { limit } = config.defaults.list
      const url = `api/onesignal/settings?limit=${limit}&page=${store.state.page}`
      const response = await NotificationServiceApi().get(url)
      store.commit('storeList', response.data)
      return response.data
    })
  },
  async fetchOne (store, siteUuid) {
    return callApi(store, async () => {
      const url = `api/onesignal/settings/${siteUuid}`
      const response = await NotificationServiceApi().get(url)
      response.data.siteUuid = siteUuid
      store.commit('storeDetail', response.data)
      return response.data
    })
  },
  async deleteRecord (store, { siteUuid }) {
    await callApi(store, async () => {
      const url = `${API_NAME}/${siteUuid}`
      await NotificationServiceApi().delete(url)
    })
  },
  async getRemainingNotification (store, siteUuid) {
    return callApi(store, async () => {
      const url = `${API_NAME}/${siteUuid}/limit`
      return NotificationServiceApi().get(url)
    })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
