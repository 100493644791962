const messages = {
  id: 'ID',
  created_at: 'Created at',
  created_at_from: 'Created at from',
  created_at_to: 'Created at to',
  created_by: 'Created by',
  modified_at: 'Modified at',
  modified_by: 'Modified by',
  type: 'Type',
  code: 'Code',
  digitalServices: 'Digital services',
  activated: 'Activated',
  expiration_at: 'Expiration at',
  activeSince: 'Expiration at from',
  activeUntil: 'Expiration at to',
  activeDays: 'Days',
  setting: {
    marketing: 'Marketing',
    admin: 'Admin'
  },
  admin: 'Admin',
  countOfCodes: 'Count of codes',
  list: 'List',
  accounts: 'Count',
  create_new: 'Create new',
  save: 'Save',
  systemInfo: 'System info',
  subscribers: 'Subscribers',
  email: 'Email',
  importTrend: {
    datvys: 'DATVYS',
    cisfak: 'CISFAK',
    cisdo: 'CISDO',
    pocet: 'POCET',
    prij: 'PRIJ',
    dateTo: 'Date to'
  },
  filter: {
    export: 'Export',
    search: 'Search',
    reset_filter: 'Reset filter'
  },
  button: {
    importTrend: 'Import TREND',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  error: {
    required_fields: 'Please fill all required fields',
    required_date_interval: 'Please fill date interval fields'
  },
  message: {
    created_record: 'Record was created!',
    updated_record: 'Record was updated!',
    deleted_record: 'Record was deleted!'
  }
}

export default messages
