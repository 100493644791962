import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_REDIRECT_PERMISSIONS

const routes = [
  {
    path: 'redirect/:id',
    component: () => import('../../views/redirect/vlm/RedirectViewVlm'),
    name: 'redirect_detail',
    meta: {
      description: 'Redirect detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'redirect/:id/edit',
    component: () => import('../../views/redirect/vlm/RedirectEditViewVlm'),
    name: 'redirect_edit',
    meta: {
      description: 'Redirect edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'redirect/new',
    component: () => import('../../views/redirect/vlm/RedirectNewViewVlm'),
    name: 'redirect_new',
    meta: {
      description: 'Redirect new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'redirect',
    component: () => import('../../views/redirect/vlm/RedirectListViewVlm'),
    name: 'redirect_list',
    meta: {
      description: 'Redirect list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
