const messages = {
  id: 'ID',
  created_at: 'Vytvorené',
  created_at_from: 'Vytvorené od',
  created_at_to: 'Vytvorené do',
  created_by: 'BE používateľ',
  modified_at: 'Zmenené',
  modified_by: 'Úpravil',
  type: 'Typ',
  order: 'Objednávka',
  payment: 'Platba',
  description: 'Popis',
  feUser: 'FE používateľ',
  filter: {
    export: 'Exportovať',
    search: 'Hľadať',
    reset_filter: 'Zrušiť filter'
  }
}

export default messages
