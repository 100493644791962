import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'shop/dashboard',
    component: () => import('../../../components/eshop/Dashboard'),
    name: 'eshopDashboard',
    meta: {
      description: 'Dashboard',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .ESHOP_DASHBOARD_PERMISSIONS
        .listRoute
    }
  }
]

export default routes
