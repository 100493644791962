import config from '../../config'
import coreApi from '../../api/core'
import RecipeModel from '../../model/RecipeModel'
import RecipeFilterModel from '../../model/RecipeFilter'
import RecipeFilterService from '../../services/recipe/recipeFilterService'
import RecipeService from '../../services/recipe/recipeService'
import _ from 'lodash'
import ParserOnLoad from '@/services/tinymce/ParserOnLoad'

const API_NAME = '/recipe'

const STEP_BREAK = '\n###BREAK###\n'

const state = {
  error: null,
  detail: {
    ...RecipeModel,
    instructions: RecipeModel.instructions.split(STEP_BREAK)
  },
  list: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(RecipeFilterModel),
  lastFiltered: null,
  view: 'minimal',
  units: [],
  difficulties: [],
  allergens: [],
  previousTabIndex: 0
}

const mutations = {
  storeDetail (state, responseData) {
    state.detail = responseData
    const instructions = ParserOnLoad.parseAll(state.detail.instructions, state.detail)
    state.detail.instructions = instructions.split(STEP_BREAK)
  },
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeUnits (state, responseData) {
    state.units = responseData
  },
  storeDifficulties (state, responseData) {
    state.difficulties = responseData
  },
  storeAllergens (state, responseData) {
    state.allergens = responseData
  },
  addMediaToGallery (state, media) {
    state.detail.expanded.gallery.push(media)
  },
  removeMediaFromGallery (state, mediaToRemove) {
    state.detail.expanded.gallery = state.detail.expanded.gallery.filter(media => media.id !== mediaToRemove.id)
  },
  updateGallery (state, updatedGallery) {
    state.detail.expanded.gallery = updatedGallery
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    const date = new Date()
    state.lastFiltered = date.getTime()
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(RecipeFilterModel)
    const date = new Date()
    state.lastFiltered = date.getTime()
    state.page = 1
  },
  setPage (state, page) {
    state.page = page
  },
  setView (state, view = 'minimal') {
    state.view = view
  },
  setPreviousTabIndex (state, index) {
    state.previousTabIndex = index
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page - 1) * config.defaults.list.limit
    const url = API_NAME + '?view=' + store.state.view + '&limit=' + config.defaults.list.limit +
      '&offset=' + offset + RecipeFilterService.buildFilterQuery(store.state.filter) +
      '&order[id]=desc'
    return coreApi().get(url)
      .then(response => {
        store.commit('storeList', response.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
        return Promise.resolve(response.data)
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchUnits (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    coreApi().get(API_NAME + 'Type/unit')
      .then(response => {
        store.commit('storeUnits', response.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchDifficulties (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    coreApi().get(API_NAME + 'Type/difficulty')
      .then(response => {
        store.commit('storeDifficulties', response.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchAllergens (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    coreApi().get(API_NAME + 'Type/allergen')
      .then(response => {
        store.commit('storeAllergens', response.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchOne (store, id) {
    return coreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  create (store, recipe) {
    return coreApi().post(API_NAME, JSON.stringify(RecipeService.prepareApiRequest(recipe)))
      .then(response => {
        if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
        throw error
      })
  },
  update (store, recipe) {
    return coreApi().put(API_NAME + '/' + recipe.id, JSON.stringify(RecipeService.prepareApiRequest(recipe)))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data)
        }
        throw error
      })
  },
  deleteRecord (store, record) {
    return coreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data)
        }
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  lastFiltered (state) {
    return state.lastFiltered
  },
  page (state) {
    return state.page
  },
  units (state) {
    return state.units
  },
  difficulties (state) {
    return state.difficulties
  },
  allergens (state) {
    return state.allergens
  },
  previousTabIndex (state) {
    return state.previousTabIndex
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
