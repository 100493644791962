import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.LIKE_DISLIKE_PERMISSIONS

const routes = [
  {
    path: 'likeDislike/new',
    component: () => import('../../views/poll/PollLikeDislikeNewView'),
    name: 'likeDislike_new',
    meta: {
      description: 'Like dislike new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'likeDislike/:id/edit',
    component: () => import('../../views/poll/PollLikeDislikeEditView'),
    name: 'likeDislike_edit',
    meta: {
      description: 'Like dislike edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'likeDislike/:id',
    component: () => import('../../views/poll/PollLikeDislikeView'),
    name: 'likeDislike_detail',
    meta: {
      description: 'Like dislike detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'likeDislike',
    component: () => import('../../views/poll/PollLikeDislikeListView'),
    name: 'likeDislike_list',
    meta: {
      description: 'Like dislike list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
