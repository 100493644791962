<template>
  <span>
    <div
      class="modal fade show"
      @click.self="clickSelf"
    >
      <div class="modal-dialog" :class="modalSize">
        <div class="modal-content">
          <div class="modal-header" v-if="disableHeader === false">
            <div :class="{'col-6': hasHeaderSlot, 'col-10': !hasHeaderSlot}">
              <h4 class="modal-title" v-html="title"></h4>
            </div>
            <div class="text-right" :class="{'col-6': hasHeaderSlot, 'col-2': !hasHeaderSlot}">
              <slot name="header"></slot>
              <button
                v-if="showCloseButton"
                type="button"
                class="close"
                @click="close"
              >
                ×
              </button>
            </div>
          </div>
          <div class="modal-footer" v-if="hasSubHeaderSlot">
            <slot name="sub-header"></slot>
          </div>
          <div class="modal-body form-horizontal">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer" v-if="hasFooterSlot">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </span>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String
    },
    modalSize: {
      type: String,
      default: 'modal-lg'
    },
    closeOnClickSelf: {
      type: Boolean,
      default: false
    },
    disableHeader: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots.header
    },
    hasSubHeaderSlot () {
      return !!this.$slots['sub-header']
    },
    hasFooterSlot () {
      return !!this.$slots.footer
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    clickSelf () {
      if (this.closeOnClickSelf) {
        this.$emit('close')
      }
    }
  },
  mounted () {
    document.body.style.overflowY = 'hidden'
  },
  destroyed () {
    document.body.style.overflowY = 'scroll'
  }
}
</script>

<style scoped lang="scss">
  .modal {
    display: block;
    padding-right: rem(15px);
  }
</style>
