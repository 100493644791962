var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'select-area',
    { 'form-group': !_vm.disableFormGroup },
    { 'select-area--has-danger': _vm.error },
    { 'select-area--compact-width': _vm.compactWidth },
    { 'select-area--compact-height': _vm.compactHeight }
  ]},[(!_vm.noLabel)?_c('label',{staticClass:"select-area__label",attrs:{"for":_vm.id}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n    "),(_vm.required)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.toolTip.length)?_c('Tooltip',{attrs:{"title":_vm.toolTip,"customInfoClass":"seo"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"select-area__select-wr"},[_c('select',{staticClass:"select-area__select",class:( _obj = {}, _obj[("has-status-" + _vm.status)] = _vm.status, _obj['select-area__select--enabled'] =  !_vm.disabled, _obj['select-area__select--error'] =  _vm.error, _obj ),attrs:{"id":_vm.id,"disabled":_vm.disabled,"data-test":_vm.dataAttr ? _vm.dataAttr : _vm.id},on:{"input":function($event){_vm.$emit('input', _vm.getConvertedValue($event.target.value))},"change":function($event){_vm.$emit('change', _vm.getConvertedValue($event.target.value))}}},[(_vm.noEmptyValue === false)?_c('option',{staticClass:"select-area__option",attrs:{"value":"0"}},[_vm._v(_vm._s(_vm.emptyValueTitle))]):_vm._e(),_vm._v(" "),_vm._l((_vm.options),function(option,index){return _c('option',{key:("item-" + index),staticClass:"select-area__option",class:{ 'select-area__option--heading': option.heading },attrs:{"disabled":option.disabled},domProps:{"value":_vm.getValueFromOption(option),"selected":_vm.isOptionSelected(option)}},[(Object.prototype.hasOwnProperty.call(option, 'lvl') && option.lvl === 1)?[_vm._v("\n              \n        ")]:_vm._e(),_vm._v(" "),(Object.prototype.hasOwnProperty.call(option, 'lvl') && option.lvl === 2)?[_vm._v("\n                      \n        ")]:_vm._e(),_vm._v("\n        "+_vm._s(_vm.getOptionTitle(option))+"\n      ")],2)})],2),_vm._v(" "),_c('SvgIconArrowDown',{staticClass:"select-area__arrow-icon"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }