const messages = {
  period_type: {
    none: 'Žiadne',
    week: 'Týždenná platba s automatickou obnovou',
    month: 'Mesačne s automatickou obnovou',
    three_months: '3 mesiace',
    six_months: '6 mesiacov',
    one_year: '12 mesiacov',
    two_years: '24 mesiacov',
    one_article: 'Jeden článok'
  },
  visibility_type: {
    public: 'Verejný',
    with_link: 'S odkazom'
  },
  top_product_position_type: {
    left: 'Vľavo',
    middle: 'V strede',
    right: 'Vpravo'
  },
  payment_type: {
    free: 'Bezplatne',
    transfer: 'Prevodom na účet',
    cardpay: 'Online CardPay (kartou)',
    tatrapay: 'Online TatraPay',
    comfortpay: 'Online ComfortPay (opakovane)',
    sms_recurring: 'SMS (opakovane)',
    csob_cardpay: 'Online CSOB (kartou)',
    csob_googlepay: 'Online GooglePay',
    csob_mastercard_mob: 'Online Mastercard Mobile'
  },
  payment_status_type: {
    common: 'Obyčajná',
    first: 'Prvá',
    recurring: 'Opakovaná'
  },
  payment_result_type: {
    success: 'Úspešná',
    failed: 'Neúspešná',
    unknown: 'Neznámy výsledok'
  },
  order_address_type: {
    person: 'Fyzická osoba',
    company: 'Firma'
  },
  history_type: {
    payment_created: 'Vytvorenie platby',
    order_created: 'Vytvorenie objednávky',
    payment_gateway_redirect: 'Presmerovanie na platobnú bránu',
    payment_gateway_return: 'Návrat z platobnej brány',
    mail_sent: 'Poslanie objednávkového mailu',
    payment_abo_ready_to_export: 'Objednávka pripravená na export do ABO',
    payment_abo_exported: 'Objednávka exportovaná do ABO',
    payment_abo_export_failed: 'Objednávka nebola exportovaná v ABO',
    payment_abo_processed: 'Objednávka spracovaná v ABO',
    payment_abo_not_processed: 'Objednávka nebola spracovaná v ABO',
    payment_abo_periodik_ready_to_export: 'Objednávka pripravená na export do Periodik ABO',
    payment_abo_periodik_exported: 'Objednávka exportovaná do Periodik ABO',
    payment_abo_periodik_export_failed: 'Objednávka nebola exportovaná v Periodik ABO',
    payment_abo_periodik_processed: 'Objednávka spracovaná v Periodik ABO',
    payment_abo_periodik_not_processed: 'Objednávka nebola spracovaná v Periodik ABO',
    subscription_activated: 'Predplatné aktivované',
    next_payment_set: 'Nastavenie nasledujúcej platby',
    sms_code_created: 'Vytvorenie SMS kódu',
    mo_sms_delivered: 'Prijatie MO SMS',
    mt_sms_created: 'Vytvorenie MT SMS',
    mt_sms_sent: 'Poslanie MT SMS',
    mt_sms_sent_error: 'Poslanie MT SMS s chybou',
    mt_sms_status_delivered: 'Prijatie MT SMS statusu',
    payment_cancelled: 'Zrušenie platby',
    payment_result_set: 'Výsledok platby',
    closed_subscription_mail_3_days: 'Odoslaná notifikácia po 3 dňoch od skončenia predplatného',
    closed_subscription_mail_next_day: 'Odoslaná notifikácia nasledujúci deň po skončení predplatného',
    next_recurring_payment_mail: 'Odoslaná notifikácia pred ďalšou platbou'
  },
  subscription_type: {
    date_interval: 'Platnosť kódu interval',
    interval_in_days: 'Platnosť kódu v dňoch'
  },
  notify: {
    export_failed: 'Nastala chyba pri generovaní exportu.'
  }
}

export default messages
