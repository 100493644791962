import { dateTimeNow } from '../../services/DateTimeService'

export default {
  id: null,
  articleId: '',
  articleTitle: '',
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  createdBy: '',
  modifiedBy: '',
  feUser: 0,
  feUserExpanded: {}
}
