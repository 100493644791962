const messages = {
  title: 'Weights settings',
  add: 'Add',
  save: 'Save',
  group: 'Group',
  name: 'Name',
  printQuality: 'Print quality',
  online: 'Online quality',
  subscriptions: 'Subscriptions',
  socialEngagement: 'Social Engagement',
  videoQuality: 'Video Quality',
  articlesQuality: 'Articles Quality',
  videoQuantity: 'Video Quantity',
  periodFor: 'Period for',
  defaultPeriod: 'Default',
  tableListing: {
    printQuality: 'Print quality',
    pageViewsNormalized: 'Page views (*normalized)',
    engagedPageViews: 'Engaged Page Views',
    subscriptions: 'Subscriptions',
    disqus: 'Disqus',
    fbShares: 'Facebook Shares',
    videoViews: 'Video Views',
    videoPlaythrough: 'Video Playthrough',
    articlesQuantity: 'Articles Quantity',
    videoQuantity: 'Video Quantity',
    avgDailyUsers: 'Average number of users (RUs)',
    avgTimeSpentPerUserArticle: 'Average Time Spent in Article',
    avgArticleCompletion: 'Average Article Completion Rate',
    avgTimeSpentPerUserGallery: 'Average Time Spent in Gallery',
    avgGalleryCompletion: 'Average Gallery Completion Rate',
    avgDailyUsersOrganic: 'Average daily organic traffic'
  }
}

export default messages
