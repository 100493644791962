import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ESHOP_SUBSCRIPTION_PERMISSIONS

const routes = [
  {
    path: 'shop/subscription/subscription/:id/edit',
    component: () => import('../../../components/eshop/SubscriptionEdit'),
    name: 'eshopSubscription_edit',
    meta: {
      description: 'Subscription edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'shop/subscription/subscription/new',
    component: () => import('../../../components/eshop/SubscriptionNew'),
    name: 'eshopSubscription_new',
    meta: {
      description: 'Subscription new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'shop/subscription/subscription',
    component: () => import('../../../components/eshop/SubscriptionList'),
    name: 'eshopSubscription_list',
    meta: {
      description: 'Subscription list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
