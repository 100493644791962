<template>
  <div
    class="dash-header-hamburger"
    :class="{'dash-header-hamburger--open': dashSidebarIsOpen}"
    @click="clickHandler"
  >
    <span class="dash-header-hamburger__hr"></span>
    <span class="dash-header-hamburger__hr"></span>
    <span class="dash-header-hamburger__hr"></span>
    <span class="dash-header-hamburger__text">{{ $t('dashboard.menu') }}</span>
  </div>
</template>

<script>
export default {
  name: 'DashHeaderHamburger',
  computed: {
    dashSidebarIsOpen () {
      return this.$store.state.dash.sidebar.isOpen
    }
  },
  methods: {
    clickHandler () {
      this.$store.commit('dash/setSidebarOpen', !this.dashSidebarIsOpen)
    }
  }
}
</script>

<style scoped lang="scss">
  .dash-header-hamburger {
    @include size(24px);
    display: grid;
    align-content: flex-start;
    cursor: pointer;
    &--open {
      @include margin(12px _ _);
    }
    @include bp(7) {
      display: none;
    }
    &__hr {
      @include size(100% 2px);
      @include margin(_ _ 3px);
      display: block;
      background: #d3dcec;
      transition: all 300ms;
      &:nth-child(1) {
        .dash-header-hamburger--open & {
          transform: rotate(45deg);
        }
      }
      &:nth-child(2) {
        .dash-header-hamburger--open & {
          display: none;
        }
      }
      &:nth-child(3) {
        .dash-header-hamburger--open & {
          @include relative(_ _ 5px);
          transform: rotate(-45deg);
        }
      }
    }
    &__text {
      @include font(500 9px "Roboto");
      color: #94a3be;
      .dash-header-hamburger--open & {
        @include margin(5px _ _);
      }
    }
  }
</style>
