import _ from 'lodash'
import CoreApi from '../../../api/core'
import BeUserModel from '../../../model/beUser/BeUser.js'
import config from '../../../config'
import BeUserFilterModel from '../../../model/beUser/BeUserFilter'
import BeUserFilterService from '../../../services/beUser/BeUserFilterService'

const API_NAME = '/user'

const state = {
  error: null,
  detail: BeUserModel,
  list: [],
  totalCount: 0,
  page: 1,
  filterQuery: '',
  filter: _.cloneDeep(BeUserFilterModel)
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setFilterQuery (state, filterQuery) {
    state.filterQuery = filterQuery
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const filter = store.state.filter
    if (filter.department === null) {
      filter.managedDepartments = store.rootGetters['department/getManagedByCurrentUser']
      store.commit('setFilter', filter)
    }
    const offset = (store.state.page * config.defaults.list.limit) - config.defaults.list.limit
    const url = API_NAME + '?limit=' + config.defaults.list.limit + '&offset=' + offset + '&order[id]=desc' +
      BeUserFilterService.buildFilterQuery(filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME + '/kpi/' + record.id, JSON.stringify(record.kpi))
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
          store.commit('storeDetail', response.data)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  detail (state, getters, rootState, rootGetters) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  filterQuery (state) {
    return state.filterQuery
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
