const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.articleId) {
    filterQuery += '&filter_contains[articleId]=' + filter.articleId
  }
  if (filter.feUser) {
    filterQuery += '&filter_eq[feUser]=' + filter.feUser
  }
  if (filter.feUser_email) {
    filterQuery += '&filter_custom[feUser.email]=' + filter.feUser_email
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
