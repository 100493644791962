import {
  PLATFORM_TOTAL,
  PLATFORM_PC,
  PLATFORM_MOBILE_PHONES,
  PLATFORM_MOBILE_TABLETS
} from '../../model/HistoricalDashboard'

const messages = {
  product: 'Produkt',
  from: 'Od',
  to: 'Do',
  real_users: 'RU',
  users: 'RU',
  page_views: 'PVs',
  stream_views: 'VVs',
  visits: 'Visits',
  sessions: 'Visits',
  time_spent_visit: 'ATS',
  avg_session_duration: 'ATS',
  page_views_visits: 'PV / Visits',
  page_views_sessions: 'PV / Visits',
  competitors: 'Vývoj trhu',
  channels: 'Zdroje návštev',
  devices: 'Zariadenia',
  products_perf: 'Produkcia obsahu',
  article_types: 'Typy článkov',
  departments: 'Oddelenia',
  platform: {
    [PLATFORM_TOTAL]: 'Všetky',
    [PLATFORM_PC]: 'Desktop',
    [PLATFORM_MOBILE_PHONES]: 'Mobil',
    [PLATFORM_MOBILE_TABLETS]: 'Tablet'
  },
  unit: {
    label: 'Typ údajov',
    day: 'dni',
    month: 'mesiace'
  }
}

export default messages
