<template>
  <div class="app" :class="`${this.$route.name}`">
    <!-- with the :key in router-view any change to the path will trigger a reload of the component with the new data -->
    <router-view :key="$route.fullPath"></router-view>
    <AppNotifications />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppNotifications from '@/AppNotifications.vue'
import EnvironmentService from '@/services/EnvironmentService'

window.DEBUG = {
  ...(window.DEBUG ?? {}),
  timeouts: false,
  intervals: false
}

const findCallerStack = () => {
  try {
    throw new Error('Caller:')
  } catch (error) {
    // matches this function, the caller and the parent
    const { stack } = error
    let allMatches = stack.match(/(\w+)@|at VueComponent\.(\w+) \(/g)
    if (!allMatches) {
      allMatches = stack.match(/(\w+)@|at UserManagerEvents\.(\w+) \(/g)
    }
    const caller = allMatches ? allMatches[0] : 'unknown'
    return { caller, stack }
  }
}

export default {
  name: 'App',
  components: { AppNotifications },
  metaInfo () {
    return {
      title: this.appHeadTitle,
      link: [
        { rel: 'icon', href: this.appFavicon }
      ]
    }
  },
  data () {
    return {
      appHeadTitle: '',
      appFavicon: ''
    }
  },
  computed: {
    ...mapState({
      articlePreviewUrl: state => state.config.articlePreviewUrl
    }),
    ...mapGetters(['vlm'])
  },
  methods: {
    clientDimensionChange () {
      const windowWidth = window.innerWidth
      this.$store.commit('site/setWidth', windowWidth)
      if (windowWidth <= 600) {
        this.notificationWidth = '100%'
      } else if (windowWidth > 1200) {
        this.notificationWidth = 800
      } else {
        this.notificationWidth = 600
      }
    },
    setAppHeadTitle () {
      const { name } = EnvironmentService.getEnvironmentVisualSettings()
      this.appHeadTitle = this.vlm
        ? `Aqua${name ? ' - ' + name : ''}`
        : `Eagle ADMIN${name ? ' - ' + name : ''}`
    },
    setAppFavicon () {
      this.appFavicon = this.vlm ? '/favicon/vlm/favicon.ico' : '/favicon/nmh/favicon.ico'
    },
    /**
     * See https://community.atlassian.com/t5/Jira-Service-Management/Dynamically-Embedding-Service-Desk-Widget/qaq-p/899686
     */
    appendJiraSupportScript () {
      const jiraSupportScript = document.createElement('script')
      const jiraSupportDataKey = this.vlm ? 'd9aea4e6-4dd6-4fbe-90ab-7cb144b14165' : 'dc480a2a-bfa2-4519-a9df-1b1b621cfb0c'

      jiraSupportScript.type = 'text/javascript'
      jiraSupportScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js'
      jiraSupportScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com')
      jiraSupportScript.setAttribute('data-key', jiraSupportDataKey)
      jiraSupportScript.setAttribute('data-jsd-embedded', '')
      jiraSupportScript.onload = () => {
        const domContentLoadedEvent = new Event('DOMContentLoaded', { bubbles: true, cancelable: true })
        window.document.dispatchEvent(domContentLoadedEvent)
      }

      document.body.appendChild(jiraSupportScript)
    },
    rewriteTimeoutFunctions () {
      const timeoutCount = () => {
        return `Number of active timeouts: ${Object.keys(window.timeoutList).length}.`
      }

      window.timeoutList = {}
      window.oldSetTimeout = window.setTimeout
      window.oldClearTimeout = window.clearTimeout

      window.setTimeout = function (callback, delay) {
        let timeoutId = 'unknown'
        let caller = 'unknown'
        const adjustedCallback = () => {
          if (window.DEBUG.timeouts) {
            console.debug(` *Running timeout function from Caller: ${caller}, \n    TimeoutId: ${timeoutId} \n    Function:\n      ${callback}`)
          }
          callback()
        }
        timeoutId = window.oldSetTimeout(adjustedCallback, delay)
        const callerStack = findCallerStack()
        caller = callerStack.caller
        const { stack } = callerStack
        if (window.DEBUG.timeouts) {
          console.debug(`+Setting timeout: ${timeoutId}. ${timeoutCount()} Caller: ${caller}.... See "window.timeoutList" for the whole stack`)
        }
        window.timeoutList[timeoutId] = {
          caller,
          createdAt: new Date().toLocaleString(),
          functionBody: callback.toString(),
          timeOutIn: `${delay / 1000}s`,
          stack
        }
        return timeoutId
      }
      window.clearTimeout = function (timeoutId) {
        delete window.timeoutList[timeoutId]
        window.oldClearTimeout(timeoutId)
        if (window.DEBUG.timeouts) {
          console.debug(` -Clearing timeout: ${timeoutId}. ${timeoutCount()}`)
        }
      }
      window.clearAllTimeouts = function () {
        Object.keys(window.timeoutList).forEach(timeoutId => {
          window.oldClearTimeout(timeoutId)
        })
        window.timeoutList = {}
      }
    },
    rewriteIntervalFunctions () {
      const intervalCount = () => {
        return `Number of active intervals: ${Object.keys(window.intervalList).length}.`
      }
      window.intervalList = {}
      window.oldSetInterval = window.setInterval
      window.oldClearInterval = window.clearInterval

      window.setInterval = function (callback, delay) {
        let intervalId = 'unknown'
        let caller = 'unknown'
        const adjustedCallback = () => {
          if (window.DEBUG.intervals) {
            const oidcFunctionBody = ['this._expiration', '.getEpochTime()']
            const isOidcFunction = callback.toString().includes(oidcFunctionBody[0]) && callback.toString().includes(oidcFunctionBody[1])
            if (isOidcFunction) {
              console.debug(`   *You can ignore this fore now: Running intreval oidc function that includes: ${oidcFunctionBody}`)
            } else {
              console.debug(` *Running interval function from Caller: ${caller}, \n    IntervalId: ${intervalId} \n    Function:\n      ${callback}`)
            }
          }
          callback()
        }
        intervalId = window.oldSetInterval(adjustedCallback, delay)
        const callerStack = findCallerStack()
        caller = callerStack.caller
        const { stack } = callerStack
        if (window.DEBUG.intervals) {
          console.debug(`+Setting interval: ${intervalId}. ${intervalCount()} Caller: ${caller}.... See "window.intervalList" for the whole stack`)
        }
        window.intervalList[intervalId] = {
          caller,
          createdAt: new Date().toLocaleString(),
          functionBody: callback.toString(),
          repeatsEvery: `${delay / 1000}s`,
          stack
        }
        return intervalId
      }
      window.clearInterval = function (intervalId) {
        delete window.intervalList[intervalId]
        window.oldClearInterval(intervalId)
        if (window.DEBUG.intervals) {
          console.debug(` -Clearing interval: ${intervalId}. ${intervalCount()}`)
        }
      }
      window.clearAllIntervals = function () {
        Object.keys(window.intervalList).forEach(intervalId => {
          window.oldClearInterval(intervalId)
        })
        window.intervalList = {}
      }
    }
  },
  mounted () {
    this.appendJiraSupportScript()
    this.setAppHeadTitle()
    this.setAppFavicon()
    this.clientDimensionChange()
    window.addEventListener('resize', this.clientDimensionChange)
    this.rewriteTimeoutFunctions()
    this.rewriteIntervalFunctions()
  },
  destroyed () {
    window.removeEventListener('resize', this.clientDimensionChange)
  }
}
</script>

<style scoped lang="scss">
 .app {
   background: #f5f6fa;
   min-height: 100vh;
 }
</style>
