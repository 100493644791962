import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.VIDEO_PLUS_TV_SHOW_PERMISSIONS

const routes = [
  {
    path: 'videoShow/:id/edit',
    component: () => import('../../views/video/VideoShowEditView'),
    name: 'videoShow_edit',
    meta: {
      description: 'Video show edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'videoShow/new',
    component: () => import('../../views/video/VideoShowNewView'),
    name: 'videoShow_new',
    meta: {
      description: 'Video show new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'videoShow',
    component: () => import('../../views/video/VideoShowListView'),
    name: 'videoShow_list',
    meta: {
      description: 'Video show list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
