import _ from 'lodash'

const prepareApiRequest = function (editedRecipe) {
  const recipe = _.cloneDeep(editedRecipe)
  if (recipe.expanded) {
    if (recipe.expanded.gallery) {
      recipe.gallery = recipe.expanded.gallery.map(record => record.id)
    }
    if (recipe.expanded.tags) {
      recipe.tags = recipe.expanded.tags.map(record => record.id)
    }
    if (recipe.expanded.image) {
      recipe.image = recipe.expanded.image.id
    }
    if (recipe.expanded.video) {
      recipe.video = recipe.expanded.video.id
    }
  }
  delete recipe.articleEmbeds

  return recipe
}

export default {
  prepareApiRequest
}
