import ssoApi from '@/api/sso'
import PermissionGroupModel from '@/model/PermissionGroupModel'

const API_NAME = '/groups'

const state = {
  error: null,
  detail: PermissionGroupModel,
  list: [],
  totalCount: 0,
  page: 1
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.groups
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  }
}

const actions = {
  fetch (store, { all } = { all: false }) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    store.commit('LOADING_ADD', null, { root: true })
    let url
    if (all) {
      url = `${API_NAME}?showAll=true`
    } else {
      url = `${API_NAME}?page=${store.state.page}`
    }
    ssoApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
      .finally(() => store.commit('LOADING_REMOVE', null, { root: true }))
  },
  async fetchOne (store, id) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    store.commit('LOADING_ADD', null, { root: true })
    return await ssoApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
        return response.data
      })
      .catch(error => {
        console.error(error)
        return []
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
      .finally(() => store.commit('LOADING_REMOVE', null, { root: true }))
  },
  async create (store, record) {
    return await ssoApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
          return response.data // 'id' of the group
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async update (store, record) {
    return await ssoApi().put(API_NAME + '/' + record.groupId, JSON.stringify(record))
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
          return null // server does not return anything
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async deleteRecord (store, record) {
    return await ssoApi().delete(API_NAME + '/' + record.groupId)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
