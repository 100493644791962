import EshopApi from '../../../api/eshop'

const API_NAME = '/abo-periodik/price'

const state = {
  all: []
}

const mutations = {
  storeAll (state, responseData) {
    state.all = responseData.data
  }
}

const actions = {
  async fetchAll ({ commit }) {
    if (state.all.length > 0) {
      return
    }
    await EshopApi().get(API_NAME)
      .then(res => {
        commit('storeAll', res.data)
      })
      .catch(error => console.log(error))
  }
}

const getters = {
  all (state) {
    return state.all
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
