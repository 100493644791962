const messages = {
  original: {
    body_text: 'Text původního článku',
    title: 'Titulek původního článku',
    first_paragraph: 'Perex původního článku',
    content_ratio: 'Shoda',
    tooltip: {
      content_ratio: 'V procentech vyjádřená shoda mezi originálním článkem a tím současným-přebraným. Podobnost se vypočítává pouze z titulku a textu, přičemž přepočet bere v úvahu pouze změny znaků (slov). V přepočtu mají titulek a text stejnou váhu. Do výpočtu shody nespadají aktivity jako přidání souvisejících článků, úpravy galerie či změny perexu. Procento shody rozpočítává spravedlivě výkonnostní metriky z převzatého článku mezi původního autora (originálu) a současného.',
      shared_users: 'Vepisovat pouze autory současného (přebraného) článku. Výkonnost z převzatého článku se automaticky přerozdělí mezi originálního autora a autora současného (přebraného) článku. A to v závislosti na procentuální shodě mezi originálním článkem a převzatým.'
    }
  },
  show: 'Zobrazit',
  status: {
    new: 'Nový',
    transferred: 'Přebrán'
  },
  transfer: 'Převzít',
  transfer_article: 'Převzít článek',
  transferred_articles: 'Přebrané články'
}

export default messages
