import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_ESHOP_FEATURE_PERMISSIONS

const routes = [
  {
    path: 'eshopFeature/:id/edit',
    component: () => import('../../../components/eshop/feature/FeatureEditView'),
    name: 'eshopFeature_edit',
    meta: {
      description: 'Eshop feature edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'eshopFeature',
    component: () => import('../../../components/eshop/feature/FeatureListView'),
    name: 'eshopFeature_list',
    meta: {
      description: 'Eshop feature list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
