const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.publicationStatus !== 0) {
    filterQuery += '&filter_custom[quiz.publicationStatus]=' + filter.publicationStatus
  }
  if (filter.category !== 0) {
    filterQuery += '&filter_eq[category]=' + filter.category
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
