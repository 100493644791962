import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.PRODUCT_STATISTICS_PERMISSIONS

const routes = [
  {
    path: 'historicalDashboard',
    component: () => import('../../views/dashboard/DashboardStatisticsHistoricalView'),
    name: 'historical_dashboard',
    meta: {
      description: 'Historical Dashboard',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
