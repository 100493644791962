const messages = {
  filter: {
    server: 'Server',
    originalUrl: 'Original URL',
    alias: 'Alias',
    validFrom: 'Valid from',
    validTo: 'Valid to'
  },
  id: 'Id',
  server: 'Server',
  originalUrl: 'Original URL',
  alias: 'Alias',
  aliasUrl: 'URL Alias',
  validFrom: 'Validity from',
  reservedUntil: 'Validity to',
  selectDate: 'Select date',
  status: 'Status',
  description: 'Description',
  redirectType: 'Redirect type',
  redirectTypes: {
    permanent: '301: Permanent',
    temporary: '302: Temporary'
  },
  type: 'Type',
  aliasType: 'Alias / Redirect URL',
  aliasTypes: {
    alias: 'Alias',
    redirect: 'Redirect'
  },
  statuses: {
    new: 'New',
    active: 'Active',
    inactive: 'Inactive',
    deleted: 'Deleted'
  },
  audits: {
    date: 'Date',
    user: 'User',
    description: 'Description of the action',
    create: 'Alias created',
    update: 'Alias edited',
    delete: 'Alias deleted'
  },
  errors: {
    nonExistingOriginalUrl: 'You must enter a valid URL (e.g. the URL of an existing article)',
    invalidOriginalUrl: 'You must enter a valid URL'
  }
}

export default messages
