import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'hpTopicOffer',
    component: () => import('../../views/hp/HpTopicOfferList'),
    name: 'hp_topic_offer_list',
    meta: {
      description: 'HP topic offer',
      requiresAuth: true,
      requiredPermissionModules: PermissionService.REQUIRED_PERMISSIONS.HOMEPAGE_TOPIC_OFFER_PERMISSIONS.listRoute
    }
  },
  {
    path: 'hpTopicOfferOld',
    component: () => import('../../components/hpTopicOffer/HpTopicOfferList'),
    name: 'hp_topic_offer_list_old',
    meta: {
      description: 'HP topic offer(old)',
      requiresAuth: true,
      requiredPermissionModules: PermissionService.REQUIRED_PERMISSIONS.HOMEPAGE_TOPIC_OFFER_PERMISSIONS.listRoute
    }
  }

]

export default routes
