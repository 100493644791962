import axios from 'axios'
import appConfig from '../config'

const dataHubApi = (api, apiKey) => axios.create({
  baseURL: `${appConfig.dataHub.apiUrl()}/${api}`,
  timeout: appConfig.dataHub.apiTimeout * 1000,
  headers: {
    'X-API-KEY': appConfig.dataHub.xApiKey[apiKey ?? api](),
    'Content-Type': 'application/json'
  }
})

const dataHubEditPerf = () => axios.create({
  baseURL: `${appConfig.dataHub.editPerf.apiUrl()}`,
  timeout: appConfig.dataHub.apiTimeout * 1000,
  headers: {
    'X-API-KEY': appConfig.dataHub.editPerf.xApiKey(),
    'Content-Type': 'application/json'
  }
})

const dataHubRecommendArticlesApi = (api) => axios.create({
  baseURL: `${appConfig.dataHub.recommend.apiUrl()}/${api}`,
  timeout: appConfig.dataHub.apiTimeout * 1000,
  headers: {
    'X-API-KEY': appConfig.dataHub.recommend.xApiKey(),
    'Content-Type': 'application/json'
  }
})

const dataHubNativeCampaignPerfPrediction = () => axios.create({
  baseURL: appConfig.dataHub.nativeCampaignPerfPrediction.apiUrl(),
  timeout: appConfig.dataHub.nativeCampaignPerfPrediction.apiTimeout * 1000,
  headers: {
    // TODO 'X-API-KEY': appConfig.dataHub.nativeCampaignPerfPrediction.xApiKey(),
    'Content-Type': 'application/json',
    access_token: appConfig.dataHub.nativeCampaignPerfPrediction.xApiKey()
  }
})

export const dataHubAbnApi = () => dataHubApi('abn')
export const dataHubEditPerfApi = () => dataHubEditPerf()
export const dataHubNativeCampaignPerfPredictionApi = () => dataHubNativeCampaignPerfPrediction()
export const dataHubRealtimeApi = () => dataHubApi('realtime')
export const dataHubHpctrApi = () => dataHubApi('hpctr')
export const dataHubVideoApi = () => dataHubApi('video')
export const dataHubRecommend = () => dataHubRecommendArticlesApi('recommend', 'recommend')
export const dataHubUniversalDashboardingApi = () => dataHubApi('universal-dashboarding', 'universalDashboarding')
