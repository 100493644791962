import moment from 'moment'

export const twoWeeksFromNowUtcFormat = () => {
  return moment().utc().add(14, 'days').format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
}

export const dateTimeNow = () => {
  return moment().utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
}

export const dateTimeToday = () => {
  return moment().startOf('day').toJSON()
}

export const dateTimeDayStart = (datetime) => {
  return moment(datetime).startOf('day').toJSON()
}

export const dateTimeDayEnd = (datetime) => {
  return moment(datetime).endOf('day').toJSON()
}

export const yearNow = () => {
  return moment().utc().format('YYYY')
}

export default {
  dateTimeMin: '1900-01-01T00:00:00.000000Z',
  dateTimeMax: '2100-01-01T00:00:00.000000Z'
}
