const messages = {
  list: {
    id: 'Id',
    name: 'Name',
    description: 'Description',
    enabled: 'Enabled',
    data: 'Data',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril'
  },
  filter: {
    id: 'Id',
    name: 'Name',
    search: 'Search'
  },
  id: 'Id',
  name: 'Name',
  description: 'Description',
  enabled: 'Enabled',
  data: 'Data'
}

export default messages
