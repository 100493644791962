import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.QUOTE_PERMISSIONS

const routes = [
  {
    path: 'quote/new',
    component: () => import('../../views/quote/QuoteNewView'),
    name: 'quote_new',
    meta: {
      description: 'Quote new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'quote/:id/edit',
    component: () => import('../../views/quote/QuoteEditView'),
    name: 'quote_edit',
    meta: {
      description: 'Quote edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'quote/:id',
    component: () => import('../../views/quote/QuoteView'),
    name: 'quote_detail',
    meta: {
      description: 'Quote detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'quote',
    component: () => import('../../views/quote/QuoteListView'),
    name: 'quote_list',
    meta: {
      description: 'Quote list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
