<template>
  <div></div>
</template>

<script>
import UserMixin from '../../components/mixins/User'

export default {
  name: 'UserLogoutView',
  mixins: [UserMixin],
  created () {
    this.logout()
  }
}
</script>
