import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_TASR_NEWS_CATEGORY_PERMISSIONS

const routes = [
  {
    path: 'tasrNewsCategory/:id/edit',
    component: () => import('../../components/tasr/TasrNewsCategoryEdit'),
    name: 'tasrNewsCategory_edit',
    meta: {
      description: 'Tasr News Category edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'tasrNewsCategory/new',
    component: () => import('../../components/tasr/TasrNewsCategoryNew'),
    name: 'tasrNewsCategory_new',
    meta: {
      description: 'Tasr News Category new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'tasrNewsCategory',
    component: () => import('../../components/tasr/TasrNewsCategoryList'),
    name: 'tasrNewsCategory_list',
    meta: {
      description: 'Tasr News Category list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
