import EdonApi from '../../api/edon'
import ScaleModel from '../../model/Scale'
import ScaleFilterService from '../../services/scale/ScaleFilterService'

const API_NAME = '/scale'
const LIMIT = 28

const state = {
  error: null,
  detail: ScaleModel,
  list: [],
  all: [],
  totalCount: 0,
  page: 1,
  filter: {
    id: '',
    name: ''
  }
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * LIMIT) - LIMIT
    const order = '&order[siteName]=asc&order[scaleGroup]=asc&order[name]=asc'
    const url = API_NAME + '?limit=' + LIMIT + '&offset=' + offset + order +
      ScaleFilterService.buildFilterQuery(store.state.filter)
    EdonApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchOne (store, id) {
    await EdonApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async update (store, record) {
    return await EdonApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  getById (state) {
    return id => state.all.find(scale => {
      if (parseInt(id) === parseInt(scale.id)) {
        return scale
      }
    })
  },
  filter (state) {
    return state.filter
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  detail (state) {
    return state.detail
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
