const messages = {
  list: {
    id: 'Id',
    title: 'Titulok',
    heading: 'Nadpis na webe',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril'
  },
  filter: {
    title: 'Titulok',
    heading: 'Nadpis na webe',
    id: 'Id',
    bodytext: 'Text',
    search: 'Hľadať'
  },
  title: 'Titulok',
  title_placeholder: 'Napište nadpis infoboxu',
  image: 'Fotka k infoboxu',
  heading: 'Nadpis na webe',
  heading_placeholder: 'Napište nadpis na webu',
  published_since: 'Dátum a čas zobrazenia na webe',
  published_since_placeholder: 'Vyberte dátum publikovania na webe',
  published_until: 'Publikované do',
  published_until_placeholder: 'Vyberte dátum',
  bodytext: 'Obsah infoboxu',
  size_type: 'Veľkosť',
  normal: 'Normálna',
  big: 'Veľká'
}

export default messages
