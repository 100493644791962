import { dataHubUniversalDashboardingApi } from '@/api/datahub'
import KpiNmhFilter from '@/model/KpiNmhDataHubFilter'

const API_NAME = '/kpi'

const state = {
  filter: KpiNmhFilter,
  list: [],
  tree: [],
  settings: {
    list: []
  }
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData
  },
  storeTree (state, responseData) {
    state.tree = responseData
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  storeSettingsList (state, responseData) {
    state.settings.list = responseData
  },
  storeCompetitionList (state, responseData) {
    state.competitionList = responseData
  }
}

const actions = {
  async fetchTree (store) {
    try {
      const response = await dataHubUniversalDashboardingApi().get(`${API_NAME}/tree`)
      store.commit('storeTree', response.data.data)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async fetch (store) {
    try {
      const { startDate, endDate, brands } = store.state.filter
      const response = await dataHubUniversalDashboardingApi().post(`${API_NAME}/data`, {
        startDate: `${startDate}-01`,
        endDate: `${endDate}-01`,
        brands
      })
      store.commit('storeList', response.data.data)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async fetchSettings (store) {
    try {
      const { startDate, endDate, brands } = store.state.filter
      const response = await dataHubUniversalDashboardingApi().post(`${API_NAME}/budget`, {
        startDate: `${startDate}-01`,
        endDate: `${endDate}-01`,
        brands
      })
      store.commit('storeSettingsList', response.data.data)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async fetchCompetition (store) {
    try {
      const { startDate, endDate, brands } = store.state.filter
      const response = await dataHubUniversalDashboardingApi().post(`${API_NAME}/competition`, {
        startDate: `${startDate}-01`,
        endDate: `${endDate}-01`,
        brands
      })
      store.commit('storeCompetitionList', response.data.data)
      return response.data
    } catch (error) {
      console.error(error)
    }
  }
}

const getters = {
  filter (state) {
    return state.filter
  },
  list (state) {
    return state.list
  },
  tree (state) {
    return state.tree
  },
  settingsList (state) {
    return state.settings.list
  },
  competitionList (state) {
    return state.competitionList
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
