import { getOrInitializeDataLayer, hasScript, loadScript } from './utils'

import { isEqual } from 'lodash'
import { GTM_BRAND_INFO, GTM_PARENT_BRAND_INFO } from '@/plugins/gtm/config'

/**
 * The GTM Support main class.
 */
export class GtmSupport {
  constructor (options) {
    this.id = options.id
    this.options = {
      enabled: true,
      loadScript: true,
      defer: false,
      compatibility: false,
      vueRouter: null,
      excludedRoutes: [],
      ...options
    }

    delete this.options.id
    this.scriptElements = []
  }

  isInBrowserContext () {
    return typeof window !== 'undefined'
  }

  enabled () {
    return this.options.enabled ?? true
  }

  enable (enabled = true, source) {
    this.options.enabled = enabled

    if (this.isInBrowserContext() && enabled && !hasScript(source) && this.options.loadScript) {
      const scriptElement = loadScript(this.id, { ...this.options })
      this.scriptElements.push(scriptElement)
    }
  }

  dataLayer () {
    if (this.isInBrowserContext() && this.options.enabled) {
      return getOrInitializeDataLayer(window)
    }
    return false
  }

  trackView (pageType, articleBrand = 'all', eventType = 'virtual_pageview', ...rest) {
    const trigger = this.isInBrowserContext() && (this.options.enabled ?? false)

    if (trigger) {
      const dataLayer = getOrInitializeDataLayer(window)
      const event = {
        page_info: {
          page_type: pageType || this.options.vueRouter.currentRoute.name,
          article_brand: articleBrand
        },
        brand_info: {
          brand: GTM_BRAND_INFO,
          parent_brand: GTM_PARENT_BRAND_INFO
        },
        ...rest
      }
      eventType && (event.event = eventType)
      dataLayer.push(event)
    }
  }

  trackEventRouteHook (routeTo, routeFrom) {
    if (this.options.excludedRoutes.includes(routeTo)) {
      return
    }
    const event = routeFrom === null ? null : undefined
    this.trackView(routeTo, 'all', event)
  }

  trackUser ({ email, firstName, lastName }) {
    const trigger = this.isInBrowserContext() && (this.options.enabled ?? false)
    const newUser = {
      user_info: {
        user_email: email,
        user_name: `${firstName}.${lastName}`.toLowerCase()
      }
    }
    const dataLayer = getOrInitializeDataLayer(window)
    const userExists = dataLayer.some(user => isEqual(user, newUser))
    if (!trigger || userExists) {
      return
    }

    dataLayer.push(newUser)
  }

  push (data) {
    const trigger = this.isInBrowserContext() && (this.options.enabled ?? false)

    if (trigger) {
      const dataLayer = getOrInitializeDataLayer(window)
      dataLayer.push(data)
    }
  }
}
