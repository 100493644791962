import { dateTimeNow } from '../services/DateTimeService'

export default {
  id: null,
  title: '',
  slug: '',
  description: '',
  image: null,
  printTitle: null,
  publicationDate: dateTimeNow()
}
