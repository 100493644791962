import EshopApi from '../../../api/eshop'
import AboCountryModel from '../../../model/feUser/AboCountry'

const API_NAME = '/abo/country'

const state = {
  error: null,
  detail: AboCountryModel,
  list: [],
  totalCount: 0,
  page: 1,
  filterQuery: '',
  filter: {}
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setFilterQuery (state, filterQuery) {
    state.filterQuery = filterQuery
  }
}

const actions = {
  async fetch ({ commit, state }, query) {
    await EshopApi().get(API_NAME + '?limit=150&offset=0&filter_contains[name]=' + query)
      .then(res => {
        commit('storeList', res.data)
      })
      .catch(error => console.log(error))
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  filterQuery (state) {
    return state.filterQuery
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
