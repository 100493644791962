import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_DEPARTMENT_PERMISSIONS

const routes = [
  {
    path: 'department/:id/edit',
    component: () => import('../../components/department/DepartmentEdit'),
    name: 'department_edit',
    meta: {
      description: 'Department edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'department/new',
    component: () => import('../../components/department/DepartmentNew'),
    name: 'department_new',
    meta: {
      description: 'Department new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'department',
    component: () => import('../../components/department/DepartmentList'),
    name: 'department_list',
    meta: {
      description: 'Department list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
