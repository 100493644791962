import Config from '@/config/index'
import { NMH_PERMISSIONS, VLM_PERMISSIONS } from '@/model/ValueObject/ComponentRequiredPermissions'

/**
 * Check if any of the required permission modules match the user's permissions.
 *
 * @param {[[module_code, capability]]} requiredPermissionModuleArray
 * @param {{ module_code: { capability: [ site_name] } }} userPermissionModules
 * @param {site_name} siteName
 * @returns {boolean}
 */
const checkRequiredPermission = (requiredPermissionModuleArray, userPermissionModules, siteName = null) => {
  if (!requiredPermissionModuleArray || !userPermissionModules) {
    return false
  }

  if (requiredPermissionModuleArray.length === 0) {
    return true
  }

  return requiredPermissionModuleArray.some(requiredPermissionModule => {
    const [requiredModule, requiredPermission] = requiredPermissionModule
    const userPermissionModule = userPermissionModules[requiredModule]

    if (siteName) {
      // need to check per-site - i.e. checking if user has permissions for the specific siteName
      const userSiteNamesForPermissions = userPermissionModule?.[requiredPermission]
      return !!userSiteNamesForPermissions?.includes(siteName)
    }

    return userPermissionModule?.[requiredPermission]
  })
}

const hasRouteRequiredPermission = (route, userPermissionModules) => {
  if (!route.meta.requiresAuth || !route.meta.requiredPermissionModules) {
    return true
  }

  const requiredPermissions = route.meta.requiredPermissionModules
  return checkRequiredPermission(requiredPermissions, userPermissionModules)
}

const REQUIRED_PERMISSIONS = Config.isVlm()
  ? VLM_PERMISSIONS : NMH_PERMISSIONS

export default {
  hasRouteRequiredPermission,
  checkRequiredPermission,
  REQUIRED_PERMISSIONS
}
