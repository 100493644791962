import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.PRINT_ARTICLE_PERMISSIONS

const routes = [
  {
    path: 'printArticle/:id/edit',
    component: () => import('../../components/print/PrintArticleEdit'),
    name: 'printArticle_edit',
    meta: {
      description: 'Print Article edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'printArticle/new',
    component: () => import('../../components/print/PrintArticleNew'),
    name: 'printArticle_new',
    meta: {
      description: 'Print Article new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'printArticle',
    component: () => import('../../components/print/PrintArticleList'),
    name: 'printArticle_list',
    meta: {
      description: 'Print Article',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
