export default {
  documentId: '',
  title: '',
  orderDate: '',
  sharedUsers: {
    userId: 0,
    userName: ''
  },
  type: '',
  quality: ''
}
