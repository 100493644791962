import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'videoManagement',
    component: () => import('../../views/video/VideoManagementView'),
    name: 'videoManagement',
    meta: {
      description: 'Video management',
      requiresAuth: true,
      requiredPermissionModules:
        PermissionService.REQUIRED_PERMISSIONS.VIDEO_MANAGEMENT_PERMISSIONS.listRoute
    }
  }
]

export default routes
