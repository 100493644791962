import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.BREAKING_NEWS_PERMISSIONS

const routes = [
  {
    path: 'breakingnews/new',
    component: () => import('../../views/breakingnews/BreakingNewsNewView'),
    name: 'breakingnews_new',
    meta: {
      description: 'BreakingNews new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'breakingnews/:id/edit',
    component: () => import('../../views/breakingnews/BreakingNewsEditView'),
    name: 'breakingnews_edit',
    meta: {
      description: 'BreakingNews edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'breakingnews',
    component: () => import('../../views/breakingnews/BreakingNewsListView'),
    name: 'breakingnews_list',
    meta: {
      description: 'BreakingNews list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
