import Store from '@/store'
import { router } from '@/main'

export const PREDEFINED_DAM_USERS = {
  'c4703282-494f-4191-a5d8-b5d935bbbcf4': 'TASR'
  // 'nmh.cumulus': 'CUMULUS',
  // 'nmh.naxos': 'NAXOS'
}

export const PREDEFINED_DAM_PDF_USERS = {
  // 'nmh.tasr.pdf': 'TASR',
  // 'nmh.cumulus.pdf': 'CUMULUS',
  // 'nmh.naxos.pdf': 'NAXOS'
}

// STATIC CROPS
export const STATIC_CROP_320_240 = '320x240'

// ROUTES
export const GALLERY_LIST_ROUTE = 'gallery_list'
export const GALLERY_EDIT_ROUTE = 'gallery_edit'

const findSmallestCropSelectedByAuthor = mediaRatioUsages => {
  const foundRatioUsage = mediaRatioUsages.find(ratioUsage => ratioUsage.selectedByAuthor) ?? mediaRatioUsages[0]
  const availableCrops = foundRatioUsage.availableDimensionsCsv.split(';')
  return availableCrops.reduce((previous, current) => {
    const prevWidth = +(previous.split('x')[0])
    const currWidth = +(current.split('x')[0])
    return prevWidth < currWidth ? previous : current
  })
}

const findFirstDefaultCrop = ({ dynamic } = { dynamic: false }) => {
  const damConfigInfo = Store.getters['dam/configInfo']
  return damConfigInfo.auxiliaryCropsPrefixes.find(prefix => prefix.includes('dynamic') === Boolean(dynamic))
}

const getCdnRelativePath = (asset, { dynamic } = { dynamic: false }) => {
  const foundCrop = findFirstDefaultCrop({ dynamic })
  const { cdnRelativePath } = asset.auxiliaryCrops.find(crop => crop.cdnRelativePath.startsWith(foundCrop))
  return cdnRelativePath
}

const getDamImagePathForAsset = (asset, { dynamic } = { dynamic: true }) => {
  return getDamImagePath(getCdnRelativePath(asset, { dynamic }))
}

const getDamImagePathForMedia = (media, mediaUsageType, staticCrop = null) => {
  let thumbnailCrop
  if (mediaUsageType) {
    thumbnailCrop = Store.getters['media/getThumbnailCrops'](mediaUsageType)?.[0]
  }

  let crop
  if (thumbnailCrop) {
    crop = thumbnailCrop
  } else if (media.mediaRatioUsages?.length > 0) {
    crop = findSmallestCropSelectedByAuthor(media.mediaRatioUsages)
  } else {
    crop = findFirstDefaultCrop()
  }

  if (
    !Store.getters.vlm &&
    (
      router.currentRoute.name === GALLERY_LIST_ROUTE ||
      router.currentRoute.name === GALLERY_EDIT_ROUTE ||
      staticCrop
    )
  ) {
    crop = STATIC_CROP_320_240 // https://newsandmedia.atlassian.net/browse/CMS-5635?focusedCommentId=92459
  }

  let fileName = null
  if (typeof media === 'string') {
    fileName = media
  } else {
    const { filename } = media.damMediaEmbed
    fileName = filename
  }
  const cdnRelativePath = fileName ? `${crop}/${fileName}` : media.damMediaEmbed.cdnRelativePath

  return getDamImagePath(cdnRelativePath)
}

const getDamImagePath = (cdnRelativePath) => {
  const { imageCdnUri } = Store.getters['dam/configInfo']
  const imagePath = `${imageCdnUri}/${cdnRelativePath}`

  // this is to ensure the browser always shows an updated non-cached image
  //   the value in vuex is not updated all the time
  //   (e.g. it is optimized to not be updated while paginating in DAM list)
  const ignoreBrowserCache = `?ignoreBrowserCache=${Store.getters['dam/imageCache']}`
  return `${imagePath}${ignoreBrowserCache}`
}

export default {
  getCdnRelativePath,
  getDamImagePathForAsset,
  getDamImagePathForMedia
}
