const messages = {
  list: {
    id: 'Id',
    title: 'Titulek',
    enabled: 'Povoleno',
    video_count: 'Počet videí',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil'
  },
  filter: {
    id: 'Id',
    title: 'Titulek',
    search: 'Hledat'
  },
  id: 'Id',
  title: 'Titulek',
  slug: 'Slug',
  enabled: 'Povoleno',
  created_at: 'Datum vytvoření',
  modified_at: 'Datum poslední změny',
  created_by: 'Vytvořil',
  image: 'Obrázek'
}

export default messages
