import _ from 'lodash'
import CoreApi from '@/api/core'
import BreakingNewsModel from '@/model/BreakingNewsModel'
import BreakingNewsFilterService from '@/services/breakingNews/BreakingNewsFilterService'
import Config from '@/config'
import BreakingNewsFilter from '@/model/BreakingNewsFilter'

const API_NAME = '/breakingnews'

const state = {
  error: null,
  list: [],
  detail: BreakingNewsModel,
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(BreakingNewsFilter)
}

const mutations = {
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(BreakingNewsFilter)
  },
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * Config.defaults.list.limit) - Config.defaults.list.limit
    const url = API_NAME +
      '?limit=' + Config.defaults.list.limit +
      '&offset=' + offset +
      '&order[id]=desc' + BreakingNewsFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.error(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.error(error))
  },
  async create (store, record) {
    return await CoreApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 201 || response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
          return response.data
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async publish (store, record) {
    return await CoreApi().patch(API_NAME + '/' + record + '/publish')
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async unpublish (store, record) {
    return await CoreApi().patch(API_NAME + '/' + record + '/unpublish')
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  error (state) {
    return state.error
  },
  list (state) {
    return state.list
  },
  detail (state) {
    return state.detail
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  filter (state) {
    return state.filter
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
