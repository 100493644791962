import store from '@/store/index'

const urlParser = document.createElement('a')
const moment = require('moment')
const isVlm = () => store.getters.vlm

export function domain (url) {
  urlParser.href = url
  return urlParser.hostname
}

export function count (arr) {
  return arr.length
}

export function toString (number) {
  return number || number === 0 ? number.toString() : number
}

export function prettyDateTime (date) {
  return date ? moment(date).format('DD.MM.YYYY HH:mm') : ''
}

export function prettyDate (date) {
  return date ? moment(date).format('DD.MM.YYYY') : ''
}

export function prettyTime (date) {
  return date ? moment(date).format('HH:mm') : ''
}

export function formatMilliseconds (milliseconds) {
  let sec = Math.floor(milliseconds / 1000)
  const hrs = Math.floor(sec / 3600)
  sec -= hrs * 3600
  let min = Math.floor(sec / 60)
  sec -= min * 60

  sec = '' + sec
  sec = ('00' + sec).substring(sec.length)

  min = '' + min
  min = ('00' + min).substring(min.length)
  if (hrs > 0) {
    return hrs + ':' + min + ':' + sec
  } else {
    return min + ':' + sec
  }
}

export function prettyJson (jsonData) {
  return JSON.stringify(jsonData, null, 2)
}

export function pluralize (time, label) {
  if (time === 1) {
    return time + label
  }

  return time + label + 's'
}

export function capitalize (text) {
  return text ? `${text.charAt(0).toUpperCase()}${text.slice(1)}` : text
}

export function truncate (str, length) {
  const ending = '...'
  if (length == null) {
    length = 70
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  } else {
    return str
  }
}

export function formatPercentage (number) {
  const convertedNumber = number === undefined ? 0 : +number
  return `${formatNumber(convertedNumber * 100)} %`
}

export function formatCurrency (number) {
  const currency = isVlm() ? 'Kč' : '€'
  return `${formatNumber((number ? +number : 0))} ${currency}`
}

export function formatSeconds (seconds, format = 'HH:mm:ss') {
  if (seconds) {
    return moment.utc(seconds * 1000).format(format)
  }
  return '0:00'
}

export function formatNumber (number, minFraction = 0, maxFraction = 2) {
  if (!number && number !== 0) {
    return ''
  }
  return Number(number).toLocaleString('sk-SK', {
    maximumFractionDigits: maxFraction,
    minimumFractionDigits: minFraction
  })
}

export const isURL = (string) => {
  const regex = /^(https?:\/\/)?([\da-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/

  if (!regex.test(string)) {
    return false
  }

  const isValidURL = (str) => {
    try {
      return Boolean(new URL(str))
    } catch (_) {
      return false
    }
  }

  return isValidURL(string.startsWith('http') ? string : `http://${string}`)
}

export const scriptExists = (url) => {
  return document.querySelector(`script[src="${url}"]`)
}
