const messages = {
  title: 'Nastavení vah',
  add: 'Přidat zobrazení',
  save: 'Uložit',
  group: 'Skupina',
  name: 'Název',
  printQuality: 'Kvalita Printu',
  online: 'Kvalita Online',
  subscriptions: 'subscriptions',
  socialEngagement: 'socialEngagement',
  videoQuality: 'Kvalita Videa',
  articlesQuality: 'articlesQuality',
  videoQuantity: 'Kvantita Videa',
  periodFor: 'Období za',
  defaultPeriod: 'Výchozí',
  tableListing: {
    printQuality: 'Kvalita Printu',
    pageViewsNormalized: 'Zobrazení PV (*normalizované)',
    engagedPageViews: 'Engaged Page Views',
    subscriptions: 'Subscriptions',
    disqus: 'Disqus',
    fbShares: 'Facebook Shares',
    videoViews: 'Video Views',
    videoPlaythrough: 'Video Playthrough',
    articlesQuantity: 'Množství článků',
    videoQuantity: 'Množství videa',
    avgDailyUsers: 'Průměrný počet uživatelů (RUs)',
    avgTimeSpentPerUserArticle: 'Průměrná doba strávená v článku',
    avgArticleCompletion: 'Průměrná míra dokončení článku',
    avgTimeSpentPerUserGallery: 'Průměrná doba strávená v galerii',
    avgGalleryCompletion: 'Průměrná míra prohlédnutí galerie'
  }
}

export default messages
