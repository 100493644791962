import moment from 'moment'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
    return filterQuery
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.site) {
    filterQuery += '&filter_eq[site]=' + filter.site
  }
  if (filter.tags && filter.tags.length) {
    filterQuery += '&filter_memberOf[tags]=' + filter.tags.map(tag => tag.id)
  }
  if (filter.category) {
    filterQuery += '&filter_contains[category]=' + filter.category
  }
  if (filter.dateFrom) {
    const date = moment(filter.dateFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[createdAt]=' + date
  }
  if (filter.dateTo) {
    const date = moment(filter.dateTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[createdAt]=' + date
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
