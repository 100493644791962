const messages = {
  id: 'ID',
  created_at: 'Vytvořeno',
  created_at_from: 'Vytvořeno od',
  created_at_to: 'Vytvořeno do',
  created_by: 'Vytvořil',
  modified_at: 'Změněno',
  modified_by: 'Úpravil',
  type: 'Typ',
  code: 'Kód',
  digitalServices: 'Digitální služby',
  activated: 'Aktivován',
  expiration_at: 'Datum expirace',
  activeSince: 'Datum platnosti od',
  activeUntil: 'Datum platnosti do',
  activeDays: 'Počet dní',
  setting: {
    marketing: 'Marketing',
    admin: 'Admin'
  },
  admin: 'Admin',
  countOfCodes: 'Počet kódů',
  list: 'Seznam',
  accounts: 'Počet přístupů',
  create_new: 'Vytvoř nový',
  save: 'Uložit',
  systemInfo: 'Systémové informace',
  subscribers: 'Předplatitelé',
  email: 'Email',
  importTrend: {
    datvys: 'DATVYS',
    cisfak: 'CISFAK',
    cisdo: 'CISDO',
    pocet: 'POCET',
    prij: 'PRIJ',
    dateTo: 'Datum do'
  },
  filter: {
    export: 'Exportovat',
    search: 'Hledat',
    reset_filter: 'Zrušit filtr'
  },
  button: {
    importTrend: 'Import TREND',
    cancel: 'Zrušit',
    confirm: 'Potrvdit'
  },
  error: {
    required_fields: 'Prosím, vyplňte všechna povinná pole',
    required_date_interval: 'Prosím, vyplňte datum platnosti od-do'
  },
  message: {
    created_record: 'Záznam byl vytvořen!',
    updated_record: 'Záznam byl změněn!',
    deleted_record: 'Záznam byl vymazán!'
  }
}

export default messages
