const messages = {
  list: {
    id: 'Id',
    category: 'Kategorie',
    service: 'Servis',
    headline: 'Titulek',
    author: 'Autor',
    status: 'Status',
    sites: 'Stránky',
    contentCreated: 'Čas zprávy',
    show: 'Zobrazit'
  },
  filter: {
    category: 'Kategorie',
    service: 'Servis',
    headline: 'Titulek',
    author: 'Autor',
    status: 'Status',
    search: 'Hledat',
    created_at_from: 'Čas zprávy od',
    created_at_to: 'Čas zprávy do'
  },
  id: 'Id',
  category: 'Kategorie',
  service: 'Servis',
  headline: 'Titulek',
  slugline: 'Klíčová slova',
  author: 'TASR autor',
  status: 'Status',
  raw: 'Raw',
  source: 'Zdroj',
  contentCreated: 'Čas zprávy',
  content: 'Obsah',
  create_article: 'Vytvoř článek ze zprávy',
  edit_article: 'Edituj článek',
  article_created: 'Vytvořený koncept z TASR zprávy',
  article_created_error: 'Chyba! Problem s vytvořením TASR článku',
  article_not_found: 'Article not found',
  created_articles: 'Vytvořené články ze zprávy'
}

export default messages
