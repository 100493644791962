import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'shop/history',
    component: () => import('../../../components/eshop/HistoryList'),
    name: 'eshopHistory_list',
    meta: {
      description: 'History list',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .ESHOP_HISTORY_PERMISSIONS
        .listRoute
    }
  }
]

export default routes
