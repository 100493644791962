/**
 * STATUS: 409; Returned when record cannot be deleted because it is used somewhere else.
 */
export const CANNOT_DELETE_IN_USE = 'error_cannot_delete_in_use'

export const ARTICLE_ERRORS = {
  error_article_in_draft_exists: 'error_article_in_draft_exists',
  error_article_in_review_exists: 'error_article_in_review_exists',
  error_article_in_ready_exists: 'error_article_in_ready_exists',
  entity_has_redirect: 'entity_has_redirect'
}

export const isArticleError = error => {
  const errorCode = typeof error === 'string' ? error : error?.response?.data?.error
  return Object.keys(ARTICLE_ERRORS).includes(errorCode)
}
