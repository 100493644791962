import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_RUBRIC_PERMISSIONS

const routes = [
  {
    path: 'rubric/:id/edit',
    component: () => import('../../views/rubric/RubricEditView'),
    name: 'rubric_edit',
    meta: {
      description: 'Rubric edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'rubric/new',
    component: () => import('../../views/rubric/RubricNewView'),
    name: 'rubric_new',
    meta: {
      description: 'Rubric new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'rubric',
    component: () => import('../../views/rubric/RubricListView'),
    name: 'rubric_list',
    meta: {
      description: 'Rubric list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
