const messages = {
  id: 'Id',
  name: 'Name',
  site: 'Site',
  managers: 'Managers',
  search: 'Search',
  created_at: 'Created at',
  modified_at: 'Modified at',
  created_by: 'Created by'
}

export default messages
