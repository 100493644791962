const messages = {
  date: 'Date',
  date_from: 'Since',
  date_to: 'Until',
  budget: 'Budget KPI´s',
  nmh_amb: 'NMH amb. KPI´s',
  pageViews: 'PV',
  realUsers: 'RU',
  videoViews: 'VV',
  realityVsKpi: 'reality vs KPI',
  valuesOfCompetition: 'Values ​​of competition',
  sourceGemius: 'Source: Gemius',
  classicPageViews: '(Classic PVs)',
  new: {
    pageViews: {
      tooltipInfo: '<div style="width: 400px">*normalized PV - recalculation of PV according to the difference<br /> monetization potential between <br /> article and gallery PV, in the ratio: 1 article PV = 14 gallery PV</div >',
      header: 'Pageviews (normalized)',
      budget: 'Monthly PV budget',
      pageViews: 'Continuous real fulfillment of PV',
      realityVsKpi: 'Budget vs forecast from ongoing PV fulfillment'
    },
    realUsers: {
      header: 'Real Users',
      budget: 'Budget of daily average RU',
      uniqueUsersDailyAvg: 'Running daily average RU',
      realityVsKpi: 'Budget vs continuous daily average RU'
    },
    videoViews: {
      header: 'Videoviews',
      budget: 'Monthly VV budget',
      videoViews: 'Continuous real fulfillment of VV',
      realityVsKpi: 'Budget vs forecast from ongoing implementation of VV'
    },
    averageTimeSpent: {
      header: 'Average Time Spent',
      budget: 'Budget of average daily TS per RU',
      timeSpentPerUserDailyAvg: 'Continuous daily average TS per RU',
      realityVsKpi: 'Budget vs ongoing average daily TS per RU'
    }
  }
}

export default messages
