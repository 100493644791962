export default {
  company (state) {
    const NMH_COMPANY = 'nmh'
    const VLM_COMPANY = 'vlm'
    return state.config.customerSpecific.vlm === 'true' ? VLM_COMPANY : NMH_COMPANY
  },
  /**
   * Usage:
   *  ```
   *  computed: {
   *    ...mapGetters(['vlm'])
   *  }
   * ```
   */
  vlm (state) {
    return state.config.customerSpecific.vlm === 'true'
  },
  vlmDotyk (state) {
    // for now, just check if it's 'vlm',
    //   in the future, we may change it to a new separate config value
    return state.config.customerSpecific.vlm === 'true'
  },
  nmh (state) {
    return state.config.customerSpecific.nmh === 'true'
  },
  production (state) {
    return state.config.environment === 'production'
  }
}
