const buildFilterQuery = function (filter) {
  let filterQuery = ''

  if (filter.status) {
    filterQuery += '&status=' + filter.status
  }
  if (filter.agency) {
    filterQuery += '&agency=' + filter.agency
  }
  if (filter.client) {
    filterQuery += '&client=' + filter.client
  }
  if (filter.title) {
    filterQuery += '&title=' + filter.title
  }

  return filterQuery
}

export default {
  buildFilterQuery
}
