const messages = {
  id: 'ID',
  created_at: 'Vytvořeno',
  created_at_from: 'Vytvořeno od',
  created_at_to: 'Vytvořeno do',
  created_by: 'Vytvořil',
  modified_at: 'Změněno',
  modified_by: 'Úpravil',
  email: 'Email',
  variableSymbol: 'VS',
  name: 'Název',
  price: 'Cena',
  product: 'Produkt',
  payment: 'Způsob platby',
  paymentResult: 'Výsledek platby',
  cardNumber: 'Číslo studentské karty',
  count: 'Počet',
  sameDeliveryAddress: 'Stejná doručovací adresa',
  invoiceAddress: 'Fakturační adresa',
  deliveryAddress: 'Doručovací adresa',
  notice: 'Poznámka',
  giftSubscription: 'Dárkové předplatné',
  aboStartEdition: 'Od vydání',
  systemInfo: 'Systémové informace',
  history: 'Historie',
  historyType: 'Typ',
  historyDescription: 'Popis',
  payments: 'Platby',
  paymentStatusType: 'Typ',
  create_new: 'Vytvoř novou',
  save: 'Uložit',
  address: {
    type: 'Typ',
    name: 'Jméno',
    surname: 'Příjmení',
    company: 'Společnost',
    ico: 'IČO',
    dic: 'DIČ',
    icDph: 'IČ DPH',
    street: 'Ulice',
    city: 'Město',
    zip: 'PSČ',
    country: 'Země'
  },
  abo: {
    title: 'ABO',
    idObj: 'ID objednávky',
    pcZakaz: 'PC zákazníka'
  },
  aboPeriodik: {
    title: 'Periodik ABO',
    orderId: 'ID objednávky',
    customerId: 'ID zákazníka'
  },
  subscriptionStatus: {
    mailSent: 'Odeslaný mail',
    aboExported: 'Exportována do ABO',
    activated: 'Aktivované předplatné'
  },
  feUser: 'Uživatel',
  filter: {
    export: 'Exportovat',
    search: 'Hledat',
    reset_filter: 'Zrušit filtr'
  },
  button: {
    changePaymentResult: 'Změň výsledek platby',
    more: 'Více'
  },
  modal: {
    changePaymentResultHeader: 'Změnit výsledek platby?',
    changePaymentResultText: '',
    changePaymentResultCancelBtn: 'Ne',
    changePaymentResultConfirmBtn: 'Ano, změň'
  },
  error: {
    required_fields: 'Prosím, vyplňte všechna povinná pole'
  },
  message: {
    updated_record: 'Záznam byl změněn!',
    deleted_record: 'Záznam byl vymazán!'
  }
}

export default messages
