import moment from 'moment'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.variableSymbol) {
    filterQuery += '&filter_eq[variableSymbol]=' + filter.variableSymbol
  }
  if (filter.order_email) {
    filterQuery += '&filter_custom[order.email]=' + filter.order_email
  }
  if (filter.order_phone) {
    filterQuery += '&filter_custom[order.phone]=' + filter.order_phone
  }
  if (filter.order_product) {
    filterQuery += '&filter_custom[order.product]=' + filter.order_product
  }
  if (filter.order_payment) {
    filterQuery += '&filter_custom[order.payment]=' + filter.order_payment
  }
  if (filter.paymentResult) {
    filterQuery += '&filter_eq[paymentResult]=' + filter.paymentResult
  }
  if (filter.price) {
    filterQuery += '&filter_eq[price]=' + Math.floor(filter.price.replace(',', '.') * 100)
  }
  if (filter.order) {
    filterQuery += '&filter_eq[order]=' + filter.order
  }
  if (filter.status) {
    filterQuery += '&filter_eq[status]=' + filter.status
  }
  if (filter.createdAtFrom) {
    const date = moment(filter.createdAtFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[createdAt]=' + date
  }
  if (filter.createdAtTo) {
    const date = moment(filter.createdAtTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[createdAt]=' + date
  }
  if (filter.nextSmsPaymentAt) {
    const date = moment(filter.nextSmsPaymentAt).format('YYYY-MM-DD')
    filterQuery += '&filter_custom[nextSmsPayment]=' + date
  }
  if (filter.nextComfortPayPaymentAt) {
    const date = moment(filter.nextComfortPayPaymentAt).format('YYYY-MM-DD')
    filterQuery += '&filter_custom[nextComfortPayPayment]=' + date
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
