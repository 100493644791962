const messages = {
  id: 'ID',
  created_at: 'Created at',
  created_at_from: 'Created at from',
  created_at_to: 'Created at to',
  created_by: 'Created by',
  modified_at: 'Modified at',
  modified_by: 'Modified by',
  name: 'Name',
  slug: 'Slug',
  period: 'Period',
  price: 'Price',
  visibility: 'Visibility',
  description: {
    title: 'Description',
    short: 'Short',
    main: 'Main',
    landingPage: 'Landing page',
    summaryPage: 'Summary page'
  },
  metadata: {
    title: 'Metadata',
    keywords: 'Keywords',
    description: 'Description'
  },
  setting: {
    cardNumber: 'Student card number',
    company: 'Company',
    invoiceAddress: 'Invoice address',
    deliveryAddress: 'Delivery address',
    notice: 'Notice',
    platform: {
      print: 'Print',
      iOS: 'iOS',
      android: 'Android',
      ePaper: 'ePaper',
      windows8: 'Windows 8'
    },
    tag: {
      recommended: 'Recommended',
      convenientOffer: 'Convenient offer'
    },
    email: 'Email restriction'
  },
  subscriptionSetting: {
    giftSubscription: 'Gift subscription',
    companyProduct: 'Company product',
    companyProductCount: 'Company product count',
    aboStartEdition: 'ABO edition',
    notifyBeforeNextRecurringPayment: 'Notify before next recurring payment'
  },
  trialPeriod: {
    enabled: 'Trial price',
    price: 'Price',
    aboProduct: 'ABO product',
    aboPeriodikPrice: 'Periodik ABO price'
  },
  paymentGateways: 'Payment gateways',
  payment: {
    default: 'Default',
    free: 'Free',
    transfer: 'Transfer',
    cardpay: 'CardPay',
    tatrapay: 'TatraPay',
    comfortpay: 'ComfortPay (recurring)',
    smsRecurring: 'SMS (recurring)',
    discountCode: 'Discount code',
    csobCardPay: 'Online CSOB (card)',
    csobGooglePay: 'Online GooglePay',
    csobMasterCardMobile: 'Online Mastercard Mobile'
  },
  aboProduct: 'ABO product',
  addOnProduct: 'Add-on product',
  addOnProductDescription: 'Add-on product - description',
  addPeriod: 'Add period',
  addRelatedProduct: 'Add related product',
  digitalServices: 'Digital services',
  priceDescription: 'Price description',
  published_since: 'Published since',
  published_until: 'Published until',
  image: 'Image',
  productImageType: {
    title: 'Image type',
    static: 'Static',
    dynamic: 'Dynamic'
  },
  insertImage: 'Insert image',
  imageUuid: 'Image ID',
  productImageSiteType: {
    title: 'Site',
    trend: 'TREND'
  },
  selectPage: 'Select page',
  yes: 'Yes',
  no: 'No',
  enabled: 'Enabled',
  detail: 'Detail',
  abo: 'Abo',
  systemInfo: 'System info',
  create_new: 'Create new',
  save: 'Save',
  tab: {
    general: 'General',
    prices: 'Payments, ABO'
  },
  filter: {
    search: 'Search',
    reset_filter: 'Reset filter'
  },
  error: {
    required_fields: 'Please fill all required fields'
  },
  message: {
    updated_record: 'Record was updated!',
    deleted_record: 'Record was deleted!'
  }
}

export default messages
