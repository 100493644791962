const messages = {
  list: {
    id: 'Id',
    title: 'Titulek',
    heading: 'Nadpis na webu',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil'
  },
  filter: {
    title: 'Titulek',
    heading: 'Nadpis na webu',
    id: 'Id',
    bodytext: 'Text',
    search: 'Hledat'
  },
  title: 'Titulek',
  title_placeholder: 'Napište nadpis infoboxu',
  image: 'Fotka k infoboxu',
  heading: 'Nadpis na webu',
  heading_placeholder: 'Napište nadpis na webu',
  published_since: 'Datum a čas zobrazení na webu',
  published_since_placeholder: 'Vyberte datum publikování na webu',
  published_until: 'Publikované do',
  published_until_placeholder: 'Vyberte datum',
  bodytext: 'Obsah infoboxu',
  size_type: 'Velikost',
  normal: 'Normální',
  big: 'Velká'
}

export default messages
