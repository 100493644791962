import CoreApi from '../../api/core'
import DepartmentModel from '../../model/Department'
import DepartmentFilterService from '../../services/department/DepartmentFilterService'
import Config from '../../config'

const API_NAME = '/department'
const LIMIT = 200

const state = {
  error: null,
  detail: DepartmentModel,
  list: [],
  all: [],
  managedByCurrentUser: [],
  totalCount: 0,
  page: 1,
  filter: {
    id: '',
    name: ''
  }
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  storeAll (state, responseData) {
    state.all = responseData.data
  },
  storeManagedByCurrentUser (state, departments) {
    state.managedByCurrentUser = departments
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * Config.defaults.list.limit) - Config.defaults.list.limit
    const url = API_NAME + '?limit=' + Config.defaults.list.limit + '&offset=' + offset + '&order[id]=desc' +
      DepartmentFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchAll ({ commit, rootGetters }) {
    return await CoreApi().get(API_NAME + '?limit=' + LIMIT + '&order[name]=asc')
      .then(res => {
        commit('storeAll', res.data)
        const currentUser = rootGetters['user/currentUser']
        // TODO CMS-163 change to permissions
        const isSuperAdmin = false
        if (isSuperAdmin) {
          commit('storeManagedByCurrentUser', res.data.data)
          return
        }

        const managedByCurrentUser = res.data.data.filter(department => {
          return department.managers.includes(currentUser.id)
        })
        commit('storeManagedByCurrentUser', managedByCurrentUser)
      })
      .catch(error => console.log(error))
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async create (store, record) {
    return await CoreApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  list (state) {
    return state.list
  },
  all (state) {
    return state.all
  },
  totalCount (state) {
    return state.totalCount
  },
  departmentsBySite (state) {
    return siteId => state.all.filter(department => {
      if (parseInt(siteId) === parseInt(department.site)) {
        return department
      }
    })
  },
  getManagedByCurrentUser (state) {
    return state.managedByCurrentUser
  },
  getById (state) {
    return id => state.all.find(department => {
      if (parseInt(id) === parseInt(department.id)) {
        return department
      }
    })
  },
  filter (state) {
    return state.filter
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  detail (state) {
    return state.detail
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
