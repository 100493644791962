const messages = {
  all_slots: 'Všetky',
  approved: 'schválené',
  articles_missing: '2 články neboli doporučené.',
  department_articles_missing: 'Články zo strediska {department} neboli doposiaľ doručené.',
  base: 'Stredisko',
  date: 'Dátum publikovania na HP',
  disapprove: 'Odobrať',
  fb_shared: 'Pridané na FB',
  pageViews: 'PVs',
  slot_base: 'Slot - stredisko',
  site: 'Stránka',
  buttons: {
    select_article: 'Vyber z HP ponuky'
  },
  list: {
    headers: {
      title: 'Titulok',
      subTitle: 'Slot - Stredisko - Stránka - Rubrika',
      pv: 'PV`s',
      publishWeb: 'Dátum publikovania na webe',
      publishHp: 'Dátum publikovania na HP',
      fb: 'Priradené na FB',
      actions: 'Akcie'
    }
  }
}

export default messages
