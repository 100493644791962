const messages = {
  list: {
    id: 'Id',
    title: 'Title',
    heading: 'Heading',
    created_at: 'Created at',
    modified_at: 'Modified at',
    created_by: 'Created by'
  },
  filter: {
    title: 'Title',
    heading: 'Heading',
    id: 'Id',
    bodytext: 'BodyText',
    search: 'Search'
  },
  title: 'Title',
  title_placeholder: 'Write title',
  image: 'Infobox photo',
  heading: 'Heading',
  heading_placeholder: 'Write heading',
  published_since: 'Date and time of publishing',
  published_since_placeholder: 'Choose date of publishing',
  published_until: 'Published until',
  published_until_placeholder: 'Choose date',
  bodytext: 'Infobox content',
  size_type: 'Size',
  normal: 'Normal',
  big: 'Big'
}

export default messages
