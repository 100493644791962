import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_PERMISSION_GROUP_PERMISSIONS

const routes = [
  {
    path: 'permissionGroup/:id/edit',
    component: () => import('../../../views/permissionGroup/PermissionGroupEditView'),
    name: 'permissionGroup_edit',
    meta: {
      description: 'PermissionGroup edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'permissionGroup/new',
    component: () => import('../../../views/permissionGroup/PermissionGroupNewView'),
    name: 'permissionGroup_new',
    meta: {
      description: 'PermissionGroup new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'permissionGroup/:id',
    component: () => import('../../../views/permissionGroup/PermissionGroupView'),
    name: 'permissionGroup_detail',
    meta: {
      description: 'Infobox detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'permissionGroup',
    component: () => import('../../../views/permissionGroup/PermissionGroupListView'),
    name: 'permissionGroup_list',
    meta: {
      description: 'PermissionGroup list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
