import Sport24Api from '../../api/sport24'
import SportTeamSeasonModel from '../../model/SportTeamSeasonModel.js'

const API_NAME = '/teamSeason'

const state = {
  error: null,
  detail: SportTeamSeasonModel
}

const mutations = {
  storeDetail (state, responseData) {
    state.detail = responseData
  }
}

const actions = {
  async fetch (store, id) {
    await Sport24Api().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  error (state) {
    return state.error
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
