const messages = {
  id: 'ID',
  created_at: 'Created at',
  created_at_from: 'Created at from',
  created_at_to: 'Created at to',
  modified_at: 'Modified at',
  email: 'Email',
  phone: 'Phone',
  variableSymbol: 'VS',
  price: 'Price',
  product: 'Product',
  payment: 'Payment type',
  paymentResult: 'Payment result',
  nextPaymentAt: 'Next payment at',
  nextSmsPaymentAt: 'Next SMS payment at',
  nextComfortPayPaymentAt: 'Next ComfortPay payment at',
  order: 'Order',
  status: 'Type',
  comfortPaySmsStatus: 'Status',
  subscriptionStatus: {
    aboExported: 'Abo'
  },
  filter: {
    export: 'Export',
    search: 'Search',
    reset_filter: 'Reset filter'
  },
  modal: {
    button: {
      show: 'Edit',
      cancel: 'Cancel',
      confirm: 'Confirm'
    },
    aboExport: {
      title: 'ABO',
      confirmation: 'Please, confirm ABO export for payment ID',
      button: 'Export',
      success: 'Payment exported.',
      error: 'Error encountered.'
    },
    changePaymentResult: {
      title: 'Change payment result',
      confirmation: 'Please, confirm payment status change for ID',
      button: 'Change',
      success: 'Payment status has been changed',
      error: 'An error occurred. Please try again later.'
    },
    activatePayment: {
      title: 'Activate payment recurrence',
      confirmation: 'Please, confirm payment change for ID',
      button: 'Activate',
      success: 'Payment has been activated',
      error: 'An error occurred. Please try again later'
    },
    changeNextPaymentAt: {
      title: 'Change next payment date',
      confirmation: 'Please, confirm next payment date change for ID',
      button: 'Change',
      success: 'Payment has been updated',
      error: 'An error occurred. Please try again later'
    },
    cancelPayment: {
      title: 'Deactivate payment',
      confirmation: 'Please, confirm payment deactiavtion for ID',
      button: 'Deactivate payment',
      success: 'Payment deactivated.',
      error: 'Error encountered.'
    }
  },
  message: {
    updated_record: 'Record was updated!'
  }
}

export default messages
