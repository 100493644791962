import moment from 'moment'
import { HISTORY_PAGE_VIEWS } from './ValueObject/HistoricalPerformanceMetrics'
import { CLUSTER_VIEW } from './ValueObject/HistoricalViewOptions'
import { TIMEFRAME_MONTH } from './ValueObject/HistoricalViewTimeframes'

const defaultDateStart = moment().subtract(4, 'months').startOf('month').toISOString()
const defaultEndDate = moment().toISOString()

const DATE_PICKER_DIVIDER = ' do '

export default {
  startDate: defaultDateStart,
  endDate: defaultEndDate,
  cluster: '5fce46aec66f30385db9900c',
  department: '',
  timeGroupType: TIMEFRAME_MONTH,
  metricType: HISTORY_PAGE_VIEWS,
  filterType: CLUSTER_VIEW,
  dateRange: defaultDateStart + DATE_PICKER_DIVIDER + defaultEndDate
}
