export default [
  {
    id: 'dashboard',
    isAllowedToDisplay: ['dashboard'],
    subMenu: false,
    displayName: 'menu.dashboard',
    routeName: 'dashboard',
    nestedListItems: []
  },
  {
    id: 'listOfArticles',
    isAllowedToDisplay: ['article_list'],
    subMenu: false,
    displayName: 'menu.articles',
    routeName: '',
    nestedListItems: []
  },
  {
    id: 'homepage',
    isAllowedToDisplay: ['contentBlockItem_list', 'hp_topic_offer_list'],
    subMenu: false,
    displayName: 'menu.hp',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.content_block_items',
        routeName: 'contentBlockItem_list',
        dataTest: 'content_block_items'
      },
      {
        displayName: 'menu.content_block_items_old',
        routeName: 'contentBlockItem_list_old',
        dataTest: 'content_block_items_old'
      },
      {
        displayName: 'menu.hp_topic_offer',
        routeName: 'hp_topic_offer_list',
        dataTest: 'hp_topic_offer'
      },
      {
        displayName: 'menu.hp_topic_offer_old',
        routeName: 'hp_topic_offer_list_old',
        dataTest: 'hp_topic_offer_old'
      }
    ]
  },
  {
    id: 'dam',
    isAllowedToDisplay: ['dam_list', 'dam_pdf_list'],
    subMenu: false,
    displayName: 'menu.dam',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.dam_images',
        routeName: 'dam_list',
        dataTest: 'image'
      },
      {
        displayName: 'menu.dam_pdf',
        routeName: 'dam_pdf_list',
        dataTest: 'doc'
      }
    ]
  },
  {
    id: 'video',
    isAllowedToDisplay: ['video_list', 'videoShow_list', 'videoCategory_list'],
    subMenu: false,
    displayName: 'menu.video',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.video_list',
        routeName: 'video_list',
        dataTest: 'video_list'
      },
      {
        displayName: 'menu.video_management',
        routeName: 'videoManagement',
        dataTest: 'video_management'
      },
      {
        displayName: 'menu.video_stats',
        routeName: 'videoDashboard',
        dataTest: 'video_stats'
      },
      {
        displayName: 'menu.video_daily_report',
        routeName: 'videoDailyReport',
        dataTest: 'video_daily_report'
      },
      {
        displayName: 'menu.video_published_report',
        routeName: 'videoPublishedReport',
        dataTest: 'video_published_report'
      },
      {
        displayName: 'menu.video_kpi_reports',
        routeName: 'videoKpiReports',
        dataTest: 'video_kpi_reports'
      },
      {
        displayName: 'menu.video_show_list',
        routeName: 'videoShow_list',
        dataTest: 'video_show_list'
      },
      {
        displayName: 'menu.video_category_list',
        routeName: 'videoCategory_list',
        dataTest: 'video_category_list'
      }
    ]
  },
  {
    id: 'modules',
    isAllowedToDisplay: ['infobox_list', 'joke_list', 'poll_list', 'poll_management', 'quiz_list', 'oneQuestion_list',
      'sport24_list', 'topic_list', 'tasrNews_list', 'recipe_list'],
    subMenu: false,
    displayName: 'menu.modules',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.infoboxes',
        routeName: 'infobox_list',
        dataTest: 'infoboxes'
      },
      {
        displayName: 'menu.jokes',
        routeName: 'joke_list',
        dataTest: 'joke_list'
      },
      {
        displayName: 'menu.quotes',
        routeName: 'quote_list',
        dataTest: 'quotes'
      },
      {
        displayName: 'menu.polls',
        routeName: 'poll_list',
        dataTest: 'polls'
      },
      {
        displayName: 'menu.polls_on_homepage',
        routeName: 'poll_management',
        dataTest: 'polls_on_homepage'
      },
      {
        displayName: 'menu.like_dislike',
        routeName: 'likeDislike_list',
        dataTest: 'like_dislike'
      },
      {
        displayName: 'menu.quizzes',
        routeName: 'quiz_list',
        dataTest: 'quizzes'
      },
      {
        displayName: 'menu.oneQuestion',
        routeName: 'oneQuestion_list',
        dataTest: 'oneQuestion'
      },
      {
        displayName: 'menu.sport24',
        routeName: 'sport24_list',
        dataTest: 'sport24'
      },
      {
        displayName: 'menu.topic',
        routeName: 'topic_list',
        dataTest: 'topic'
      },
      {
        displayName: 'menu.recipes',
        routeName: 'recipe_list',
        dataTest: 'recipes'
      },
      {
        displayName: 'menu.galleries',
        routeName: 'gallery_list',
        dataTest: 'galleries'
      }
    ]
  },
  {
    id: 'tests',
    isAllowedToDisplay: ['car_list', 'restaurant_list', 'hotel_list'],
    subMenu: false,
    displayName: 'menu.tests',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.cars',
        routeName: 'car_list',
        dataTest: 'cars'
      },
      {
        displayName: 'menu.restaurants',
        routeName: 'restaurant_list',
        dataTest: 'restaurants',
        alwaysHidden: true
      },
      {
        displayName: 'menu.hotels',
        routeName: 'hotel_list',
        dataTest: 'hotels',
        alwaysHidden: true
      }
    ]
  },
  {
    id: 'articleOffers',
    isAllowedToDisplay: ['infobox_list', 'joke_list', 'poll_list', 'poll_management', 'quiz_list', 'oneQuestion_list',
      'sport24_list', 'topic_list', 'tasrNews_list', 'recipe_list'],
    displayName: 'menu.article_offer',
    subMenu: false,
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.tasrNews',
        routeName: 'tasrNews_list',
        dataTest: 'tasrNews'
      },
      {
        displayName: 'menu.topicOffer',
        routeName: 'topicOffer_list',
        dataTest: 'topicOffer'
      },
      {
        displayName: 'menu.wood_wing',
        routeName: 'woodWing_list',
        dataTest: 'wood_wing'
      }
    ]
  },
  {
    id: 'print',
    isAllowedToDisplay: ['printPublication_list', 'printArticle_list'],
    subMenu: false,
    displayName: 'menu.print',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.printPublications',
        routeName: 'printPublication_list',
        dataTest: 'printPublications'
      },
      {
        displayName: 'menu.printArticles',
        routeName: 'printArticle_list',
        dataTest: 'printArticles'
      }
    ]
  },
  {
    id: 'editorPerformance',
    isAllowedToDisplay: ['beUserKpi_list', 'kpi_comparison', 'kpi_nmh', 'kpi_nmh_old'],
    subMenu: false,
    displayName: 'menu.performance',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.performance_product',
        routeName: 'performance_product',
        dataTest: 'performance_product'
      },
      {
        displayName: 'menu.performance_department',
        routeName: 'performance_department',
        dataTest: 'performance_department'
      },
      {
        displayName: 'menu.historical_performance',
        routeName: 'historical_performance',
        dataTest: 'historical_performance',
        alwaysHidden: true
      },
      {
        displayName: 'menu.performance_weights',
        routeName: 'performance_weights',
        dataTest: 'performance_weights'
      },
      {
        displayName: 'menu.kpi_settings',
        routeName: 'performance_kpi_settings',
        dataTest: 'kpi_settings'
      },
      {
        displayName: 'menu.edonProduct_list',
        routeName: 'edonProduct_list',
        dataTest: 'edonProduct_list'
      },
      {
        displayName: 'menu.kpi_nmh',
        routeName: 'kpi_nmh',
        dataTest: 'kpi_nmh'
      },
      {
        displayName: 'menu.kpi_nmh_old',
        routeName: 'kpi_nmh_old',
        dataTest: 'kpi_nmh_old'
      },
      {
        displayName: 'menu.kpi_nmh_settings',
        routeName: 'kpi_nmh_settings',
        dataTest: 'kpi_nmh_settings',
        alwaysHidden: true
      }
    ]
  },
  {
    id: 'sponsoredArticles',
    isAllowedToDisplay: ['native_campaign_list', 'don_npa_list', 'don_npa_predictions'],
    subMenu: false,
    displayName: 'menu.don',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.don_campaign_management',
        routeName: 'native_campaign_list',
        dataTest: 'don_box_campaign_management'
      },
      {
        displayName: 'menu.don_campaign_predictions',
        routeName: 'don_npa_predictions',
        dataTest: 'don_campaign_predictions'
      }
    ]
  },
  {
    id: 'users',
    alwaysHidden: true,
    isAllowedToDisplay: ['feUser_list', 'feUserDeliveryAddress_list', 'feUserBillingAddress_list',
      'feUserArticle_list'],
    subMenu: false,
    displayName: 'menu.feUsers',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.feUsers',
        routeName: 'feUser_list',
        dataTest: 'feUsers'
      },
      {
        displayName: 'menu.feUsersPollVotes',
        routeName: 'feUserPollVote_list',
        dataTest: 'feUsersPollVotes'
      },
      {
        displayName: 'menu.feUsersDeliveryAddresses',
        routeName: 'feUserDeliveryAddress_list',
        dataTest: 'feUsersDeliveryAddressesfeUsersDeliveryAddresses'
      },
      {
        displayName: 'menu.feUsersBillingAddresses',
        routeName: 'feUserBillingAddress_list',
        dataTest: 'feUsersBillingAddresses'
      },
      {
        displayName: 'menu.feUsersArticles',
        routeName: 'feUserArticle_list',
        dataTest: 'feUsersArticles'
      }
    ]
  },
  {
    id: 'eshop',
    alwaysHidden: true,
    isAllowedToDisplay: ['eshopSubscriptionProduct_list', 'eshopSubscriptionOrder_list',
      'eshopSubscriptionPayment_list', 'eshopSubscription_list', 'eshopSubscriber_list', 'eshopHistory_list'],
    subMenu: false,
    displayName: 'menu.eshop',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.eshopDashboard',
        routeName: 'eshopDashboard',
        dataTest: 'eshopDashboard'
      },
      {
        displayName: 'menu.eshopSubscriptionProducts',
        routeName: 'eshopSubscriptionProduct_list',
        dataTest: 'eshopSubscriptionProducts'
      },
      {
        displayName: 'menu.eshopTopProductSetting',
        routeName: 'eshopTopProductSetting_edit',
        dataTest: 'eshopTopProductSetting'
      },
      {
        displayName: 'menu.eshopSubscriptionOrders',
        routeName: 'eshopSubscriptionOrder_list',
        dataTest: 'eshopSubscriptionOrders'
      },
      {
        displayName: 'menu.eshopSubscriptionPayments',
        routeName: 'eshopSubscriptionPayment_list',
        dataTest: 'eshopSubscriptionPayments'
      },
      {
        displayName: 'menu.eshopSubscriptions',
        routeName: 'eshopSubscription_list',
        dataTest: 'eshopSubscriptions'
      },
      {
        displayName: 'menu.eshopSubscribers',
        routeName: 'eshopSubscriber_list',
        dataTest: 'eshopSubscribers'
      },
      {
        displayName: 'menu.eshopHistory',
        routeName: 'eshopHistory_list',
        dataTest: 'eshopHistory'
      }
    ]
  },
  {
    id: 'system',
    isAllowedToDisplay: ['author_list', 'contentBlock_list', 'pollBlock_list', 'site_list', 'rubric_list', 'app_performance',
      'scale_list', 'recipe_category_list', 'department_list',
      'category_list', 'tag_list', 'safetyTopic_list', 'redirect_list', 'eshopFeature_list',
      'printTitle_list', 'articleHistory_list', 'tasrNewsCategory_list', 'recipe_list', 'permissionGroup_list', 'beUser_list'],
    subMenu: false,
    displayName: 'menu.system',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.permission_groups',
        routeName: 'permissionGroup_list'
      },
      {
        displayName: 'menu.beUsers',
        routeName: 'beUser_list',
        dataTest: 'beUsers'
      },
      {
        displayName: 'menu.departments',
        routeName: 'department_list',
        dataTest: 'departments'
      },
      {
        displayName: 'menu.app_performance',
        routeName: 'app_performance',
        dataTest: 'app_performance',
        alwaysHidden: true // see CMS-4028
      },
      {
        displayName: 'menu.scale_list',
        routeName: 'scale_list',
        dataTest: 'scale_list',
        alwaysHidden: true // see CMS-4028
      },
      {
        displayName: 'menu.authors',
        routeName: 'author_list',
        dataTest: 'authors'
      },
      {
        displayName: 'menu.content_blocks',
        routeName: 'contentBlock_list',
        dataTest: 'content_blocks'
      },
      {
        displayName: 'menu.poll_blocks',
        routeName: 'pollBlock_list',
        dataTest: 'poll_blocks'
      },
      {
        displayName: 'menu.sites',
        routeName: 'site_list',
        dataTest: 'sites'
      },
      {
        displayName: 'menu.rubrics',
        routeName: 'rubric_list',
        dataTest: 'rubrics'
      },
      {
        displayName: 'menu.categories',
        routeName: 'category_list',
        dataTest: 'categories'
      },
      {
        displayName: 'menu.recipe_categories',
        routeName: 'recipe_category_list',
        dataTest: 'recipe_categories'
      },
      {
        displayName: 'menu.tags',
        routeName: 'tag_list',
        dataTest: 'tags'
      },
      {
        displayName: 'menu.safetyTopics',
        routeName: 'safetyTopic_list',
        dataTest: 'safetyTopics'
      },
      {
        displayName: 'menu.redirects',
        routeName: 'redirect_list',
        dataTest: 'redirects',
        featureName: 'redirect'
      },
      {
        displayName: 'menu.eshopFeatures',
        routeName: 'eshopFeature_list',
        dataTest: 'eshopFeatures',
        alwaysHidden: true
      },
      {
        displayName: 'menu.articleHistory',
        routeName: 'articleHistory_list',
        dataTest: 'articleHistory'
      },
      {
        displayName: 'menu.tasrNewsCategory',
        routeName: 'tasrNewsCategory_list',
        dataTest: 'tasrNewsCategory'
      },
      {
        displayName: 'menu.printTitles',
        routeName: 'printTitle_list',
        dataTest: 'printTitles'
      }
    ]
  },
  {
    id: 'language',
    isAllowedToDisplay: true,
    subMenu: false,
    displayName: 'menu.language',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'Czech',
        routeName: '',
        dataTest: 'Czech'
      },
      {
        displayName: 'English',
        routeName: '',
        dataTest: 'English'
      },
      {
        displayName: 'Slovak',
        routeName: '',
        dataTest: 'Slovak'
      }
    ]
  },
  {
    id: 'videoTutorial',
    isAllowedToDisplay: true,
    subMenu: false,
    displayName: 'menu.videoTutorial',
    routeName: 'help_video_list',
    nestedListItems: []
  }
]
