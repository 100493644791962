const messages = {
  id: 'ID',
  created_at: 'Vytvorené',
  created_at_from: 'Vytvorené od',
  created_at_to: 'Vytvorené do',
  created_by: 'Vytvoril',
  modified_at: 'Zmenené',
  modified_by: 'Upravil',
  last_login: 'Posledné prihlásenie',
  last_login_from: 'Posledné prihlásenie od',
  last_login_to: 'Posledné prihlásenie do',
  main_settings: 'Hlavné nastavenia',
  name: 'Meno',
  surname: 'Priezvisko',
  street: 'Ulica',
  city: 'Mesto',
  zip: 'PSČ',
  aboCountry: 'Krajina',
  phone: 'Telefón',
  user_email: 'Email používateľa',
  user_id: 'ID používateľa',
  save: 'Uložiť',
  username: 'Užívateľské meno',
  password: 'Heslo',
  firstName: 'Meno',
  lastName: 'Priezvisko',
  email: 'Email',
  deliveryAddresses: 'Doručovacie adresy',
  billingAddresses: 'Fakturačné adresy',
  systemInfo: 'Systémové informácie',
  facebook_login: 'Facebook Login',
  google_login: 'Google Login',
  roles: 'Role',
  permissions: 'Povolenia',
  role_no: 'Roľa no.',
  permission_no: 'Povolenie č.',
  removeRole: 'Vymaž roľu',
  removePermission: 'Vymaž povolenie',
  yes: 'Áno',
  no: 'Nie',
  enabled: 'Zapnuté',
  ico: 'ICO',
  dic: 'DIC',
  icDph: 'IC DPH',
  addressType: 'Typ',
  company: 'Firma',
  type_person: 'fyzická osoba',
  type_company: 'právnická osoba',
  article_id: 'Document ID článku',
  article_title: 'Nadpis článku',
  article: 'Článok',
  articles: 'Články',
  polls: 'Ankety',
  poll: 'Anketa',
  poll_option: 'Možnosť',
  masterSite: 'Hlavná stránka',
  otherSites: 'Ostatné stránky',
  detail: 'Detail',
  create_new: 'Vytvor novú',
  consent: {
    gdpr: 'GDPR súhlas',
    marketing: 'Marketingový súhlas'
  },
  tab: {
    general: 'General',
    addresses: 'Addresses',
    articles: 'Articles'
  },
  filter: {
    search: 'Hľadať',
    reset_filter: 'Zrušiť filter',
    export: 'Exportovať'
  },
  error: {
    required_fields: 'Prosím, vyplňte všetky povinné polia'
  },
  message: {
    updated_record: 'Záznam bol zmenený!',
    deleted_record: 'Záznam bol vymazaný!'
  }
}

export default messages
