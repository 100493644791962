const messages = {
  list: {
    id: 'Id',
    title: 'Title',
    name: 'Name',
    site: 'Site'
  },
  filter: {
    id: 'Id',
    title: 'Title',
    name: 'Name',
    site: 'Site',
    search: 'Search'
  },
  site: 'Site',
  title: 'Title',
  name: 'Name',
  name_validation: 'Must contains \'a-z\' and \'-\' only and must be unique'
}

export default messages
