const messages = {
  id: 'WoodWing ID',
  create_article: 'Přebrat článek',
  export: {
    category: 'Název kategorie vydání',
    header: 'Export WoodWing',
    info: 'Přejete si exportovat článek do systému WoodWing? <b>Tato operace je nezvratná a lze ji provést pouze 1 krát!</b><br>V případě pokud si přejete pokračovat prosím klikněte na tlačítko "Exportovat".<br>Článek bude exportován do <b>{brand}< /b>',
    print: 'Název printového vydání',
    open: 'Exportovat do Woodwing',
    save: 'Exportovat',
    error: 'Článek se nepodařilo exportovat',
    success: 'Článek byl exportován do WoodWingu'
  },
  issue: 'Vydání',
  status: {
    article_export: 'Export status ID článku do WoodWing',
    article_import: 'Import status ID článku z WoodWing',
    image_export: 'Export status ID fotky do WoodWing',
    image_import: 'Import status ID fotky z WoodWing'
  }
}

export default messages
