const messages = {
  list: {
    id: 'Id',
    site: 'Site',
    rubric: 'Rubric',
    service: 'Service',
    code: 'Code',
    title: 'Title'
  },
  filter: {
    site: 'Site',
    rubric: 'Rubric',
    service: 'Service',
    code: 'Code',
    title: 'Title',
    search: 'Search'
  },
  id: 'Id',
  site: 'Site',
  rubric: 'Rubric',
  service: 'Service',
  code: 'Code',
  title: 'Title'
}

export default messages
