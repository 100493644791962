export const POLL_TYPE_POLL = 'poll'
export const POLL_TYPE_LIKE_DISLIKE = 'likeDislike'
export const POLL_TYPE_RESULTS = 'results'

export const POLL_TYPE_DEFAULT = POLL_TYPE_POLL

export default {
  computed: {
    pollTypeValues () {
      return [
        {
          id: POLL_TYPE_POLL,
          title: this.$t('poll.poll_type.poll')
        },
        {
          id: POLL_TYPE_LIKE_DISLIKE,
          title: this.$t('poll.poll_type.like_dislike')
        },
        {
          id: POLL_TYPE_RESULTS,
          title: this.$t('poll.poll_type.results')
        }
      ]
    }
  }
}
