const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += `&guid=${filter.id}`
  }
  if (filter.firstName) {
    filterQuery += `&givenName=${filter.firstName}`
  }
  if (filter.lastName) {
    filterQuery += `&familyName=${filter.lastName}`
  }
  if (filter.email) {
    filterQuery += `&email=${filter.email}`
  }
  if (filter.showLocalUsers) {
    filterQuery += '&showLocalUsers=true'
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
