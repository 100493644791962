import moment from 'moment'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.variableSymbol) {
    filterQuery += '&filter_eq[variableSymbol]=' + filter.variableSymbol
  }
  if (filter.price) {
    filterQuery += '&filter_eq[price]=' + Math.floor(filter.price.replace(',', '.') * 100)
  }
  if (filter.email) {
    filterQuery += '&filter_contains[email]=' + filter.email
  }
  if (filter.address_name) {
    filterQuery += '&filter_contains[address.name]=' + filter.address_name
  }
  if (filter.address_surname) {
    filterQuery += '&filter_contains[address.surname]=' + filter.address_surname
  }
  if (filter.address_company) {
    filterQuery += '&filter_contains[address.company]=' + filter.address_company
  }
  if (filter.address_ico) {
    filterQuery += '&filter_contains[address.ico]=' + filter.address_ico
  }
  if (filter.product !== 'none') {
    filterQuery += '&filter_eq[product]=' + filter.product
  }
  if (filter.payment !== 'none') {
    filterQuery += '&filter_eq[payment]=' + filter.payment
  }
  if (filter.paymentResult !== 'none') {
    filterQuery += '&filter_eq[paymentResult]=' + filter.paymentResult
  }
  if (filter.createdAtFrom) {
    const date = moment(filter.createdAtFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[createdAt]=' + date
  }
  if (filter.createdAtTo) {
    const date = moment(filter.createdAtTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[createdAt]=' + date
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
