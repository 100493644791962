import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ARTICLE_PERMISSIONS

const routes = [
  {
    path: 'article/:id/edit',
    component: () => import('../../views/article/ArticleEditView'),
    name: 'article_edit',
    meta: {
      description: 'Article edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'article/:id',
    component: () => import('../../views/article/ArticleView'),
    name: 'article_detail',
    meta: {
      description: 'Article detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'article',
    component: () => import('../../views/article/ArticleListView'),
    name: 'article_list',
    meta: {
      description: 'List of articles',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  },
  {
    path: 'articleByDocumentId/:id',
    component: () => import('../../views/article/ArticleByDocumentIdView'),
    name: 'articleByDocumentId',
    meta: {
      description: 'Article by document id',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.articleByDocumentRoute
    }
  }
]

export default routes
