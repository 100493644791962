export const TYPE_IMAGE_ANSWERS = 'image_answers'
export const TYPE_TEXT_ANSWERS = 'text_answers'
export const AVAILABLE_LAYOUTS = [
  {
    id: TYPE_IMAGE_ANSWERS,
    title: 'Image answers'
  },
  {
    id: TYPE_TEXT_ANSWERS,
    title: 'Text answers'
  }
]
