import _ from 'lodash'
import { dataHubEditPerfApi } from '@/api/datahub'
import ProductPerformanceFilter from '../../model/ProductPerformanceFilter'
import EditorialPerformanceWeights from '../../model/EditorialPerformanceWeights'
import UserPerformance from '../../model/UserPerformance'
import UserMetrics from '../../model/UserMetrics'
import OnlineArticles from '../../model/OnlineArticles'
import PrintArticles from '../../model/PrintArticles'
import Videos from '../../model/Videos'
import { KpiData } from '@/model/Kpi'
import NotifyService from '@/services/NotifyService'
import PerformanceWeights from '@/model/PerformanceWeights'

const API_NAME = '/performance'

function adjustDate (dateString) {
  const [year, month, day] = dateString.split('-').map(Number)
  if (day !== 1) {
    let newMonth = month + 1
    let newYear = year
    if (newMonth > 12) {
      newMonth = 1
      newYear += 1
    }
    return `${newYear}-${newMonth.toString().padStart(2, '0')}-01`
  }
  return dateString
}

const state = {
  loadedProduct: false,
  loadedDepartment: false,
  filter: ProductPerformanceFilter,
  weights: [_.cloneDeep(PerformanceWeights)],
  weightedData: _.cloneDeep(EditorialPerformanceWeights),
  usersPerformance: [_.cloneDeep(UserPerformance)],
  usersMetrics: [_.cloneDeep(UserMetrics)],
  onlineArticles: [_.cloneDeep(OnlineArticles)],
  printArticles: [_.cloneDeep(PrintArticles)],
  videos: [_.cloneDeep(Videos)],
  kpi: _.cloneDeep(KpiData),
  usersPerformanceCount: 0,
  performanceStatistics: {}
}

const mutations = {
  storeWeights (state, responseData) {
    state.weights = responseData.weights
    state.weightedData = responseData.weightedData
    if (responseData.weightedData.length > 0) {
      state.usersPerformance = responseData.weightedData[0].usersPerformance
      state.usersPerformanceCount = responseData.weightedData[0].usersPerformanceCount
    } else {
      state.usersPerformance = _.cloneDeep(UserPerformance)
      state.usersPerformanceCount = 0
    }
  },
  storeUsersMetrics (state, responseData) {
    state.usersMetrics = responseData.userMetrics
  },
  storeOnlineArticles (state, responseData) {
    state.onlineArticles = responseData.onlineArticles
  },
  storePrintArticles (state, responseData) {
    state.printArticles = responseData.printArticles
  },
  storeVideos (state, responseData) {
    state.videos = responseData.videos
  },
  storeKpi (state, responseData) {
    state.kpi = responseData.kpi
  },
  storeStatistics (state, responseData) {
    if (responseData.productMetrics) {
      state.performanceStatistics = responseData.productMetrics[0]
    } else if (responseData.departmentMetrics) {
      state.performanceStatistics = responseData.departmentMetrics[0]
    } else {
      state.performanceStatistics = []
    }
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setLoadedProduct (state, loaded) {
    state.loadedProduct = loaded
  },
  setLoadedDepartment (state, loaded) {
    state.loadedDepartment = loaded
  },
  setUsersPerformance (state, usersPerformance) {
    state.usersPerformance = usersPerformance
  },
  setUsersPerformanceCount (state, usersPerformanceCount) {
    state.usersPerformanceCount = usersPerformanceCount
  }
}

const actions = {
  async fetchProductView (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const [datePart] = store.state.filter.seedDate.split('T')
    const adjustedDatePart = adjustDate(datePart)
    const dataHubUrl = `${API_NAME}/product/${store.state.filter.product}/${store.state.filter.timeGroupType}/${adjustedDatePart}`
    return dataHubEditPerfApi().get(dataHubUrl)
      .then(response => {
        store.commit('storeWeights', response.data)
        store.commit('storeUsersMetrics', response.data)
        store.commit('storeOnlineArticles', response.data)
        store.commit('storePrintArticles', response.data)
        store.commit('storeStatistics', response.data)
        store.commit('setLoadedProduct', true)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.error(error)
        NotifyService.setApiErrorMessage(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },

  async fetchDepartmentView (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const [datePart] = store.state.filter.seedDate.split('T')
    const adjustedDatePart = adjustDate(datePart)
    const dataHubUrl = `${API_NAME}/department/${store.state.filter.department}/${store.state.filter.timeGroupType}/${adjustedDatePart}`
    return dataHubEditPerfApi().get(dataHubUrl)
      .then(response => {
        store.commit('storeWeights', response.data)
        store.commit('storeUsersMetrics', response.data)
        store.commit('storeOnlineArticles', response.data)
        store.commit('storePrintArticles', response.data)
        store.commit('storeVideos', response.data)
        store.commit('storeStatistics', response.data)
        store.commit('storeKpi', response.data)
        store.commit('setLoadedDepartment', true)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.error(error)
        NotifyService.setApiErrorMessage(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  }
}

const getters = {
  weights (state) {
    return state.weights
  },
  weightedData (state) {
    return state.weightedData
  },
  usersMetrics (state) {
    return state.usersMetrics
  },
  usersMetricsCount (state) {
    return state.usersMetrics.length
  },
  usersPerformance (state) {
    return Array.isArray(state.usersPerformance) ? state.usersPerformance : []
  },
  usersPerformanceCount (state) {
    return state.usersPerformanceCount
  },
  onlineArticles (state) {
    return state.onlineArticles
  },
  onlineArticlesCount (state) {
    return state.onlineArticles.length
  },
  printArticles (state) {
    return state.printArticles
  },
  printArticlesCount (state) {
    return state.printArticles.length
  },
  videos (state) {
    return state.videos
  },
  videosCount (state) {
    return state.videos.length
  },
  filter (state) {
    return state.filter
  },
  loadedProduct (state) {
    return state.loadedProduct
  },
  loadedDepartment (state) {
    return state.loadedDepartment
  },
  performanceStatistics (state) {
    return state.performanceStatistics
  },
  kpi (state) {
    return state.kpi
  },
  usersKpi (state) {
    return state.kpi.usersKpi
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
