import { yearNow } from '../services/DateTimeService'

export default {
  id: null,
  title: '',
  description: '',
  image: null,
  name: '',
  address: null,
  yearOfReview: yearNow(),
  openingHours: '',
  phone: '',
  website: '',
  cuisineCategories: [],
  chef: '',
  priceLevel: 0,
  rating: 0,
  prosCons: {
    pros: [],
    cons: []
  },
  expanded: {
    address: null,
    cuisineCategories: []
  }
}
