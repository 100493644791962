<template>
  <div class="header-profile">
    <i class="header-profile__user-icon fas fa-user" @click="clickHandler"></i>
    <div v-if="dropdown" class="header-profile__wr" v-click-outside="clickOutsideHandler">
      <div class="header-profile__text-wr">
        <h4 class="header-profile__username">
          {{ user.currentUser.username }}
        </h4>
        <h5 class="header-profile__email">
          {{ user.currentUser.email }}
        </h5>
      </div>
      <router-link
        class="header-profile__logout-link"
        tag="a"
        :to="{ name: 'userLogout' }"
        :title="$t('login.logout')"
      >
        <i class="header-profile__logout-icon fa fa-power-off"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'DashHeaderProfile',
  data () {
    return {
      dropdown: false
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    siteWidth () {
      return this.$store.state.site.width
    }
  },
  methods: {
    clickHandler () {
      if (this.siteWidth > 767) {
        return
      }
      this.$store.commit('dash/setSidebarOpen', false)
      this.dropdown = !this.dropdown
    },
    clickOutsideHandler () {
      if (this.siteWidth > 767) {
        return
      }
      this.dropdown = false
    },
    setDropdown () {
      this.siteWidth > 767 ? this.dropdown = true : this.dropdown = false
    }
  },
  mounted () {
    this.setDropdown()
  },
  watch: {
    siteWidth () {
      this.setDropdown()
    }
  }
}
</script>

<style scoped lang="scss">
  .header-profile {
    position: relative;
    @include bp(7) {
      @include grid-gap(12px);
      display: grid;
      grid-template-columns: repeat(3, min-content);
      align-items: center;
    }
    &__wr {
      @include grid-gap(12px);
      display: grid;
      grid-template-columns: repeat(2, min-content);
      align-items: center;
      @include bp(0 7) {
        @include absolute(30px -9px _ _, 2);
        @include padding(5px 10px);
        @include radius(3px);
        background: #6599fe;
        color: #fff;
      }
    }
    &__user-icon {
      @include size(26px);
      @include radius(50%);
      background: #d3dcec;
      font-size: 13px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__username {
      @include font(700 14px "Roboto");
      @include margin(_ _ 1px);
      color: #465674;
      @include bp(0 7) {
        color: #fff;
      }
    }
    &__email {
      @include font(400 12px "Roboto");
      @include margin(0);
      color: #8a96ac;
      @include bp(0 7) {
        color: #fff;
      }
    }
    &__logout-link {
      @include margin(_ _ _ 18px);
    }
    &__logout-icon {
      font-size: 24px;
      color: #d3dcec;
      @include bp(10) {
        transition: all 200ms;
        &:hover {
          color: #f57ba3;
        }
      }
    }
  }
</style>
