const messages = {
  filter: {
    id: 'Id',
    title: 'Titulek',
    site: 'Stránka',
    slug: 'URL',
    category: 'Kategorie'
  },
  list: {
    id: 'Id',
    title: 'Titulek',
    site: 'Stránka',
    slug: 'URL',
    category: 'Kategorie'
  },
  topic_category: {
    common: 'Výchozí',
    tag: 'Tag',
    sport24_player: 'Sport24 Hráč',
    sport24_team: 'Sport24 Tým',
    wanda_celebrity: 'Wanda Celebrity',
    zdravie_overall_condition: 'Zdraví Celkový stav',
    zdravie_head_neck: 'Zdraví Hlava a krk',
    zdravie_chest: 'Zdraví Hrudník',
    zdravie_motion_system: 'Zdraví Pohybové ústrojí',
    zdravie_belly: 'Zdraví Břicho',
    zdravie_underbelly: 'Zdraví Podbřišek',
    zdravie_skin_hair_nails: 'Zdraví Kůže, vlasy, nehty',
    zdravie_pregnancy_motherhood: 'Zdraví Těhotenství a mateřství',
    zdravie_psyche: 'Zdraví Psychika',
    zdravie_life_style: 'Zdraví Životní styl',
    zdravie_healthy_eating: 'Zdraví Zdravé stravování'
  },
  query: 'Hlavní nastavení',
  id: 'Id',
  category: 'Kategorie',
  slug: 'URL',
  slug_desc: 'Minimální délka 3 znaky, může obsahovat pouze znaky (a-z), pomlčku (-) nebo číslo (0-9) a pro stránku musí být unikátní',
  title: 'Titulek',
  title_placeholder: 'Napište název tématu',
  site: 'Stránka',
  description: 'Popis',
  meta: 'Meta nastavení',
  meta_title: 'Meta titulek (title)',
  meta_description: 'Meta popis (description)',
  meta_keywords: 'Meta klíčová slova (keywords)',
  image: 'Hlavní obrázek',
  image_bg: 'Obrázek v pozadí',
  tags_required: 'Povinná klíčová slova',
  tags_required_desc: 'Seznam klíčových slov, která musí článek obsahovat',
  tags_optional: 'Doplňková klíčová slova',
  tags_optional_desc: 'Článek musí obsahovat alespoň jedno z těchto klíčových slov',
  sites_selected: 'Vybrané stránky',
  sites_selected_desc: 'Pokud není vybrána žádná stránka, vyberou se všechny',
  rubrics_selected: 'Vybrané rubriky',
  rubrics_selected_desc: 'Pokud není vybrána žádná rubrika, vyberou se všechny',
  article_types: 'Typ článku',
  article_types_desc: 'Pokud není vybrán žádný typ, vyberou se všechny',
  article_count: 'Počet článků tématu',
  article_count_desc: 'Aktualizuje se jednou za den',
  sport_table: {
    title: 'Sportovní tabulky',
    season_id: 'ID sezóny',
    active_tab: 'Aktivní tab (první = 1)'
  },
  notify: {
    slug_site_combination_must_be_unique: 'URL pro stránku musí být unikátní, zvolte jinou URL'
  }
}

export default messages
