import moment from 'moment'

const getDataHubFilterStartDate = () => {
  const minStartDate = moment('2024-01-01')
  let result = moment().startOf('month').add(-6, 'months')
  if (result.isBefore(minStartDate)) {
    result = minStartDate
  }
  return result
}

export default {
  startDate: getDataHubFilterStartDate().format('Y-MM'),
  endDate: moment().startOf('month').format('Y-MM'),
  brands: []
}
