import { dateTimeNow } from '../services/DateTimeService'

export default {
  id: null,
  title: '',
  bodyText: '',
  author: '',
  image: null,
  site: null,
  publishedSince: dateTimeNow()
}
