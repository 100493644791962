import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'videoDashboard',
    component: () => import('../../views/video/VideoStatsDashboardView'),
    name: 'videoDashboard',
    meta: {
      description: 'Video statistic dashboard',
      requiresAuth: true,
      requiredPermissionModules:
        PermissionService.REQUIRED_PERMISSIONS.VIDEO_STATISTICS_PERMISSIONS.listRoute
    }
  },
  {
    path: 'videoPublishedReport',
    component: () => import('../../views/video/VideoStatsPublishedReportView'),
    name: 'videoPublishedReport',
    meta: {
      description: 'Published video statistics',
      requiresAuth: true,
      requiredPermissionModules:
        PermissionService.REQUIRED_PERMISSIONS.VIDEO_PUBLISHED_REPORT_PERMISSIONS.listRoute
    }
  },
  {
    path: 'videoDailyReport',
    component: () => import('../../views/video/VideoStatsDailyReportView'),
    name: 'videoDailyReport',
    meta: {
      description: 'Video daily report',
      requiresAuth: true,
      requiredPermissionModules:
        PermissionService.REQUIRED_PERMISSIONS.VIDEO_DAILY_REPORT_PERMISSIONS.listRoute
    }
  },
  {
    path: 'videoKpiReports',
    component: () => import('../../views/video/VideoStatsKpiReportsView'),
    name: 'videoKpiReports',
    meta: {
      description: 'KPI reports',
      requiresAuth: true,
      requiredPermissionModules:
        PermissionService.REQUIRED_PERMISSIONS.VIDEO_KPI_REPORT_PERMISSIONS.listRoute
    }
  }
]

export default routes
