import { TOPIC_CATEGORY_DEFAULT } from '../components/mixins/valueObject/TopicCategoryMixin'

export default {
  id: null,
  category: TOPIC_CATEGORY_DEFAULT,
  slug: '',
  title: '',
  site: null,
  description: '',
  image: null,
  imageBg: null,
  tagsRequired: [],
  tagsOptional: [],
  sitesSelected: [],
  articleTypes: [],
  meta: {
    title: '',
    description: '',
    keywords: ''
  },
  sportTable: {
    seasonId: 0,
    activeTab: 1
  },
  expanded: {
    sitesSelected: [],
    tagsRequired: [],
    tagsOptional: [],
    articleTypes: []
  },
  articleCount: 0
}
