export const SOURCE_UNDEFINED = 'undefined'
export const SOURCE_USER_DEFINED = 'user_defined'
export const SOURCE_EXTERNAL_FALLBACK = 'external_fallback'
export const SOURCE_INTERNAL_FALLBACK = 'internal_fallback'

export const AVAILABLE_TYPES = [
  {
    id: SOURCE_UNDEFINED,
    title: 'Undefined'
  },
  {
    id: SOURCE_USER_DEFINED,
    title: 'User defined'
  },
  {
    id: SOURCE_EXTERNAL_FALLBACK,
    title: 'External fallback'
  },
  {
    id: SOURCE_INTERNAL_FALLBACK,
    title: 'Internal fallback'
  }
]
