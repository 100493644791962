import moment from 'moment'
import AnalyticsApi from '../../api/analytics'
import EdonApi from '../../api/edon'
import KpiNmhFilter from '../../model/KpiNmhFilter'
import KpiNmhSettingsFilter from '../../model/KpiNmhSettingsFilter'

const API_NAME = '/GemiusReports'
const SETTINGS_API_NAME = '/nmh-kpi-settings'

const state = {
  filter: KpiNmhFilter,
  list: [],
  month: [],
  settings: {
    filter: KpiNmhSettingsFilter,
    item: {},
    list: []
  }
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData
  },
  storeMonth (state, responseData) {
    state.month = responseData
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setSettingsFilter (state, filter) {
    state.filter = filter
  },
  storeSettingsItem (state, responseData) {
    state.settings.item = responseData
  },
  storeSettingsList (state, responseData) {
    state.settings.list = responseData.data
  }
}

const actions = {
  async fetch (store) {
    try {
      const { dateFrom, dateTo, platform } = store.state.filter
      const monthEnd = moment(dateTo).endOf('month').format('Y-MM-DD')
      const response = await AnalyticsApi().get(API_NAME + `/List?platform=${platform}&from=${dateFrom}-01&to=${monthEnd}&unit=month&siteType=nmh`)
      store.commit('storeList', response.data)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async fetchMonthPrediction (store) {
    try {
      const date = moment().format('Y-MM')
      const monthEnd = moment(date).endOf('month').format('Y-MM-DD')
      const response = await AnalyticsApi().get(API_NAME + `/List?platform=99&from=${date}-01&to=${monthEnd}&unit=day&siteType=nmh`)
      store.commit('storeMonth', response.data)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async fetchSettings (store) {
    try {
      const { dateFrom, dateTo } = store.state.filter
      const response = await EdonApi().get(SETTINGS_API_NAME + `?filter_gte[id]=${dateFrom}&filter_lte[id]=${dateTo}`)
      store.commit('storeSettingsList', response.data)
    } catch (error) {
      console.error(error)
    }
  },
  async fetchSettingsItem (store) {
    try {
      const response = await EdonApi().get(SETTINGS_API_NAME + '/' + store.state.settings.filter.date)
      store.commit('storeSettingsItem', response.data)
    } catch (error) {
      console.error(error)
    }
  },
  async updateSettingsItem (store, record) {
    try {
      const response = await EdonApi().post(SETTINGS_API_NAME, JSON.stringify(record))
      store.commit('storeSettingsItem', response.data)
    } catch (error) {
      console.error(error)
    }
  }
}

const getters = {
  filter (state) {
    return state.filter
  },
  list (state) {
    return state.list
  },
  month (state) {
    return state.month
  },
  settingsFilter (state) {
    return state.settings.filter
  },
  settingsItem (state) {
    return state.settings.item
  },
  settingsList (state) {
    return state.settings.list
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
