import PermissionService from '@/services/PermissionService'

const managementRequiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SPONSORED_ARTICLES_CAMPAIGN_MANAGEMENT_PERMISSIONS
const progressRequiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SPONSORED_ARTICLES_CAMPAIGN_PROGRESS_PERMISSIONS

const routes = [
  {
    path: 'nativePerfArticle/new',
    component: () => import('../../components/don/NativePerfArticleNew'),
    name: 'don_npa_new',
    meta: {
      description: 'Create a Native Perf Article',
      requiresAuth: true,
      requiredPermissionModules: managementRequiredPermissions.newRoute
    }
  },
  {
    path: 'nativePerfArticle/:id/edit',
    component: () => import('../../components/don/NativePerfArticleEdit'),
    name: 'don_npa_edit',
    meta: {
      description: 'Edit a Native Perf Article',
      requiresAuth: true,
      requiredPermissionModules: [
        ...managementRequiredPermissions.editRoute,
        ...progressRequiredPermissions.editRoute
      ]
    }
  },
  {
    path: 'nativePerfArticle/:id/duplicate',
    component: () => import('../../components/don/NativePerfArticleDuplicate'),
    name: 'don_npa_duplicate',
    meta: {
      description: 'Duplicate a Native Perf Article',
      requiresAuth: true,
      requiredPermissionModules: managementRequiredPermissions.duplicateRoute
    }
  },
  {
    path: 'nativePerfArticle/:id/detail',
    component: () => import('../../components/don/NativePerfArticle'),
    name: 'don_npa_detail',
    meta: {
      description: 'Get a Native Perf Article detail',
      requiresAuth: true,
      requiredPermissionModules: [
        ...managementRequiredPermissions.detailRoute,
        ...progressRequiredPermissions.detailRoute
      ]
    }
  },
  {
    path: 'nativePerfArticle',
    component: () => import('../../components/don/NativePerfArticleList'),
    name: 'don_npa_list',
    meta: {
      description: 'Get a Native Perf Article list',
      requiresAuth: true,
      requiredPermissionModules: managementRequiredPermissions.listRoute
    }
  },
  {
    path: 'nativePerfArticle/config',
    component: () => import('../../components/don/NativePerfConfig'),
    name: 'don_npa_config',
    meta: {
      description: 'Configure Native Perf Article settings',
      requiresAuth: true,
      requiredPermissionModules: managementRequiredPermissions.configRoute
    }
  },
  {
    path: 'nativePerfPredictions',
    component: () => import('../../components/don/NativePerfPredictions'),
    name: 'don_npa_predictions',
    meta: {
      description: 'Get Native Perf Article predictions',
      requiresAuth: true,
      requiredPermissionModules: progressRequiredPermissions.listRoute
    }
  }
]

export default routes
