import { BREAKING_NEWS_STATE_PUBLISHED } from '@/components/mixins/valueObject/BreakingNewsTypeMixin'

const buildFilterQuery = function (filter) {
  var filterQuery = ''
  if (filter.text) {
    filterQuery += '&filter_contains[text]=' + filter.text
  }
  if (filter.site?.length > 0) {
    filterQuery += '&filter_in[site]=' + filter.site.join(',')
  }
  if (filter.isPublished !== null) {
    const query = BREAKING_NEWS_STATE_PUBLISHED === filter.isPublished ? 'true' : 'false'
    filterQuery += '&filter_eq[isPublished]=' + query
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
