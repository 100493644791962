export const TOPIC_CATEGORY_COMMON = 'common'
export const TOPIC_CATEGORY_TAG = 'tag'
export const TOPIC_CATEGORY_SPORT24_PLAYER = 'sport24_player'
export const TOPIC_CATEGORY_SPORT24_TEAM = 'sport24_team'
export const TOPIC_CATEGORY_WANDA_CELEBRITY = 'wanda_celebrity'
export const TOPIC_CATEGORY_ZDRAVIE_OVERALL_CONDITION = 'zdravie_overall_condition'
export const TOPIC_CATEGORY_ZDRAVIE_HEAD_NECK = 'zdravie_head_neck'
export const TOPIC_CATEGORY_ZDRAVIE_CHEST = 'zdravie_chest'
export const TOPIC_CATEGORY_ZDRAVIE_MOTION_SYSTEM = 'zdravie_motion_system'
export const TOPIC_CATEGORY_ZDRAVIE_BELLY = 'zdravie_belly'
export const TOPIC_CATEGORY_ZDRAVIE_UNDERBELLY = 'zdravie_underbelly'
export const TOPIC_CATEGORY_ZDRAVIE_SKIN_HAIR_NAILS = 'zdravie_skin_hair_nails'
export const TOPIC_CATEGORY_ZDRAVIE_PREGNANCY_MOTHERHOOD = 'zdravie_pregnancy_motherhood'
export const TOPIC_CATEGORY_ZDRAVIE_PSYCHE = 'zdravie_psyche'
export const TOPIC_CATEGORY_ZDRAVIE_LIFE_STYLE = 'zdravie_life_style'
export const TOPIC_CATEGORY_ZDRAVIE_HEALTHY_EATING = 'zdravie_healthy_eating'

export const TOPIC_CATEGORY_DEFAULT = TOPIC_CATEGORY_COMMON

export default {
  computed: {
    topicCategoryValues () {
      return [
        {
          id: TOPIC_CATEGORY_COMMON,
          title: this.$t('topic.topic_category.common')
        },
        {
          id: TOPIC_CATEGORY_TAG,
          title: this.$t('topic.topic_category.tag')
        },
        {
          id: TOPIC_CATEGORY_SPORT24_PLAYER,
          title: this.$t('topic.topic_category.sport24_player')
        },
        {
          id: TOPIC_CATEGORY_SPORT24_TEAM,
          title: this.$t('topic.topic_category.sport24_team')
        },
        {
          id: TOPIC_CATEGORY_WANDA_CELEBRITY,
          title: this.$t('topic.topic_category.wanda_celebrity')
        },
        {
          id: TOPIC_CATEGORY_ZDRAVIE_OVERALL_CONDITION,
          title: this.$t('topic.topic_category.zdravie_overall_condition')
        },
        {
          id: TOPIC_CATEGORY_ZDRAVIE_HEAD_NECK,
          title: this.$t('topic.topic_category.zdravie_head_neck')
        },
        {
          id: TOPIC_CATEGORY_ZDRAVIE_CHEST,
          title: this.$t('topic.topic_category.zdravie_chest')
        },
        {
          id: TOPIC_CATEGORY_ZDRAVIE_MOTION_SYSTEM,
          title: this.$t('topic.topic_category.zdravie_motion_system')
        },
        {
          id: TOPIC_CATEGORY_ZDRAVIE_BELLY,
          title: this.$t('topic.topic_category.zdravie_belly')
        },
        {
          id: TOPIC_CATEGORY_ZDRAVIE_UNDERBELLY,
          title: this.$t('topic.topic_category.zdravie_underbelly')
        },
        {
          id: TOPIC_CATEGORY_ZDRAVIE_SKIN_HAIR_NAILS,
          title: this.$t('topic.topic_category.zdravie_skin_hair_nails')
        },
        {
          id: TOPIC_CATEGORY_ZDRAVIE_PREGNANCY_MOTHERHOOD,
          title: this.$t('topic.topic_category.zdravie_pregnancy_motherhood')
        },
        {
          id: TOPIC_CATEGORY_ZDRAVIE_PSYCHE,
          title: this.$t('topic.topic_category.zdravie_psyche')
        },
        {
          id: TOPIC_CATEGORY_ZDRAVIE_LIFE_STYLE,
          title: this.$t('topic.topic_category.zdravie_life_style')
        },
        {
          id: TOPIC_CATEGORY_ZDRAVIE_HEALTHY_EATING,
          title: this.$t('topic.topic_category.zdravie_healthy_eating')
        }
      ]
    }
  }
}
