import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_NOTIFICATION_PERMISSIONS

const routes = [
  {
    path: 'notification/new',
    component: () => import('../../views/notificationService/notifications/NotificationNewView'),
    name: 'notification_new',
    meta: {
      description: 'Notification new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'notification/:id/edit',
    component: () => import('../../views/notificationService/notifications/NotificationEditView'),
    name: 'notification_edit',
    meta: {
      description: 'Notification edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'notification/:id',
    component: () => import('../../views/notificationService/notifications/NotificationView'),
    name: 'notification_detail',
    meta: {
      description: 'Notification detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'notification',
    component: () => import('../../views/notificationService/notifications/NotificationListView'),
    name: 'notification_list',
    meta: {
      description: 'Notification list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
