import { UserManager, OidcClient } from 'oidc-client-ts'
import appConfig from '@/config'

const getSettings = () => {
  return {
    authority: appConfig.sso.oidcManager.authority(),
    client_id: appConfig.sso.oidcManager.clientId(),
    scope: appConfig.sso.oidcManager.scope(),
    response_type: appConfig.sso.oidcManager.responseType(),
    response_mode: appConfig.sso.oidcManager.responseMode(),
    redirect_uri: appConfig.sso.oidcManager.redirectUri(),
    post_logout_redirect_uri: appConfig.sso.oidcManager.postLogoutRedirectUri(),
    // keep automaticSilentRenew set to false as we renew the token manually in userStore.js (setRefreshTimer())
    //    and 'automaticSilentRenew: true' would cause the refresh to be called five times with errors each time (see CMS-2205)
    automaticSilentRenew: false
  }
}

const getOidcUserManager = () => {
  return new UserManager(getSettings())
}

const getOidcClient = () => {
  return new OidcClient(getSettings())
}

export default {
  getOidcUserManager,
  getOidcClient
}
