import _ from 'lodash'

const prepareApiRequest = function (editedGallery) {
  const gallery = _.cloneDeep(editedGallery)
  if (gallery.expanded) {
    if (gallery.expanded.medias) {
      gallery.medias = gallery.expanded.medias.map((image) => image.id)
    }
    if (gallery.expanded.tags) {
      gallery.tags = gallery.expanded.tags.map((tag) => tag.id)
    }
  }

  return gallery
}

export default {
  prepareApiRequest
}
