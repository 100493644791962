export const PAYMENT_TYPE_NEW = 'new'
export const PAYMENT_TYPE_RECURRING = 'recurring'
export const PAYMENT_TYPE_TOTAL = 'total'

export default {
  computed: {
    paymentTypeValues () {
      return [
        {
          id: PAYMENT_TYPE_NEW,
          title: this.$t('eshopDashboard.payment_type.new')
        },
        {
          id: PAYMENT_TYPE_RECURRING,
          title: this.$t('eshopDashboard.payment_type.recurring')
        },
        {
          id: PAYMENT_TYPE_TOTAL,
          title: this.$t('eshopDashboard.payment_type.total')
        }
      ]
    }
  }
}
