import moment from 'moment'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.feUser) {
    filterQuery += '&filter_eq[feUser]=' + filter.feUser
  }
  if (filter.userEmail) {
    filterQuery += '&filter_custom[subscriber.user.email]=' + filter.userEmail
  }
  if (filter.product) {
    filterQuery += '&filter_custom[subscriber.product]=' + filter.product
  }
  if (filter.subscriptionCode) {
    filterQuery += '&filter_custom[subscription.code]=' + filter.subscriptionCode
  }
  if (filter.uniqueEmail !== 'none') {
    filterQuery += '&filter_custom[subscriber.user.unique_email]=' + filter.uniqueEmail
  }
  if (filter.subscriptionActiveSince && filter.subscriptionActiveUntil) {
    const activeSince = moment(filter.subscriptionActiveSince).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    const activeUntil = moment(filter.subscriptionActiveUntil).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_custom[subscription.active]=' + activeSince + ';' + activeUntil
  }
  if (filter.createdAtFrom) {
    const date = moment(filter.createdAtFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[createdAt]=' + date
  }
  if (filter.createdAtTo) {
    const date = moment(filter.createdAtTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[createdAt]=' + date
  }
  if (filter.cancelledAtFrom) {
    const date = moment(filter.cancelledAtFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[cancelledAt]=' + date
  }
  if (filter.cancelledAtTo) {
    const date = moment(filter.cancelledAtTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[cancelledAt]=' + date
  }
  if (filter.firstOrder !== 'none') {
    filterQuery += '&filter_custom[order.first]=' + filter.firstOrder
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
