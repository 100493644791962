<template>
  <div class="version-checker" v-if="appVersionClient !== appVersionServer">
    <p class="version-checker__text">
      <strong class="version-checker__text-strong">{{ $t('version.header_2') }}</strong>
    </p>
    <div class="version-checker__wr">
      <p class="version-checker__text version-checker__text--versions">
        <span>
          {{ $t('version.current_version') }}: <strong class="version-checker__strong"> {{ appVersionClient }} </strong>
        </span>
        <span>
          {{ $t('version.available_version') }}: <strong class="version-checker__strong"> {{ appVersionServer }} </strong>
        </span>
      </p>
      <button class="version-checker__btn" @click="pageReload">
        {{ $t('version.load_new_version') }}
      </button>
    </div>
  </div>
</template>

<script>
import appConfig from '@/config'

const CHECK_VERSION_INTERVAL = 60000

export default {
  name: 'Versionchecker',
  data () {
    return {
      checkVersionId: 0,
      appVersionClient: appConfig.appVersion(),
      appVersionServer: appConfig.appVersion()
    }
  },
  methods: {
    checkVersionInLoop () {
      this.checkVersionId = setInterval(() => {
        this.checkVersion()
      }, CHECK_VERSION_INTERVAL)
    },
    checkVersion () {
      fetch('/config.json', { cache: 'no-cache' })
        .then(response => {
          return response.json()
        })
        .then(appVersionServer => {
          this.appVersionServer = appVersionServer.appVersion
        })
        .catch(error => {
          console.error(error)
        })
    },
    pageReload () {
      window.location.reload()
    }
  },
  created () {
    this.checkVersion()
    this.checkVersionInLoop()
  },
  beforeDestroy () {
    clearInterval(this.checkVersionId)
  }
}
</script>

<style scoped lang="scss">
  .version-checker {
    @include fixed(0 _ _ 50%, 999);
    @include size(400px _);
    @include padding(15px 10px);
    @include grid-gap(15px);
    transform: translate(-50%, 0);
    background: #6599fe;
    text-align: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: grid;
    &__wr {
      @include grid-gap(20px);
      display: grid;
      grid-template-columns: max-content max-content;
      justify-content: center;
      text-align: left;
      align-items: center;
    }
    &__text {
      @include font(500 18px "Roboto");
      color: #fff;
      margin: 0;
      padding: 0;
      &--versions {
        @include font(400 15px "Roboto");
        @include grid-gap(5px);
        display: grid;
      }
    }
    &__strong {
      @include font(600 15px "Roboto");
    }
    &__btn {
      @include font(500 14px "Roboto");
      @include size(auto 40px);
      @include radius(4px);
      @include padding(0 15px);
      cursor: pointer;
      margin: 0;
      color: #6599fe;
      border: none;
      background: #fff;
      transition: 150ms;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
</style>
