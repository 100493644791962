const messages = {
  id: 'ID',
  created_at: 'Vytvorené',
  created_at_from: 'Vytvorené od',
  created_at_to: 'Vytvorené do',
  created_by: 'Vytvoril',
  modified_at: 'Zmenené',
  modified_by: 'Úpravil',
  type: 'Typ',
  code: 'Kód',
  digitalServices: 'Digitálne služby',
  activated: 'Aktivovaný',
  expiration_at: 'Dátum expirácie',
  activeSince: 'Dátum platnosti od',
  activeUntil: 'Dátum platnosti do',
  activeDays: 'Počet dní',
  setting: {
    marketing: 'Marketing',
    admin: 'Admin'
  },
  admin: 'Admin',
  countOfCodes: 'Počet kódov',
  list: 'Zoznam',
  accounts: 'Počet prístupov',
  create_new: 'Vytvor nový',
  save: 'Uložiť',
  systemInfo: 'Systémové informácie',
  subscribers: 'Predplatitelia',
  email: 'Email',
  importTrend: {
    datvys: 'DATVYS',
    cisfak: 'CISFAK',
    cisdo: 'CISDO',
    pocet: 'POCET',
    prij: 'PRIJ',
    dateTo: 'Dátum do'
  },
  filter: {
    export: 'Exportovať',
    search: 'Hľadať',
    reset_filter: 'Zrušiť filter'
  },
  button: {
    importTrend: 'Import TREND',
    cancel: 'Zrušiť',
    confirm: 'Potrvdiť'
  },
  error: {
    required_fields: 'Prosím, vyplňte všetky povinné polia',
    required_date_interval: 'Prosím, vyplňte dátum platnosti od-do'
  },
  message: {
    created_record: 'Záznam bol vytvorený!',
    updated_record: 'Záznam bol zmenený!',
    deleted_record: 'Záznam bol vymazaný!'
  }
}

export default messages
