const messages = {
  universal: {
    yesOrNoArea: {
      yes: 'Yes',
      no: 'No'
    }
  }
}

export default messages
