import CoreApi from '@/api/core'
import QuizModel from '@/model/QuizModelVlm.js'
import QuizFilterService from '@/services/quiz/QuizFilterService'
import config from '@/config'

const API_NAME = '/quiz'

const state = {
  error: null,
  detail: QuizModel,
  list: [],
  totalCount: 0,
  page: 1,
  filter: {
    id: '',
    title: ''
  }
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = {
      id: '',
      title: '',
      type: '',
      category: ''
    }
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * config.defaults.list.limit) - config.defaults.list.limit
    const url = API_NAME +
      '?limit=' + config.defaults.list.limit +
      '&offset=' + offset +
      '&order[id]=desc&filter_neq[type]=one_question' + QuizFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async create (store, record) {
    return await CoreApi().post(API_NAME, record)
      .then(response => {
        store.commit('storeDetail', response.data)
        store.commit('setError', null)
      })
      .catch(error => {
        store.commit('setError', error)
      })
  },
  async update (store, record) {
    return await CoreApi().put(`${API_NAME}/${record.id}`, record)
      .then(response => {
        store.commit('storeDetail', response.data)
        store.commit('setError', null)
      })
      .catch(error => {
        store.commit('setError', error)
      })
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(`${API_NAME}/${record.id}`)
      .then(() => {
        store.commit('setError', null)
      })
      .catch(error => {
        store.commit('setError', error)
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
