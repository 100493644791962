const messages = {
  list: {
    id: 'Id',
    title: 'Titulok',
    code: 'Kód',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril'
  },
  filter: {
    title: 'Titulok',
    id: 'Id',
    code: 'Kód',
    search: 'Hľadať'
  },
  id: 'Id',
  title: 'Titulok',
  code: 'Kód'
}

export default messages
