const messages = {
  time: 'Čas',
  backwards: 'Dozadu',
  forwards: 'Dopředu',
  refresh: 'Načíst znovu',
  now: 'Nyní',
  position: 'Pozice',
  add_article: 'Přidat článek',
  add_video: 'Přidat video',
  copy_article: 'Kopírovat článek',
  insert_article: 'Duplikovat článek',
  insert_video: 'Duplikovat video',
  interval_1: '15 minut',
  interval_2: '30 minut',
  interval_3: '1 hodina',
  interval_4: '4 hodiny',
  delete_modal_header: 'Odstranit tento záznam?',
  delete_modal_text: 'Jste si jisti, že chcete odstranit tento záznam?',
  delete_modal_no: 'Ne',
  delete_modal_yes: 'Ano',
  create_modal_header: 'Přidat článek',
  create_video_modal_header: 'Přidat video',
  info_modal_header: 'Info',
  article_not_found: 'Článek nebyl nalezen nebo je ve stavu draft.',
  video_not_found: 'Video nebylo nalezeno.',
  gap_busy: 'Pozice v daném intervalu nemá volné místo na další článek.',
  article_status_warning: 'Článek momentálně není publikován, chcete ho stejně naplánovat?',
  filter: {
    site: 'Stránka',
    visible_blocks: 'Viditelné boxy',
    reset: 'Zrušit filtr'
  },
  status: 'Status',
  type: 'Typ',
  internal: 'Interní',
  external: 'Externí',
  published_since: 'Publikováno od',
  published_until: 'Publikováno do',
  article_document_id: 'ID článku',
  article_info: 'Info článku',
  video_info: 'Info videa',
  image: 'Obrázek',
  video: 'Video',
  video_id: 'Video ID',
  title: 'Titulek',
  ctr_info: 'CTR je vyjádřeno jako průměrné CTR na dané pozici za posledních 7 dní',
  alternative_title: 'Alternativní titulek',
  article: {
    status: 'Stav',
    title: 'Titulek',
    url: 'URL',
    first_paragraph: 'Perex',
    site_name: 'Jméno stránky',
    site_url: 'URL adresa stránky',
    rubric_name: 'Název rubriky',
    rubric_url: 'URL adresa rubriky',
    author: 'Autor',
    image: 'Obrázek',
    flags: 'Značky na webe',
    rubric: 'Rubrika',
    order_date: 'Datum publikování',
    published_since: 'Publikováno od',
    published_until: 'Publikováno do'
  }
}

export default messages
