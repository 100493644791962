import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: '/help',
    component: () => import('../../views/video/VideoHelpListView'),
    name: 'help_video_list',
    meta: {
      description: 'Help',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .VIDEO_TUTORIAL_PERMISSIONS
        .listRoute
    }
  }
]

export default routes
