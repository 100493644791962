export default {
  id: null,
  street: '',
  district: null,
  city: null,
  region: null,
  country: null,
  expanded: {
    district: null,
    city: null,
    region: null,
    country: null
  }
}
