const messages = {
  id: 'ID',
  created_at: 'Created at',
  created_at_from: 'Created at from',
  created_at_to: 'Created at to',
  created_by: 'Created by',
  modified_at: 'Modified at',
  modified_by: 'Modified by',
  email: 'Email',
  variableSymbol: 'VS',
  name: 'Name',
  price: 'Price',
  product: 'Product',
  payment: 'Payment',
  paymentResult: 'Payment result',
  cardNumber: 'Student card number',
  count: 'Count',
  sameDeliveryAddress: 'Same delivery address',
  invoiceAddress: 'Invoice address',
  deliveryAddress: 'Delivery address',
  notice: 'Notice',
  giftSubscription: 'Darčekové predplatné',
  aboStartEdition: 'Start edition',
  systemInfo: 'System info',
  history: 'History',
  historyType: 'type',
  historyDescription: 'description',
  payments: 'Payments',
  paymentStatusType: 'Typ',
  create_new: 'Create new',
  save: 'Save',
  address: {
    type: 'Type',
    name: 'Name',
    surname: 'Surname',
    company: 'Company',
    ico: 'ICO',
    dic: 'DIC',
    icDph: 'IC DPH',
    street: 'Street',
    city: 'City',
    zip: 'Zip',
    country: 'Country'
  },
  abo: {
    title: 'ABO',
    idObj: "Order's number",
    pcZakaz: "Customer's number"
  },
  subscriptionStatus: {
    mailSent: 'Mail sent',
    aboExported: 'ABO exported',
    activated: 'Activated'
  },
  feUser: 'Frontend user',
  filter: {
    export: 'Export',
    search: 'Search',
    reset_filter: 'Reset filter'
  },
  button: {
    changePaymentResult: 'Change payment result',
    more: 'More'
  },
  modal: {
    changePaymentResultHeader: 'Change payment result?',
    changePaymentResultText: '',
    changePaymentResultCancelBtn: 'No',
    changePaymentResultConfirmBtn: 'Yes, change'
  },
  error: {
    required_fields: 'Please fill all required fields'
  },
  message: {
    updated_record: 'Record was updated!',
    deleted_record: 'Record was deleted!'
  }
}

export default messages
