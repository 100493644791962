export default {
  weightsId: '',
  weightsName: '',
  usersPerformance: {
    printQuality: 0,
    onlineQuality: 0,
    subscriptions: 0,
    socialEngagement: 0,
    videoQuality: 0,
    articlesQuantity: 0,
    videoQuantity: 0,
    total: 0
  }
}
