<template>
  <div v-if="canCreateArticles">
    <button
      class="dash--new-article-btn"
      type="button"
      @click="showCreateModal"
    >
      <SvgIconPenToSquare />
      <span>{{ $t('buttons.new_article') }}</span>
    </button>
    <ArticleCreateModal
      v-if="createModal"
      @close="closeCreateModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SvgIconPenToSquare from '@/assets/img/svg/pen-to-square.svg?inline'
import ArticleCreateModal from '@/components/article/ArticleCreateModal'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'DashHeaderNewArticleBtn',
  components: {
    SvgIconPenToSquare,
    ArticleCreateModal
  },
  data () {
    return {
      createModal: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.ARTICLE_PERMISSIONS
    }
  },
  computed: {
    ...mapGetters('user', ['currentUser']),
    canCreateArticles () {
      return this.hasPermission(this.requiredPermissions.createButton)
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    showCreateModal () {
      this.createModal = true
    },
    closeCreateModal () {
      this.createModal = false
    }
  }
}
</script>

<style lang="scss" scoped>

.dash--new-article-btn {
    padding-left: rem(15px);
    padding-right: rem(15px);
    margin-right: rem(15px);
    font-family: "Roboto", sans-serif;
    font-size: rem(13px);
    font-weight: 500;
    color: #6599FE;
    background: transparent;
    border: none;
    border-right: 1px solid #d1dbe4;
    cursor: pointer;
    height: rem(40px);
    transition: background 100ms;
    display: flex;
    align-items: center;
    span {
      margin-left: rem(5px);
      transform: translateY(1px);
    }
    svg {
      fill: #6599FE;
    }

    @include bp(0 7) {
      span {
        display: none;
      }
    }
}

</style>
