import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'app_performance',
    component: () => import('../../views/app/AppPerformanceView'),
    name: 'app_performance',
    meta: {
      description: 'AppPerformance',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .SYSTEM_APP_PERFORMANCE_PERMISSIONS
        .listRoute
    }
  }
]

export default routes
