import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.TOPIC_PERMISSIONS

const routes = [
  {
    path: 'topic/new',
    component: () => import('../../views/topic/TopicNewView'),
    name: 'topic_new',
    meta: {
      description: 'Topic new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'topic/:id/edit',
    component: () => import('../../views/topic/TopicEditView'),
    name: 'topic_edit',
    meta: {
      description: 'Topic edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'topic/:id',
    component: () => import('../../views/topic/TopicView'),
    name: 'topic_detail',
    meta: {
      description: 'Topic detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'topic',
    component: () => import('../../views/topic/TopicListView'),
    name: 'topic_list',
    meta: {
      description: 'Topic list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
