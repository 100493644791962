import moment from 'moment'

const PLATFORM_ALL = 99

const getFilterEndDate = () => {
  const maxEndDate = moment('2023-08-01')
  let result = moment().startOf('month')
  if (result.isAfter(maxEndDate)) {
    result = maxEndDate
  }
  return result
}
export default {
  dateFrom: getFilterEndDate().add(-6, 'months').format('Y-MM'),
  dateTo: getFilterEndDate().format('Y-MM'),
  platform: PLATFORM_ALL
}
