import moment from 'moment'

const ATLAS_HP = 2
const CENTRUM_HP = 6
const PLUSKA_UMBRELLA = 13
export const P1D = 14
const PLUS_TV = 17
const UJCO = 21
const CENTRUM_FREEMAIL = 28
const POBOX_HP = 29
const ENJOY = 30
const MEDIALNE = 31
const REALITY = 32
const PLUSKA_HP = 64

export const PRODUCT_SITE = {
  1: 19, // adam
  2: 102, // atlas
  3: 23, // autobild
  4: 6, // automix
  5: 22, // casprezeny
  6: 101, // centrum
  7: 18, // etrend
  8: 12, // emma
  9: 25, // dobrejedlo
  10: 20, // eva
  11: 7, // lepsiebyvanie
  12: 5, // magazin
  13: 1, // pluskaFull
  14: 1, // plus1den
  15: 2, // plus7dni
  16: 9, // peknebyvanie
  17: 104, // plustv
  18: 0, // referaty
  19: 13, // sarm
  20: 17, // sport24
  21: 0, // ujszo
  22: 3, // wanda
  23: 8, // zahradkar
  24: 10, // zdravie
  25: 4, // zena
  26: 21, // zivot
  27: 11, // polovnictvo
  28: 0, // freemail
  29: 103, // pobox
  30: 24, // enjoy
  31: 27, // medialne
  32: 26 // reality
}

export const COLORS = [
  '#EACD3F',
  '#6F308A',
  '#DA6927',
  '#98CDE5',
  '#B81F34',
  '#C0BC82',
  '#7F7E80',
  '#61A547',
  '#D386B1',
  '#4578B4',
  '#DD8465',
  '#473896',
  '#E0A02F',
  '#90278A',
  '#E8E756',
  '#7D1615',
  '#93AD3C',
  '#6E3413',
  '#D12D27',
  '#2B3516'
]
export const GROUPS = [
  'competitors',
  'channels',
  'devices',
  'products_perf'
]

export const hideChannels = (id) => [
  PLUSKA_UMBRELLA,
  CENTRUM_FREEMAIL,
  PLUS_TV,
  PLUSKA_HP,
  ATLAS_HP,
  CENTRUM_HP,
  POBOX_HP,
  ENJOY,
  UJCO,
  MEDIALNE,
  REALITY
].includes(id)

export const hideProductsPerf = (id) => [
  CENTRUM_FREEMAIL,
  PLUS_TV,
  ATLAS_HP,
  CENTRUM_HP,
  ENJOY,
  MEDIALNE,
  PLUSKA_HP,
  POBOX_HP,
  REALITY,
  UJCO
].includes(id)

export const PLATFORM_TOTAL = '99'
export const PLATFORM_MOBILE = '100'
export const PLATFORM_PC = '101'
export const PLATFORM_MOBILE_PHONES = '102'
export const PLATFORM_MOBILE_TABLETS = '103'
export const PLATFORM_PC_HOME = '104'
export const PLATFORM_PC_WORK = '105'

export const UNIT_DAY = 'day'
export const UNIT_MONTH = 'month'

export const getUnits = (from, to) => {
  const monthDiff = moment(to).diff(from, 'month')
  if (monthDiff >= 2 && monthDiff < 3) {
    return [UNIT_DAY, UNIT_MONTH]
  } else if (monthDiff >= 3) {
    return [UNIT_MONTH]
  }
  return [UNIT_DAY]
}

export default {
  product: PLUSKA_UMBRELLA,
  from: moment().subtract(3, 'week').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  unit: UNIT_DAY
}
