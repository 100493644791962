export default {
  id: 0,
  title: '',
  publication: null,
  sharedUsers: [],
  type: '',
  quality: '',
  native: null,
  department: null,
  publicationWeek: null,
  user: null
}
