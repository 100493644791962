import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SPORT24_PERMISSIONS

const routes = [
  {
    path: 'sport24/:id',
    component: () => import('../../views/sport/SportTeamSeasonView'),
    name: 'sport24_detail',
    meta: {
      description: 'Sport detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'sport24',
    component: () => import('../../views/sport/SportSeasonListView'),
    name: 'sport24_list',
    meta: {
      description: 'Sport tables',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
