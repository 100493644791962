const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.name) {
    filterQuery += '&filter_contains[name]=' + filter.name
  }
  if (filter.surname) {
    filterQuery += '&filter_contains[surname]=' + filter.surname
  }
  if (filter.street) {
    filterQuery += '&filter_contains[street]=' + filter.street
  }
  if (filter.city) {
    filterQuery += '&filter_contains[city]=' + filter.city
  }
  if (filter.zip) {
    filterQuery += '&filter_contains[zip]=' + filter.zip
  }
  if (filter.aboCountryExpanded && filter.aboCountryExpanded.id) {
    filterQuery += '&filter_eq[aboCountry]=' + filter.aboCountryExpanded.id
  }
  if (filter.phone) {
    filterQuery += '&filter_contains[phone]=' + filter.phone
  }
  if (filter.feUser) {
    filterQuery += '&filter_eq[feUser]=' + filter.feUser
  }
  if (filter.feUser_email) {
    filterQuery += '&filter_custom[feUser.email]=' + filter.feUser_email
  }
  if (filter.ico) {
    filterQuery += '&filter_contains[ico]=' + filter.ico
  }
  if (filter.dic) {
    filterQuery += '&filter_contains[dic]=' + filter.dic
  }
  if (filter.icDph) {
    filterQuery += '&filter_contains[icDph]=' + filter.icDph
  }
  if (filter.company) {
    filterQuery += '&filter_contains[company]=' + filter.company
  }
  if (filter.type) {
    filterQuery += '&filter_eq[type]=' + filter.type
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
