import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ARTICLE_TEST_RESTAURANT_PERMISSIONS

const routes = [
  {
    path: 'articleTest/restaurant/new',
    component: () => import('../../views/article/ArticleTestRestaurantNewView'),
    name: 'restaurant_new',
    meta: {
      description: 'Restaurant new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'articleTest/restaurant/:id/edit',
    component: () => import('../../views/article/ArticleTestRestaurantEditView'),
    name: 'restaurant_edit',
    meta: {
      description: 'Restaurant edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'articleTest/restaurant/:id',
    component: () => import('../../views/article/ArticleTestRestaurantView'),
    name: 'restaurant_detail',
    meta: {
      description: 'Restaurant detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'articleTest/restaurant',
    component: () => import('../../views/article/ArticleTestRestaurantListView'),
    name: 'restaurant_list',
    meta: {
      description: 'Restaurant list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
