import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'articleHistory',
    component: () => import('../../views/article/ArticleHistoryListView'),
    name: 'articleHistory_list',
    meta: {
      description: 'Article history list',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .SYSTEM_ARTICLE_HISTORY_PERMISSIONS
        .listRoute
    }
  }
]

export default routes
