import { dateTimeNow } from '../../services/DateTimeService'
import SubscriptionOrder from './SubscriptionOrder'

export default {
  id: null,
  variableSymbol: '',
  price: '',
  order: 0,
  priceInEuro: '',
  orderProductName: '',
  orderPayment: '',
  orderPaymentExport: '',
  paymentResult: '',
  orderFull: SubscriptionOrder,
  orderEmail: '',
  orderPhone: '',
  status: '',
  comfortPaySmsStatus: '',
  smsStatus: '',
  comfortPayStatus: '',
  aboStatus: '',
  aboPeriodikStatus: '',
  payedAt: '',
  nextPaymentAt: '',
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  createdBy: 0,
  modifiedBy: 0,
  aboPeriodik: {
    orderId: '',
    variableSymbol: ''
  }
}
