import { dateTimeNow } from '../../services/DateTimeService'

export default {
  id: null,
  name: '',
  slug: '',
  period: '',
  trialPeriod: {
    enabled: 0,
    price: '',
    priceInEuro: '',
    description: ''
  },
  trialPeriod_aboProduct: 0,
  trialPeriod_aboPeriodikPrice: 0,
  payment: {
    default: '',
    free: 0,
    transfer: 0,
    cardpay: 0,
    tatrapay: 0,
    comfortpay: 0,
    smsRecurring: 0,
    csobCardPay: 0,
    csobGooglePay: 0,
    csobMasterCardMobile: 0
  },
  visibility: '',
  publishedSince: '',
  publishedUntil: '',
  description: {
    short: '',
    main: '',
    landingPage: '',
    summaryPage: ''
  },
  metadata: {
    keywords: '',
    description: ''
  },
  setting: {
    cardNumber: '',
    company: '',
    invoiceAddress: '',
    deliveryAddress: '',
    notice: '',
    platform: {
      print: 0,
      iOS: 0,
      android: 0,
      ePaper: 0,
      windows8: 0
    },
    tag: {
      recommended: 0,
      convenientOffer: 0
    },
    email: ''
  },
  relatedProducts: [],
  digitalServices: [],
  subscriptionSetting: {
    giftSubscription: 0,
    companyProduct: 0,
    companyProductCount: 0,
    notifyBeforeNextRecurringPayment: 0
  },
  image: {
    type: '',
    uuid: '',
    site: ''
  },
  aboProduct: 0,
  aboPeriodikPrice: 0,
  aboStartEdition: 0,
  addOnProduct: 0,
  addOnProductDescription: '',
  price: '',
  priceInEuro: '',
  priceDescription: '',
  enabled: true,
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  createdBy: 0,
  modifiedBy: 0
}
