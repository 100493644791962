import { dateTimeNow } from '../../services/DateTimeService'

export default {
  id: null,
  name: '',
  surname: '',
  street: '',
  city: '',
  zip: '',
  aboCountry: 0,
  aboCountryExpanded: {},
  phone: '',
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  createdBy: '',
  modifiedBy: '',
  feUser: 0,
  feUserExpanded: {},
  type: '',
  company: '',
  ico: '',
  dic: '',
  icDph: ''
}
