const messages = {
  id: 'ID',
  created_at: 'Vytvorené',
  created_at_from: 'Vytvorené od',
  created_at_to: 'Vytvorené do',
  created_by: 'Vytvoril',
  modified_at: 'Zmenené',
  modified_by: 'Úpravil',
  name: 'Názov',
  slug: 'Slug',
  period: 'Obdobie',
  price: 'Cena',
  visibility: 'Umiestnenie',
  description: {
    title: 'Popis produktu',
    short: 'Krátky',
    main: 'Hlavný',
    landingPage: 'Landing page',
    summaryPage: 'Zhrnutie'
  },
  metadata: {
    title: 'Metadata',
    keywords: 'Kľúčové slová',
    description: 'Popis'
  },
  setting: {
    cardNumber: 'Číslo študentskej karty',
    company: 'Na firmu',
    invoiceAddress: 'Fakturačná adresa',
    deliveryAddress: 'Doručovacia adresa',
    notice: 'Poznámka',
    platform: {
      print: 'Print',
      iOS: 'iOS',
      android: 'Android',
      ePaper: 'ePaper',
      windows8: 'Windows 8'
    },
    tag: {
      recommended: 'Odporúčame',
      convenientOffer: 'Výhodná ponuka'
    },
    email: 'Obmedzenie na email'
  },
  subscriptionSetting: {
    giftSubscription: 'Darčekové predplatné',
    companyProduct: 'Firemný produkt',
    companyProductCount: 'Počet firemnych prístupov',
    aboStartEdition: 'Objednať od vydania',
    notifyBeforeNextRecurringPayment: 'Upozorniť pred nasledujúcou opakovanou platbou'
  },
  trialPeriod: {
    enabled: 'Skúšobná cena',
    price: 'Cena',
    aboProduct: 'ABO produkt',
    aboPeriodikPrice: 'Periodik ABO cena'
  },
  paymentGateways: 'Platobné brány',
  payment: {
    default: 'Default',
    free: 'Bezplatne',
    transfer: 'Prevodom na účet',
    cardpay: 'Online CardPay (kartou)',
    tatrapay: 'Online TatraPay',
    comfortpay: 'Online ComfortPay (opakovane)',
    smsRecurring: 'SMS (opakovane)',
    discountCode: 'Zľavový kód',
    csobCardPay: 'Online CSOB (kartou)',
    csobGooglePay: 'Online GooglePay',
    csobMasterCardMobile: 'Online Mastercard Mobile'
  },
  aboProduct: 'ABO produkt',
  aboPeriodikPrice: 'Periodik ABO cena',
  addOnProduct: 'Doplnkový produkt',
  addOnProductDescription: 'Doplnkový produkt - popis',
  addPeriod: 'Pridaj obdobie',
  addRelatedProduct: 'Pridaj príbuzný produkt',
  digitalServices: 'Digitálne služby',
  priceDescription: 'Popis ceny',
  published_since: 'Publikované od',
  published_until: 'Publikované do',
  image: 'Obrázok',
  productImageType: {
    title: 'Typ obrázku',
    static: 'Static',
    dynamic: 'Dynamic'
  },
  insertImage: 'Vlož obrázok',
  imageUuid: 'Image ID',
  productImageSiteType: {
    title: 'Stránka',
    trend: 'TREND'
  },
  selectPage: 'Vyber stránku',
  yes: 'Áno',
  no: 'Nie',
  enabled: 'Zapnuté',
  detail: 'Detail',
  abo: 'Abo',
  systemInfo: 'Systémové informácie',
  create_new: 'Vytvor novú',
  save: 'Uložiť',
  tab: {
    general: 'Hlavné nastavenia',
    prices: 'Platby, ABO'
  },
  filter: {
    search: 'Hľadať',
    reset_filter: 'Zrušiť filter'
  },
  error: {
    required_fields: 'Prosím, vyplňte všetky povinné polia'
  },
  message: {
    updated_record: 'Záznam bol zmenený!',
    deleted_record: 'Záznam bol vymazaný!'
  }
}

export default messages
