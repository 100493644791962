export default {
  variableSymbol: '',
  price: '',
  email: '',
  payment: 'none',
  paymentResult: 'none',
  product: 'none',
  address_name: '',
  address_surname: '',
  address_company: '',
  address_ico: '',
  createdAtFrom: '',
  createdAtTo: '',
  id: ''
}
