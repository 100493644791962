const buildFilter = function (state) {
  const filter = { ...state.filter }

  const commaSeparatedProperties = ['personsInImage', 'entityUuids']
  commaSeparatedProperties.forEach(key => {
    if (filter[key]?.trim()) {
      filter[key] = filter[key].split(',').map(item => item.trim())
    } else {
      filter[key] = null
    }
  })
  const multiSelectProperties = [
    'uploadSourceCodes',
    'createdBy',
    'categoryCodes',
    'orientationCodes',
    'albums'
  ]
  multiSelectProperties.forEach(key => {
    if (filter[key]) {
      filter[key] = filter[key].map(value => value.id)
    }
  })
  if (filter.siteLocks) {
    filter.siteLocks = filter.siteLocks
      .map(value => value.name)
  }

  filter.pageSortData = {
    pageSize: state.limit,
    pageNumber: state.page,
    orderBy: filter.pageSortData.orderBy,
    isDescending: true
  }

  Object.keys(filter).forEach(key => {
    if (filter[key] === '') {
      filter[key] = null
    }
  })

  return filter
}

const getQuery = function (state) {
  const query = state.isPdf ? '/pdf/filter' : '/image/filter'
  const filter = buildFilter(state)
  return { query, filter }
}

export default {
  getQuery
}
