const messages = {
  filter: {
    id: 'Id',
    title: 'Titulok',
    name: 'Názov'
  },
  list: {
    id: 'Id',
    title: 'Titulok',
    name: 'Názov'
  },
  id: 'Id',
  title: 'Titulok',
  name: 'Názov',
  description: 'Popis',
  address: 'Adresa',
  rating: 'Hodnotenie',
  yearOfReview: 'Rok recenzie',
  image: 'Obrázok',
  openingHours: 'Otváracie hodiny',
  phone: 'Telefón',
  website: 'Web',
  chef: 'Šéfkuchár',
  priceLevel: 'Cenová úroveň',
  cuisineCategories: 'Kategórie jedla',
  pros: 'Plusy',
  cons: 'Mínusy',
  create_new_category: 'Vytvor kategóriu',
  create_new_category_message: 'Nová kategória vytvorená'
}

export default messages
