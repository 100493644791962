const messages = {
  select_site: 'Vyber stránku',
  top_pages: 'Top články',
  all_sites: 'Všechny stránky',
  people: 'Lidé',
  performance: 'Výkon',
  recommendation: 'Tip',
  status: 'Stav',
  article_title: 'Článek',
  today_page_views: 'Za 3 hod.',
  today_video_views: 'Za 3 hod.',
  page_views: 'Zobraz. článku',
  video_views: 'Zobraz. videí',
  recirculation: 'Recirkulace',
  readingTime: 'Průměrná doba čtení',
  ab_status_active: 'Aktivní',
  ab_status_finished: 'Dokončený',
  variant: 'Verze',
  impressions: 'Imprese',
  clicks: 'Kliknutí',
  ctr: 'CTR',
  historical_pv: 'Historický výkon článků',
  google_data_studio: 'Google Data Studio',
  open_report: 'Zobrazit report',
  show: 'Zobrazit',
  show_chart: 'Zobrazit graf',
  peoplemeter_title: 'Počet lidí na stránce',
  peoplemeter_max: 'Maximální počet lidí',
  tooltip: {
    people: 'Unikátní lidé na stránce za 1 minutu. Statistiky započítávají i čtenáře prohlížející galerii článku',
    article_title: 'Titulek článku',
    recirculation: '% míra prokliku z daného článku na další stránky daného webu (nebo jiného NMH webu) - setrvání na NMH webech',
    page_views: 'Vývoj počtu zobrazení článku (PV) za aktuální den (od půlnoci do teď). Statistiky jsou včetně fotografií v galerii. * na článcích jejichž datum první publikace je starší 7 dnů, neměříme Zobrazení článku (PVs), a Zobrazení videí (VVs) v reálném čase',
    video_views: 'Vývoj počtu zobrazení videí (VV) za aktuální den (od půlnoci do teď). Pokud je v článku více videí, VV se sečtou. Započteny jsou jakékoli VV bez ohledu na to, zda je Hero video článek nebo je video v textu. Započítává pouze VV přes Ooyala player. * na článcích jejichž datum první publikace je starší 7 dnů, neměříme Zobrazení článku (PVs), a Zobrazení videí (VVs) v reálném čase',
    page_video_views_hint: '* na článcích jejichž datum první publikace je starší 7 dnů, neměříme Zobrazení článku (PVs), a Zobrazení videí (VVs) v reálném čase',
    today_page_views: 'Počet zobrazení článku (PV) za uplynulé 3 hodiny. Statistiky jsou včetně fotografií v galerii',
    today_video_views: 'Počet zobrazení videí (VV) za uplynulé 3 hodiny. Pokud je v článku více videí, VV se sečtou. Započteny jsou jakékoli VV bez ohledu na to, zda je Hero video článek nebo je video v textu. Započítává pouze VV přes Ooyala player',
    order_change_up: 'Článek stoupl ve čtenosti',
    order_change_down: 'Článek klesl ve čtenosti',
    order_change_none: 'Článek si drží místo ve čtenosti',
    order_date: 'Datum zařazení',
    picture_count: 'Počet použitých obrázků v celém článku',
    video_count: 'Počet použitých videí v celém článku',
    peoplemeter_max: 'Maximální počet lidí za minutu ve stejném dni v týdnu jako aktuálním během posledních 4 týdnů'
  },
  all: 'Všechny',
  sum: 'Celkem',
  device: 'Zařízení',
  channel: 'Kanál',
  desktop: 'Počítač',
  mobile: 'Telefon',
  tablet: 'Tablet',
  alwaysRefresh: 'Automaticky obnovit i při rozbaleném řádku',
  autoRefreshPaused: 'Automatické obnovení tabulky je pozastaveno',
  filter: {
    title: 'Titulek',
    documentId: 'Id',
    author: 'Autor'
  },
  menu: 'MENU'
}

export default messages
