const buildFilterQuery = function (filter) {
  var filterQuery = ''
  if (filter.site) {
    filterQuery += '&filter_eq[site]=' + filter.site
  }
  if (filter.rubric) {
    filterQuery += '&filter_eq[rubric]=' + filter.rubric
  }
  if (filter.service) {
    filterQuery += '&filter_eq[service]=' + filter.service
  }
  if (filter.code) {
    filterQuery += '&filter_contains[code]=' + filter.code
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
