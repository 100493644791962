const messages = {
  id: 'ID',
  login: 'Login',
  email: 'Email',
  yes: 'Ano',
  no: 'Ne',
  firstName: 'Jméno',
  lastName: 'Příjmení',
  name: 'Jméno',
  save: 'Uložit',
  localLogin: 'Lokální',
  showLocalUsers: 'Lokální uživatelé',
  settings: 'Nastavení',
  details: 'Detaily',
  searchPermissionGroups: 'Skupina přístupů',
  userAuthorization: 'Autorizace uživatele',
  jobTitle: 'Pracovní pozice',
  employmentType: 'Typ pracovního poměru',
  defaultPermissionGroup: 'Skupiny oprávnění',
  fullscreen: 'Celá obrazovka',
  disabledPermissionTooltip: 'Oprávnění je součástí skupiny <b>"{groupName}"</b> a není možné jej editovat.',
  filter: {
    search: 'Hledat',
    reset_filter: 'Zrušit filtr'
  },
  error: {
    required_fields: 'Prosím, vyplňte všechna povinná pole',
    user_has_relations: 'Nelze smazat, uživatel má relace.'
  },
  message: {
    updated_record: 'Záznam byl změněn!',
    deleted_record: 'Záznam byl vymazán!',
    password_reset: 'Heslo resetováno!',
    resetLinkCopied: 'Reset linka zkopírována!'
  }
}

export default messages
