import { dateTimeNow } from '../services/DateTimeService'

export default {
  id: null,
  articleDocId: '',
  articleId: '',
  changeType: '',
  changedBy: 0,
  changedAt: dateTimeNow()
}
