const messages = {
  all_slots: 'All',
  approved: 'approved',
  articles_missing: '2 articles are missing.',
  department_articles_missing: '{department} department articles are missing.',
  base: 'Base',
  date: 'Publish date at HP',
  disapprove: 'Disapprove',
  fb_shared: 'FB shared',
  pageViews: 'PVs',
  slot_base: 'Slot - base',
  site: 'Site',
  buttons: {
    select_article: 'Select from HP offer'
  },
  list: {
    headers: {
      title: 'Title',
      subTitle: 'Slot - Center - Page - Rubric',
      pv: 'PV`s',
      publishWeb: 'Date of publication on the web',
      publishHp: 'Date of publication on the HP',
      fb: 'Assigned to FB',
      actions: 'Actions'
    }
  }
}

export default messages
