import _ from 'lodash'
import DamApi from '@/api/dam'
import DamFilter from '@/model/DamFilter'
import DamFilterService from '@/services/dam/DamFilterService'
import config from '@/config'
import NotifyService from '@/services/NotifyService'
import { i18n } from '@/main'

const state = {
  error: null,
  detail: {},
  list: [],
  photoAlbums: [],
  prevPage: { page: 0, list: [] },
  currentPage: { page: 1, list: [] },
  nextPage: { page: 2, list: [] },
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(DamFilter),
  limit: config.defaults.list.limit,
  maxCreatedAt: '',
  /**
   * // damId=15466
   * ```
   * '15466': {
   *   crops: { '16:9': { x, y, width, height }, '3:2': { x, y, width, height } }
   *   selectedByAuthorCropName: '16:9',
   *   assetWidth: 100,
   *   assetHeight: 100
   * },
   * ```
   */
  cropPositionEmbed: {},
  restrictedSites: '',
  imageCache: Date.now(), // this prevents the images to be cached in the browser
  isPdf: false,
  advancedFilter: false,
  configInfo: {},
  uploadProgress: {}
}

const callUpdateMetadata = async ({ store, url, payload }) => {
  (Array.isArray(payload) ? payload : [payload]).forEach(metadata => {
    Object.keys(metadata).forEach(key => {
      if (key === 'siteLocks') {
        metadata[key] = metadata[key] ?? []
      } else {
        // null or undefined are ignored by BE, an empty string must be sent for deleting the value
        metadata[key] = metadata[key] ?? ''
      }
    })
  })

  return DamApi().put(url, payload)
    .then(response => {
      if (response.status === 200) {
        store.commit('storeDetail', response.data)
        store.commit('setError', null)
      } else {
        store.commit('setError', 'Error')
      }
      return response
    })
    .catch(error => {
      if (error.response.status === 500) {
        store.commit('setError', error.response.status)
      } else {
        store.commit('setError', error)
      }
    })
    .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
}

const mutations = {
  clearList (state) {
    state.list = []
    state.prevPage = { page: 0, list: [] }
    state.currentPage = { page: 1, list: [] }
    state.nextPage = { page: 2, list: [] }
  },
  storePhotoAlbums (state, responseData) {
    state.photoAlbums = responseData.data
  },
  storeList (state, responseData) {
    state.list = responseData.data
  },
  storePageList (state, { pageKey, page, list }) {
    state[pageKey] = { page, list }
  },
  pageShiftLeft (state) {
    state.nextPage = { page: state.currentPage.page, list: state.currentPage.list }
    state.currentPage = { page: state.prevPage.page, list: state.prevPage.list }
  },
  pageShiftRight (state) {
    state.prevPage = { page: state.currentPage.page, list: state.currentPage.list }
    state.currentPage = { page: state.nextPage.page, list: state.nextPage.list }
  },
  addToList (state, responseData) {
    state.list = state.list.concat(responseData.data)
  },
  storeTotalCount (state, responseData) {
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setLimit (state, limit) {
    state.limit = limit
  },
  setMaxCreatedAt (state, maxCreatedAt) {
    state.maxCreatedAt = maxCreatedAt
  },
  incrementPage (state) {
    state.page++
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
    state.page = 1
  },
  resetImageCache (state) {
    state.imageCache = Date.now()
  },
  setCropPositionEmbed (state, { damId, cropPositionEmbed }) {
    state.cropPositionEmbed[damId] = cropPositionEmbed
  },
  setCropSelectedByAuthor (state, { damId, cropName }) {
    state.cropPositionEmbed[damId].selectedByAuthorCropName = cropName
  },
  changeRoiOfCropPositionEmbed (state, { damId, aspectRatio, roi }) {
    state.cropPositionEmbed[damId].crops[aspectRatio] = { ...roi }
  },
  removeCropPositionEmbed (state, damId) {
    delete state.cropPositionEmbed[damId]
  },
  setIsPdf (state, isPdf) {
    state.isPdf = isPdf
  },
  toggleAdvancedFilter (state, showAdvancedFilter) {
    state.advancedFilter = typeof showAdvancedFilter === 'boolean' ? showAdvancedFilter : !state.advancedFilter
  },
  setConfigInfo (state, response) {
    state.configInfo = response.data
  },
  clearUploadProgress (state) {
    state.uploadProgress = {}
  },
  setUploadProgress (state, { uploadKey, percentCompleted }) {
    state.uploadProgress = {
      ...state.uploadProgress,
      [uploadKey]: percentCompleted
    }
  }
}

const actions = {
  async fetchConfigInfo (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    await DamApi().get('/ConfigInfo')
      .then(res => {
        store.commit('setConfigInfo', res)
      }).catch(error => console.error(error))
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async fetchPhotoAlbums (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    await DamApi().get('/album/all')
      .then(res => {
        store.commit('storePhotoAlbums', res)
      }).catch(error => console.error(error))
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const { query, filter } = DamFilterService.getQuery(store.state)
    await DamApi().post(query, filter)
      .then(res => {
        if (store.state.maxCreatedAt === '') {
          store.commit('storeList', res.data)
          store.commit('storeTotalCount', res.data)
        } else {
          store.commit('addToList', res.data)
        }
      }).catch(error => console.error(error))
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async fetchCurrentPage (store, { resetImageCache } = { resetImageCache: false }) {
    const { query, filter } = DamFilterService.getQuery(store.state)
    await DamApi().post(query, filter)
      .then(result => {
        store.commit('storePageList', { pageKey: 'currentPage', page: store.state.page, list: result.data.data })
        store.commit('storeTotalCount', result.data)
        if (resetImageCache) {
          store.commit('resetImageCache')
        }
      })
      .catch(error => {
        console.error(error)
        store.commit('storePageList', { pageKey: 'currentPage', page: store.state.page, list: [] })
        if (error.response.status === 500) {
          NotifyService.setErrorMessage(error.response.status)
        } else {
          NotifyService.setErrorMessage(error.response.data.error)
        }
      })
  },
  async fetchPrevPage (store) {
    if (store.state.page > 1) {
      const { query, filter } = DamFilterService.getQuery({ ...store.state, page: store.state.page - 1 })
      await DamApi().post(query, filter)
        .then(prevResult => {
          store.commit('storePageList', { pageKey: 'prevPage', page: store.state.page - 1, list: prevResult.data.data })
        })
        .catch(error => console.error(error))
    }
  },
  async fetchNextPage (store) {
    if (store.state.page < store.getters.pageCount) {
      const { query, filter } = DamFilterService.getQuery({ ...store.state, page: store.state.page + 1 })
      await DamApi().post(query, filter)
        .then(nextResult => {
          store.commit('storePageList', { pageKey: 'nextPage', page: store.state.page + 1, list: nextResult.data.data })
        })
        .catch(error => console.error(error))
    }
  },
  async fetchPage (store, { resetImageCache } = { resetImageCache: false }) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const shiftLeft = store.state.page === store.state.currentPage.page - 1
    const shiftRight = store.state.page === store.state.currentPage.page + 1
    if (shiftLeft) {
      store.commit('pageShiftLeft')
    } else if (shiftRight) {
      store.commit('pageShiftRight')
    }
    await store.dispatch('fetchCurrentPage', { resetImageCache })
    store.commit('TOGGLE_LOADING', null, { root: true })
    if (!shiftRight) {
      store.dispatch('fetchPrevPage')
    }
    if (!shiftLeft) {
      store.dispatch('fetchNextPage')
    }
  },
  async fetchOne (store, entityUuid) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return DamApi().get(`/${store.state.isPdf ? 'pdf' : 'image'}/${entityUuid}`)
      .then(response => {
        store.commit('storeDetail', response.data)
        return response.data
      }).catch(error => console.error(error))
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async create (store, record) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await DamApi().post(`/${store.state.isPdf ? 'pdf' : 'image'}`, record)
      .then(response => {
        if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async deleteRecord (store, entityUuid) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await DamApi().delete(`/${store.state.isPdf ? 'pdf' : 'image'}/${entityUuid}/soft`)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        const { status } = error.response
        if (status === 500) {
          store.commit('setError', error.response.status)
        } else if (status === 404) {
          store.commit('setError', i18n.t('dam.cannot_delete'))
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async deleteRecordPermanent (store, entityUuid) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await DamApi().delete(`/${store.state.isPdf ? 'pdf' : 'image'}/${entityUuid}/permanent`)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async deleteRecords (store, assetsIds) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const config = { data: assetsIds }
    return await DamApi().delete(`/${store.state.isPdf ? 'pdf' : 'image'}/bulk-soft`, config)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async updateMetadata (store, metadata) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const url = `/${store.state.isPdf ? 'pdf' : 'image'}/metadata`
    return callUpdateMetadata({ store, url, payload: metadata })
  },
  /**
   * Bulk update of all assets with the same (single) metadata.
   */
  async updateMetadataBulkSingle (store, singleMetadata) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const url = store.state.isPdf ? '/pdf/metadata-bulk' : '/image/metadata-bulk-single'
    return callUpdateMetadata({ store, url, payload: singleMetadata })
  },
  /**
   * Bulk update of each asset with its unique metadata.
   */
  async updateMetadataBulkMulti (store, multiMetadata) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    if (store.state.isPdf) {
      throw Error('Update metadata bulk multi endpoint does not exist for PDF.')
    }
    const url = '/image/metadata-bulk-multi'
    return callUpdateMetadata({ store, url, payload: multiMetadata })
  },
  async getBaseCropsForRatios (store, payload) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return DamApi().post('/image/base-crops-dims', payload)
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
          return response.data
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async download (store, asset) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await DamApi(0).get(`/${store.state.isPdf ? 'pdf' : 'image'}/${asset.entityUuid}/download`, { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        const filename = `${asset.fileName}${asset.fileExtension}`
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        console.error(error)
        throw error
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async downloadZip (store, assetsIds) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await DamApi(0).post(`/${store.state.isPdf ? 'pdf' : 'image'}/bulk-download`, assetsIds, { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        const filename = 'dam_download_' + new Date().getTime() + '.zip'
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  photoAlbums (state) {
    return state.photoAlbums
  },
  list (state) {
    return state.list
  },
  prevPage (state) {
    return state.prevPage.list
  },
  currentPage (state) {
    return state.currentPage.list
  },
  nextPage (state) {
    return state.nextPage.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  limit (state) {
    return state.limit
  },
  pageCount (state) {
    return Math.ceil(state.totalCount / state.limit)
  },
  maxCreatedAt (state) {
    return state.maxCreatedAt
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  imageCache (state) {
    return state.imageCache
  },
  cropPositionEmbed (state) {
    return state.cropPositionEmbed
  },
  isPdf (state) {
    return state.isPdf
  },
  advancedFilter (state) {
    return state.advancedFilter
  },
  configInfo (state) {
    return state.configInfo
  },
  uploadProgress (state) {
    return state.uploadProgress
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
