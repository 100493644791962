import coreApi from '@/api/core'
import { cloneDeepWith } from 'lodash'

const ERRORS = {
  error_id_mismatch: 'Translate: error_id_mismatch',
  error_field_empty: 'Translate: error_field_empty',
  error_field_length_min: 'Translate: error_field_length_min',
  error_field_length_max: 'Translate: error_field_length_max',
  error_field_invalid: 'Translate: error_field_invalid',
  error_field_not_unique: 'Translate: error_field_not_unique',
  error_field_value_not_found: 'Translate: error_field_value_not_found',
  error_gap: 'Translate: error_gap',
  error_fe_user_has_active_payment: 'Translate: fe_user_has_active_payment'
}

function getErrorMessage (code) {
  if (ERRORS[code] === undefined) {
    return code
  }
  return ERRORS[code]
}

const handleError = function (store, error) {
  if (error.response.status === 400) {
    for (var fieldName in error.response.data) {
      error.response.data[fieldName].forEach(error => {
        store.commit('setError', getErrorMessage(error))
      })
    }
  } else if (error.response.status === 500) {
    store.commit('setError', error.response.status)
  } else {
    store.commit('setError', error.response.data)
  }
  console.log(error)
}

function omitDeepLargeData (object) {
  if (!object) {
    return null
  }

  return cloneDeepWith(object, (value) => {
    if (value && typeof value === 'string') {
      const startOfString = value.substring(0, 50)
      if (startOfString.includes('data:image') && startOfString.includes('base64')) {
        // omits 'base64' images
        return ''
      }
      if (value.length > 1000) {
        return value.substring(0, 1000)
      }
    } else if (Array.isArray(value)) {
      return value.slice(0, 10)
    }
  })
}

const vueErrorHandler = function (err, vm, info) {
  const innerText = vm.$el?.innerText ?? vm.$el?.children?.find(child => child.innerText)?.innerText
  const data = {
    baseURI: vm.$el?.baseURI,
    nodeName: vm.$el?.nodeName,
    className: vm.$el?.className,
    componentTag: vm.$options?._componentTag,
    innerHTML: vm.$el?.innerHTML?.slice(0, 255),
    parentComponentTag: vm.$parent?.$options?._componentTag,
    parentInnerHTML: vm.$parent?.$el?.innerHTML?.slice(0, 255),
    innerText,
    vmData: omitDeepLargeData(vm.$data),
    additionalInfo: info
  }

  return coreApi().post('/adminlog/error', {
    message: err.message,
    stack: err.stack,
    data: JSON.stringify(data)
  })
    .then(() => {
      console.log('Error logged to BE:' + err.message)
      console.log(err.stack)
      console.log(data)
    })
    .catch(error => {
      console.error(err)
      console.error(error)
      console.error(data)
    })
}

export default {
  handleError,
  vueErrorHandler
}
