export const getMessages = (files, defaultMessages) => {
  const fileMessages = files.keys().reduce((acc, fileName) => {
    const moduleName = fileName.replace('.js', '').replace('./', '')
    if (moduleName !== 'messages') {
      if (defaultMessages[moduleName]) {
        console.error(`Messages for i18n module "${moduleName}" are being replaced by default messages from 'messages.js'`)
      }
      acc[moduleName] = files(fileName).default
    }
    return acc
  }, {})

  return {
    ...fileMessages,
    ...defaultMessages
  }
}
