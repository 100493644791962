import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.FRONTEND_USER_PERMISSIONS

const routes = [
  {
    path: 'feUser/:id/edit',
    component: () => import('../../../components/feUser/FeUserEdit'),
    name: 'feUser_edit',
    meta: {
      description: 'FeUser edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'feUser/new',
    component: () => import('../../../components/feUser/FeUserNew'),
    name: 'feUser_new',
    meta: {
      description: 'FeUser new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'feUser',
    component: () => import('../../../components/feUser/FeUserList'),
    name: 'feUser_list',
    meta: {
      description: 'FeUser list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
