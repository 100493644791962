import _ from 'lodash'
import EshopApi from '../../../api/eshop'
import ChurnRateFilterService from '../../../services/eshop/DashboardChurnRateFilterService'
import PaymentStatsFilterService from '../../../services/eshop/DashboardSubscriptionPaymentStatsFilterService'
import PaymentStatsFilterModel from '../../../model/eshop/DashboardSubscriptionPaymentStatsFilter'
import ChurnRateFilterModel from '../../../model/eshop/DashboardChurnRateFilter'

const API_NAME = '/dashboard/'
const CHURN_RATE_TYPES = ['annual', 'q1', 'q2', 'q3', 'q4', 'monthly']

const state = {
  error: null,
  loadingCounter: 0,
  churnRate: {
    annual: [],
    q1: [],
    q2: [],
    q3: [],
    q4: [],
    monthly: []
  },
  paymentStats: [],
  filter: {
    churnRate: _.cloneDeep(ChurnRateFilterModel),
    paymentStats: _.cloneDeep(PaymentStatsFilterModel)
  }
}

const mutations = {
  storeChurnRate (state, response) {
    state.churnRate[response.type] = response.data
  },
  storePaymentStats (state, responseData) {
    state.paymentStats = responseData.data
  },
  setError (state, message) {
    state.error = message
  },
  unsetLoadingCounter (state) {
    state.loadingCounter = 0
  },
  incrementLoadingCounter (state) {
    state.loadingCounter++
  },
  setChurnRateFilter (state, filter) {
    state.filter.churnRate = filter
  },
  setPaymentStatsFilter (state, filter) {
    state.filter.paymentStats = filter
  }
}

const actions = {
  fetchChurnRate (store) {
    store.commit('unsetLoadingCounter')
    CHURN_RATE_TYPES.forEach(type => {
      store.commit('TOGGLE_LOADING', null, { root: true })
      const limit = (type === 'monthly') ? '' : '&limit=1'
      const url = API_NAME + 'churnRate?view=full' + limit + '&order[dateTo]=desc&filter_eq[type]=' + type +
        ChurnRateFilterService.buildFilterQuery(store.state.filter.churnRate)
      EshopApi().get(url)
        .then(res => {
          store.commit('storeChurnRate', { data: res.data.data, type })
          store.commit('TOGGLE_LOADING', null, { root: true })
          store.commit('incrementLoadingCounter')
        })
        .catch(error => {
          console.log(error)
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
    })
  },
  fetchPaymentStats (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const url = API_NAME + 'subscriptionPaymentStats?view=full&limit=1000' +
      PaymentStatsFilterService.buildFilterQuery(store.state.filter.paymentStats)
    EshopApi().get(url)
      .then(res => {
        store.commit('storePaymentStats', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  }
}

const getters = {
  churnRate (state) {
    return state.churnRate
  },
  paymentStats (state) {
    return state.paymentStats
  },
  error (state) {
    return state.error
  },
  churnRateFilter (state) {
    return state.filter.churnRate
  },
  paymentStatsFilter (state) {
    return state.filter.paymentStats
  },
  churnRateLoaded (state) {
    return state.loadingCounter === 6
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
