const messages = {
  list: {
    id: 'Id',
    site: 'Site',
    source_url: 'Source Url',
    destination_url: 'Destination Url',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil'
  },
  filter: {
    site: 'Site',
    source_url: 'Source Url',
    destination_url: 'Destination Url',
    id: 'Id',
    search: 'Search'
  },
  id: 'Id',
  site: 'Site',
  source_url: 'Source Url',
  destination_url: 'Destination Url'
}

export default messages
