export const BREAKING_NEWS_STATE_PUBLISHED = 'Active'
export const BREAKING_NEWS_STATE_UNPUBLISHED = 'Inactive'

export const BREAKING_NEWS_TYPE_DEFAULT = BREAKING_NEWS_STATE_PUBLISHED

export default {
  computed: {
    breakingNewsStateValues () {
      return [
        {
          id: BREAKING_NEWS_STATE_PUBLISHED,
          title: this.$t('breakingnews.type_value.published')
        },
        {
          id: BREAKING_NEWS_STATE_UNPUBLISHED,
          title: this.$t('breakingnews.type_value.unpublished')
        }
      ]
    }
  }
}
