export const TYPE_ARTICLE = 'article'
export const TYPE_EXTERNAL = 'external'
export const TYPE_VIDEO = 'video'
export const TYPE_DEFAULT = TYPE_ARTICLE
export const AVAILABLE_TYPES = [
  {
    id: TYPE_ARTICLE,
    title: 'Article'
  },
  {
    id: TYPE_EXTERNAL,
    title: 'External'
  },
  {
    id: TYPE_VIDEO,
    title: 'Video'
  }
]
