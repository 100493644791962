const buildFilterQuery = function (filter) {
  var filterQuery = ''
  if (filter.site && filter.site !== '0' && filter.site !== null) {
    filterQuery += '&filter_eq[site]=' + filter.site
  }
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.bodyText) {
    filterQuery += '&filter_contains[bodyText]=' + filter.bodyText
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
