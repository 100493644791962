const messages = {
  id: 'ID',
  created_at: 'Created at',
  created_at_from: 'Created at from',
  created_at_to: 'Created at to',
  created_by: 'Created by',
  modified_at: 'Modified at',
  modified_by: 'Modified by',
  last_login: 'Last login',
  last_login_from: 'Last login from',
  last_login_to: 'Last login to',
  main_settings: 'Main settings',
  name: 'Name',
  surname: 'Surname',
  street: 'Street',
  city: 'City',
  zip: 'Zip',
  aboCountry: 'Country',
  phone: 'Phone',
  user_email: 'User email',
  user_id: 'User ID',
  save: 'Save',
  username: 'Username',
  password: 'Password',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  deliveryAddresses: 'Delivery addresses',
  billingAddresses: 'Billing addresses',
  systemInfo: 'System info',
  facebook_login: 'Facebook Login',
  google_login: 'Google Login',
  roles: 'Roles',
  permissions: 'Permissions',
  role_no: 'Role no.',
  permission_no: 'Permission no.',
  removeRole: 'Remove role',
  removePermission: 'Remove permission',
  yes: 'Yes',
  no: 'No',
  enabled: 'Enabled',
  ico: 'ICO',
  dic: 'DIC',
  icDph: 'IC DPH',
  addressType: 'Type',
  company: 'Company',
  type_person: 'fyzická osoba',
  type_company: 'právnická osoba',
  article_id: 'Article document ID',
  article_title: 'Article title',
  article: 'Article',
  articles: 'Articles',
  polls: 'Polls',
  poll: 'Poll',
  poll_option: 'Poll option',
  masterSite: 'Master site',
  otherSites: 'Other sites',
  detail: 'Detail',
  create_new: 'Create new',
  consent: {
    gdpr: 'GDPR consent',
    marketing: 'Marketing consent'
  },
  tab: {
    general: 'General',
    addresses: 'Addresses',
    articles: 'Articles'
  },
  filter: {
    search: 'Search',
    reset_filter: 'Reset filter',
    export: 'Export'
  },
  error: {
    required_fields: 'Please fill all required fields'
  },
  message: {
    updated_record: 'Record was updated!',
    deleted_record: 'Record was deleted!'
  }
}

export default messages
