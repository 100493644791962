const messages = {
  list: {
    id: 'Id',
    title: 'Title',
    short_title: 'Short title',
    name: 'Name',
    enabled: 'Enabled',
    created_at: 'Created at',
    modified_at: 'Modified at',
    created_by: 'Created by'
  },
  filter: {
    title: 'Title',
    name: 'Name',
    id: 'Id',
    search: 'Search'
  },
  id: 'Id',
  title: 'Title',
  name: 'Name',
  enabled: 'Enabled',
  production: 'Production operation',
  enabled_sites: 'List of enabled sites',
  shortTitle: 'Short title',
  meta_title: 'Meta title',
  meta_description: 'Meta description',
  meta_keywords: 'Meta keywords',
  parent: 'Parent',
  conflictData: 'The page contains the same virtual rubric.'
}

export default messages
