const messages = {
  list: {
    id: 'Id',
    title: 'Titulek',
    slug: 'Slug',
    isVirtual: 'Virtuální rubrika',
    site: 'Stránka',
    parent: 'Parent',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil',
    absolute_url: 'Absolutní url',
    sorting: 'Seřazení subrubrik'
  },
  filter: {
    title: 'Titulek',
    id: 'Id',
    slug: 'Slug',
    site: 'Stránka',
    parent: 'Parent',
    search: 'Hledat'
  },
  id: 'Id',
  title: 'Titulek',
  slug: 'Slug',
  textForSlug: 'Text pro Slug',
  site: 'Stránka',
  parent: 'Rodič',
  iptcCategory: 'IPTC kategorie',
  meta_title: 'Meta titulek',
  meta_description: 'Meta popis',
  meta_keywords: 'Meta klíčová slova',
  setting_advertCategory: 'ITM area parametr (advertCategory)',
  setting_chartbeatSection: 'Chartbeat section',
  setting_gemiusId: 'Gemius Id',
  setting_prRubric: 'PR rubrika',
  setting_special: 'Speciální rubrika',
  setting_advertSection: 'Unit section for Google ads (advertSection)',
  sportTable_seasonId: 'Sport table Season Id',
  sportTable_activeTab: 'Sport table Active Tab (first = 1)',
  rubric_without_articles: 'Rubrika bez článků (zakázat možnost nastavit tuto rubriku pro články, používá se pro vlastní seznamové rubriky)',
  hiddenInMenu: 'Skryté v automaticky generovaném menu',
  isArticleToppingEnabled: 'Topovat články',
  description: 'Popis rubriky',
  heroImage: 'Hero image',
  miniatureImage: 'Miniatura',
  isVirtualized: 'Virtuální rubrika',
  virtualized: 'Virtualizovat',
  unvirtualized: 'Zrušit virtualizaci',
  cannotVirtualize: 'Rubriku nebylo možné virtualizovat, protože je v kolizi s těmito rubrikami: ',
  movingForbidden: 'Přesun virtuální rubriky {rubricId} je zakázán.',
  sorting: 'Seřazení subrubrik',
  product: 'Produkt',
  enableVirtualizationMsg: 'Opravdu chcete virtualizovat rubriku?',
  disableVirtualizationMsg: 'Opravdu chcete zrušit virtualizaci rubriky?',
  conflictData: 'Rubrika koliduje s rubrikou:',
  rubricId: 'Id rubriky',
  rubricTitle: 'Název rubriky'
}

export default messages
