const messages = {
  siteName: 'Server',
  appId: 'AppId',
  apiKey: 'ApiKey',
  utm: 'Utm',
  limit: 'Limit',
  limit_placeholder: 'Info: 0 for unlimited daily limit',
  reset: 'Reset',
  active: 'Active',
  addSite: 'Add server',
  no_results_found_for_this_gps: 'No results found for this GPS',
  error_fetching_address_from_gps: 'Error fetching address from GPS',
  invalid_gps_coordinates: 'Invalid GPS coordinates',
  vlm_valid_GPS_format: 'required GPS format: 50.05649280076552,14.375121168745256'
}

export default messages
