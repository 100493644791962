import _ from 'lodash'
import EdonApi from '../../api/edon'
import ProducPerformanceFilter from '../../model/ProductPerformanceFilter'
import PerformanceCoefficient from '../../model/PerformanceCoefficient'

const API_NAME = '/coefficient'

const state = {
  error: null,
  loaded: false,
  filter: ProducPerformanceFilter,
  coefficients: [_.cloneDeep(PerformanceCoefficient)]
}

const mutations = {
  storeCoefficients (state, responseData) {
    state.coefficients = responseData
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setLoaded (state, loaded) {
    state.loaded = loaded
  },
  addNewCoefficient (state, newCoefficient) {
    state.coefficients.push(newCoefficient)
  },
  setError (state, message) {
    state.error = message
  },
  updateCoefficients (state, monthYear) {
    state.coefficients.forEach((coef) => {
      if (coef.month !== monthYear[0] || coef.year !== monthYear[1]) {
        coef.id = ''
        coef.month = monthYear[0]
        coef.year = monthYear[1]
      }
    })
  }
}

const actions = {
  async fetch (store) {
    if (store.state.filter.product) {
      const monthYear = store.state.filter.monthYear.split('-')
      return await EdonApi().get(API_NAME + '/' + store.state.filter.product +
        '/' + monthYear[0] + '/' + monthYear[1] + '/' + 1
      )
        .then(response => {
          store.commit('storeCoefficients', response.data)
          store.commit('setLoaded', true)
        })
        .catch(error => console.log(error))
    }
  },
  add (store) {
    if (store.state.filter.product) {
      const monthYear = store.state.filter.monthYear.split('-')
      const newCoefficient = _.cloneDeep(PerformanceCoefficient)
      newCoefficient.productId = store.state.filter.product
      newCoefficient.month = monthYear[0]
      newCoefficient.year = monthYear[1]
      if (state.coefficients.length === 0) {
        newCoefficient.default = true
      }
      store.commit('addNewCoefficient', newCoefficient)
      store.commit('updateCoefficients', monthYear)
    }
  },
  toggleDefault (store, index) {
    if (state.coefficients[index].default) {
      state.coefficients[index].default = null
    } else {
      state.coefficients[index].default = true
    }
  },
  async save (store) {
    if (store.state.filter.product) {
      const monthYear = store.state.filter.monthYear.split('-')
      return await EdonApi().post(API_NAME + '/' + store.state.filter.product +
        '/' + monthYear[0] + '/' + monthYear[1] + '/' + 1,
      { coefficients: state.coefficients }
      )
        .then(response => {
          if (response.status === 200) {
            store.commit('setError', null)
          } else {
            store.commit('setError', 'Error')
          }
        })
        .catch(error => {
          if (error.response.status === 500) {
            store.commit('setError', error.response.status)
          } else {
            store.commit('setError', error.response.data.error)
          }
        })
    }
  }
}

const getters = {
  coefficients (state) {
    return state.coefficients
  },
  filter (state) {
    return state.filter
  },
  error (state) {
    return state.error
  },
  loaded (state) {
    return state.loaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
