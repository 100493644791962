import _ from 'lodash'
import WeightsSettingFilter from '../../model/WeightsSettingsFilter'
import PerformanceWeights from '../../model/PerformanceWeights'
import { dataHubEditPerfApi } from '@/api/datahub'

const API_NAME = '/weights'

const state = {
  error: null,
  loaded: false,
  filter: WeightsSettingFilter,
  weights: [_.cloneDeep(PerformanceWeights)]
}

const mutations = {
  storeWeights (state, responseData) {
    state.weights = responseData
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setLoaded (state, loaded) {
    state.loaded = loaded
  },
  addNewWeight (state, newWeight) {
    state.weights.push(newWeight)
  },
  setError (state, message) {
    state.error = message
  },
  setDefault (state, index) {
    state.weights.forEach((weight, i) => {
      weight.default = i === index
    })
  },
  updateWeights (state, monthYear) {
    state.weights.forEach((weight) => {
      if (!weight.id && (weight.month !== parseInt(monthYear[0]) || weight.year !== parseInt(monthYear[1]))) {
        weight.id = ''
        weight.month = parseInt(monthYear[0], 10)
        weight.year = parseInt(monthYear[1], 10)
      }
    })
  }
}

const actions = {
  async fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    if (store.state.filter.product || store.state.filter.department) {
      const monthYear = store.state.filter.monthYear.split('-')
      let polymorphId = store.state.filter.product
      if (store.state.filter.department > 0) {
        polymorphId = store.state.filter.department
      }
      return await dataHubEditPerfApi().get(`${API_NAME}/get?month=${monthYear[0]}&year=${monthYear[1]}&polymorph_id=${polymorphId}`
      )
        .then(response => {
          store.commit('storeWeights', response.data)
          store.commit('setLoaded', true)
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
        .catch(error => {
          console.log(error)
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
    }
  },
  add (store) {
    if (store.state.filter.product || store.state.filter.department) {
      let polymorphId = store.state.filter.product
      if (store.state.filter.department > 0) {
        polymorphId = store.state.filter.department
      }
      const monthYear = store.state.filter.monthYear.split('-')
      const newWeight = _.cloneDeep(PerformanceWeights)
      newWeight.productId = store.state.filter.product
      newWeight.departmentId = store.state.filter.department
      newWeight.polymorphId = polymorphId
      newWeight.month = monthYear[0]
      newWeight.year = monthYear[1]
      if (state.weights.length === 0) {
        newWeight.default = true
      }
      store.commit('addNewWeight', newWeight)
      store.commit('updateWeights', monthYear)
    }
  },
  toggleDefault (store, index) {
    store.commit('setDefault', index)
  },
  async save (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    if (store.state.filter.product || store.state.filter.department) {
      if (store.state.filter.product) {
        state.weights.forEach(w => {
          w.productId = store.state.filter.product.toString()
          delete w.departmentId
        })
      }
      if (store.state.filter.department > 0) {
        state.weights.forEach(w => {
          w.departmentId = store.state.filter.department
          delete w.productId
        })
      }
      return await dataHubEditPerfApi().post(`${API_NAME}/create-update`,
        state.weights
      )
        .then(response => {
          if (response.status === 200) {
            store.commit('setError', null)
          } else {
            store.commit('setError', 'Error')
          }
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
        .catch(error => {
          if (error.response.status === 500) {
            store.commit('setError', error.response.status)
          } else {
            store.commit('setError', error.response.data.error)
          }
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
    }
  }
}

const getters = {
  weights (state) {
    return state.weights
  },
  filter (state) {
    return state.filter
  },
  error (state) {
    return state.error
  },
  loaded (state) {
    return state.loaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
