const messages = {
  npa: {
    status: 'Stav',
    title: 'Titulek',
    agency: 'Agentura',
    client: 'Klient',
    interval: 'Trvání',
    days: 'Aktivní zobrazování (dny)',
    impressions: 'Imprese',
    visits: 'Vidění',
    clicks: 'Kliknutí',
    total_price: 'Celková cena',
    ctr: 'CTR in-view',
    clicks_limit: 'Zaplacené kliknutí',
    click_price: 'Cena za klik (€)',
    clicks_total_price: 'Cena za proklik (€)',
    bonus_clicks_limit: 'Nárok na bonusová kliknutí',
    bonus_clicks: 'Aktuální bonusová kliknutí',
    views: 'Zobrazení',
    progress: 'Plnění',
    bonus: 'Bonus',
    actions: 'Akce',
    inactive: 'Inactive',
    active: 'Active',
    done: 'Done',
    paused: 'Paused',
    category: 'Kategorie kampaně',
    category_selection: 'Výběr kategorie',
    divided_by_days: 'Rovnoměrné rozložení kliků',
    divided_by_days_shortname: 'RRK',
    url: 'URL stránky',
    image: 'Obrázek',
    published_since: 'Platnost od',
    published_until: 'Platnost do',
    native_perf_count: 'Počet článků v boxu',
    total_items_count: 'Počet všech článků',
    total_pages_count: 'Počet všech stran',
    alternating_campaigns: 'Alternování kampaní',
    alternative_title: 'Titulek pro alternativu',
    alternative_url: 'URL alternativy',
    alternative_image: 'Obrázek pro alternativu',
    alternative: 'Alternativa',
    alternative_in_views_count: 'Počet zobrazení',
    alternative_clicks_count: 'Počet kliknutí',
    alternative_ctr: 'CTR',
    enable_scheduling: 'Nastavit doručování kampaně',
    algorithm_overridden: 'Vždy zobrazit',
    paid_campaign: 'Placená kampaň',
    campaign_delivery_day: 'Den / dny doručení',
    campaign_delivered_from: 'Doba doručení od',
    campaign_delivered_to: 'Doba doručení do',
    buttons: {
      add: 'Přidat',
      settings: 'Nastavení',
      duplicate: 'Zduplikovat',
      add_alternative: '+ Přidat alternativu',
      remove_alternative: 'Odstranit alternativu'
    },
    statuses: {
      inactive: 'Inactive',
      active: 'Active',
      done: 'Done',
      paused: 'Paused'
    },
    original_daily_calculation: 'Denní přepočet (původní)',
    first_day_estimate: '1. den',
    second_day_estimate: '2. den',
    third_day_estimate: '3. den',
    fourth_day_estimate: '4. den',
    fifth_day_estimate: '5. den',
    sixth_day_estimate: '6. den',
    seventh_day_estimate: '7. den',
    total_daily_campaign_performance: 'Výkon kampaní dohromady',
    predicted_estimate_by_campaigns: 'Odhad prostoru NMH',
    daily_stats_chart: 'Vývoj kampaně',
    daily_stats_table: 'Vývoj kampaně podle brandu',
    of_in_views: 'in views',
    of_clicks: 'kliků',
    validation: {
      main_image_check: 'Hlavní obrázek musí být vyplněn.',
      alternative_b_check: 'Alespoň jeden z dvojice titulek - obrázek pro alternativu B musí být vyplněn.',
      alternative_c_check: 'Alespoň jeden z dvojice titulek - obrázek pro alternativu C musí být vyplněn.',
      duplicate_exists: 'Pro danou kampaň již existuje duplikátní kampaň.',
      delivery_time_check: 'Čas konce doručování kampaně musí být větší než čas začátku.'
    }
  }
}

export default messages
