import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ESHOP_ORDER_PERMISSIONS

const routes = [
  {
    path: 'shop/subscription/order/:id',
    component: () => import('../../../components/eshop/SubscriptionOrder'),
    name: 'eshopSubscriptionOrder_detail',
    meta: {
      description: 'Subscription Order detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'shop/subscription/order',
    component: () => import('../../../components/eshop/SubscriptionOrderList'),
    name: 'eshopSubscriptionOrder_list',
    meta: {
      description: 'Subscription Order list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
