import moment from 'moment'

const buildFilterQuery = function (filter) {
  var filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.articleDocId) {
    filterQuery += '&filter_eq[articleDocId]=' + filter.articleDocId
  }
  if (filter.articleId) {
    filterQuery += '&filter_eq[articleId]=' + filter.articleId
  }
  if (filter.changeType) {
    filterQuery += '&filter_contains[changeType]=' + filter.changeType
  }
  if (filter.changedBy) {
    filterQuery += '&filter_eq[changedBy]=' + filter.changedBy
  }
  if (filter.changedAtFrom) {
    const date = moment(filter.changedAtFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[changedAt]=' + date
  }
  if (filter.changedAtTo) {
    const date = moment(filter.changedAtTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[changedAt]=' + date
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
