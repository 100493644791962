const messages = {
  list: {
    id: 'Id',
    title: 'Question',
    type: 'Type',
    hidden: 'Hidden',
    enabled_voting: 'Enabled voting',
    category: 'Category',
    created_at: 'Created at',
    modified_at: 'Modified at',
    created_by: 'Created by'
  },
  filter: {
    title: 'Question',
    id: 'Id',
    type: 'Type',
    category: 'Category',
    search: 'Search'
  },
  id: 'Id',
  title: 'Question',
  title_placeholder: 'Write poll name',
  city: 'City',
  description: 'Description',
  type: 'Type',
  hidden: 'Hidden',
  image: 'Image',
  enabled_voting: 'Enabled voting',
  category: 'Category',
  created_at: 'Created ad',
  modified_at: 'Modified at',
  created_by: 'Created by',
  display_type: 'Display type',
  restriction_type: 'Restriction type',
  order_date: 'Order date',
  end_of_voting: 'End of voting',
  published_until: 'Published until',
  postpone: 'Postpone',
  published_since: 'Published since',
  select_date: 'Select date',
  options: 'Options',
  option: 'Option',
  option_title: 'Title *',
  option_image: 'Image',
  option_position: 'Position',
  option_votes: 'Votes',
  option_like: 'Like',
  option_dislike: 'Dislike',
  add_option: 'Add option',
  remove_option: 'Remove option',
  total_votes: 'Total votes:',
  migrated_id: 'Migrated ID',
  slug_url: 'Slug URL',
  button_manage_votes: 'Manage votes',
  results: 'Poll results',
  management: {
    no_poll_selected: 'Nie je vybraná žiadna anketa',
    create_poll: 'Vytvoriť anketu',
    assign_poll: 'Priradiť anketu',
    remove_poll: 'Odstrániť anketu'
  },
  poll_type: {
    poll: 'Poll',
    like_dislike: 'Like/Dislike',
    results: 'Results'
  },
  article_uuid: 'Article ID',
  generate_poll: 'Generate poll from article',
  fill_article_uuid: 'Fill article ID',
  invalid_article_uuid: 'The article was not found. Please check the article ID and try again.',
  error_generating_poll: 'Poll generation failed. Try again.',
  article_has_no_content: 'Article has no content. Fill in text in article and try again.'
}

export default messages
