import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'eagle-dam/image',
    component: () => import('../../components/dam/DamList'),
    name: 'dam_list',
    meta: {
      description: 'Dam list',
      requiresAuth: true,
      requiredPermissionModules: PermissionService.REQUIRED_PERMISSIONS.DAM_PERMISSIONS.listRoute
    }
  },
  {
    path: 'eagle-dam/pdf',
    component: () => import('../../components/dam/DamPdfList'),
    name: 'dam_pdf_list',
    meta: {
      description: 'Dam list',
      requiresAuth: true,
      requiredPermissionModules: PermissionService.REQUIRED_PERMISSIONS.DAM_PDF_PERMISSIONS.listRoute
    }
  }
]

export default routes
