<template>
  <div>
    <div class="article-preview-wrap">
      <div class="article-preview-toolbar">
        <div class="row">
          <div class="col-lg-4 m-t-5">
            <span>
              {{ $t('article.preview.article_preview') }}
            </span>
          </div>
          <div class="col-lg-4" v-if="totalPages > 1">
            <button
              class="btn btn-inverse"
              @click="prevPage"
              :disabled="this.totalPages < 2 || this.currentPage === 1"
            >
              {{ $t('article.preview.prev') }}
            </button>
            <button
              class="btn btn-inverse"
              @click="nextPage"
              :disabled="this.totalPages < 2 || this.currentPage === this.totalPages"
            >
              {{ $t('article.preview.next') }}
            </button>
          </div>
          <div class="col-lg-4 m-t-5" v-if="totalPages > 1">
            <span>
              {{ $t('article.preview.page_no') }} {{ currentPage }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <iframe
      :src="previewUrl"
      class="article-preview-iframe"
      width="100%"
    >
    </iframe>
  </div>
</template>

<script>
import CoreApi from '@/api/core'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'ArticlePreview',
  data () {
    return {
      previewUrl: '',
      currentPage: 1,
      totalPages: 1
    }
  },
  methods: {
    preview (articleId) {
      CoreApi().get('/preview/article/' + articleId + '/page/' + this.currentPage)
        .then(result => {
          this.previewUrl = result.data.url
          this.totalPages = result.data.pages
        })
        .catch(error => {
          console.error(error)
          NotifyService.setErrorMessage(this.$t('article.preview.preview_error'))
        })
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        this.preview(this.$route.params.id)
      }
    },
    nextPage () {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
        this.preview(this.$route.params.id)
      }
    }
  },
  created () {
    if (this.$route.params.id === undefined) {
      return
    }
    this.preview(this.$route.params.id)
  }
}
</script>

<style lang="scss">
  .article-preview-wrap {
    position: fixed;
    width: 100%;
    height: 60px;
    padding: 10px;
    text-align: center;
    background: #1e88e5;
  }
  .article-preview-toolbar {
    margin: 0 auto;
    top: 0;
    max-width: 1200px;
    text-align: center;
    span {
      font-size: 16px;
      color: #fff;
    }
  }
  .article-preview-iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 80px;
    width: 100%;
  }
</style>
