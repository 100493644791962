const messages = {
  id: 'ID',
  created_at: 'Created at',
  created_at_from: 'Created at from',
  created_at_to: 'Created at to',
  created_by: 'BE user',
  modified_at: 'Modified at',
  modified_by: 'Modified by',
  type: 'Type',
  order: 'Order',
  payment: 'Payment',
  description: 'Descrition',
  feUser: 'FE user',
  filter: {
    export: 'Export',
    search: 'Search',
    reset_filter: 'Reset filter'
  }
}

export default messages
