import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.FRONTEND_USER_BILLING_ADDRESS_PERMISSIONS

const routes = [
  {
    path: 'feUserBillingAddress/:id/edit',
    component: () => import('../../../components/feUser/address/FeUserBillingAddressEdit'),
    name: 'feUserBillingAddress_edit',
    meta: {
      description: 'FeUser Billing Address edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'feUserBillingAddress/new/:feUserId?',
    component: () => import('../../../components/feUser/address/FeUserBillingAddressNew'),
    name: 'feUserBillingAddress_new',
    meta: {
      description: 'FeUser Billing Address new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'feUserBillingAddress',
    component: () => import('../../../components/feUser/address/FeUserBillingAddressList'),
    name: 'feUserBillingAddress_list',
    meta: {
      description: 'FeUser Billing Address list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
