import EshopApi from '../../../api/eshop'

const API_NAME = '/abo/edition'
const LIMIT = 1000

const state = {
  all: []
}

const mutations = {
  storeAll (state, responseData) {
    state.all = responseData.data
  }
}

const actions = {
  async fetchAll ({ commit }) {
    if (state.all.length > 0) {
      return
    }
    await EshopApi().get(API_NAME + '?limit=' + LIMIT)
      .then(res => {
        commit('storeAll', res.data)
      })
      .catch(error => console.log(error))
  }
}

const getters = {
  all (state) {
    return state.all
  },
  editionById (state) {
    return id => state.all.find(edition => {
      if (id === edition.id) {
        return edition
      }
    })
  },
  editionByProductIdYearAndNumber (state) {
    return (productId, year, number) => state.all.find(edition => {
      if (productId === edition.cisTit && year === edition.rok && number === edition.vydani) {
        return edition
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
