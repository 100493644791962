const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.name) {
    filterQuery += '&filter_contains[name]=' + filter.name
  }
  if (filter.site && filter.site > 0) {
    filterQuery += '&filter_eq[site]=' + filter.site
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
