const messages = {
  time: 'Čas',
  backwards: 'Dozadu',
  forwards: 'Dopredu',
  refresh: 'Načítať znovu',
  now: 'Teraz',
  position: 'Pozícia',
  add_article: 'Pridať článok',
  add_video: 'Pridať video',
  copy_article: 'Kopírovať článok',
  insert_article: 'Duplikovať článok',
  insert_video: 'Duplikovať video',
  interval_1: '15 minút',
  interval_2: '30 minut',
  interval_3: '1 hodina',
  interval_4: '4 hodiny',
  delete_modal_header: 'Odstrániť tento záznam?',
  delete_modal_text: 'Ste si istí, že chcete odstrániť tento záznam?',
  delete_modal_no: 'Nie',
  delete_modal_yes: 'Áno',
  create_modal_header: 'Pridať článok',
  create_video_modal_header: 'Pridať video',
  info_modal_header: 'Info',
  article_not_found: 'Článok nebol nájdený alebo je v stave draft.',
  video_not_found: 'Video nebolo nájdené.',
  gap_busy: 'Pozícia v danom intervale nemá voľné miesto na ďalší článok.',
  article_status_warning: 'Článok momentálne nie je publikovaný, chcete ho aj tak naplánovať?',
  filter: {
    site: 'Stránka',
    visible_blocks: 'Viditeľné boxy',
    reset: 'Zrušiť filter'
  },
  status: 'Status',
  type: 'Typ',
  internal: 'Interný',
  external: 'Externý',
  published_since: 'Publikované od',
  published_until: 'Publikované do',
  article_document_id: 'ID článku',
  article_info: 'Info článku',
  video_info: 'Info videa',
  image: 'Obrázok',
  video: 'Video',
  video_id: 'Video ID',
  title: 'Titulok',
  ctr_info: 'CTR je vyjadrené ako priemerné CTR na danej pozíicii za posledných 7 dní',
  alternative_title: 'Alternatívny titulok',
  article: {
    status: 'Stav',
    title: 'Titulok',
    url: 'URL',
    first_paragraph: 'Perex',
    site_name: 'Meno stránky',
    site_url: 'URL adresa stránky',
    rubric_name: 'Názov rubriky',
    rubric_url: 'URL adresa rubriky',
    author: 'Autor',
    image: 'Obrázok',
    flags: 'Značky na webe',
    rubric: 'Rubrika',
    order_date: 'Dátum publikovania',
    published_since: 'Publikované od',
    published_until: 'Publikované do'
  }
}

export default messages
