import _ from 'lodash'
import DonBoxApi from '@/api/donBox'
import NativeCampaignModel from '@/model/NativeCampaignModel'
import NativeCampaignManagementFilterModel from '@/model/NativeCampaignManagementFilterModel'
import NativeCampaignManagementFilterService from '@/services/donBox/NativeCampaignManagementFilterService'
import UserService from '@/services/user/UserService'

const API_NAME = '/Campaigns'

const state = {
  error: null,
  detail: NativeCampaignModel,
  list: [],
  refreshUrl: null,
  pagination: {
    currentPage: 1,
    totalCount: 0,
    currentPageCount: 0,
    limit: 10
  },
  filter: _.cloneDeep(NativeCampaignManagementFilterModel),
  articlesLoaded: false,
  autoRefresh: false
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.records
    state.pagination.totalCount = responseData.totalCount
    state.pagination.currentPageCount = state.list.length
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setRefreshUrl (state, url) {
    state.refreshUrl = url
  },
  setError (state, message) {
    state.error = message
  },
  setCurrentPage (state, currentPage) {
    state.pagination.currentPage = currentPage
  },
  setLimit (state, limit) {
    state.pagination.limit = limit
  },
  resetList (state) {
    state.list = []
    state.pagination.totalCount = 0
  },
  resetPagination (state) {
    state.list = []
    state.pagination.totalCount = 0
    state.pagination.currentPageCount = 0
    state.pagination.currentPage = 1
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(NativeCampaignManagementFilterModel)
  },
  setArticlesLoaded (state, articlesLoaded) {
    state.articlesLoaded = articlesLoaded
  },
  setAutoRefresh (state, autoRefresh) {
    state.autoRefresh = autoRefresh
  }
}

const actions = {
  async fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const limit = `limit=${store.state.pagination.limit}`
    const page = `page=${store.state.pagination.currentPage}`
    const filter = NativeCampaignManagementFilterService.buildFilterQuery(store.state.filter)
    const url = `${API_NAME}?${limit}&${page}${filter}`

    await DonBoxApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
      })
      .catch(error => {
        console.error(error)
        store.commit('resetList')
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async fetchOne (store, id) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await DonBoxApi().get(`${API_NAME}/${id}`)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => store.commit('setError', error.response?.data?.title ?? error.response.status))
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async create (store, record) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await DonBoxApi().post(API_NAME, record)
      .then(response => {
        store.commit('setError', null)
        if (typeof response.data === 'object') {
          store.commit('storeDetail', response.data)
          return response.data.data.campaignId
        }
        return response.data
      })
      .catch(error => {
        store.commit('setError', error.response?.data?.title ?? error.response.status)
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },

  async update (store, record) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await DonBoxApi().put(API_NAME, record)
      .then(response => {
        if (response.status === 200) {
          if (typeof response.data === 'object') {
            store.commit('storeDetail', response.data)
          }
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          store.commit('setError', error.response.data)
        } else if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.title)
        }
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },

  async deleteRecord (store, record) {
    return await DonBoxApi().delete(API_NAME + '/' + record.campaignId)
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.title)
        }
      })
  },
  async resetCampaign (store, campaignId) {
    const currentUser = store.rootGetters['user/currentUser']
    const user = {
      id: currentUser.id.toString(),
      name: UserService.getUserInfo(currentUser)
    }
    await DonBoxApi().post(`${API_NAME}/${campaignId}/reset`, user)
  },
  async exportCampaigns (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const filter = NativeCampaignManagementFilterService.buildFilterQuery(store.state.filter, true)
    const url = `${API_NAME}/export-to-csv?${filter}`

    return DonBoxApi().get(url)
      .then(res => {
        return res.data
      })
      .catch(error => {
        console.error(error)
        return []
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  error (state) {
    return state.error
  },
  pagination (state) {
    return state.pagination
  },
  filter (state) {
    return state.filter
  },
  isArticlesLoaded (state) {
    return state.articlesLoaded
  },
  autoRefresh (state) {
    return state.autoRefresh
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
