const DEFAULT_TYPE = 'internal'

export default {
  id: null,
  name: '',
  originName: '',
  slug: '',
  position: '',
  description: '',
  email: '',
  image: null,
  type: DEFAULT_TYPE,
  hiddenOnPage: false,
  disabled: false,
  detailPage: false,
  social: {
    facebook: '',
    google: '',
    twitter: '',
    instagram: ''
  },
  defaultSite: null
}
