import EdonApi from '../../api/edon'
import KpiFilter from '../../model/KpiFilter'
import appConfig from '../../config'

const API_NAME = '/kpi-summary'

const state = {
  filter: KpiFilter,
  listUser: [],
  listArticle: []
}

const mutations = {
  storeListUser (state, responseData) {
    state.listUser = responseData
  },
  storeListArticle (state, responseData) {
    state.listArticle = responseData
  },
  setFilter (state, filter) {
    state.filter = filter
  }
}

const actions = {
  async fetchUserList (store) {
    const startDate = store.state.filter.startDate
    if (store.state.filter.department) {
      return await EdonApi().get(API_NAME + '/department/' + store.state.filter.department + '/' + startDate)
        .then(response => {
          store.commit('storeListUser', response.data)
        })
        .catch(error => console.log(error))
    }
  },
  async fetchArticleList (store) {
    const startDate = store.state.filter.startDate
    if (store.state.filter.id) {
      return await EdonApi().get(API_NAME + '/article/' + store.state.filter.id + '/' + startDate)
        .then(response => {
          store.commit('storeListArticle', response.data)
        })
        .catch(error => console.log(error))
    }
  },
  async downloadDeptSum (store) {
    const startDate = store.state.filter.startDate
    const department = store.state.filter.department
    if (department) {
      return await EdonApi().get(API_NAME + '/download/department/' + department + '/' + startDate)
        .then(response => {
          window.open(appConfig.edon.downloadUrl + response.data.id, '_blank')
        })
        .catch(error => console.log(error))
    }
  }
}

const getters = {
  filter (state) {
    return state.filter
  },
  listUser (state) {
    return state.listUser
  },
  listArticle (state) {
    return state.listArticle
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
