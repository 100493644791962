const messages = {
  select_site: 'Vyber stránku',
  top_pages: 'Top články',
  all_sites: 'Všetky stránky',
  people: 'Ľudia',
  performance: 'Výkon',
  recommendation: 'Tip',
  status: 'Stav',
  article_title: 'Článok',
  today_page_views: 'Za 3 hod.',
  today_video_views: 'Za 3 hod.',
  page_views: 'Zobraz. článku',
  video_views: 'Zobraz. videí',
  recirculation: 'Recirkulácia',
  readingTime: 'Priemerný čas čítania',
  ab_status_active: 'Aktívny',
  ab_status_finished: 'Dokončený',
  variant: 'Verzia',
  impressions: 'Impresie',
  clicks: 'Kliknutia',
  ctr: 'CTR',
  historical_pv: 'Historický výkon článkov',
  google_data_studio: 'Google Data Studio',
  open_report: 'Zobraziť report',
  show: 'Zobraziť',
  show_chart: 'Zobraziť graf',
  peoplemeter_title: 'Počet ľudí na stránke',
  peoplemeter_max: 'Maximálny počet ľudí',
  tooltip: {
    people: 'Unikátni ľudia na stránke za 1 minútu. Štatistiky započítavajú aj čitateľov prezerajúcich si galériu článku',
    article_title: 'Titulok článku',
    recirculation: '% miera prekliku z daného článku na ďalšie stránky daného webu (alebo iného NMH webu) - zotrvanie na NMH weboch',
    page_views: 'Vývoj počtu zobrazení článku (PV) za aktuálny deň (od polnoci do teraz). Štatistiky sú vrátane fotografií v galérii. * na článkoch ktorých dátum prvej publikácie je starší ako 7 dní, nemeriame Zobrazenia článku (PVs), a Zobrazenia videí (VVs) v reálnom čase',
    video_views: 'Vývoj počtu zobrazení videí (VV) za aktuálny deň (od polnoci do teraz). Ak je v článku viac videí, VV sa zrátavajú. Započítané sú akékoľvek VV bez ohľadu na to, či je Hero video článok alebo je video v texte. Započítava iba VV cez Ooyala player. * na článkoch ktorých dátum prvej publikácie je starší ako 7 dní, nemeriame Zobrazenia článku (PVs), a Zobrazenia videí (VVs) v reálnom čase',
    page_video_views_hint: '* na článkoch ktorých dátum prvej publikácie je starší ako 7 dní, nemeriame Zobrazenia článku (PVs), a Zobrazenia videí (VVs) v reálnom čase',
    today_page_views: 'Počet zobrazení článku (PV) za uplynulé 3 hodiny. Štatistiky sú vrátane fotografií v galérii',
    today_video_views: 'Počet zobrazení videí (VV) za uplynulé 3 hodiny. Ak je v článku viac videí, VV sa zrátavajú. Započítané sú akékoľvek VV bez ohľadu na to, či je Hero video článok alebo je video v texte. Započítava iba VV cez Ooyala player',
    order_change_up: 'Článok stúpol v čítanosti',
    order_change_down: 'Článok klesol v čítanosti',
    order_change_none: 'Článok si drží miesto v čítanosti',
    order_date: 'Dátum zaradenia',
    picture_count: 'Počet použitých obrázkov v celom článku',
    video_count: 'Počet použitých videí v celom článku',
    peoplemeter_max: 'Maximálny počet ľudí za minútu v rovnakom dni v týždni ako aktuálnom počas posledných 4 týždňov'
  },
  all: 'Všetky',
  sum: 'Celkom',
  device: 'Zariadenie',
  channel: 'Kanál',
  desktop: 'Počítač',
  mobile: 'Telefón',
  tablet: 'Tablet',
  alwaysRefresh: 'Automaticky obnoviť aj pri rozbalenom riadku',
  autoRefreshPaused: 'Automatické obnovenie tabuľky je pozastavené',
  filter: {
    title: 'Titulok',
    documentId: 'Id',
    author: 'Autor'
  },
  menu: 'MENU'
}

export default messages
