<template>
  <div id="notFound">
    <img src="/img/logo.png" class="center-block logo">
    <div class="text-center col-sm-12">
      <h1>{{ $t('youAreLost') }}.</h1>
      <h4>{{ $t('youAreLostDesc') }}.</h4>
      <router-link to="/" class="vertical-5p lead">{{ $t('takeMeHome') }}.</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style lang="scss">
  #notFound {
    padding: 10em;
    color: #fff;
  }
</style>
