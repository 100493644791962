import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ESHOP_PRODUCT_PERMISSIONS

const routes = [
  {
    path: 'shop/subscription/product/:id/edit',
    component: () => import('../../../components/eshop/SubscriptionProductEdit'),
    name: 'eshopSubscriptionProduct_edit',
    meta: {
      description: 'Subscription Product edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'shop/subscription/product/new',
    component: () => import('../../../components/eshop/SubscriptionProductNew'),
    name: 'eshopSubscriptionProduct_new',
    meta: {
      description: 'Subscription Product new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'shop/subscription/product',
    component: () => import('../../../components/eshop/SubscriptionProductList'),
    name: 'eshopSubscriptionProduct_list',
    meta: {
      description: 'Subscription Product list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
