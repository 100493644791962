const messages = {
  period_type: {
    none: 'Žádné',
    week: 'Týdenní platba s automatickou obnovou',
    month: 'Měsíčně s automatickou obnovou',
    three_months: '3 měsíce',
    six_months: '6 měsíců',
    one_year: '12 měsíců',
    two_years: '24 měsíců',
    one_article: 'Jeden článek'
  },
  visibility_type: {
    public: 'Veřejný',
    with_link: 'S odkazem'
  },
  top_product_position_type: {
    left: 'Vlevo',
    middle: 'Ve středu',
    right: 'Vpravo'
  },
  payment_type: {
    free: 'Bezplatně',
    transfer: 'Převodem na účet',
    cardpay: 'Online CardPay (kartou)',
    tatrapay: 'Online TatraPay',
    comfortpay: 'Online ComfortPay (opakovaně)',
    sms_recurring: 'SMS (opakovaně)',
    csob_cardpay: 'Online CSOB (kartou)',
    csob_googlepay: 'Online GooglePay',
    csob_mastercard_mob: 'Online Mastercard Mobile'
  },
  payment_status_type: {
    common: 'Obyčejná',
    first: 'První',
    recurring: 'Opakovaná'
  },
  payment_result_type: {
    success: 'Úspěšná',
    failed: 'Neúspěšná',
    unknown: 'Neznámý výsledek'
  },
  order_address_type: {
    person: 'Fyzická osoba',
    company: 'Firma'
  },
  history_type: {
    payment_created: 'Vytvoření platby',
    order_created: 'Vytvoření objednávky',
    payment_gateway_redirect: 'Přesměrování na platební bránu',
    payment_gateway_return: 'Návrat z platební brány',
    mail_sent: 'Poslání objednávkového mailu',
    payment_abo_ready_to_export: 'Objednávka připravená k exportu do ABO',
    payment_abo_exported: 'Objednávka exportována do ABO',
    payment_abo_export_failed: 'Objednávka nebyla exportována v ABO',
    payment_abo_processed: 'Objednávka zpracovaná v ABO',
    payment_abo_not_processed: 'Objednávka nebyla zpracována v ABO',
    payment_abo_periodik_ready_to_export: 'Objednávka připravená k exportu do Periodik ABO',
    payment_abo_periodik_exported: 'Objednávka exportována do Periodik ABO',
    payment_abo_periodik_export_failed: 'Objednávka nebyla exportována v Periodik ABO',
    payment_abo_periodik_processed: 'Objednávka zpracovaná v Periodik ABO',
    payment_abo_periodik_not_processed: 'Objednávka nebyla zpracována v Periodik ABO',
    subscription_activated: 'Předplatné aktivováno',
    next_payment_set: 'Nastavení následující platby',
    sms_code_created: 'Vytvoření SMS kódu',
    mo_sms_delivered: 'Přijetí MO SMS',
    mt_sms_created: 'Vytvoření MT SMS',
    mt_sms_sent: 'Poslání MT SMS',
    mt_sms_sent_error: 'Poslání MT SMS s chybou',
    mt_sms_status_delivered: 'Přijetí MT SMS statusu',
    payment_cancelled: 'Zrušení platby',
    payment_result_set: 'Výsledek platby',
    closed_subscription_mail_3_days: 'Odeslaná notifikace po 3 dnech od skončení předplatného',
    closed_subscription_mail_next_day: 'Odeslaná notifikace následující den po skončení předplatného',
    next_recurring_payment_mail: 'Odeslaná notifikace před další platbou'
  },
  subscription_type: {
    date_interval: 'Platnost kódu interval',
    interval_in_days: 'Platnost kódu ve dnech'
  },
  notify: {
    export_failed: 'Nastala chyba při generování exportu.'
  }
}

export default messages
