import { cloneDeep } from 'lodash'
import HistoricalPerformanceValues from './HistoricalPerformanceValues'

export default {
  departmentId: 0,
  userId: 0,
  performanceHistory: [
    cloneDeep(HistoricalPerformanceValues)
  ]
}
