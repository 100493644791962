export default {
  id: null,
  type: '',
  title: '',
  searchIn: 'title',
  videoId: '',
  status: '',
  dateFrom: null,
  dateTo: null,
  defaultSite: null,
  meta_title: '',
  meta_description: '',
  meta_keywords: '',
  authorUsers: [],
  authorUsersDepartments: [],
  cameraUsers: [],
  editingUsers: [],
  videoShows: [],
  setting_editorialContent: 0,
  setting_agencyContent: 0,
  setting_marketingContent: 0,
  setting_internal: 0,
  setting_social: 0,
  setting_agency: 0,
  setting_camera: 0,
  setting_mobilePhone: 0,
  setting_slideshow: 0,
  setting_show: 0,
  setting_forAdults: 0,
  setting_productPlacement: 0
}
