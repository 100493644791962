const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.resource) {
    filterQuery += '&filter_contains[resource]=' + filter.resource
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
