const buildFilterQuery = function (filter) {
  let filterQuery = ''

  if (filter.id) {
    filterQuery += '&filter_equals[id]=' + filter.id
  }
  if (filter.siteName) {
    filterQuery += '&filter_equals[siteName]=' + filter.siteName
  }
  if (filter.scaleGroup) {
    filterQuery += '&filter_equals[scaleGroup]=' + filter.scaleGroup
  }
  if (filter.name) {
    filterQuery += '&filter_equals[name]=' + filter.name
  }

  return filterQuery
}

export default {
  buildFilterQuery
}
