import CoreApi from '@/api/core'
import { dataHubRecommend } from '@/api/datahub'
import { i18n } from '@/main'
import NotifyService from '@/services/NotifyService'

const aiFeaturesUsageApi = '/AiFeatureUsageLog'

const state = {
  relatedArticles: [],
  recommendedIds: [],
  lastBodyText: '',
  articleId: null
}

const mutations = {
  SET_RELATED_ARTICLES (state, articles) {
    state.relatedArticles = articles
  },
  SET_RECOMMENDED_IDS (state, ids) {
    state.recommendedIds = ids
  },
  SET_BODY_TEXT (state, bodyText) {
    state.lastBodyText = bodyText
  },
  SAVE_ARTICLE_ID (state, articleId) {
    state.articleId = articleId
  }
}

const actions = {
  logAiFeaturesUsage ({ commit }, payload) {
    return CoreApi().post(aiFeaturesUsageApi, payload)
      .catch(error => {
        console.error('Error logging AI features usage:', error)
      })
  },

  async fetchRelatedArticles ({ commit, state }, { articleId, bodyText }) {
    if (articleId !== state.articleId) {
      commit('SET_RELATED_ARTICLES', [])
    }

    commit('SAVE_ARTICLE_ID', articleId)
    if (bodyText === '') {
      NotifyService.setErrorMessage(
        i18n.t('article.ai_service.recommended_articles_error_title'),
        i18n.t('article.ai_service.recommended_articles_error_body'),
        7000
      )
      return
    }
    if (bodyText !== state.lastBodyText) {
      commit('SET_BODY_TEXT', bodyText)
      try {
        const recommendedIds = await actions.fetchRecommendedArticleIds({ bodyText })
        if (recommendedIds.length > 0) {
          const relatedArticles = await actions.fetchArticles({ documentIds: recommendedIds })
          commit('SET_RELATED_ARTICLES', relatedArticles)
        }
      } catch (error) {
        actions.handleError(error)
      }
    }
  },

  async fetchRecommendedArticleIds ({ bodyText }) {
    const result = await dataHubRecommend().post('', { body_text: bodyText }, { timeout: 180000 })
    return result.data.documentIds
  },

  async fetchArticles ({ documentIds }) {
    const url = `/article?view=minimal&filter_eq[setting.status]=published&filter_in[documentId]=${documentIds}`
    const response = await CoreApi().get(url)
    if (response.status === 200) {
      response.data.data.forEach(article => {
        article.aiRelated = true
      })
      return response.data.data
    }
    return []
  },

  handleError (error) {
    if (error.response) {
      const { status, data } = error.response
      let errorMessageKey = 'article.ai_service.recommended_articles_service_unavailable'
      if (status === 400 && data.detail === 'Length of the article is too short') {
        errorMessageKey = 'article.ai_service.recommended_articles_error_body'
      } else if (status !== 404) {
        console.error(error)
        return
      }
      NotifyService.setErrorMessage(i18n.t('article.ai_service.recommended_articles_error_title'), i18n.t(errorMessageKey), 7000)
    } else {
      console.error(error)
    }
  }
}

const getters = {
  relatedArticles: state => state.relatedArticles,
  lastBodyText: state => state.lastBodyText,
  recommendedIds: state => state.recommendedIds
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
