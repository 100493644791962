const messages = {
  list: {
    id: 'Id',
    title: 'Title',
    sites: 'Stránky',
    voting: 'Hlasovanie',
    published_since: 'Published since',
    created_at: 'Created at',
    modified_at: 'Modified at',
    created_by: 'Created by'
  },
  filter: {
    title: 'Title',
    id: 'Id',
    bodytext: 'BodyText',
    search: 'Search'
  },
  id: 'Id',
  title: 'Title',
  title_placeholder: 'Write title',
  bodytext: 'Joke text',
  sites: 'Sites',
  voting: 'Allow voting',
  author: 'Author',
  published_since: 'Published since',
  published_since_placeholder: 'Choose date of publishing',
  published_until: 'Published until',
  published_until_placeholder: 'Choose date'
}

export default messages
