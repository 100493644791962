const messages = {
  geo: 'GPS',
  address: 'Adresa',
  districtId: 'Okres',
  regionId: 'Kraj',
  countryId: 'Země',
  city: 'Město',
  region: 'Kraj',
  district: 'Okres',
  street_number: 'Ulice a číslo',
  create_new_city: 'Vytvoř',
  create_new_city_message: 'Nové město bylo přidáno',
  confirm: 'Nastav'
}

export default messages
