export default {
  id: '',
  name: '',
  surname: '',
  street: '',
  city: '',
  zip: '',
  aboCountry: '',
  aboCountryExpanded: {},
  phone: '',
  feUser: '',
  feUser_email: ''
}
