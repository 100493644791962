const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.ids) {
    filterQuery += '&filter_in[id]=' + filter.ids
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
