const messages = {
  source: 'Source',
  firstName: 'Name',
  lastName: 'Last Name',
  customText: 'Custom text',
  user: 'User',
  url: 'URL',
  shortcut: 'Shortcut',
  sex: 'Sex',
  sexMale: 'male',
  sexFemale: 'female',
  function: 'Function',
  typeAuthor: 'Author',
  typeEditor: 'Editor',
  typeSportEditor: 'Sport editor',
  typeReaderReporter: 'Reader reporter',
  typePhotographer: 'Photographer/Video',
  typeExternalAuthor: 'External author',
  phone: 'Phone',
  email: 'Email',
  showContactForm: 'Show contact form',
  defaultSite: 'Default site',
  sites: 'Sites',
  editorSites: 'Editor of sites',
  editorInChiefSites: 'Chief editor of sites',
  otherFunction: 'Other function',
  hiddenOnPage: 'Hide author on site',
  hiddenOnPage_short: 'Hide on site',
  disabled: 'Disabled',
  authorImage: 'Author photo',
  showCompleteProfile: 'Show complete profile',
  briefProfile: 'Brief author profile',
  completeProfile: 'Complete author profile',
  twitter: 'Twitter profile URL',
  linkedin: 'LinkedIn profile URL',
  facebook: 'Facebook profile URL',
  instagram: 'Instagram profile URL',
  pinterest: 'Pinterest profile URL',
  warningMessages: {
    duplicateEntryInServers: 'The Sites field contains a duplicate entry with the default site. Upon saving, the duplicate will be removed.'
  }
}

export default messages
