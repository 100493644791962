const messages = {
  messages: {
    error: 'Nešpecifikovaná správa, prosím pridajte ju do konfigurácie',
    0: 'Chýbajúci A/B Testing. Zapni A/B Testing!',
    1: 'Žiaden obrázok v článku. Pridaj obrázky! (Optimálny počet obrázkov: {optimalValue})',
    2: 'Málo obrázkov v článku. Pridaj obrázky (Optimálny počet obrázkov: {optimalValue})!',
    3: 'Žiadne video v článku. Pridaj videá! (Optimálny počet videí: {optimalValue})',
    4: 'Nízky počet videí v článku. Pridaj videá! (Optimálny počet videí: {optimalValue})',
    5: 'Veľmi nízka návštevnost z Google vyhľadávača. (Dosiahnutá hodnota: {realValue}% Optimálna hodnota: {optimalValue}%)',
    6: 'Nízka návštevnost z Google vyhľadávača. (Dosiahnutá hodnota: {realValue}% Optimálna hodnota: {optimalValue}%)',
    7: 'Veľmi nízka návštevnost z Facebooku. Podporte ho postom. (Dosiahnutá hodnota: {realValue}% Optimálna hodnota: {optimalValue}%)',
    8: 'Nízka návštevnost z Facebooku. Podporte ho postom. (Dosiahnutá hodnota: {realValue}% Optimálna hodnota: {optimalValue}%)',
    9: 'Veľmi nizka návštevnost z NMH kanálov. Článok nie je dostatočne promovaný na ostatných brandoch. (Dosiahnutá hodnota: {realValue}% Optimálna hodnota: {optimalValue}%)',
    10: 'Nízka návštevnost z NMH kanálov. Článok nie je dostatočne promovaný na ostatných brandoch. (Dosiahnutá hodnota: {realValue}% Optimálna hodnota: {optimalValue}%)',
    11: 'Užívatelia trávia v článku extrémne krátku dobu. (Dosiahnutá hodnota: {realValue} sekúnd Optimálna hodnota: {optimalValue} sekúnd)',
    12: 'Užívatelia trávia v článku krátku dobu. (Dosiahnutá hodnota: {realValue} sekúnd Optimálna hodnota: {optimalValue} sekúnd)',
    13: 'Užívatelia trávia v galérií extrémne krátku dobu. (Dosiahnutá hodnota: {realValue} sekúnd Optimálna hodnota: {optimalValue} sekúnd)',
    14: 'Užívatelia trávia v galérií krátku dobu. (Dosiahnutá hodnota: {realValue} sekúnd Optimálna hodnota: {optimalValue} sekúnd)'
  }
}

export default messages
