const messages = {
  id: 'ID',
  activated_at: 'Aktivované',
  activated_at_from: 'Aktivované od',
  activated_at_to: 'Aktivované do',
  cancelled_at: 'Zrušené',
  cancelled_at_from: 'Zrušené od',
  cancelled_at_to: 'Zrušené do',
  activeSince: 'Aktívny od',
  activeUntil: 'Aktívny do',
  email: 'Email',
  code: 'Kód',
  feUser: 'Používateľ',
  product: 'Produkt',
  firstOrder: 'Prvá objednávka',
  filter: {
    export: 'Exportovať',
    search: 'Hľadať',
    reset_filter: 'Zrušiť filter',
    unique_email: 'Unikátny email'
  }
}

export default messages
