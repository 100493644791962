import axios from 'axios'
import appConfig from '@/config'

const contentBlockServiceApi = () => axios.create({
  baseURL: appConfig.contentBlockService.apiUrl(),
  timeout: appConfig.contentBlockService.apiTimeout * 1000,
  headers: {
    Authorization: localStorage.getItem('access_token'),
    'Content-Type': 'application/json'
  }
})

export default contentBlockServiceApi
