import KpiPerformance from './PerformanceKpi'

const PerformanceKpi = {
  ...KpiPerformance,
  month: 0,
  year: 0,
  departmentId: ''
}

const UsersKpi = {
  ...PerformanceKpi,
  userId: '',
  userName: ''
}

const KpiData = {
  departmentKpi: { ...PerformanceKpi },
  usersKpi: [
    { ...UsersKpi }
  ]
}

const DefaultKpiData = {
  departmentKpi: { ...PerformanceKpi },
  usersKpi: []
}

export { KpiData, UsersKpi, DefaultKpiData }
