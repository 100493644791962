import WoodwingService from '@/api/woodwingService'
import config from '@/config'
import WoodWingFilterModel from '@/model/WoodWingFilter'
import WoodWingModel from '@/model/WoodWing'
import WoodWingFilterService from '@/services/woodWing/woodWingFilterService'
import _ from 'lodash'

const API_NAME = '/woodWing'

const state = {
  detail: WoodWingModel,
  error: null,
  filter: _.cloneDeep(WoodWingFilterModel),
  list: [],
  categories: [],
  issues: [],
  page: 1,
  totalCount: 0
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeCategories (state, responseData) {
    state.categories = responseData.data
  },
  storeIssues (state, responseData) {
    state.issues = responseData.data
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page - 1) * config.defaults.list.limit
    return WoodwingService()
      .get(`${API_NAME}?view=minimal&limit=${config.defaults.list.limit}&offset=${offset}` +
        WoodWingFilterService.buildFilterQuery(store.state.filter) +
        '&order[id]=desc'
      )
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchCategories (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return WoodwingService()
      .get(`${API_NAME}/categories?view=minimal&limit=1000`)
      .then(res => {
        store.commit('storeCategories', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchIssues (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return WoodwingService()
      .get(`${API_NAME}/issues?view=minimal&filter_eq[active]=1&limit=2000`)
      .then(res => {
        store.commit('storeIssues', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  export (store, { article, issue, category }) {
    return WoodwingService().post(`${API_NAME}/export/${article}/${issue}/${category}`)
  },
  existsWWExport (store, documentId) {
    return WoodwingService().get(`${API_NAME}/existsArticle/${documentId}`)
  },
  fetchOne (store, id) {
    return WoodwingService().get(`${API_NAME}/${id}`)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  update (store, record) {
    return WoodwingService().put(`${API_NAME}/${record.id}`, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  categories (state) {
    return state.categories
  },
  issues (state) {
    return state.issues
  },
  filter (state) {
    return state.filter
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
