const messages = {
  list: {
    id: 'ID',
    message: 'Zpráva',
    site: 'Deník',
    from: 'Od',
    to: 'Do',
    published: 'Publikováno'
  },
  form: {
    title_label: 'Titulek',
    title_placeholder: 'Titulek zprávy',
    message_text_label: 'Text zprávy',
    message_text_placeholder: 'Text zprávy',
    link: 'Odkaz',
    link_placeholder: 'Odkaz na stránku (https://...)'
  },
  filter: {
    text: 'Zpráva',
    site: 'Deníky',
    published: 'Publikováno',
    search: 'Filtrovat'
  },
  type_value: {
    published: 'Aktivní',
    unpublished: 'Neaktivní'
  },
  inactive: 'Neaktivní',
  active: 'Aktivní',
  publish_news_label: 'Publikovat zprávu'
}

export default messages
