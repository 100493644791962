const messages = {
  siteName: 'Server',
  appId: 'AppId',
  apiKey: 'ApiKey',
  utm: 'Utm',
  limit: 'Limit',
  limit_placeholder: 'Info: 0 pro neomezený denní limit',
  reset: 'Reset',
  active: 'Aktivní',
  addSite: 'Přidat server',
  no_results_found_for_this_gps: 'Nebyly nalezeny žádné výsledky pro toto GPS',
  error_fetching_address_from_gps: 'Chyba při načítání adresy z GPS',
  invalid_gps_coordinates: 'Neplatné GPS souřadnice',
  vlm_valid_GPS_format: 'požadovaný tvar GPS: 50.05649280076552,14.375121168745256'
}

export default messages
