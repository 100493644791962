import _ from 'lodash'
import EshopApi from '../../../api/eshop'
import SubscriptionFilterService from '../../../services/eshop/SubscriptionFilterService'
import SubscriptionFilterModel from '../../../model/eshop/SubscriptionFilter'
import SubscriptionModel from '../../../model/eshop/Subscription'
import config from '../../../config'
import ErrorHandlingService from '../../../services/ErrorHandlingService'

const API_NAME = '/subscription'
const EXPORT_LIMIT = 10000

const state = {
  error: null,
  detail: SubscriptionModel,
  detailByCode: [],
  list: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(SubscriptionFilterModel)
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  storeDetailByCode (state, responseData) {
    state.detailByCode[responseData.code] = responseData.data
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * config.defaults.list.limit) - config.defaults.list.limit
    const url = API_NAME + '?view=full&limit=' + config.defaults.list.limit + '&offset=' + offset + '&order[id]=desc' +
      SubscriptionFilterService.buildFilterQuery(store.state.filter)
    EshopApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async export (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const url = API_NAME + '.csv?limit=' + EXPORT_LIMIT + '&order[id]=desc' + SubscriptionFilterService.buildFilterQuery(store.state.filter)
    return await EshopApi().get(url)
      .finally(() => {
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchOne (store, id) {
    await EshopApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => {
        ErrorHandlingService.handleError(store, error)
        console.log(error)
      })
  },
  async fetchOneByCode (store, code) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    store.commit('storeDetailByCode', { code: code, data: null })
    await EshopApi().get(API_NAME + '?view=expanded&filter_eq[code]=' + code)
      .then(response => {
        store.commit('TOGGLE_LOADING', null, { root: true })
        if (response.data.data[0]) {
          store.commit('storeDetailByCode', { code: code, data: response.data.data[0] })
        }
      })
      .catch(error => {
        store.commit('TOGGLE_LOADING', null, { root: true })
        ErrorHandlingService.handleError(store, error)
        console.log(error)
      })
  },
  async create (store, record) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await EshopApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        store.commit('TOGGLE_LOADING', null, { root: true })
        if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        store.commit('TOGGLE_LOADING', null, { root: true })
        ErrorHandlingService.handleError(store, error)
      })
  },
  async update (store, record) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await EshopApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        store.commit('TOGGLE_LOADING', null, { root: true })
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        store.commit('TOGGLE_LOADING', null, { root: true })
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
        ErrorHandlingService.handleError(store, error)
      })
  },
  async deleteRecord (store, record) {
    return await EshopApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
        ErrorHandlingService.handleError(store, error)
      })
  }
}

const getters = {
  detail (state, getters, rootState, rootGetters) {
    return state.detail
  },
  detailByCode (state, getters, rootState, rootGetters) {
    return code => {
      return state.detailByCode[code]
    }
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
