const messages = {
  list: {
    id: 'Id',
    year: 'Ročník',
    competitionName: 'Turnaj',
    fortunaSportName: 'Sport',
    nationName: 'Země',
    fortunaCatName: 'Liga'
  },
  filter: {
    id: 'Id',
    year: 'Ročník',
    competitionName: 'Turnaj',
    fortunaSportName: 'Sport',
    nationName: 'Země',
    fortunaCatName: 'Liga',
    search: 'Hledat'
  },
  hry: 'Hry',
  won: 'Výhry',
  drawn: 'Remízy',
  lost: 'Přehry',
  won_2: 'Výhry PP',
  lost_2: 'Přehry PP',
  points: 'Body'
}

export default messages
