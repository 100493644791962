import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_TOPICS_AND_SPECIALS_PERMISSIONS

const routes = [
  // * list
  {
    path: 'themeSpecialAndSeries',
    component: () => import('@/views/themeSpecialAndSeries/ThemeSpecialAndSeriesViewList.vue'),
    name: 'themeSpecialAndSeries_list',
    meta: {
      description: 'Theme Special And Series list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  },
  // * edit
  {
    path: 'themeSpecialAndSeries/:id/edit',
    component: () => import('@/views/themeSpecialAndSeries/ThemeSpecialAndSeriesEditView.vue'),
    name: 'themeSpecialAndSeries_edit',
    meta: {
      description: 'Theme Special And Series edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  // * new
  {
    path: 'themeSpecialAndSeries/new',
    component: () => import('@/views/themeSpecialAndSeries/ThemeSpecialAndSeriesNewView.vue'),
    name: 'themeSpecialAndSeries_new',
    meta: {
      description: 'Theme Special And Series new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  // * Special detail
  {
    path: 'special/:id',
    component: () => import('@/views/themeSpecialAndSeries/detailComponents/SpecialDetailView.vue'),
    name: 'special_detail',
    meta: {
      description: 'Special detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  },
  // * Theme detail
  {
    path: 'theme/:id',
    component: () => import('@/views/themeSpecialAndSeries/detailComponents/ThemeDetailView.vue'),
    name: 'theme_detail',
    meta: {
      description: 'Theme detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  },
  // * Series detail
  {
    path: 'series/:id',
    component: () => import('@/views/themeSpecialAndSeries/detailComponents/SeriesDetailView.vue'),
    name: 'series_detail',
    meta: {
      description: 'Series detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
