import { CAR_TYPE_DEFAULT } from '../components/mixins/valueObject/CarTypeMixin'
import { yearNow } from '../services/DateTimeService'

export default {
  id: null,
  title: '',
  description: '',
  image: null,
  type: CAR_TYPE_DEFAULT,
  brand: '',
  model: '',
  productionYear: yearNow(),
  rating: {
    exterior: 0,
    interior: 0,
    engine: 0,
    driving: 0,
    price: 0,
    total: 0
  },
  prosCons: {
    pros: [],
    cons: []
  },
  engine: {
    fuel: '',
    drive: '',
    gearbox: ''
  },
  fuelEngine: {
    cylinderSetup: '',
    cylinderVolume: 0,
    maxPower: 0,
    maxPowerRotationFrom: 0,
    maxPowerRotationTo: 0,
    maxTorque: 0,
    maxTorqueRotationFrom: 0,
    maxTorqueRotationTo: 0
  },
  elEngine: {
    motorType: '',
    maxPower: 0,
    maxTorque: 0,
    maxRotation: 0
  },
  measurements: {
    length: 0,
    width: 0,
    height: 0,
    wheelBase: 0,
    curbWeight: 0,
    curbWeightEu: 0,
    capacityWeight: 0,
    totalWeight: 0,
    trunkCapacityFrom: 0,
    trunkCapacityTo: 0,
    trunkLength: 0,
    trunkWidth: 0,
    trunkHeight: 0,
    tankVolume: 0,
    batteryCapacity: 0
  },
  driving: {
    maxSpeed: 0,
    acceleration: 0
  },
  fuelConsumption: {
    extraHighFrom: 0,
    extraHighTo: 0,
    highFrom: 0,
    highTo: 0,
    mediumFrom: 0,
    mediumTo: 0,
    lowFrom: 0,
    lowTo: 0,
    combinedFrom: 0,
    combinedTo: 0,
    testedCityFrom: 0,
    testedCityTo: 0,
    testedMotorwayFrom: 0,
    testedMotorwayTo: 0,
    testedCountryRoadFrom: 0,
    testedCountryRoadTo: 0
  },
  elConsumption: {
    extraHighFrom: 0,
    extraHighTo: 0,
    highFrom: 0,
    highTo: 0,
    mediumFrom: 0,
    mediumTo: 0,
    lowFrom: 0,
    lowTo: 0,
    combinedFrom: 0,
    combinedTo: 0,
    testedCityFrom: 0,
    testedCityTo: 0,
    testedMotorwayFrom: 0,
    testedMotorwayTo: 0,
    testedCountryRoadFrom: 0,
    testedCountryRoadTo: 0,
    rangeFrom: 0,
    rangeTo: 0
  },
  emission: {
    valueFrom: 0,
    valueTo: 0,
    standard: ''
  },
  price: {
    modelPrice: 0,
    versionPrice: 0,
    testedPrice: 0
  }
}
