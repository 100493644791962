import DateTimeService from '../services/DateTimeService'

export default {
  video: false,
  imageGallery: false,
  exclusive: false,
  commentary: false,
  interview: false,
  online: false,
  poll: false,
  updated: false,
  updatedUntilDatetime: DateTimeService.dateTimeMax,
  justNow: false,
  justNowUntilDatetime: DateTimeService.dateTimeMin,
  native: false,
  photostory: false,
  quiz: false,
  likeDislike: false,
  hiddenOnHp: false // only VLM
}
