const messages = {
  filter: {
    created_at_from: 'Vytvorené od',
    created_at_to: 'Vytvorené do'
  },
  main_tab: 'Hlavné informácie',
  instructions_tab: 'Postup',
  ingredients_tab: 'Suroviny a alergény',
  gallery_tab: 'Galéria',
  title: 'Titulok',
  title_placeholder: 'Napíšte názov receptu',
  published_since: 'Dátum publikovania',
  published_since_placeholder: 'Vyberte dátum publikovania na webe',
  photo_or_video: 'Vyberte hlavný obrázok alebo video',
  enabled: 'Aktívovať',
  ingredient_title: 'Titulok',
  ingredient_title_placeholder: 'Napíšte názov skupiny surovín',
  ingredient_urls: 'Odkazy na suroviny',
  spentTime: 'Dĺžka prípravy (min)',
  portions: 'Počet porcií',
  price: 'Cena',
  difficulty: 'Náročnosť',
  instructions: 'Postup',
  step: 'Krok',
  add_step: 'Pridať krok',
  ingredient_groups: 'Suroviny',
  ingredient_group: 'Skupina surovín',
  add_ingredient: 'Pridať surovinu',
  add_ingredient_group: 'Pridať ďalšiu skupinu surovín',
  ingredient: 'Surovina',
  amount: 'Množstvo',
  unit: 'Jednotka',
  allergens: 'Alergény',
  shop: 'Nakúpiť online (odkaz)',
  discount: 'Akciový leták (odkaz)',
  headline_used_in_articles: 'Použité v článku',
  article_not_found: 'Recept sa nenachádza v žiadnom článku.',
  article_exists: 'Recept je použitý v článku !',
  create_article: 'Vytvoriť článok',
  article_modal_header: 'Pre vytvorenie článku je potrebné uložiť recept. Uložiť teraz?',
  article_created_error: 'Chyba. Problem s vytvorením receptového článku.',
  article_created: 'Článok bol vytvorený'
}

export default messages
