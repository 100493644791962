const messages = {
  list: {
    id: 'Id',
    name: 'Name',
    external: 'External',
    external_group_mapping_tooltip: 'If a group is locked, it is assigned to an external system group and it cannot be deleted.'
  },
  name: 'Name',
  capabilities: 'Group permissions'
}

export default messages
