import _ from 'lodash'
import CoreApi from '../../api/core'
import VideoModel from '../../model/Video.js'
import VideoFilterModel from '../../model/VideoFilter'
import VideoFilterService from '../../services/video/VideoFilterService'
import config from '../../config'
import NotifyService from '@/services/NotifyService'

const API_NAME = '/video'

const getUrlForListing = (store, page) => {
  const offset = (page * store.state.limit) - store.state.limit
  return API_NAME +
    '?limit=' + store.state.limit +
    '&offset=' + offset +
    '&order[id]=desc' + VideoFilterService.buildFilterQuery(store.state.filter)
}

const state = {
  error: null,
  detail: _.cloneDeep(VideoModel),
  tabIndex: 0,
  list: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(VideoFilterModel),
  prevPage: { page: 0, list: [] },
  currentPage: { page: 1, list: [] },
  nextPage: { page: 2, list: [] },
  limit: config.defaults.list.limit,
  advancedFilter: false
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setTabIndex (state, tabIndex) {
    state.tabIndex = tabIndex
  },
  storePageList (state, { pageKey, page, list }) {
    state[pageKey] = { page, list }
  },
  pageShiftLeft (state) {
    state.nextPage = { page: state.currentPage.page, list: state.currentPage.list }
    state.currentPage = { page: state.prevPage.page, list: state.prevPage.list }
  },
  pageShiftRight (state) {
    state.prevPage = { page: state.currentPage.page, list: state.currentPage.list }
    state.currentPage = { page: state.nextPage.page, list: state.nextPage.list }
  },
  storeTotalCount (state, responseData) {
    state.totalCount = responseData.totalCount
  },
  setLimit (state, limit) {
    state.limit = limit
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(VideoFilterModel)
  },
  toggleAdvancedFilter (state, showAdvancedFilter) {
    state.advancedFilter = typeof showAdvancedFilter === 'boolean' ? showAdvancedFilter : !state.advancedFilter
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const url = getUrlForListing(store, store.state.page)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchCurrentPage (store) {
    const url = getUrlForListing(store, store.state.page)
    await CoreApi().get(url)
      .then(result => {
        store.commit('storePageList', { pageKey: 'currentPage', page: store.state.page, list: result.data.data })
        store.commit('storeTotalCount', result.data)
      })
      .catch(error => {
        console.error(error)
        store.commit('storePageList', { pageKey: 'currentPage', page: store.state.page, list: [] })
        if (error?.response?.status === 500) {
          NotifyService.setErrorMessage(error.response.status)
        } else {
          NotifyService.setErrorMessage(error?.response?.data?.error ?? error)
        }
      })
  },
  async fetchPrevPage (store) {
    if (store.state.page > 1) {
      const previousPage = store.state.page - 1
      const url = getUrlForListing(store, previousPage)
      await CoreApi().get(url)
        .then(prevResult => {
          store.commit('storePageList', { pageKey: 'prevPage', page: previousPage, list: prevResult.data.data })
        })
        .catch(error => console.error(error))
    }
  },
  async fetchNextPage (store) {
    if (store.state.page < store.getters.pageCount) {
      const nextPage = store.state.page + 1
      const url = getUrlForListing(store, nextPage)
      await CoreApi().get(url)
        .then(nextResult => {
          store.commit('storePageList', { pageKey: 'nextPage', page: nextPage, list: nextResult.data.data })
        })
        .catch(error => console.error(error))
    }
  },
  async fetchPage (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const shiftLeft = store.state.page === store.state.currentPage.page - 1
    const shiftRight = store.state.page === store.state.currentPage.page + 1
    if (shiftLeft) {
      store.commit('pageShiftLeft')
    } else if (shiftRight) {
      store.commit('pageShiftRight')
    }
    await store.dispatch('fetchCurrentPage')
    store.commit('TOGGLE_LOADING', null, { root: true })
    if (!shiftRight) {
      store.dispatch('fetchPrevPage')
    }
    if (!shiftLeft) {
      store.dispatch('fetchNextPage')
    }
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async create (store, record) {
    return await CoreApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  tabIndex (state) {
    return state.tabIndex
  },
  list (state) {
    return state.list
  },
  prevPage (state) {
    return state.prevPage.list
  },
  currentPage (state) {
    return state.currentPage.list
  },
  nextPage (state) {
    return state.nextPage.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  limit (state) {
    return state.limit
  },
  pageCount (state) {
    return Math.ceil(state.totalCount / state.limit)
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  advancedFilter (state) {
    return state.advancedFilter
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
