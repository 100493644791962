import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ARTICLE_TEST_HOTEL_PERMISSIONS

const routes = [
  {
    path: 'articleTest/hotel/new',
    component: () => import('../../views/article/ArticleTestHotelNewView'),
    name: 'hotel_new',
    meta: {
      description: 'Hotel new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'articleTest/hotel/:id/edit',
    component: () => import('../../views/article/ArticleTestHotelEditView'),
    name: 'hotel_edit',
    meta: {
      description: 'Hotel edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'articleTest/hotel/:id',
    component: () => import('../../views/article/ArticleTestHotelView'),
    name: 'hotel_detail',
    meta: {
      description: 'Hotel detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'articleTest/hotel',
    component: () => import('../../views/article/ArticleTestHotelListView'),
    name: 'hotel_list',
    meta: {
      description: 'Hotel list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
