const messages = {
  nativeCampaign: {
    status: 'Status',
    agency: 'Agency',
    client: 'Client',
    title: 'Title',
    interval: 'Duration',
    alternatives: 'Alter- natives',
    days: 'Active display (days)',
    daysColumn: 'Active Days',
    daysTooltip: 'Number of days when the campaign is active',
    visits: 'Visits (inview impressions)',
    visitsColumn: 'Visits',
    visitsTooltip: 'Inview impressions',
    displays: 'Displays (impressions)',
    clicks: 'Clicks',
    clicksTooltip: 'Number of clicks / Number of paid clicks',
    sumPrice: 'Sum price',
    ctr: 'CTR In-View',
    ctrColumn: 'CTR',
    ctrTooltip: 'In-View',
    clicksLimit: 'Paid clicks',
    budget: 'Budget',
    clickPrice: 'Price per click (€)',
    clickPriceColumn: 'CPC',
    clickPriceTooltip: 'Price per click',
    budgetFulfillment: 'Budget fulfillment',
    fulfillment: 'Fulfillment',
    bonusFulfillment: 'Current bonus clicks',
    bonusFulfillmentColumn: 'Bonus',
    bonusFulfillmentTooltip: 'Current bonus clicks',
    rrk: 'RRK',
    rrkTooltip: 'Divided by days',
    dividedByDays: 'Divided by days', // RRK
    note: 'Note',
    salesman: 'Salesman',
    category: 'Campaign category',
    pageUrl: 'Page URL',
    orderId: 'Order ID',
    addAlternative: 'Add alternative',
    mainAlternative: 'Main alternative',
    alternativeName: 'Alternative {name}',
    image: 'Image',
    loadImageFromArticle: 'Load image from the article',
    publishedFrom: 'Published from',
    publishedTo: 'Published to',
    segments: 'Segments',
    pause: 'Pause campaign',
    bonusClicks: 'Entitlement to bonus clicks',
    resetCampaign: 'Reset campaign',
    resetCampaignDisabled: 'Campaign is still valid',
    publishedIsInPast: 'Validity cannot be in the past.',
    brands: 'Sites for advertisement',
    allNMHBrandOption: 'All NMH',
    alternativesEnabled: 'Alternatives enabled',
    paidCampaign: 'Paid campaign',
    createNewLabel: 'New campaign',
    filter: {
      status: 'Status',
      agency: 'Agency',
      client: 'Client',
      title: 'Title',
      from: 'Date from',
      to: 'Date to',
      url: 'URL',
      salesman: 'Salesman',
      orderId: 'Order ID'
    },
    alternative: {
      ctr: 'CTR',
      visitsCount: 'Number of visits (inview impressions)',
      displaysCount: 'Number of views (impressions)',
      clickCount: 'Number of clicks'
    },
    brandsTable: {
      title: 'Development of the campaign according to the brand',
      brands: 'Brands',
      visits: 'Visits (inview impressions)',
      clicks: 'Clicks',
      ctr: 'CTR'
    }
  },
  prediction: {
    clicks: 'Real clicks',
    clicksForecast: 'Click forecast',
    clicksReserved: 'Reserved clicks',
    sold: 'Sold',
    clicksForecastTooltip: 'This figure/axis tells how much ad space NMH has available in the case of native donbox advertising.',
    clicksReservedTooltip: 'This figure / axis tells you the total booked clicks of the campaigns in the case of native donbox advertising.' +
      'That is, the number of clicks that clients have paid for within campaigns. ',
    soldTooltip: '% share between of "clicks available" and "clicks sold".',
    clicksTooltip: 'This figure / axis tells you the real number of clicks that will be made in the case of native donbox advertising'
  }
}

export default messages
