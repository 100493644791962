import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_POLL_BLOCK_PERMISSIONS

const routes = [
  {
    path: 'pollBlock/new',
    component: () => import('../../views/poll/PollBlockNewView'),
    name: 'pollBlock_new',
    meta: {
      description: 'PollBlock new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'pollBlock/:id/edit',
    component: () => import('../../views/poll/PollBlockEditView'),
    name: 'pollBlock_edit',
    meta: {
      description: 'PollBlock edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'pollBlock/:id',
    component: () => import('../../views/poll/PollBlockView'),
    name: 'pollBlock_detail',
    meta: {
      description: 'PollBlock detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'pollBlock',
    component: () => import('../../views/poll/PollBlockListView'),
    name: 'pollBlock_list',
    meta: {
      description: 'PollBlock list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
