const messages = {
  list: {
    id: 'Id',
    component: 'Component',
    name: 'Name',
    description: 'Description',
    enabled: 'Enabled',
    data: 'Data',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil'
  },
  filter: {
    id: 'Id',
    component: 'Component',
    name: 'Name',
    search: 'Search'
  },
  id: 'Id',
  component: 'Component',
  name: 'Name',
  description: 'Description',
  enabled: 'Enabled',
  data: 'Data'
}

export default messages
