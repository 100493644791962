import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.HOMEPAGE_MANAGEMENT_PERMISSIONS

const routes = [
  {
    path: 'contentBlockItem',
    component: () => import('@/views/hp/HpManagementView'),
    name: 'contentBlockItem_list',
    meta: {
      description: 'HP management',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  },
  {
    path: 'contentBlockItemOld',
    component: () => import('../../components/contentBlockItem/ContentBlockItemListOld'),
    name: 'contentBlockItem_list_old',
    meta: {
      description: 'HP management(old)',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }

]

export default routes
