<template>
  <h2 class="header-route-name">
    <ArticleListIcon v-if="icons.listOfArticles.includes($route.name)" class="header-route-name__icon" />
    <ArticleOfferIcon v-if="icons.articlesOffer.includes($route.name)" class="header-route-name__icon" />
    <EditorPerformanceIcon v-if="icons.editorialPerformance.includes($route.name)" class="header-route-name__icon" />
    <EshopIcon v-if="icons.eshop.includes($route.name)" class="header-route-name__icon" />
    <DamIcon v-if="icons.dam.includes($route.name)" class="header-route-name__icon" />
    <HomepageIcon v-if="icons.homepage.includes($route.name)" class="header-route-name__icon" />
    <ModuleIcon v-if="icons.modules.includes($route.name)" class="header-route-name__icon" />
    <PrintIcon v-if="icons.print.includes($route.name)" class="header-route-name__icon" />
    <RealtimeStatisticsIcon v-if="icons.realtimeStatistics.includes($route.name)" class="header-route-name__icon" />
    <SponsoredArticlesIcon v-if="icons.sponsoredArticles.includes($route.name)" class="header-route-name__icon" />
    <SystemIcon v-if="icons.system.includes($route.name)" class="header-route-name__icon" />
    <StatisticsIcon v-if="icons.statistics.includes($route.name)" class="header-route-name__icon" />
    <TestIcon v-if="icons.tests.includes($route.name)" class="header-route-name__icon" />
    <UserIcon v-if="icons.users.includes($route.name)" class="header-route-name__icon" />
    <VideoIcon v-if="icons.video.includes($route.name)" class="header-route-name__icon" />
    <span class="header-route-name__text">
      {{ $t('route.' + $route.name) }}
    </span>
  </h2>
</template>

<script>
import ArticleListIcon from '@/assets/img/svg/article-list.svg?inline'
import ArticleOfferIcon from '@/assets/img/svg/article-offers.svg?inline'
import EditorPerformanceIcon from '@/assets/img/svg/editor-performance.svg?inline'
import EshopIcon from '@/assets/img/svg/eshop.svg?inline'
import DamIcon from '@/assets/img/svg/dam.svg?inline'
import HomepageIcon from '@/assets/img/svg/homepage.svg?inline'
import ModuleIcon from '@/assets/img/svg/modules.svg?inline'
import PrintIcon from '@/assets/img/svg/print.svg?inline'
import SponsoredArticlesIcon from '@/assets/img/svg/sponsored-articles.svg?inline'
import SystemIcon from '@/assets/img/svg/system.svg?inline'
import StatisticsIcon from '@/assets/img/svg/statistics.svg?inline'
import TestIcon from '@/assets/img/svg/test.svg?inline'
import UserIcon from '@/assets/img/svg/users.svg?inline'
import VideoIcon from '@/assets/img/svg/video.svg?inline'
import RealtimeStatisticsIcon from '@/assets/img/svg/realtime-statistics.svg?inline'
export default {
  name: 'DashHeaderRouteName',
  components: {
    ArticleListIcon,
    ArticleOfferIcon,
    EditorPerformanceIcon,
    EshopIcon,
    DamIcon,
    HomepageIcon,
    ModuleIcon,
    PrintIcon,
    SponsoredArticlesIcon,
    SystemIcon,
    StatisticsIcon,
    TestIcon,
    UserIcon,
    VideoIcon,
    RealtimeStatisticsIcon
  },
  data () {
    return {
      icons: {
        articlesOffer: ['tasrNews_list', 'topicOffer_list', 'woodWing_list'],
        dam: ['dam_list', 'dam_pdf_list'],
        editorialPerformance: ['performance_product', 'performance_department', 'historical_performance',
          'performance_weights', 'performance_kpi_settings', 'edonProduct_list', 'kpi_nmh', 'kpi_nmh_settings'],
        eshop: ['eshopDashboard', 'eshopSubscriptionProduct_list', 'eshopSubscriptionOrder_list',
          'eshopSubscriptionPayment_list', 'eshopSubscription_list', 'eshopSubscriber_list', 'eshopHistory_list'],
        homepage: ['contentBlockItem_list', 'hp_topic_offer_list'],
        listOfArticles: ['article_list'],
        modules: ['infobox_list', 'joke_list', 'quote_list', 'poll_list', 'poll_management', 'likeDislike_list',
          'quiz_list', 'oneQuestion_list', 'sport24_list', 'topic_list', 'recipe_list'],
        print: ['printPublication_list', 'printArticle_list'],
        realtimeStatistics: ['dashboard', 'dashboard_old'],
        sponsoredArticles: ['native_campaign_list', 'don_npa_list', 'don_npa_predictions'],
        statistics: ['historical_dashboard'],
        system: ['beUser_list', 'department_list', 'app_performance', 'scale_list', 'author_list', 'contentBlock_list',
          'pollBlock_list', 'site_list', 'rubric_list', 'category_list', 'tag_list', 'safetyTopic_list',
          'redirect_list', 'feature_list', 'eshopFeature_list', 'articleHistory_list',
          'tasrNewsCategory_list', 'printTitle_list'],
        tests: ['car_list', 'restaurant_list', 'hotel_list'],
        users: ['feUser_list', 'feUserPollVote_list', 'feUserDeliveryAddress_list', 'feUserBillingAddress_list',
          'feUserArticle_list'],
        video: ['video_list', 'videoManagement', 'videoDashboard', 'videoDailyReport', 'videoPublishedReport',
          'videoKpiReports', 'videoShow_list', 'videoCategory_list']
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .header-route-name {
    @include font(500 22px "Roboto");
    @include margin(0);
    @include grid-gap(8px);
    color: #465674;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    .header & {
      @include bp(0 7) {
        display: none;
      }
    }
    &__icon {
      @include size(18px);
      fill: #6599fe;
    }
  }
</style>
