const messages = {
  universal: {
    yesOrNoArea: {
      yes: 'Ano',
      no: 'Ne'
    }
  }
}

export default messages
