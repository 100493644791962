const messages = {
  select_site: 'Select site',
  top_pages: 'Top pages',
  all_sites: 'All sites',
  people: 'People',
  performance: 'Performance',
  recommendation: 'Recommendation',
  status: 'Status',
  article_title: 'Article',
  today_page_views: 'Today',
  today_video_views: 'Today',
  page_views: 'Page views',
  video_views: 'Video views',
  recirculation: 'Recirculation',
  readingTime: 'Average reading time',
  ab_status_active: 'Active',
  ab_status_finished: 'Finished',
  variant: 'Variant',
  impressions: 'Impressions',
  clicks: 'Clicks',
  ctr: 'CTR',
  historical_pv: 'Historical article performance',
  google_data_studio: 'Google Data Studio',
  open_report: 'Show report',
  show: 'Show',
  show_chart: 'Zobraziť chart',
  peoplemeter_title: 'Count of poeple on site',
  peoplemeter_max: 'Maximal count of people',
  tooltip: {
    people: 'Amount of people reading the article',
    article_title: 'Article title',
    recirculation: '% click-through rate from the given article to other pages of the given website (or other NMH website) - staying on NMH websites',
    page_views: 'Amount of page views (PV) for the current day (from midnight till now). Statistics include photos in the gallery. * on articles with first publication date older than 7 days, we do not measure Page Views (PVs) and Video Views (VVs) in real time',
    video_views: 'Amount of video views (VV) for the current day (from midnight till now). If there are more videos in the article, VVs are added up. Any VVs are counted, regardless of whether the Hero video is an article or a video in text. Only counts VV via Ooyala player. * on articles with first publication date older than 7 days, we do not measure Page Views (PVs) and Video Views (VVs) in real time',
    page_video_views_hint: ' * articles with first date publication older than 7 days, there is no counting of page views or video views in realtime',
    today_page_views: 'Amount of page views for last 3 hours',
    today_video_views: 'Amount of video views for last 3 hours',
    order_change_up: 'Article readership increased',
    order_change_down: 'Article readership decreased',
    order_change_none: 'Article readership unchanged',
    order_date: 'Order date',
    picture_count: 'Amount of used pictures (gallery+hero+embed)',
    video_count: 'Amount of used videos (youtube+ooyala+hero)',
    peoplemeter_max: 'Maximal count of people in 1 minute interval of actual weekday during the last 4 weeks'
  },
  all: 'All',
  sum: 'Sum',
  device: 'Device',
  channel: 'Channel',
  desktop: 'Desktop',
  mobile: 'Mobile',
  tablet: 'Tablet',
  alwaysRefresh: 'Auto-refresh even with a row expanded',
  autoRefreshPaused: 'Auto-refresh is paused',
  filter: {
    title: 'Title',
    documentId: 'Id',
    author: 'Author'
  },
  menu: 'MENU'
}

export default messages
