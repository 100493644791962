export default {
  tags: 'Pridávanie tagov',
  isActive: 'Je aktívny',
  select_isActive: 'Aktívny',
  select_isInactive: 'Neaktívny',
  select_articlesCount: 'Počet článkov',
  select_display_type: 'Typ zobrazenia',
  select_display_type_cards: 'Karty',
  select_display_type_links: 'Odkazy',
  name: 'Názov',
  id: 'Id',
  save_btn: 'Uložiť',
  select_btn: 'Vybrať',
  cancelSelected_btn: 'Zrušiť vybrané',
  specials: {
    conflict_modal: 'Článok musí obsahovať aspoň jeden z týchto tagov:',
    select_modal_header: 'Vyber špeciál',
    heroImage: 'Hlavný obrázok',
    listingImage: 'Obrázok v zozname',
    header: 'Špeciály',
    table_title: 'Názov špeciálu',
    selectLabel: 'Špeciál',
    allSitesCheckboxLabel: 'Pre všetky weby denik.cz',
    sitesInputLable: 'Weby',
    name: 'Názov',
    title: 'Titul',
    description: 'Popis',
    slug: 'Slug',
    validTo: 'Dátum platnosti špeciálu',
    metaHeader: 'Meta údaje',
    meta: {
      title: 'Meta titul',
      description: 'Meta popis',
      keywords: 'Meta kľúčové slová'
    }
  },
  theme: {
    select_modal_header: 'Vyber tému',
    header: 'Témy',
    table_title: 'Názov témy',
    selectLabel: 'Téma',
    title: ''
  },
  series: {
    select_modal_header: 'Vyber seriál',
    header: 'Seriály',
    table_title: 'Názov seriálu',
    selectLabel: 'Seriál',
    title: ''
  }
}
