import EshopApi from '../../../api/eshop'
import TopProductSetting from '@/model/eshop/TopProductSetting'

const API_NAME = '/product/top-product-setting'
const SORT_CONFIG = ['left', 'middle', 'right']

const state = {
  error: null,
  all: [TopProductSetting]
}

const mutations = {
  store (state, data) {
    state.all = data
  },
  setError (state, message) {
    state.error = message
  }
}

const actions = {
  async fetchAll (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    await EshopApi().get(API_NAME)
      .then(response => {
        store.commit('store', response.data.topProductsSetting)
      })
      .catch(error => {
        store.commit('setError', error.response.data.error)
        throw error
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async update (store, record) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await EshopApi().put(API_NAME, JSON.stringify({ topProductsSetting: record }))
      .then(response => {
        if (response.status !== 204) {
          store.commit('setError', 'Error: ' + response.data)
        }
      })
      .catch(error => {
        store.commit('setError', error.response.data.error)
        throw error
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  }
}

const getters = {
  all (state) {
    return state.all
  },
  allSortedLeftToRight (state) {
    return SORT_CONFIG.map(position => state.all.find(value => value.position === position))
  },
  error (state) {
    return state.error
  },
  topProductSettingByPosition (state) {
    return position => state.all.find(topProductSetting => {
      if (position === topProductSetting.position) {
        return topProductSetting
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
