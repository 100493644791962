import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_BACKEND_USER_PERMISSIONS

const routes = [
  {
    path: 'beUser/:id/edit',
    component: () => import('../../../views/beUser/BeUserEditView'),
    name: 'beUser_edit',
    meta: {
      description: 'BeUser edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'beUser',
    component: () => import('../../../views/beUser/BeUserListView'),
    name: 'beUser_list',
    meta: {
      description: 'BeUser list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
