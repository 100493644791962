const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.name) {
    filterQuery += '&filter_contains[name]=' + filter.name
  }
  if (filter.isActive !== undefined && filter.isActive !== null && filter.isActive !== 0) {
    filterQuery += '&filter_eq[isActive]=' + filter.isActive
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
