export const QUIZ_TYPE_PERSONALITY = 'personality'
export const QUIZ_TYPE_CORRECT_ANSWER = 'correct_answer'
export const QUIZ_TYPE_ONE_QUESTION = 'one_question'
export const QUIZ_TYPE_POINTS = 'points'

export const QUIZ_TYPE_DEFAULT = QUIZ_TYPE_CORRECT_ANSWER
export const QUIZ_TYPE_DEFAULT_VLM = QUIZ_TYPE_CORRECT_ANSWER

export default {
  computed: {
    quizTypeValues () {
      return [
        {
          id: QUIZ_TYPE_PERSONALITY,
          title: this.$t('quiz.quiz_type.personality')
        },
        {
          id: QUIZ_TYPE_CORRECT_ANSWER,
          title: this.$t('quiz.quiz_type.correct_answer')
        },
        {
          id: QUIZ_TYPE_ONE_QUESTION,
          title: this.$t('quiz.quiz_type.one_question')
        }
      ]
    },
    quizTypeValuesVlm () {
      return [
        {
          id: QUIZ_TYPE_CORRECT_ANSWER,
          title: this.$t('quizVlm.quizType.correctAnswer')
        },
        {
          id: QUIZ_TYPE_POINTS,
          title: this.$t('quizVlm.quizType.points')
        }
      ]
    }
  }
}
