const messages = {
  id: 'ID',
  created_at: 'Vytvořeno',
  created_at_from: 'Vytvořeno od',
  created_at_to: 'Vytvořeno do',
  created_by: 'Vytvořil',
  modified_at: 'Změněno',
  modified_by: 'Úpravil',
  name: 'Název',
  slug: 'Slug',
  period: 'Období',
  price: 'Cena',
  visibility: 'Umístění',
  description: {
    title: 'Popis produktu',
    short: 'Krátký',
    main: 'Hlavní',
    landingPage: 'Landing page',
    summaryPage: 'Shrnutí'
  },
  metadata: {
    title: 'Metadata',
    keywords: 'Klíčová slova',
    description: 'Popis'
  },
  setting: {
    cardNumber: 'Číslo studentské karty',
    company: 'Na firmu',
    invoiceAddress: 'Fakturační adresa',
    deliveryAddress: 'Doručovací adresa',
    notice: 'Poznámka',
    platform: {
      print: 'Print',
      iOS: 'iOS',
      android: 'Android',
      ePaper: 'ePaper',
      windows8: 'Windows 8'
    },
    tag: {
      recommended: 'Doporučujeme',
      convenientOffer: 'Výhodná nabídka'
    },
    email: 'Omezení na email'
  },
  subscriptionSetting: {
    giftSubscription: 'Dárkové předplatné',
    companyProduct: 'Firemní produkt',
    companyProductCount: 'Počet firemních přístupů',
    aboStartEdition: 'Objednat od vydání',
    notifyBeforeNextRecurringPayment: 'Upozornit před následující opakovanou platbou'
  },
  trialPeriod: {
    enabled: 'Zkušební cena',
    price: 'Cena',
    aboProduct: 'ABO produkt',
    aboPeriodikPrice: 'Periodik ABO cena'
  },
  paymentGateways: 'Platební brány',
  payment: {
    default: 'Default',
    free: 'Zdarma',
    transfer: 'Prevodom na účet',
    cardpay: 'Online CardPay (kartou)',
    tatrapay: 'Online TatraPay',
    comfortpay: 'Online ComfortPay (opakovaně)',
    smsRecurring: 'SMS (opakovaně)',
    discountCode: 'Slevový kód',
    csobCardPay: 'Online CSOB (kartou)',
    csobGooglePay: 'Online GooglePay',
    csobMasterCardMobile: 'Online Mastercard Mobile'
  },
  aboProduct: 'ABO produkt',
  aboPeriodikPrice: 'Periodik ABO cena',
  addOnProduct: 'Doplňkový produkt',
  addOnProductDescription: 'Doplňkový produkt - popis',
  addPeriod: 'Přidej období',
  addRelatedProduct: 'Přidej příbuzný produkt',
  digitalServices: 'Digitální služby',
  priceDescription: 'Popis ceny',
  published_since: 'Publikováno od',
  published_until: 'Publikováno do',
  image: 'Obrázek',
  productImageType: {
    title: 'Typ obrázku',
    static: 'Static',
    dynamic: 'Dynamic'
  },
  insertImage: 'Vlož obrázek',
  imageUuid: 'Image ID',
  productImageSiteType: {
    title: 'Stránka',
    trend: 'TREND'
  },
  selectPage: 'Vyber stránku',
  yes: 'Ano',
  no: 'Ne',
  enabled: 'Zapnuto',
  detail: 'Detail',
  abo: 'Abo',
  systemInfo: 'Systémové informace',
  create_new: 'Vytvoř novou',
  save: 'Uložit',
  tab: {
    general: 'Hlavní nastavení',
    prices: 'Platby, ABO'
  },
  filter: {
    search: 'Hledat',
    reset_filter: 'Zrušit filtr'
  },
  error: {
    required_fields: 'Prosím, vyplňte všechna povinná pole'
  },
  message: {
    updated_record: 'Záznam byl změněn!',
    deleted_record: 'Záznam byl vymazán!'
  }
}

export default messages
