const messages = {
  playability: 'Video playability',
  reach: 'Video reach',
  video_performance: 'Video performance',
  ad_performance: 'Video ad performance',
  avgVideosPerUser: 'Average videos per user',
  concurrentUsers: 'Unique users',
  playConversionRate: 'In view play conv. rate',
  adConversionRate: 'Ad conv. rate',
  videoStarts: 'Video starts',
  adStarts: 'AD starts',
  adSkipRate: 'AD skipped',
  adFinishRate: 'AD watched',
  vidFinishRate: 'Video watched',
  videoPlays: 'Video starts',
  dailyAvg: 'Daily avg.',
  total: 'Total',
  inViewDisplays: 'Player displayed',
  concurrent_users: 'Realtime number of viewers in {company}',
  videoviews: 'Videoviews',
  detail: 'Detail',
  devices: 'Devices',
  desktop: 'Desktop',
  mobile: 'Mobile',
  tablet: 'Tablet',
  os: 'OS',
  windows: 'Windows',
  ios: 'iOS',
  android: 'Android',
  top_assets: 'Top videos',
  players_performance: 'Video player performance by position',
  sources: 'Sources',
  sources_domain: 'Domain sources',
  sources_url: 'Top video articles',
  type: 'Type',
  video_id: 'Video ID',
  title: 'Title',
  displays: 'Displays',
  in_view_displays: 'In view displays',
  videoplays: 'Videoplays',
  videoplays_portfolio: 'Number of video plays in {company}',
  all_sites: 'The whole {company} portfolio',
  ad_starts: 'AD starts',
  video_starts: 'Video starts',
  play_conv_rate: 'In view play conv rate %',
  conversion_rate_info: 'Conversion rate = Video plays / In view displays',
  replays: 'Replays',
  totalVideoViews: 'Video views',
  pass25Plays: 'Pass 25% plays',
  pass50Plays: 'Pass 50% plays',
  pass75Plays: 'Pass 75% plays',
  pass100Plays: 'Pass 100% plays',
  avgPassPerc: 'Pass average plays',
  unique_users_count: 'Unique users',
  characterType: {
    title: 'Content type',
    agencycontent: 'Agenncy',
    editorialcontent: 'Editorial',
    marketingcontent: 'Selfpromo (marketing)',
    nativecontent: 'Native'
  },
  load_data: 'Load data',
  displayed: 'Displayed',
  filter: {
    site: 'Site',
    department: 'Department',
    date_from: 'Date from',
    date_to: 'Date to',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    reset_filter: 'Reset filter'
  },
  no_data_found: 'No data found.',
  playlist_performance_today: 'Playlist performance today',
  other: 'other',
  daily_report: {
    author: 'Author',
    title: 'Video title',
    play_request: 'Play requests',
    site: 'Site',
    unique_users: 'Unique users',
    average: 'Average watched',
    hero: 'Hero',
    article: 'Article',
    gallery: 'Gallery',
    video_duration: 'Video duration',
    filter: {
      date: 'Date'
    }
  },
  kpi: {
    header: {
      onRequest: 'On Request',
      shows: 'Shows',
      totalVideos: 'Total videos',
      youtube: 'Youtube',
      publishedArticlesCount: 'Published articles count',
      videosArticlesRate: 'Article videos cover rate'
    },
    list: {
      department: '',
      departmentStat: 'Total',
      socialNetworks: 'Social networks',
      agencies: 'Agencies',
      mobilePhone: 'Mobile phone',
      slideShow: 'SlideShow',
      onRequest: 'Reality',
      shows: 'Reality',
      videosCount: 'Reality'
    },
    filter: {
      dateFrom: 'From',
      dateTo: 'To',
      reset_filter: 'Reset filter'
    }
  }
}

export default messages
