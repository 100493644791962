import ssoApi from '@/api/sso'

/*
 Brands are e.g. sites of articles
 */

const API_NAME = '/brands'

const state = {
  error: null,
  list: [],
  totalCount: 0
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.brands
    state.totalCount = responseData.totalCount
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return ssoApi().get(API_NAME)
      .then(response => {
        store.commit('storeList', response.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
        return response.data.brands
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
        return []
      })
  }
}

const getters = {
  list () {
    return state.list
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
