export default {
  callingAPI: false,
  callingApiCount: 0,
  config: {},
  searching: '',
  userInfo: {
    messages: [{ 1: 'test', 2: 'test' }],
    notifications: [],
    tasks: []
  },
  appVersion: '',
  isOffline: false
}
