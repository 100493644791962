const messages = {
  messages: {
    error: 'No specified message, please add it to the configuration',
    0: 'Missing A/B Testing. Turn on A/B Testing!',
    1: 'No image in the article. Add images! (Optimal number of images: {optimalValue})',
    2: 'Not enough images in the article. Add more images! (Optimal number of images: {optimalValue})',
    3: 'No video in the article. Add videos! (Optimal number of videos: {optimalValue})',
    4: 'Low number of videos in the article. Add more videos! (Optimal number of videos: {optimalValue})',
    5: 'Very low traffic from Google search. (Actual value: {realValue}% Optimal value: {optimalValue}%)',
    6: 'Low traffic from Google search. (Actual value: {realValue}% Optimal value: {optimalValue}%)',
    7: 'Very low traffic from Facebook. Support it with a post. (Actual value: {realValue}% Optimal value: {optimalValue}%)',
    8: 'Low traffic from Facebook. Support it with a post. (Actual value: {realValue}% Optimal value: {optimalValue}%)',
    9: 'Very low traffic from NMH channels. The article is not sufficiently promoted on other brands. (Actual value: {realValue}% Optimal value: {optimalValue}%)',
    10: 'Low traffic from NMH channels. The article is not sufficiently promoted on other brands. (Actual value: {realValue}% Optimal value: {optimalValue}%)',
    11: 'Users spend an extremely short time on the article. (Actual value: {realValue} seconds Optimal value: {optimalValue} seconds)',
    12: 'Users spend a short time on the article. (Actual value: {realValue} seconds Optimal value: {optimalValue} seconds)',
    13: 'Users spend an extremely short time in the gallery. (Actual value: {realValue} seconds Optimal value: {optimalValue} seconds)',
    14: 'Users spend a short time in the gallery. (Actual value: {realValue} seconds Optimal value: {optimalValue} seconds)'
  }
}

export default messages
