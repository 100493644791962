const messages = {
  filter: {
    id: 'Id',
    title: 'Title',
    site: 'Site',
    slug: 'Slug',
    category: 'Category'
  },
  list: {
    id: 'Id',
    title: 'Title',
    site: 'Site',
    slug: 'Slug',
    category: 'Category'
  },
  topic_category: {
    common: 'Default',
    tag: 'Tag',
    sport24_player: 'Šport24 Player',
    sport24_team: 'Šport24 Team',
    wanda_celebrity: 'Wanda Celebrities',
    zdravie_overall_condition: 'Zdravie Overall condition',
    zdravie_head_neck: 'Zdravie Head and neck',
    zdravie_chest: 'Zdravie Chest',
    zdravie_motion_system: 'Zdravie Motion system',
    zdravie_belly: 'Zdravie Belly',
    zdravie_underbelly: 'Zdravie Underbelly',
    zdravie_skin_hair_nails: 'Zdravie Skin, hair, nails',
    zdravie_pregnancy_motherhood: 'Zdravie Pregnancy and motherhood',
    zdravie_psyche: 'Zdravie Psyche',
    zdravie_life_style: 'Zdravie Life style',
    zdravie_healthy_eating: 'Zdravie Healthy Eating'
  },
  query: 'Query',
  id: 'Id',
  category: 'Category',
  slug: 'Slug',
  slug_desc: 'Must contains \'a-z\' and \'-\' only and combination site/slug must be unique',
  title: 'Title',
  title_placeholder: 'Write topic title',
  site: 'Site',
  description: 'Description',
  meta: 'Meta',
  meta_title: 'Meta Title',
  meta_description: 'Meta Description',
  meta_keywords: 'Meta Keywords',
  image: 'Image',
  image_bg: 'Background Image',
  tags_required: 'Tags required',
  tags_required_desc: 'Tags required description',
  tags_optional: 'Tags optional',
  tags_optional_desc: 'Tags required description',
  sites_selected: 'Selected sites',
  sites_selected_desc: 'Default all sites',
  rubrics_selected: 'Selected rubrics',
  rubrics_selected_desc: 'Default all rubrics',
  article_types: 'Article types',
  article_types_desc: 'Default all types',
  article_count: 'Počet clánkov témy',
  article_count_desc: 'Aktualizuje sa raz za deň',
  sport_table: {
    title: 'Sport table',
    season_id: 'Season Id',
    active_tab: 'Active Tab (first = 1)'
  },
  notify: {
    slug_site_combination_must_be_unique: 'Slug/site kombinácia musí byť unikátna, zvoľte iný slug.'
  }
}

export default messages
