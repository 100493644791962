export default {
  tags: 'Add tags',
  isActive: 'Is Active',
  select_isActive: 'Active',
  select_isInactive: 'Inactive',
  select_articlesCount: 'Number of articles',
  select_display_type: 'Display type',
  select_display_type_cards: 'Cards',
  select_display_type_links: 'Links',
  name: 'Title',
  id: 'Id',
  save_btn: 'Save',
  select_btn: 'Select',
  cancelSelected_btn: 'Cancel selected',
  specials: {
    conflict_modal: 'The article must contain at least one of these tags:',
    select_modal_header: 'Select special',
    heroImage: 'Hero image',
    listingImage: 'Listing image',
    header: 'Specials',
    table_title: 'Special Title',
    selectLabel: 'Special',
    allSitesCheckboxLabel: 'For all websites denik.cz',
    sitesInputLable: 'Websites',
    name: 'Name',
    title: 'Title',
    description: 'Description',
    slug: 'Slug',
    validTo: 'Special Validity Date',
    metaHeader: 'Meta Information',
    meta: {
      title: 'Meta Title',
      description: 'Meta Description',
      keywords: 'Meta Keywords'
    }
  },
  theme: {
    select_modal_header: 'Select theme',
    header: 'Theme',
    table_title: 'Theme title',
    selectLabel: 'Theme',
    title: ''
  },
  series: {
    select_modal_header: 'Select series',
    header: 'Series',
    table_title: 'Series title',
    selectLabel: 'Series',
    title: ''
  }
}
