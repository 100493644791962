import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_SITE_PERMISSIONS

const routes = [
  {
    path: 'site/:id/edit',
    component: () => import('../../views/site/SiteEditView'),
    name: 'site_edit',
    meta: {
      description: 'Site edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'site',
    component: () => import('../../views/site/SiteListView'),
    name: 'site_list',
    meta: {
      description: 'Site list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
