const messages = {
  npa: {
    status: 'Stav',
    title: 'Titulok',
    agency: 'Agentúra',
    client: 'Klient',
    interval: 'Trvanie',
    days: 'Aktívne zobrazovanie (dni)',
    impressions: 'Impresie',
    visits: 'Videnia',
    clicks: 'Kliknutia',
    total_price: 'Celková cena',
    ctr: 'CTR in-view',
    clicks_limit: 'Zaplatené kliknutia',
    click_price: 'Cena za klik (€)',
    clicks_total_price: 'Cena za kliknutia (€)',
    bonus_clicks_limit: 'Nárok na bonusové kliknutia',
    bonus_clicks: 'Aktuálne bonusové kliknutia',
    views: 'Zobrazenia',
    progress: 'Plnenie',
    bonus: 'Bonus',
    actions: 'Akcie',
    inactive: 'Inactive',
    active: 'Active',
    done: 'Done',
    paused: 'Paused',
    category: 'Kategória kampane',
    category_selection: 'Výber kategórie',
    divided_by_days: 'Rovnomerné rozloženie klikov',
    divided_by_days_shortname: 'RRK',
    url: 'URL stránky',
    image: 'Obrázok',
    published_since: 'Platnosť od',
    published_until: 'Platnosť do',
    native_perf_count: 'Počet článkov v boxe',
    total_items_count: 'Počet všetkých článkov',
    total_pages_count: 'Počet všetkých strán',
    alternating_campaigns: 'Alternovanie kampaní',
    alternative_title: 'Titulok pre alternatívu ',
    alternative_url: 'URL alternatívy ',
    alternative_image: 'Obrázok pre alternatívu ',
    alternative: 'Alternatíva',
    alternative_in_views_count: 'Počet zobrazení',
    alternative_clicks_count: 'Počet kliknutí',
    alternative_ctr: 'CTR',
    enable_scheduling: 'Nastaviť doručovanie kampane',
    algorithm_overridden: 'Vždy zobraziť',
    paid_campaign: 'Platená kampaň',
    campaign_delivery_day: 'Deň / dni doručenia',
    campaign_delivered_from: 'Čas doručenia od',
    campaign_delivered_to: 'Čas doručenia do',
    buttons: {
      add: 'Pridať',
      settings: 'Nastavenia',
      duplicate: 'Zduplikovať',
      add_alternative: '+ Pridať alternatívu',
      remove_alternative: 'Odstrániť alternatívu'
    },
    statuses: {
      inactive: 'Inactive',
      active: 'Active',
      done: 'Done',
      paused: 'Paused'
    },
    original_daily_calculation: 'Denný prepočet (pôvodný)',
    first_day_estimate: '1. deň',
    second_day_estimate: '2. deň',
    third_day_estimate: '3. deň',
    fourth_day_estimate: '4. deň',
    fifth_day_estimate: '5. deň',
    sixth_day_estimate: '6. deň',
    seventh_day_estimate: '7. deň',
    total_daily_campaign_performance: 'Výkon kampaní spolu',
    predicted_estimate_by_campaigns: 'Odhad priestoru NMH',
    daily_stats_chart: 'Vývoj kampane',
    daily_stats_table: 'Vývoj kampane podľa brandu',
    of_in_views: 'in views',
    of_clicks: 'klikov',
    validation: {
      main_image_check: 'Hlavný obrázok musí byť vyplnený.',
      alternative_b_check: 'Aspoň jeden z dvojice titulok - obrázok pre alternatívu B musí byť vyplnený.',
      alternative_c_check: 'Aspoň jeden z dvojice titulok - obrázok pre alternatívu C musí byť vyplnený.',
      duplicate_exists: 'Pre danú kampaň už existuje duplikátna kampaň.',
      delivery_time_check: 'Čas konca doručovania kampane musí byť väčší ako čas začiatku.'
    }
  }
}

export default messages
