import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.GALLERY_PERMISSIONS

const routes = [
  {
    path: 'gallery/new',
    component: () => import('../../views/gallery/GalleryNewView'),
    name: 'gallery_new',
    meta: {
      description: 'Gallery new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'gallery/:id/edit',
    component: () => import('../../views/gallery/GalleryEditView'),
    name: 'gallery_edit',
    meta: {
      description: 'Gallery edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'gallery/:id',
    component: () => import('../../views/gallery/GalleryView'),
    name: 'gallery_detail',
    meta: {
      description: 'Gallery detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'gallery',
    component: () => import('../../views/gallery/GalleryListView'),
    name: 'gallery_list',
    meta: {
      description: 'Gallery list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
