import { GtmSupport as GtmPlugin } from './index'
import { loadScript } from '@/plugins/gtm/utils'

let gtmPlugin

function install (Vue, options = { id: '' }) {
  // Apply default configuration
  options = { trackOnNextTick: false, ...options }

  // Add to vue prototype and also from globals
  gtmPlugin = new GtmPlugin(options)
  Vue.prototype.$gtm = Vue.gtm = gtmPlugin

  // Check if plugin is running in a real browser or e.g. in SSG mode
  if (gtmPlugin.isInBrowserContext()) {
    if (gtmPlugin.options.enabled && gtmPlugin.options.loadScript) {
      loadScript(options.id, options)
    }
  }
}

export { GtmPlugin, install }
export default install
