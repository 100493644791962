export default {
  type: 'none',
  code: '',
  activated: 'none',
  setting: {
    marketing: 'none',
    admin: 'none'
  },
  digitalServices: [],
  createdAtFrom: '',
  createdAtTo: '',
  id: ''
}
