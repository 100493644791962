const state = {
  compact: false,
  navigationHover: false,
  sidebar: {
    mobile: false,
    isOpen: false
  }
}

const mutations = {
  setCompactness (state, data) {
    state.compact = data
  },
  setNavigationHover (state, hover) {
    state.navigationHover = hover
  },
  setSidebarMobile (state, mobile) {
    state.sidebar.mobile = mobile
  },
  setSidebarOpen (state, open) {
    state.sidebar.isOpen = open
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
