import EshopApi from '../../../api/eshop'

const API_NAME = '/digitalService'
const LIMIT = 200

const state = {
  all: []
}

const mutations = {
  storeAll (state, responseData) {
    state.all = responseData.data
  }
}

const actions = {
  async fetchAll ({ commit }) {
    if (state.all.length > 0) {
      return
    }
    await EshopApi().get(API_NAME + '?limit=' + LIMIT)
      .then(res => {
        commit('storeAll', res.data)
      })
      .catch(error => console.log(error))
  }
}

const getters = {
  all (state) {
    return state.all
  },
  digitalServiceById (state) {
    return id => state.all.find(digitalService => {
      if (id === digitalService.id) {
        return digitalService
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
