import moment from 'moment'
import NativeCampaignManagementFilterModel from '@/model/NativeCampaignManagementFilterModel'

const buildFilterQuery = (filter, forExport = false) => {
  const convertedFilter = {
    ...filter,
    from: filter.from ? moment(filter.from).format('YYYY-MM-DD') : null,
    to: filter.to ? moment(filter.to).format('YYYY-MM-DD') : null,
    isFree: filter.isFree === false ? false : null // filtering either only-paid campaigns or all campaigns
  }
  const filterPrefix = forExport ? '' : 'FilterOptions.'
  return Object.keys(NativeCampaignManagementFilterModel)
    .reduce((acc, prop) => convertedFilter[prop] || convertedFilter[prop] === false ? `${acc}&${filterPrefix}${prop}=${convertedFilter[prop]}` : acc, '')
}

export default {
  buildFilterQuery
}
