const messages = {
  id: 'Id',
  name: 'Name',
  site: 'Site',
  scaleGroup: 'Group',
  groupValue: 'Group value',
  value: 'Value',
  search: 'Search'
}

export default messages
