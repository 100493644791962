import { dateTimeNow } from '../services/DateTimeService'

export default {
  id: null,
  title: '',
  description: '',
  instructions: '',
  articleEmbeds: [],
  image: null,
  video: '',
  videoType: '',
  spentTime: 0,
  portions: 0,
  price: '0',
  difficulty: 'low',
  shopUrl: '',
  discountUrl: '',
  category: null,
  site: null,
  allergens: [],
  ingredientGroups: [{
    id: null,
    title: '',
    ingredients: [{
      id: null,
      title: '',
      amount: '',
      unit: 'ml',
      shopUrl: ''
    }]
  }],
  expanded: {
    site: null,
    category: null,
    tags: [],
    image: null,
    gallery: []
  },
  tags: [],
  gallery: [],
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  createdBy: 1,
  modifiedBy: null
}
