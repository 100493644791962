<template>
  <i class="tooltip-custom" :style="`color: ${color};`" :class="[icon, position]">
    <span
      class="tooltip-custom--info"
      :class="tooltipClasses"
    >
      <slot>
        <span v-html="title"></span>
      </slot>
      <span :class="arrowClasses"></span>
    </span>
  </i>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    title: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'fas fa-info-circle'
    },
    customInfoClass: {
      type: String,
      default: ''
    },
    fixedWidth: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'rgb(132, 144, 167)'
    },
    placement: {
      type: String,
      default: 'top',
      validator: function (value) {
        return ['top', 'left', 'bottom'].includes(value)
      }
    },
    textAlign: {
      type: String,
      default: 'center',
      validator: function (value) {
        return ['center', 'left'].includes(value)
      }
    }
  },
  data () {
    return {
      showToolTip: false
    }
  },
  computed: {
    tooltipClasses () {
      const classes = [this.position, this.customInfoClass, `tooltip-custom--info--align-${this.textAlign}`]
      if (this.fixedWidth) {
        classes.push('tooltip-custom--info--fixed-width')
      }
      this.placement && classes.push(`tooltip-custom--info--placement-${this.placement}`)
      return classes
    },
    arrowClasses () {
      const classes = ['tooltip-custom--arrow']
      this.placement && classes.push(`tooltip-custom--arrow--placement-${this.placement}`)
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip-custom {
  position: relative;
  width: 14px;
  height: 14px;
  font-size: 14px;
  white-space: normal;
}

.tooltip-custom.absolute {
  position: absolute;
  margin-left: 2px;
}

.tooltip-custom:hover .tooltip-custom--info {
  color: rgb(132, 144, 167);
  visibility: visible;
  opacity: 1;
}

.tooltip-custom--info {
  display: block;
  padding: 10px;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  visibility: hidden;
  margin-left: -100px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2);
  transition: all .15s ease-in-out;
  opacity: 0;
  z-index: 99;

  &--fixed-width {
    width: 200px;
  }

  &--align-center {
    text-align: center;
  }

  &--align-left {
    text-align: left;
  }

  &--placement-bottom {
    top: 26px;
    right: 50%;
    transform: translate(50%, 0);
  }

  > span {
    font-weight: 400;
    font-size: 12px;
  }

  .tooltip-custom--arrow {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;

    &--placement-top {
      top: 100%;
      left: calc(50% - 10px);
    }

    &--placement-left {
      right: -14px;
      transform: rotate(270deg);
      top: calc(50% - 5px);
    }

    &--placement-bottom {
      bottom: 100%;
      transform: rotate(180deg);
      left: calc(50% - 10px);
    }
  }

  &.seo {
    text-align: justify;
    line-height: 130%;
    font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    width: 400px;
  }
}
</style>
