export default {
  id: null,
  type: 'article',
  image: null,
  imageMobile: null,
  url: '',
  videoId: '',
  title: '',
  perex: '',
  bgColor: 'rgba(0,119,192,0.8)',
  border: '1px solid #000',
  color: '#ffffff',
  start: 0,
  end: 0,
  width: 'auto',
  height: 'auto',
  position: 'br',
  expanded: {
    image: null,
    imageMobile: null
  }
}
