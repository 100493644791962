export function getOrInitializeDataLayer (window, key = 'dataLayer') {
  if (!window[key]) {
    window[key] = []
  }

  return window[key]
}

export function loadScript (id, config) {
  const doc = document
  const script = doc.createElement('script')

  const scriptLoadListener = (event) => {
    // eslint-disable-next-line no-unused-expressions
    config.onReady?.({ id, script })
    script.removeEventListener('load', scriptLoadListener)
  }

  script.addEventListener('load', scriptLoadListener)

  const dataLayer = getOrInitializeDataLayer(window)

  dataLayer.push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime()
  })

  dataLayer.push([
    'consent',
    'default',
    {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied'
    }
  ])

  window.gtag_enable_tcf_support = true

  if (!id) {
    return script
  }

  script.async = !config.defer
  script.defer = Boolean(config.defer || config.compatibility)

  const queryString = new URLSearchParams({
    id,
    ...(config.queryParams ?? {})

  })

  const source = config.source ?? 'https://www.googletagmanager.com/gtm.js'

  script.src = `${source}?${queryString}`

  const parentElement = config.parentElement ?? doc.body

  if (typeof parentElement?.appendChild !== 'function') {
    throw new Error('parentElement must be a DOM element')
  }

  parentElement.appendChild(script)

  return script
}

export function hasScript (source = 'https://www.googletagmanager.com/gtm.js') {
  return Array.from(document.getElementsByTagName('script')).some((script) =>
    script.src.includes(source)
  )
}
