import _ from 'lodash'
import EdonApi from '../../api/edon'
import HistoricalPerformanceFilter from '../../model/HistoricalPerformanceFilter'
import HistoricalPerformance from '../../model/HistoricalPerformance'

const API_NAME = '/historical-performance'

const state = {
  loaded: false,
  filter: HistoricalPerformanceFilter,
  performance: [_.cloneDeep(HistoricalPerformance)]
}

const mutations = {
  storePerformance (state, responseData) {
    state.performance = responseData
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setLoaded (state, loaded) {
    state.loaded = loaded
  }
}

const actions = {
  async fetchClusterView (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    if (store.state.filter.cluster) {
      return await EdonApi().get(API_NAME + '/cluster/' + store.state.filter.cluster +
        '/' + store.state.filter.startDate + '/' + store.state.filter.endDate +
        '/' + store.state.filter.timeGroupType
      )
        .then(response => {
          store.commit('storePerformance', response.data)
          store.commit('setLoaded', true)
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
        .catch(error => {
          console.log(error)
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
    }
  },
  async fetchDepartmentView (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    if (store.state.filter.department) {
      return await EdonApi().get(API_NAME + '/department/' + store.state.filter.department +
        '/' + store.state.filter.startDate + '/' + store.state.filter.endDate +
        '/' + store.state.filter.timeGroupType
      )
        .then(response => {
          store.commit('storePerformance', response.data)
          store.commit('setLoaded', true)
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
        .catch(error => {
          console.log(error)
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
    }
  }
}

const getters = {
  historyData (state) {
    return state.performance
  },
  filter (state) {
    return state.filter
  },
  loaded (state) {
    return state.loaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
