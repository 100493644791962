const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.defaultSite?.toString() !== '0') {
    filterQuery += '&filter_eq[defaultSite]=' + filter.defaultSite
  }
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.name) {
    filterQuery += '&filter_contains[name]=' + filter.name
  }
  if (filter.originName) {
    filterQuery += '&filter_contains[originName]=' + filter.originName
  }
  if (filter.typePhotographer) {
    filterQuery += '&filter_eq[typePhotographer]=1'
  }
  if (filter.userId) {
    filterQuery += '&filter_eq[userId]=' + filter.userId
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
