import moment from 'moment'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.code) {
    filterQuery += '&filter_eq[code]=' + filter.code
  }
  if (filter.type !== 'none') {
    filterQuery += '&filter_eq[type]=' + filter.type
  }
  if (filter.activated !== 'none') {
    filterQuery += '&filter_custom[activated]=' + filter.activated
  }
  if (filter.setting.marketing !== 'none') {
    filterQuery += '&filter_eq[setting.marketing]=' + filter.setting.marketing
  }
  if (filter.setting.admin !== 'none') {
    filterQuery += '&filter_eq[setting.admin]=' + filter.setting.admin
  }
  if (filter.digitalServices && filter.digitalServices.length) {
    filterQuery += '&filter_memberOf[digitalServices]=' + filter.digitalServices.map(digitalService => digitalService.id)
  }
  if (filter.createdAtFrom) {
    const date = moment(filter.createdAtFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[createdAt]=' + date
  }
  if (filter.createdAtTo) {
    const date = moment(filter.createdAtTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[createdAt]=' + date
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
