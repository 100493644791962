const messages = {
  id: 'Id',
  title: 'Title',
  slug: 'Slug/Title for URL',
  description: 'Description',
  image: 'Image',
  type: 'Tag type',
  tag_name_already_exists: "Tag with title '{name}' already exists.",
  list: {
    id: 'Id',
    title: 'Tag name',
    created_at: 'Created at',
    modified_at: 'Modified at',
    created_by: 'Created by'
  },
  filter: {
    title: 'Title',
    id: 'Id',
    search: 'Search'
  },
  commonTags: {
    geneeaTags: 'Geneea tags',
    editorialTags: 'Editorial tags'
  },
  geneeaTag: {
    title: 'Tag name',
    id: 'Id',
    geneeaId: 'Geneea Id',
    type: 'Tag type',
    url: 'URL',
    generalInfo: {
      title: 'General Tag Information',
      slug: 'Slug/Title for URL',
      createdAt: 'Created at',
      modifiedAt: 'Modified at'
    },
    person: {
      title: 'Person',
      familyName: 'Family name',
      birthDate: 'Date of birth',
      deathDate: 'Date of death',
      hasOccupation: 'Occupation',
      givenName: 'Name',
      birthPlace: 'Place of birth',
      deathPlace: 'Place of death'
    },
    organisation: {
      title: 'Organization',
      name: 'Name',
      alternateName: 'Alternate name',
      logo: 'Logo'
    },
    event: {
      title: 'Event',
      name: 'Event name',
      startDate: 'Start',
      endDate: 'End'
    },
    product: {
      title: 'Product',
      name: 'Product',
      manufacturer: 'Manufacturer',
      model: 'Model'
    },
    location: {
      title: 'Location',
      name: 'Location name',
      gps: 'GPS',
      streetAddress: 'Address',
      addressLocation: 'District',
      addressRegion: 'Region',
      addressCountry: 'Country',
      population: 'Population'
    },
    general: {
      title: 'General'
    }
  }
}

export default messages
