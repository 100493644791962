const messages = {
  list: {
    id: 'Id',
    year: 'Ročník',
    competitionName: 'Turnaj',
    fortunaSportName: 'Šport',
    nationName: 'Krajina',
    fortunaCatName: 'Liga'
  },
  filter: {
    id: 'Id',
    year: 'Ročník',
    competitionName: 'Turnaj',
    fortunaSportName: 'Šport',
    nationName: 'Krajina',
    fortunaCatName: 'Liga',
    search: 'Hľadať'
  },
  games: 'Hry',
  won: 'Výhry',
  drawn: 'Remízy',
  lost: 'Prehry',
  won_2: 'Výhry PP',
  lost_2: 'Prehry PP',
  points: 'Body'
}

export default messages
