<template>
  <div></div>
</template>

<script>
import Configuration from '@/config/configuration'

export default {
  name: 'Hotjar',
  mounted () {
    const hotjarAccountId = Configuration.hotjar.hotjarAccountId()
    if (hotjarAccountId !== '') {
      const hotjar = document.createElement('script')
      hotjar.innerText = '(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:' + hotjarAccountId + ',hjsv:6};a=o.getElementsByTagName("head")[0];r=o.createElement("script");r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,"https://static.hotjar.com/c/hotjar-",".js?sv=");'
      document.head.appendChild(hotjar)
    }
  }
}
</script>
