export default {
  id: 0,
  title: '',
  publication: null,
  sharedUsers: [],
  type: '',
  quality: '',
  source: '',
  native: false,
  rubric: null
}
