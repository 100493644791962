const messages = {
  list: {
    id: 'Id',
    title: 'Title',
    published_since: 'Published since',
    author: 'Author',
    image: 'Image',
    site: 'Site',
    created_at: 'Created at',
    modified_at: 'Modified at',
    created_by: 'Created by'
  },
  filter: {
    title: 'Title',
    id: 'Id',
    bodytext: 'BodyText',
    site: 'Site',
    search: 'Search'
  },
  id: 'Id',
  title: 'Title',
  title_placeholder: 'Write title',
  bodytext: 'Quote text',
  published_since: 'Published since',
  published_since_placeholder: 'Choose date of publishing',
  published_until: 'Published until',
  published_until_placeholder: 'Choose date',
  author: 'Author',
  image: 'Quote photo',
  site: 'Site'
}

export default messages
