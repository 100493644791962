import PermissionService from '@/services/PermissionService'

const printTitleRequiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_PRINT_TITLE_PERMISSIONS
const printPublicationRequiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.PRINT_PUBLICATION_PERMISSIONS

const routes = [
  {
    path: 'printTitle/:id/edit',
    component: () => import('../../components/print/PrintTitleEdit'),
    name: 'printTitle_edit',
    meta: {
      description: 'Print Title edit',
      requiresAuth: true,
      requiredPermissionModules: printTitleRequiredPermissions.editRoute
    }
  },
  {
    path: 'printTitle/:id',
    component: () => import('../../components/print/PrintTitle'),
    name: 'printTitle_detail',
    meta: {
      description: 'Print Title detail',
      requiresAuth: true,
      requiredPermissionModules: printTitleRequiredPermissions.detailRoute
    }
  },
  {
    path: 'printTitle/new',
    component: () => import('../../components/print/PrintTitleNew'),
    name: 'printTitle_new',
    meta: {
      description: 'Print Title new',
      requiresAuth: true,
      requiredPermissionModules: printTitleRequiredPermissions.newRoute
    }
  },
  {
    path: 'printTitle',
    component: () => import('../../components/print/PrintTitleList'),
    name: 'printTitle_list',
    meta: {
      description: 'Print Title',
      requiresAuth: true,
      requiredPermissionModules: printTitleRequiredPermissions.listRoute
    }
  },
  {
    path: 'printPublicationPreview',
    component: () => import('../../components/print/PrintPublicationPreview'),
    name: 'printPublication_preview',
    meta: {
      description: 'Print Publication preview',
      requiresAuth: true,
      requiredPermissionModules: printPublicationRequiredPermissions.previewRoute
    }
  },
  {
    path: 'printPublication/:id/edit',
    component: () => import('../../components/print/PrintPublicationEdit'),
    name: 'printPublication_edit',
    meta: {
      description: 'Print Publication edit',
      requiresAuth: true,
      requiredPermissionModules: printPublicationRequiredPermissions.editRoute
    }
  },
  {
    path: 'printPublication/:id',
    component: () => import('../../components/print/PrintPublication'),
    name: 'printPublication_detail',
    meta: {
      description: 'Print Publication detail',
      requiresAuth: true,
      requiredPermissionModules: printPublicationRequiredPermissions.detailRoute
    }
  },
  {
    path: 'printPublication/new',
    component: () => import('../../components/print/PrintPublicationNew'),
    name: 'printPublication_new',
    meta: {
      description: 'Print Publication new',
      requiresAuth: true,
      requiredPermissionModules: printPublicationRequiredPermissions.newRoute
    }
  },
  {
    path: 'printPublication',
    component: () => import('../../components/print/PrintPublicationList'),
    name: 'printPublication_list',
    meta: {
      description: 'Print Publication',
      requiresAuth: true,
      requiredPermissionModules: printPublicationRequiredPermissions.listRoute
    }
  }
]

export default routes
