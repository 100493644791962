const messages = {
  id: 'WoodWing ID',
  create_article: 'Prebrať článok',
  export: {
    category: 'Názov kategórie vydania',
    header: 'Export WoodWing',
    info: 'Prajete si exportovať článok do systému WoodWing? <b>Táto operácia je nezvratná a dá sa vykonať iba 1 krát!</b><br>V prípade ak si prajete pokračovať prosím kliknite na tlačidlo "Exportovať".<br>Článok bude exportovaný do <b>{brand}</b>',
    print: 'Názov printového vydania',
    open: 'Exportovať do Woodwing',
    save: 'Exportovať',
    error: 'Článok sa nepodarilo exportovať',
    success: 'Článok bol exportovaný do WoodWingu'
  },
  issue: 'Vydanie',
  status: {
    article_export: 'Export status ID článku do WoodWing',
    article_import: 'Import status ID článku z WoodWing',
    image_export: 'Export status ID fotky do WoodWing',
    image_import: 'Import status ID fotky z WoodWing'
  }
}

export default messages
