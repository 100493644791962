const messages = {
  filter: {
    id: 'Id',
    title: 'Titulok',
    type: 'Typ',
    brand: 'Značka/výrobca',
    model: 'Model'
  },
  list: {
    id: 'Id',
    title: 'Titulok',
    type: 'Typ',
    brand: 'Značka/výrobca',
    model: 'Model'
  },
  car_type: {
    petrol_diesel: 'Nafta/Benzín',
    hybrid: 'Hybrid',
    electro: 'Electro'
  },
  id: 'Id',
  title: 'Titulok',
  brand: 'Značka/výrobca',
  model: 'Model',
  productionYear: 'Modelový rok',
  type: 'Typ',
  description: 'Popis',
  image: 'Obrázok',
  main_car: 'hlavné auto',
  petrol_motor: 'benzínový motor',
  diesel_motor: 'dieselový motor',
  electro_motor: 'elektromotor',
  petrol_plus_gas: 'benzín + plyn',
  save: 'Uložiť',
  frontAxle: 'predná náprava',
  rearAxle: 'zadná náprava',
  allWheels: 'všetky kolesá',
  engine: {
    header: 'Motor a pohonný systém',
    fuel: 'Palivo',
    drive: 'Pohon',
    gearbox: 'Prevodovka'
  },
  fuelEngine: {
    cylinderSetup: 'Usporiadanie a počet valcov',
    cylinderVolume: 'Zdvihový objem',
    maxPower: 'Max. výkon',
    maxPowerRotation: 'pri otáčkach',
    maxTorque: 'Max. krútiaci moment',
    maxTorqueRotation: 'pri otáčkach'
  },
  elEngine: {
    motorType: 'Typ elektromotora',
    maxPower: 'Max. výkon',
    maxTorque: 'Max. krútiaci moment ',
    maxRotation: 'Max. otáčky'
  },
  measurements: {
    header: 'Rozmery, hmotnosti a objemy',
    length: 'Dĺžka',
    width: 'Šírka',
    height: 'Výška',
    wheelBase: 'Rázvor náprav',
    curbWeight: 'Pohotovostná hmotnosť',
    curbWeightEu: 'Pohotovostná hmotnosť (EÚ)',
    capacityWeight: 'Užitočná hmotnosť',
    totalWeight: 'Celková hmotnosť',
    trunkCapacity: 'Objem batožinového priestoru',
    trunkDimensions: 'Rozmery nakladacieho priestoru (DxŠxV)',
    tankVolume: 'Objem palivovej nádrže',
    batteryCapacity: 'Kapacita batérie'
  },
  driving: {
    header: 'Jazdné výkony',
    maxSpeed: 'Max. rýchlosť',
    acceleration: 'Zrýchlenie 0-100 km/h'
  },
  consumption: {
    header: 'Spotreba'
  },
  fuelConsumption: {
    extraHigh: 'Veľmi vysoká záťaž - normovaná',
    high: 'Vysoká záťaž - normovaná',
    medium: 'Stredná záťaž - normovaná',
    low: 'Nízka záťaž - normovaná',
    combined: 'Kombinovaná - normovaná',
    testedCity: 'Mesto - počas testu',
    testedMotorway: 'Diaľnica - počas testu',
    testedCountryRoad: 'Vidiek - počas testu'
  },
  elConsumption: {
    extraHigh: 'Veľmi vysoká záťaž - normovaná',
    high: 'Vysoká záťaž - normovaná',
    medium: 'Stredná záťaž - normovaná',
    low: 'Nízka záťaž - normovaná',
    combined: 'Kombinovaná - normovaná',
    testedCity: 'Mesto - počas testu',
    testedMotorway: 'Diaľnica - počas testu',
    testedCountryRoad: 'Vidiek - počas testu',
    range: 'Dojazd - norma WLTP'
  },
  emission: {
    header: 'Emisie',
    value: 'Emisie CO2',
    standard: 'Emisná norma'
  },
  price: {
    header: 'Aktuálne cenníkové ceny s dph',
    modelPrice: 'Základná cena modelu',
    versionPrice: 'Základná cena verzie',
    testedPrice: 'Cena testovaného exemplára'
  },
  rating: {
    header: 'Hodnotenie',
    exterior: 'Exteriér',
    interior: 'Interiér',
    engine: 'Pohon',
    driving: 'Podvozok',
    price: 'Cena',
    total: 'Celkovo'
  },
  prosCons: {
    header: 'Výhody a nevýhody',
    pros: 'Výhody',
    cons: 'Nevýhody'
  },
  unit: {
    percentage: '%',
    cylinderVolume: 'cm3',
    power: 'kW',
    power_horse: 'k',
    rotation: '/min',
    torque: 'Nm',
    dimensions: 'mm',
    weight: 'kg',
    volume: 'l',
    capacity: 'kWh',
    speed: 'km/h',
    acceleration: 's',
    fuel_consumption: 'l/100 km',
    el_consumption: 'kWh/100 km',
    emission: 'g/km',
    price: '€',
    range: 'km'
  }
}

export default messages
