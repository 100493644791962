import axios from 'axios'
import appConfig from '@/config'

const donBoxApi = () => axios.create({
  baseURL: appConfig.nativeCampaignManagement.apiUrl(),
  timeout: appConfig.nativeCampaignManagement.apiTimeout * 1000,
  headers: {
    'X-API-KEY': appConfig.nativeCampaignManagement.xApiKey(),
    'Content-Type': 'application/json'
  }
})

export default donBoxApi
