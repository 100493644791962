import _ from 'lodash'
import CoreApi from '../../api/core'
import moment from 'moment'
import AppPerformanceFilter from '../../model/appPerformance/AppPerformanceFilter'
import AppPerformanceFilterService from '../../services/appPerformance/AppPerformanceFilterService'

const API_NAME = '/app-performance'
export const LIMIT = 5

const state = {
  error: null,
  maxMemory: [],
  maxDuration: [],
  avgMemory: [],
  avgDuration: [],
  filter: _.cloneDeep(AppPerformanceFilter)
}

const createQuery = function (store, page) {
  const todayMidnight = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
  return API_NAME +
    '?filter_eq[eventDate]=' + todayMidnight +
    '&offset=' + ((page - 1) * LIMIT) +
    '&limit=' + LIMIT +
    AppPerformanceFilterService.buildFilterQuery(store.state.filter)
}

const mutations = {
  storeMaxMemory (state, responseData) {
    state.maxMemory = responseData
  },
  storeMaxDuration (state, responseData) {
    state.maxDuration = responseData
  },
  storeAvgMemory (state, responseData) {
    state.avgMemory = responseData
  },
  storeAvgDuration (state, responseData) {
    state.avgDuration = responseData
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  }
}

const actions = {
  async fetch (store, page) {
    actions.fetchMaxMemory(store, page)
    actions.fetchMaxDuration(store, page)
    actions.fetchAvgMemory(store, page)
    actions.fetchAvgDuration(store, page)
  },
  async fetchMaxMemory (store, page) {
    const url = createQuery(store, page)

    await CoreApi().get(url + '&order[max.memory]=desc')
      .then(res => {
        store.commit('storeMaxMemory', res.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  async fetchMaxDuration (store, page) {
    const url = createQuery(store, page)

    await CoreApi().get(url + '&order[max.duration]=desc')
      .then(res => {
        store.commit('storeMaxDuration', res.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  async fetchAvgMemory (store, page) {
    const url = createQuery(store, page)

    await CoreApi().get(url + '&order[avg.memory]=desc')
      .then(res => {
        store.commit('storeAvgMemory', res.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  async fetchAvgDuration (store, page) {
    const url = createQuery(store, page)

    await CoreApi().get(url + '&order[avg.duration]=desc')
      .then(res => {
        store.commit('storeAvgDuration', res.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  async clear (store) {
    await CoreApi().delete(API_NAME)
      .catch(error => {
        console.log(error)
      })
  }
}

const getters = {
  maxMemory (state) {
    return state.maxMemory
  },
  maxDuration (state) {
    return state.maxDuration
  },
  avgMemory (state) {
    return state.avgMemory
  },
  avgDuration (state) {
    return state.avgDuration
  },
  filter (state) {
    return state.filter
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
