import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.FRONTEND_USER_ARTICLE_PERMISSIONS

const routes = [
  {
    path: 'feUserArticle/:id/edit',
    component: () => import('../../../components/feUser/article/FeUserArticleEdit'),
    name: 'feUserArticle_edit',
    meta: {
      description: 'FeUser Article edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'feUserArticle/new',
    component: () => import('../../../components/feUser/article/FeUserArticleNew'),
    name: 'feUserArticle_new',
    meta: {
      description: 'FeUser Article new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'feUserArticle',
    component: () => import('../../../components/feUser/article/FeUserArticleList'),
    name: 'feUserArticle_list',
    meta: {
      description: 'FeUser Article list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
