import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_BACKEND_USER_KPI_PERMISSIONS

const routes = [
  {
    path: 'beUserKpi/:id/edit',
    component: () => import('../../../views/beUser/BeUserKpiEditView'),
    name: 'beUserKpi_edit',
    meta: {
      description: 'BeUser KPI edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'beUserKpi_list',
    component: () => import('../../../views/beUser/BeUserKpiListView'),
    name: 'beUserKpi_list',
    meta: {
      description: 'BeUser KPI list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
