const messages = {
  list: {
    id: 'Id',
    server: 'Server',
    title: 'Poll title',
    startOfVoting: 'Poll start',
    endOfVoting: 'Poll end',
    loggedInUsersOnly: 'Only logged in'
  },
  filter: {
    id: 'Id',
    title: 'Poll title',
    server: 'Server'
  },
  server: 'Server',
  title: 'Poll title',
  startOfVoting: 'Poll start',
  endOfVoting: 'Poll end',
  displayType: 'Display',
  template: 'Template',
  restrictionTypes: 'RestrictionTypes',
  image: 'Poll Image',
  loggedInUsersOnlyLabel: 'For logged in users only',
  loggedInUsersOnlyLabelFalse: 'No',
  loggedInUsersOnlyLabelTrue: 'Yes',
  displayResultsBeforeVoting: 'Results before voting',
  addOption: 'Add option',
  hidden: 'Hidden',
  enabledVoting: 'Enabled voting',
  minimumOptionsError: 'Poll must contain at least two options',
  yesNoTemplateMaxOptionsError: 'Poll with a yes/no template can only contain two options',
  options: {
    answer: 'Answer',
    image: 'Answer image',
    voteCount: 'Number of votes'
  },
  displayTypesOptions: {
    votes: 'Votes',
    percentage: 'Percentage'
  },
  templateOptions: {
    default: 'Default',
    yesNo: 'Yes no',
    basic: 'Basic'
  },
  restrictionTypeOptions: {
    fiveMinutes: '1 x every 5 minutes',
    fifteenMinutes: '1 x every 15 minutes',
    thirtyMinutes: '1 x every 30 minutes',
    sixtyMinutes: '1 x every 60 minutes',
    oneDay: '1 x per Day',
    oneMonth: '1 x per month',
    onlyOnce: '1 x for the entire duration'
  }
}

export default messages
