import MediaModel from '../../model/WidenMedia'
import CoreApi from '../../api/core'

const state = {
  detail: MediaModel,
  selectedGalleryMedia: [],
  selectedHeroImage: null,
  selectedListingImage: null,
  imageSettings: null
}

const mutations = {
  storeImageSettings (state, imageSettings) {
    state.imageSettings = imageSettings
  },
  storeDetail (state, media) {
    state.detail = media
  },
  storeSelectedGalleryMedia (state, selectedGalleryMedia) {
    state.selectedGalleryMedia = selectedGalleryMedia
  },
  storeSelectedHeroImage (state, selectedHeroImage) {
    state.selectedHeroImage = selectedHeroImage
  },
  storeSelectedListingImage (state, selectedListingImage) {
    state.selectedListingImage = selectedListingImage
  }
}

const actions = {
  async fetchImageSettings (store) {
    return await CoreApi().get('/ImageSettings')
      .then(response => {
        store.commit('storeImageSettings', response.data)
      })
      .catch(error => console.error(error))
  },
  async fetchOne (store, id) {
    return await CoreApi().get('/image/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
        return response.data
      })
      .catch(error => console.log(error))
  }
}

const getters = {
  imageSettings (state) {
    return state.imageSettings
  },
  getRatioById (state) {
    return id => state.imageSettings.ratios.find(ratio => ratio.id === id)
  },
  getThumbnailCrops (state) {
    return (mediaUsageType) => state.imageSettings.thumbnails[mediaUsageType] ?? []
  },
  getMandatoryRatios (state) {
    return (mediaUsageType) => state.imageSettings.mandatoryRatios[mediaUsageType] ?? []
  },
  getAvailableCrops (state, getters, rootState, rootGetters) {
    return (siteId, mediaUsageType) => {
      let mediaRatios = state.imageSettings.ratios
      if (siteId || siteId === 0) {
        const allSites = rootGetters['site/all']
        const site = allSites.find(site => site.id === siteId)
        if (site?.mediaRatios?.length > 0) {
          mediaRatios = site.mediaRatios
        }
      }

      if (mediaUsageType) {
        const allowedRatiosForMediaType = state.imageSettings.ratiosForMediaTypes[mediaUsageType]
        if (allowedRatiosForMediaType?.length > 0) {
          mediaRatios = mediaRatios.filter(mediaRatio => allowedRatiosForMediaType.includes(mediaRatio.ratio))
        }
      }

      return mediaRatios.map(mediaRatio => ({
        ratioId: mediaRatio.id,
        name: mediaRatio.ratio,
        ratioX: mediaRatio.ratioX,
        ratioY: mediaRatio.ratioY,
        value: mediaRatio.ratioX / mediaRatio.ratioY
      }))
    }
  },
  detail (state) {
    return state.detail
  },
  selectedGalleryMedia (state) {
    return state.selectedGalleryMedia
  },
  selectedHeroImage (state) {
    return state.selectedHeroImage
  },
  selectedListingImage (state) {
    return state.selectedListingImage
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
