const messages = {
  list: {
    id: 'Id',
    title: 'Titulek',
    published_since: 'Publikováno od',
    author: 'Autor',
    image: 'Obrázek',
    site: 'Stránka',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil'
  },
  filter: {
    title: 'Titulek',
    id: 'Id',
    bodytext: 'BodyText',
    site: 'Stránka',
    search: 'Hledat'
  },
  id: 'Id',
  title: 'Titulek',
  title_placeholder: 'Napište nadpis',
  bodytext: 'Text myšlenky',
  published_since: 'Datum publikování',
  published_since_placeholder: 'Vyberte datum publikování na webu',
  published_until: 'Publikované do',
  published_until_placeholder: 'Vyberte datum',
  author: 'Autor',
  image: 'Fotka k myšlence',
  site: 'Stránka'
}

export default messages
