import _ from 'lodash'
import EdonApi from '../../api/edon'
import KpiFilter from '../../model/KpiFilter'
import KpiComparison from '../../model/KpiComparison'

const API_NAME = '/kpi-comparison'

const state = {
  loaded: false,
  filter: KpiFilter,
  detail: _.cloneDeep(KpiComparison)
}

const mutations = {
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setLoaded (state, loaded) {
    state.loaded = loaded
  }
}

const actions = {
  async fetch (store) {
    const startDate = store.state.filter.startDate
    if (store.state.filter.id) {
      return await EdonApi().get(API_NAME + '/user/' + store.state.filter.id + '/' + startDate)
        .then(response => {
          store.commit('storeDetail', response.data)
          store.commit('setLoaded', true)
        })
        .catch(error => console.log(error))
    }

    if (store.state.filter.department) {
      return await EdonApi().get(API_NAME + '/department/' + store.state.filter.department + '/' + startDate)
        .then(response => {
          store.commit('storeDetail', response.data)
          store.commit('setLoaded', true)
        })
        .catch(error => console.log(error))
    }
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  filter (state) {
    return state.filter
  },
  loaded (state) {
    return state.loaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
