import CoreApi from '@/api/core'
import SourceFilterService from '@/services/source/SourceFilterService'
import { createCrudStore } from '@/store/modules/simpleCrudStore'

const filterModel = {
  id: '',
  name: '',
  customText: ''
}
const store = createCrudStore({
  apiName: '/authorSource',
  filterModel,
  apiInstanceCallback: () => CoreApi(),
  buildFilterQueryCallback: SourceFilterService.buildFilterQuery
})

export default store
