const messages = {
  filter: {
    created_at_from: 'Vytvořeno od',
    created_at_to: 'Vytvořeno do'
  },
  main_tab: 'Hlavní informace',
  instructions_tab: 'Postup',
  ingredients_tab: 'Suroviny a alergeny',
  gallery_tab: 'Galerie',
  title: 'Titulek',
  title_placeholder: 'Napište název receptu',
  published_since: 'Datum publikování',
  published_since_placeholder: 'Vyberte datum publikování na webu',
  photo_or_video: 'Vyberte hlavní obrázek nebo video',
  enabled: 'Aktivovat',
  ingredient_title: 'Titulek',
  ingredient_title_placeholder: 'Napište název skupiny surovin',
  ingredient_urls: 'Odkazy na suroviny',
  spentTime: 'Délka přípravy (min)',
  portions: 'Počet porcí',
  cena: 'Cena',
  difficulty: 'Náročnost',
  instructions: 'Postup',
  step: 'Krok',
  add_step: 'Přidat krok',
  ingredient_groups: 'Suroviny',
  ingredient_group: 'Skupina surovin',
  add_ingredient: 'Přidat surovinu',
  add_ingredient_group: 'Přidat další skupinu surovin',
  ingredient: 'Surovina',
  amount: 'Množství',
  unit: 'Jednotka',
  allergens: 'Alergeny',
  shop: 'Nakoupit online (odkaz)',
  discount: 'Akční leták (odkaz)',
  headline_used_in_articles: 'Použito v článku',
  article_not_found: 'Recept se nenachází v žádném článku.',
  article_exists: 'Recept je použit v článku !',
  create_article: 'Vytvořit článek',
  article_modal_header: 'Pro vytvoření článku je třeba uložit recept. Uložit teď?',
  article_created_error: 'Chyba. Problem s vytvořením receptového článku.',
  article_created: 'Článek byl vytvořen'
}

export default messages
