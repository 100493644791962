const messages = {
  list: {
    id: 'ID',
    message: 'Message',
    site: 'Journal',
    from: 'From',
    to: 'To',
    published: 'Published'
  },
  form: {
    title_label: 'Title',
    title_placeholder: 'Title of the message',
    message_text_label: 'Message text',
    message_text_placeholder: 'Message text',
    link: 'Link',
    link_placeholder: 'Link to page (https://...)'
  },
  filter: {
    text: 'Message',
    site: 'Journals',
    published: 'Published',
    search: 'Filter'
  },
  type_value: {
    published: 'Active',
    unpublished: 'Inactive'
  },
  inactive: 'Inactive',
  active: 'Active',
  publish_news_label: 'Publish message'
}

export default messages
