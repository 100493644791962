const messages = {
  headline_used_in_articles: 'Použité v článku',
  article_not_found: 'Galéria nieje použitá v žiadnom článku.',
  article_exists: 'Galéria je použitá v článku !',
  image_count: 'Počet obrázkov',
  notify: {
    created: 'galéria bola vytvorená.',
    not_created: 'galéria nebola vytvorená.'
  },
  add: 'Pridať galériu',
  created: 'Vytvorené',
  public_gallery: 'Galéria je verejná',
  title_placeholder: 'Napíšte názov galérie',
  main_tab: 'Hlavné informácie',
  gallery_tab: 'Galéria'
}

export default messages
