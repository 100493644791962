export default {
  /**
   * Transform into String[] with `.split(',')` before a BE request
   */
  entityUuids: null,
  text: null, // fulltext
  createdSince: null,
  createdUntil: null,
  createdBy: [],
  keywords: null,
  uploadSourceCodes: [],
  pageSortData: {
    pageSize: 10,
    pageNumber: 1,
    orderBy: 'CreatedAt', // 'CreatedAt' || 'OriginalDateTime'
    isDescending: true
  },
  articleId: null,
  author: null,
  categoryCodes: [],
  albums: [], // these are the photoSeries
  orientationCodes: [],
  size: 0,
  resolution: null,
  description: null,
  siteLocks: [],
  /**
   * Transform into String[] with `.split(',')` before a BE request
   */
  personsInImage: null,
  takenSince: null,
  takenUntil: null
}
