import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.EDITOR_PERFORMANCE_EDON_PRODUCT_PERMISSIONS

const routes = [
  {
    path: 'edonProduct/:id/edit',
    component: () => import('../../views/edon/EdonProductEditView'),
    name: 'edonProduct_edit',
    meta: {
      description: 'EdonProduct edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'edonProduct/new',
    component: () => import('../../views/edon/EdonProductNewView'),
    name: 'edonProduct_new',
    meta: {
      description: 'EdonProduct new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'edonProduct',
    component: () => import('../../views/edon/EdonProductListView'),
    name: 'edonProduct_list',
    meta: {
      description: 'EdonProduct list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
