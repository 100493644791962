import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ESHOP_PAYMENT_PERMISSIONS

const routes = [
  {
    path: 'shop/subscription/payment/:id',
    component: () => import('../../../components/eshop/SubscriptionPayment'),
    name: 'eshopSubscriptionPayment_detail',
    meta: {
      description: 'Subscription Payment detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'shop/subscription/payment',
    component: () => import('../../../components/eshop/SubscriptionPaymentList'),
    name: 'eshopSubscriptionPayment_list',
    meta: {
      description: 'Subscription Payment list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
