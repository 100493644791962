import AnalyticsApi from '../../api/analytics'
import EDonApi from '../../api/edon'
import HistoricalDashboardFilter, { getUnits, UNIT_MONTH } from '../../model/HistoricalDashboard'
import moment from 'moment'

const API_GEMIUS = '/GemiusReports'
const API_GOOGLE_ANAL = '/GoogleAnalyticsReports'
const API_SITE = '/Site'
const API_PRODUCTS_PERF = '/articles-performance'

const state = {
  loading: {
    list: false,
    gaList: false,
    ppList: false
  },
  filter: { ...HistoricalDashboardFilter },
  list: [],
  gaList: [],
  ppList: [],
  products: []
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData
    state.loading.list = false
  },
  storeGaList (state, responseData) {
    state.gaList = responseData
    state.loading.gaList = false
  },
  storeProducts (state, responseData) {
    state.products = responseData
  },
  storeProductsPerf (state, responseData) {
    state.ppList = responseData
    state.loading.ppList = false
  },
  setFilter (state, filter) {
    state.filter = filter
  }
}

function unitsToUrl (units) {
  if (units.length === 2) {
    return ''
  }
  return '&unit=' + units
}

const actions = {
  async fetch (store) {
    try {
      state.loading.list = true
      state.loading.gaList = true
      const { from, to } = store.state.filter
      const unit = unitsToUrl(getUnits(from, to))
      const params = `/List?from=${from}&to=${to}${unit}`
      const requests = [API_GEMIUS, API_GOOGLE_ANAL].map((api) => AnalyticsApi().get(api + params))
      const [gemiusResponse, gaResponse] = await Promise.all(requests)
      store.commit('storeList', gemiusResponse.data)
      store.commit('storeGaList', gaResponse.data)
      return {
        gemius: gemiusResponse.data,
        ga: gaResponse.data
      }
    } catch (error) {
      console.error(error)
      state.loading.list = false
      state.loading.gaList = false
    }
  },
  async resetFilter (store) {
    store.commit('setFilter', { ...HistoricalDashboardFilter })
  },
  async fetchProducts (store) {
    const response = await AnalyticsApi().get(`${API_SITE}/List`)
    store.commit('storeProducts', response.data)
  },
  async fetchProductsPerf (store) {
    let { from, to } = { ...store.state.filter }
    try {
      state.loading.ppList = true
      const requests = getUnits(from, to).map((unit) => {
        if (unit === UNIT_MONTH) {
          from = moment(from).startOf('month').format('Y-MM-DD')
          to = moment(to).endOf('month').format('Y-MM-DD')
        }
        return EDonApi()
          .get(`${API_PRODUCTS_PERF}/${from}/${to}/${unit}`)
          .then(({ data }) => data.map((item) => ({ ...item, unit })))
      })
      const responses = await Promise.all(requests)
      store.commit('storeProductsPerf', responses.reduce((arr, i) => [...arr, ...i]))
    } catch (error) {
      console.error(error)
      state.loading.ppList = false
    }
  }
}

const getters = {
  filter (state) {
    return state.filter
  },
  isLoadingGemius () {
    return state.loading.list
  },
  isLoadingGA () {
    return state.loading.gaList
  },
  isLoadingPP () {
    return state.loading.ppList
  },
  list (state) {
    return state.list
  },
  googleAnalyticsList (state) {
    return state.gaList
  },
  productsPerfList (state) {
    return state.ppList
  },
  products (state) {
    return state.products
  },
  product (state) {
    return state.products.find((product) => product.id === state.filter.product)
  },
  productById (state) {
    return (id) => state.products.find((product) => product.id === id)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
