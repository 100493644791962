const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.type !== null && filter.type !== 0) {
    filterQuery += '&filter_eq[type]=' + filter.type
  }
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.brand) {
    filterQuery += '&filter_contains[brand]=' + filter.brand
  }
  if (filter.model) {
    filterQuery += '&filter_contains[model]=' + filter.model
  }
  return filterQuery
}
const buildUsagesQuery = function (testId) {
  return '&filter_eq[articleTest]=' + testId
}
export default {
  buildFilterQuery,
  buildUsagesQuery
}
