const messages = {
  id: 'ID',
  created_at: 'Vytvořeno',
  created_at_from: 'Vytvořeno od',
  created_at_to: 'Vytvořeno do',
  created_by: 'BE uživatel',
  modified_at: 'Změněno',
  modified_by: 'Úpravil',
  type: 'Typ',
  order: 'Objednávka',
  payment: 'Platba',
  description: 'Popis',
  feUser: 'FE uživatel',
  filter: {
    export: 'Exportovat',
    search: 'Hledat',
    reset_filter: 'Zrušit filtr'
  }
}

export default messages
