const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.name) {
    filterQuery += '&filter_contains[name]=' + filter.name
  }
  if (filter.customText) {
    filterQuery += '&filter_contains[customText]=' + filter.customText
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
