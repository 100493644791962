const messages = {
  filter: {
    id: 'Id',
    title: 'Titulok',
    site: 'Stránka',
    slug: 'URL',
    category: 'Kategória'
  },
  list: {
    id: 'Id',
    title: 'Titulok',
    site: 'Stránka',
    slug: 'URL',
    category: 'Kategória'
  },
  topic_category: {
    common: 'Predvolené',
    tag: 'Tag',
    sport24_player: 'Šport24 Hráč',
    sport24_team: 'Šport24 Tím',
    wanda_celebrity: 'Wanda Celebrity',
    zdravie_overall_condition: 'Zdravie Celkový stav',
    zdravie_head_neck: 'Zdravie Hlava a krk',
    zdravie_chest: 'Zdravie Hrudník',
    zdravie_motion_system: 'Zdravie Pohybové ústrojenstvo',
    zdravie_belly: 'Zdravie Brucho',
    zdravie_underbelly: 'Zdravie Podbrušie',
    zdravie_skin_hair_nails: 'Zdravie Koža, vlasy, nechty',
    zdravie_pregnancy_motherhood: 'Zdravie Tehotenstvo a materstvo',
    zdravie_psyche: 'Zdravie Psychika',
    zdravie_life_style: 'Zdravie Životný štýl',
    zdravie_healthy_eating: 'Zdravie Zdravé stravovanie'
  },
  query: 'Hlavné nastavenia',
  id: 'Id',
  category: 'Kategória',
  slug: 'URL',
  slug_desc: 'Minimálna dĺžka 3 znaky, môže obsahovať iba znaky (a-z), pomlčku (-) alebo číslo (0-9) a pre stránku musí byť unikátna',
  title: 'Titulok',
  title_placeholder: 'Napíšte názov témy',
  site: 'Stránka',
  description: 'Popis',
  meta: 'Meta nastavenia',
  meta_title: 'Meta titulok (title)',
  meta_description: 'Meta popis (description)',
  meta_keywords: 'Meta kľúčové slová (keywords)',
  image: 'Hlavný obrázok',
  image_bg: 'Obrázok v pozadí',
  tags_required: 'Povinné kľúčové slová',
  tags_required_desc: 'Zoznam kľúčových slov ktoré musí článok obsahovať',
  tags_optional: 'Doplnkové kľúčové slová',
  tags_optional_desc: 'Článok musí obsahovať aspoň jedno z týchto kľúčových slov',
  sites_selected: 'Vybrané stránky',
  sites_selected_desc: 'Pokiaľ nie je vybratá žiadna stránka, vyberú sa všetky',
  rubrics_selected: 'Vybrané rubriky',
  rubrics_selected_desc: 'Pokiaľ nie je vybratá žiadna rubrika, vyberú sa všetky',
  article_types: 'Typ článku',
  article_types_desc: 'Pokiaľ nie je vybratý žiaden typ, vyberú sa všetky',
  article_count: 'Počet článkov témy',
  article_count_desc: 'Aktualizuje sa raz za deň',
  sport_table: {
    title: 'Športové tabuľky',
    season_id: 'ID sezóny',
    active_tab: 'Aktívny tab (prvý = 1)'
  },
  notify: {
    slug_site_combination_must_be_unique: 'URL pre stránku musí byť unikátna, zvoľte inú URL'
  }
}

export default messages
