export default {
  videoId: '',
  siteName: '',
  title: '',
  videoType: '',
  orderDate: '',
  sharedUsers: {
    userId: 0,
    userName: ''
  }
}
