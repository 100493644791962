import CoreApi from '@/api/core'
import OtherFunctionFilterService from '@/services/otherFunction/OtherFunctionFilterService'
import { createCrudStore } from '@/store/modules/simpleCrudStore'

const filterModel = {
  id: '',
  name: ''
}
const store = createCrudStore({
  apiName: '/authorOtherFunction',
  filterModel,
  apiInstanceCallback: () => CoreApi(),
  buildFilterQueryCallback: OtherFunctionFilterService.buildFilterQuery
})

export default store
