const messages = {
  filter: {
    id: 'Id',
    title: 'Titulek',
    name: 'Název'
  },
  list: {
    id: 'Id',
    title: 'Titulek',
    name: 'Název'
  },
  id: 'Id',
  title: 'Titulek',
  name: 'Název',
  description: 'Popis',
  address: 'Adresa',
  rating: 'Hodnocení',
  yearOfReview: 'Rok recenze',
  image: 'Obrázek',
  openingHours: 'Otevírací doba',
  telefon: 'Telefon',
  website: 'Web',
  chef: 'Šéfkuchař',
  priceLevel: 'Cenová úroveň',
  cuisineCategories: 'Kategorie jídla',
  pros: 'Plusy',
  cons: 'Mínusy',
  create_new_category: 'Vytvořite kategorii',
  create_new_category_message: 'Nová kategorie vytvořená'
}

export default messages
