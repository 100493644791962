import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.INFOBOX_PERMISSIONS

const routes = [
  {
    path: 'infobox/new',
    component: () => import('../../views/infobox/InfoboxNewView'),
    name: 'infobox_new',
    meta: {
      description: 'Infobox new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'infobox/:id/edit',
    component: () => import('../../views/infobox/InfoboxEditView'),
    name: 'infobox_edit',
    meta: {
      description: 'Infobox edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'infobox/:id',
    component: () => import('../../views/infobox/InfoboxView'),
    name: 'infobox_detail',
    meta: {
      description: 'Infobox detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'infobox',
    component: () => import('../../views/infobox/InfoboxListView'),
    name: 'infobox_list',
    meta: {
      description: 'Infobox list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
