import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_SCALE_PERMISSIONS

const routes = [
  {
    path: 'scale/:id/edit',
    component: () => import('../../components/scale/ScaleEdit'),
    name: 'scale_edit',
    meta: {
      description: 'Scale edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'scale',
    component: () => import('../../components/scale/ScaleList'),
    name: 'scale_list',
    meta: {
      description: 'Scale list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
