import Vue from 'vue'

export default () => {
  Vue.directive('click-outside', {
    bind: function (el, binding) {
      setTimeout(() => {
        const clickEventHandler = event => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event)
          }
        }
        const keyDownEventHandler = event => {
          if (event.key === 'Escape') {
            binding.value(event)
          }
        }
        el.__vueClickEventHandler__ = clickEventHandler
        el.__vueKeyDownEventHandler__ = keyDownEventHandler
        document.addEventListener('click', clickEventHandler)
        document.addEventListener('keydown', keyDownEventHandler)
      })
    },
    unbind: function (el) {
      document.removeEventListener('click', el.__vueClickEventHandler__)
      document.removeEventListener('keydown', el.__vueKeyDownEventHandler__)
    }
  })
}
