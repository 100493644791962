const messages = {
  csvExport: 'Export CSV',
  problemsContact: 'In case of problems, please contact',
  annotationsFor: 'Annotations for',
  numberOfViews: 'Number of views',
  numberOfClicks: 'Number of clicks',
  ctr: 'CTR',
  previewPosition: 'Preview position',
  checkAndUpdateStatus: 'Check and update status',
  checkingStatusInLivebox: 'Checking status in Livebox',
  isComplete: 'Video is COMPLETE',
  currentStatusInLivebox: 'Current status in Livebox',
  isNotComplete: 'Video is not COMPLETE',
  unableToGetLiveboxAssetInfo: 'Unable to get livebox asset info',
  unableToGetStatusFromLivebox: 'Unable to get status from Livebox',
  canNotRetrieveGeneratedPreviewImages: 'Can not retrieve generated preview images',
  statistics: 'Video statistics',
  playerPerformance: 'Player performance',
  player: 'Player',
  time_section: 'Time section',
  annotation: 'Annotation',
  annotation_visualization: 'Visual style',
  annotation_range: 'Annotation display range',
  annotation_info: 'Annotation information',
  new_annotation: 'New annotation',
  preview_annotations: 'Preview video with annotations',
  video_required_upload: 'In order to add annotation, it is necessary to upload a video first',
  video_content: 'Video content',
  upload_video: 'Upload a Video',
  uploaded_video: 'Uploaded video',
  tabs: {
    main: 'Main information',
    video: 'Video',
    annotations: 'Annotations',
    stats: 'Statistics'
  },
  list: {
    id: 'Id',
    type: 'Type',
    preview_image: 'Preview image',
    video: 'Video',
    ooyala_id: 'Ooyala Id',
    title: 'Title',
    default_site: 'Default site',
    status: 'Status',
    used_in_article: 'Used in article',
    article_count: 'Used in article',
    video_duration: 'Video duration',
    created_at: 'Created at',
    created_by: 'Created by'
  },
  filter: {
    default_site: 'Default site',
    type: 'Type',
    title: 'Title',
    keywords: 'Keywords',
    description: 'Perex',
    all: 'All',
    search: 'Search',
    id: 'Id',
    ids: 'Id / Brightcove Id / Ooyala Id / Livebox Id',
    ooyala_id: 'Ooyala Id',
    brightcove_id: 'Brightcove Id',
    livebox_asset_id: 'Livebox Id',
    author: 'Author',
    camera: 'Camera',
    editing: 'Editing',
    site: 'Site',
    video_show: 'PLUS TV shows',
    video_nature: 'The nature of the video',
    video_source: 'Video origin',
    video_type: 'Video type (device)',
    status: 'Status',
    flags: 'Flags',
    setting_editorial_content: 'Editorial content',
    setting_agency_content: 'Agency content',
    setting_marketing_content: 'Marketing content',
    setting_internal: 'Internal',
    setting_social: 'Social',
    setting_agency: 'Agency',
    setting_camera: 'On request',
    setting_mobile_phone: 'Mobile phone',
    setting_slideshow: 'Slideshow',
    setting_show: 'Show',
    setting_for_adults: 'For adults +18',
    setting_product_placement: 'Product placement',
    setting_exclusive: 'Exclusive',
    date_from: 'Created from',
    date_to: 'Created to'
  },
  id: 'Id',
  type: 'Type',
  file_name: 'File name',
  file_size: 'File size',
  status: 'Status',
  video_duration: 'Video duration',
  video_width: 'Video width',
  video_height: 'Video height',
  default_site: 'Default site',
  video_show: 'PLUS TV shows',
  video_category: 'PLUS TV categories',
  video_episode_number: 'PLUS TV Episode number',
  setting_editorial_content: 'Editorial content',
  setting_agency_content: 'Agency content',
  setting_marketing_content: 'Marketing content',
  setting_native_content: 'Native content',
  setting_internal: 'Internal',
  setting_social: 'Social',
  setting_agency: 'Agency',
  setting_camera: 'On request',
  setting_mobile_phone: 'Mobile phone',
  setting_slideshow: 'Slideshow',
  setting_show: 'Show',
  setting_for_adults: 'For adults +18',
  setting_product_placement: 'Product placement',
  setting_exclusive: 'Exclusive',
  setting_no_ads: 'No ads',
  setting_dynamic_preview: 'Dynamic preview',
  setting_dynamic_preview_start: 'Dynamic preview start (seconds)',
  setting_dynamic_preview_duration: 'Dynamic preview duration (seconds)',
  video_thumbnail_position: 'Thumbnail - percentage between 5-95',
  meta_title: 'Title',
  meta_description: 'Perex',
  meta_keywords: 'Keywords',
  author: 'Author',
  camera: 'Camera',
  editing: 'Editing',
  preview_image: 'Preview image',
  annotations: 'Annotations',
  annotation_web: 'web',
  annotation_video: 'video',
  annotation_article: 'article',
  categories: 'Categories',
  safety_topics: 'Safety topics',
  video_nature: 'The nature of the video',
  video_source: 'Video origin',
  video_source_gdpr: 'Video source',
  video_type: 'Video type',
  ooyala_id: 'Ooyala ID',
  brightcove_id: 'Brightcove ID',
  livebox_asset_id: 'Livebox Id',
  youtube_id: 'Youtube ID',
  annotation_type: 'Typ',
  annotation_title: 'Title',
  annotation_perex: 'Perex',
  annotation_url: 'Url',
  annotation_video_id: 'Video ID',
  annotation_image: 'Image',
  annotation_image_mobile: 'Image Mobile',
  annotation_bg_color: 'Background color',
  annotation_color: 'Text color',
  annotation_border: 'Border',
  annotation_start: 'Start',
  annotation_end: 'End',
  annotation_position: 'Position',
  status_new: 'New',
  status_uploading: 'Uploading',
  status_processing: 'Processing',
  status_live: 'Uploaded',
  status_published: 'Published',
  status_error: 'Error',
  created_by: 'Created by',
  modal: {
    set_preview_image: 'Naozaj chcete nastaviť tento náhľadový obrázok ?',
    video_header: 'Select video'
  },
  title_is_too_short: 'Title is short.',
  title_is_too_long: 'Title is too long.',
  description_is_too_short: 'Perex is short.',
  description_is_too_long: 'Perex is too long.',
  video_type_title: 'Typ',
  video_type_value: {
    ooyala: 'Ooyala',
    youtube: 'Youtube',
    livebox: 'Livebox'
  },
  video_status_title: 'Status',
  video_status_value: {
    new: 'New',
    uploading: 'Uploading',
    processing: 'Processsing',
    live: 'Uploaded',
    published: 'Published',
    error: 'Error'
  },
  main_content: 'Main content',
  main_settings: 'Main settings',
  headline_preview_image: 'Preview image',
  headline_video_parameters: 'Video parameters',
  headline_used_in_articles: 'Used in articles',
  video_upload_required_fields_message: 'Pre nahratie videa je potrebné vyplniť všetky povinné údaje',
  video_article_not_found: 'Video is not used in articles.',
  video_article_exists: 'Pre video už existuje článok',
  range_slider_info_1: 'vyznačený úsek pre pridanie',
  annotations_not_found: 'Annotations not found.',
  video_is_processing: 'Video is processing by Livebox.',
  video_upload_success_message: 'Vaše video bolo úspešne nahrané a bude sprocesované na strane Livebox.',
  video_upload_error_message: 'Problem s nahrávaním na strane Livebox.',
  error_delete_video_article_exists: 'Video is used in article.',
  error_accesss_denied: 'Access denied.',
  none: 'N/A',
  download: 'Download',
  editVideo: 'Edit video',
  help: {
    sections: {
      intro: 'Úvod',
      filter: 'Filtrovanie a hľadanie článkov v CMS',
      articleCreationAndEditing: 'Tvorba a úprava článku',
      workWithPhotos: 'Práca s fotkami',
      workWithVideos: 'Práca s videami',
      modules: 'Modules',
      print: 'Print',
      boxManagement: 'Spravovanie boxov na úvodných stránkach webov',
      sponsoredArticles: 'Sponzorované články',
      statisticsAndPerformance: 'Štatistiky a výkonnosť'
    },
    v_13961: {
      title: 'Prihlasovacia obrazovka',
      item1: 'URL, prihlasovacia obrazovka',
      item2: 'Obnovenie zabudnutého hesla',
      item3: 'Menu',
      item4: 'Načítanie novej verzie'
    },
    v_13960: {
      title: 'Stavy článku',
      item1: 'Popísané stavy článku',
      item2: 'Prechody medzi stavmi článku'
    },
    v_26427: {
      title: 'Realtime štatistiky',
      item1: 'Ukazovateľ výkonnosti stránky',
      item2: 'Grafové ukazovateľe výkonu stránky za poslednách 24 hod',
      item3: 'Najvýkonnejšie, najčitannejšie články v aktuálnom momente'
    },
    v_13948: {
      title: 'Zoznam článkov',
      item1: 'Vyhľadávacie filtre',
      item2: 'Karty pre stavy článkov',
      item3: 'Tlačidlá pre vstup do úpravy, náhľadu článku a kopírovanie ID článku',
      item4: 'Informačná karta článku'
    },
    v_13962: {
      title: 'Vyhľadávanie článku a Chrome plugin',
      item1: 'Vyhľadavanie članku podľa URL',
      item2: 'Chrome plugin',
      item3: 'Odkaz na rozšírenie pre Google Chrome'
    },
    v_14926: {
      title: 'TASR agentúrne články',
      item1: 'Vyhľadavanie správy z TASR',
      item2: 'Vytvorenie článku z TASR správy'
    },
    v_24702: {
      title: 'Ponuka obsahu',
      item1: 'Vyhľadávanie článkov v Ponuke obsahu',
      item2: 'Prebratie článku z Ponuky obsahu',
      item3: 'Zhoda článkov'
    },
    v_52695: {
      title: 'Nový Article editor',
      item1: 'Nový Article editor, práca s ním a rozdiely oproti starému editoru'
    },
    v_13943: {
      title: 'Hlavný obsah',
      item1: 'Ako vytvoriť nový článok',
      item2: 'Hlavná lišta v článku a práca s tlačidlami',
      item3: 'Titulok, titulok pre URL, perex'
    },
    v_13942: {
      title: 'Hlavné video',
      item1: 'Práca s videom namiesto hlavnej fotky článku'
    },
    v_17483: {
      title: 'Autorský podiel',
      item1: 'Akým spôsobom vypĺňať nové povinné pole autorský podiel v hlavných nastaveniach článku'
    },
    v_13945: {
      title: 'Súvisiace články pod textom',
      item1: 'Nastavenie a radenie súvisiacich článkov pod telom článku'
    },
    v_13944: {
      title: 'Nastavenia',
      item1: 'Rubriky, Plánovanie publikovania, Autori na webe',
      item2: 'Povaha článku a zdroj, Témy, Obsahové kategórie',
      item3: 'URL článku a galérie, Ďalšie nastavenia článku'
    },
    v_13940: {
      title: 'Ďalšie typy článkov',
      item1: 'Externý článok',
      item2: 'Kvíz',
      item3: 'Video článok'
    },
    v_13946: {
      title: 'Fotostory',
      item1: 'Vytváranie fotostory'
    },
    v_28655: {
      title: 'Ochorenie'
    },
    v_13941: {
      title: 'Telo článku',
      item1: 'Vrchná lišta pre úpravy textu',
      item2: 'Práca s modulmi v tele článku (obrázok, videá, citát, anketa, súvisiaci článok, HTML embed, stránkovanie, infobox)'
    },
    v_13958: {
      title: 'Hlavná fotografia',
      item1: 'Vyhľadávanie fotiek',
      item2: 'Nahrávanie fotiek z PC / URL',
      item3: 'Orezávanie fotky',
      item4: 'Úprava popisu fotky'
    },
    v_13955: {
      title: 'Fotka v tele článku',
      item1: 'pridávanie alebo vyhľadávanie fotiek v tele článku'
    },
    v_13956: {
      title: 'Galéria',
      item1: 'Pridávanie fotiek do galérie'
    },
    v_48883: {
      title: 'Galéria',
      item1: 'Vkladanie obrázkov z verejných Instagram a Facebook postov do galérií článkov'
    },
    v_13957: {
      title: 'DAM (banka fotiek)',
      item1: 'Zobrazenie fotobanky a filtrácia fotiek',
      item2: 'Nahrávanie nových fotiek',
      item3: 'Úprava orezu alebo metadát fotky',
      item4: 'Vodoznak - návod na vloženie vodoznaku do fotografii'
    },
    v_15946: {
      title: 'DAM - pokročilé vyhľadávanie fotiek',
      item1: 'Tipy pre pokročilé vyhľadávanie fotiek',
      item2: 'Nastavenie filtrov'
    },
    v_18941: {
      title: 'TASR fotky',
      item1: 'Filtrovanie fotiek z tasr',
      item2: 'Sťahovanie fotiek z tasr',
      item3: 'Použitie fotiek z tasr v článku, galérií'
    },
    v_18956: {
      title: 'Hromadné operácie',
      item1: 'Hromadné sťahovanie fotiek z DAM',
      item2: 'Obmedzenia',
      item3: 'Vymazávanie'
    },
    v_18172: {
      title: 'Zoznam videí - vyhľadávanie',
      item1: 'Vyhľadávanie videí',
      item2: 'Používanie filtrov',
      item3: 'Značky pri videách'
    },
    v_18796: {
      title: 'Nahrávanie nových videí',
      item1: 'Nahranie videa do Eagle',
      item2: 'Stavy videa',
      item3: 'Úprava videa',
      item4: 'Anotácie'
    },
    v_18173: {
      title: 'Video management',
      item1: 'Spravovanie video boxov a endscreenov',
      item2: 'Prepínanie medzi stránkami',
      item3: 'Nastavenie časového rozmedzia'
    },
    v_13949: {
      title: 'Infoboxy'
    },
    v_13953: {
      title: 'Vtipy'
    },
    v_61595: {
      title: 'Thought of the week'
    },
    v_13950: {
      title: 'Ankety'
    },
    v_13951: {
      title: 'Anketa na HP'
    },
    v_13954: {
      title: 'Páči / Nepáči'
    },
    v_13952: {
      title: 'Kvízy',
      item1: 'Prezentácia ku kvízom'
    },
    v_14925: {
      title: 'Športové tabuľky'
    },
    v_17482: {
      title: 'Témy',
      item1: 'Dynamický listing',
      item2: 'Príležitostné akcie - napr. Vianoce, Autosalón, sezónne recepty, profily športovcov',
      item3: 'Výber metadát a kľúčových slov vhodných z hľadiska SEO'
    },
    v_17484: {
      title: 'Vozidlá',
      item1: 'Modul vhodný nielen pre Autobild, ale aj ostatné tituly',
      item2: 'Možnosť písania recenzií do tabuliek',
      item3: 'Vytváranie ďalšieho typu článku s názvom Testy'
    },
    v_28656: {
      title: 'Recepty'
    },
    v_50823: {
      title: 'Galérie',
      item1: 'Vytváranie galérií v moduloch a ich vkladanie do článkov (aj pre opätovné použitie vo viacerých článkoch)'
    },
    v_17538: {
      title: 'Printové publikácie',
      item1: 'postup nahrávania printových publikácií grafikmi do Eagle Admin',
      item2: 'vyhľadávanie a filtrovanie v zozname',
      item3: 'vypĺňanie povinných polí'
    },
    v_17537: {
      title: 'Printové články',
      item1: 'hodnotenie printových článkov printovými asistentkami',
      item2: 'postup pre vypĺňanie jednotlivých povinných polí',
      item3: 'filtrovanie v zozname existujúcich hodnotených printových článkov'
    },
    v_13947: {
      title: 'HP management',
      item1: 'Prepínanie medzi stránkami',
      item2: 'Viditeľné boxy, pohyb a granularita časovej osi',
      item3: 'Interný a externý článok'
    },
    v_26426: {
      title: 'HP management - Topbox CTR',
      item1: 'Historické CTR pre Topbox pozície',
      item2: 'Realtime CTR pre články v Topbox pozíciach'
    },
    v_28651: {
      title: 'HP ponuka článkov',
      item1: 'PDF prezentácia',
      item2: 'Rozhranie pre zaradenie článku do Homepage ponuky článkov',
      item3: 'Rozhranie schvaľovania článkov pre Vedúcich jednotlivých oddelení',
      item4: 'Homepage ponuka článkov (feed) pre OCM'
    },
    v_26425: {
      title: 'Manažment a vývoj kampane',
      item1: 'Pridanie/editácia kampane',
      item2: 'Ovládanie a vývoj kampane'
    },
    v_36247: {
      title: 'NMH KPI',
      item1: 'Plnenie KPI',
      item2: 'Výkonnosť v čase'
    },
    v_36206: {
      title: 'Štatistiky produktu',
      item1: 'Porovnanie s konkurenciou na trhu',
      item2: 'Zdroje návštev',
      item3: 'Produkcia obsahu'
    },
    v_42377: {
      title: 'KPI',
      item1: 'KPI',
      item2: 'Výkony oddelení a autorov'
    }
  }
}

export default messages
