const messages = {
  headline_used_in_articles: 'Used in articles',
  article_not_found: 'Gallery article not found.',
  article_exists: 'Gallery is used by articles.',
  image_count: 'Image count',
  notify: {
    created: 'Gallery was created.',
    not_created: 'Gallery was not created.'
  },
  add: 'Add gallery',
  created: 'Created',
  public_gallery: 'Gallery is public',
  title_placeholder: 'Write title',
  main_tab: 'Main content',
  gallery_tab: 'Gallery'
}

export default messages
