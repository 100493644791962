import _ from 'lodash'
import KpiSettingsFilter from '../../model/KpiSettingsFilter'
import { KpiData, UsersKpi, DefaultKpiData } from '@/model/Kpi'
import { dataHubEditPerfApi } from '@/api/datahub'

const API_NAME = '/kpis/department'

const state = {
  error: null,
  loaded: false,
  filter: KpiSettingsFilter,
  kpi: _.cloneDeep(KpiData),
  defaultKpi: _.cloneDeep(DefaultKpiData)
}

const mutations = {
  storeKpi (state, responseData) {
    state.kpi = responseData
  },
  setDefaultKpi (state, { monthYear, departmentId }) {
    const [month, year] = monthYear.map(Number)
    state.kpi = _.cloneDeep(state.defaultKpi)
    state.kpi.departmentKpi.id = null
    state.kpi.departmentKpi.month = month
    state.kpi.departmentKpi.year = year
    state.kpi.departmentKpi.departmentId = departmentId
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setLoaded (state, loaded) {
    state.loaded = loaded
  },
  setError (state, message) {
    state.error = message
  },
  updateKpi (state, { monthYear, departmentId }) {
    const [month, year] = monthYear.map(Number)
    if (state.kpi.departmentKpi.month !== month || state.kpi.departmentKpi.year !== year) {
      state.kpi.departmentKpi.id = null
      state.kpi.departmentKpi.month = month
      state.kpi.departmentKpi.year = year
    }
    state.kpi.usersKpi.forEach(userKpi => {
      if (userKpi.month !== month || userKpi.year !== year) {
        userKpi.month = month
        userKpi.year = year
      }
      if (!userKpi.departmentId) {
        userKpi.departmentId = departmentId
      }
      if (userKpi.userId && typeof userKpi.userId === 'number') {
        userKpi.userId = userKpi.userId.toString()
      }
    })
  },
  syncKpiUsers (state, { monthYear, departmentId }) {
    const [month, year] = monthYear.map(Number)
    const userKpis = []
    state.filter.departmentUsers.forEach((user) => {
      const existingUserKpi = state.kpi.usersKpi.find(u => +u.userId === +user.id)
      let userKpi
      if (existingUserKpi) {
        userKpi = existingUserKpi
      } else {
        userKpi = _.cloneDeep(UsersKpi)
        userKpi.userId = user.id.toString()
        userKpi.userName = user.userDetail.fullName
        if (userKpi.month !== month || userKpi.year !== year) {
          userKpi.month = month
          userKpi.year = year
        }
        if (!userKpi.departmentId) {
          userKpi.departmentId = departmentId
        }
      }
      userKpis.push(userKpi)
    })
    state.kpi.usersKpi = userKpis
  }
}
const actions = {
  async fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const departmentId = store.state.filter.department

    if (departmentId) {
      const [month, year] = store.state.filter.monthYear.split('-')

      dataHubEditPerfApi()
        .get(`${API_NAME}/get?department_id=${departmentId}&month=${month}&year=${year}&strict=false`)
        .then(response => {
          store.commit('storeKpi', response.data)
          store.commit('syncKpiUsers', { monthYear: [month, year], departmentId })
          store.commit('updateKpi', { monthYear: [month, year], departmentId })
          store.commit('setLoaded', true)
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            store.commit('setDefaultKpi', { monthYear: [month, year], departmentId })
            store.commit('syncKpiUsers', { monthYear: [month, year], departmentId })
          } else {
            store.commit('setError', 'Error fetching KPI data')
          }
          store.commit('setLoaded', false)
        })
        .finally(() => {
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
    }
  },
  async create (store) {
    return await dataHubEditPerfApi().post(`${API_NAME}/create-update`, JSON.stringify(store.state.kpi))
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  kpi (state) {
    return state.kpi
  },
  suggestedPageViews (state) {
    return state.kpi.suggestedPageViews
  },
  suggestedVideoViews (state) {
    return state.kpi.suggestedVideoViews
  },
  filter (state) {
    return state.filter
  },
  error (state) {
    return state.error
  },
  loaded (state) {
    return state.loaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
