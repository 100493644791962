import CoreApi from '@/api/core'
import HottopicsModel from '@/model/HottopicsModel'

const API_NAME = '/hottopic'
const API_SITE_NAME = '/site'

const state = {
  error: null,
  detail: HottopicsModel,
  board: [],
  hottopicImage: null,
  siteId: null
}

const mutations = {
  storeHottopicImage (state, hottopicImage) {
    state.hottopicImage = hottopicImage
  },
  storeBoard (state, responseData) {
    state.board = responseData
  },
  setError (state, message) {
    state.error = message
  },
  setSiteId (state, siteId) {
    state.siteId = siteId
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  }
}

const actions = {
  async fetchImage (store, id) {
    await CoreApi().get('/image/' + id)
      .then(response => {
        store.commit('storeHottopicImage', response.data)
      })
      .catch(error => console.error(error))
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/items/' + id)
      .then(response => {
        store.commit('storeHottopicImage', null)
        store.commit('storeDetail', response.data)
        if (response.data.imageId) {
          store.dispatch('fetchImage', response.data.imageId)
        }
      })
      .catch(error => console.error(error))
  },
  async fetchBoard (store, id) {
    await CoreApi().get(API_NAME + '/site/' + id)
      .then(response => {
        store.commit('storeBoard', response.data)
        store.commit('setSiteId', id)
      })
      .catch(error => console.error(error))
  },
  async create (store, record) {
    return await CoreApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 201 || response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME + '/items/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
          return response.data
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/items/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async unpublish (store, record) {
    return await CoreApi().put(API_NAME + '/items/unpublish/' + record)
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async updateGraphicsTextSwitch (store, { siteId, textOnly }) {
    return await CoreApi().put(API_SITE_NAME + '/hottopictextonly/' + siteId, JSON.stringify({ siteId, textOnly }))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeBoard', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  hottopicImage (state) {
    return state.hottopicImage
  },
  board (state) {
    return state.board
  },
  error (state) {
    return state.error
  },
  siteId (state) {
    return state.siteId
  },
  detail (state) {
    return state.detail
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
