import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'kpi_comparison',
    component: () => import('../../components/kpi/KpiComparison'),
    name: 'kpi_comparison',
    meta: {
      description: 'KPI comparison',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .EDITOR_PERFORMANCE_KPI_COMPARISON_PERMISSIONS
        .listRoute
    }
  },
  {
    path: 'performance_product',
    component: () => import('../../components/kpi/ProductPerformance'),
    name: 'performance_product',
    meta: {
      description: 'Product performance',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .EDITOR_PERFORMANCE_PRODUCT_PERMISSIONS
        .listRoute
    }
  },
  {
    path: 'performance_department',
    component: () => import('../../components/kpi/DepartmentPerformance'),
    name: 'performance_department',
    meta: {
      description: 'Department performance',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .EDITOR_PERFORMANCE_DEPARTMENT_PERMISSIONS
        .listRoute
    }
  },
  {
    path: 'historical_performance',
    component: () => import('../../components/kpi/HistoricalPerformance'),
    name: 'historical_performance',
    meta: {
      description: 'Historical performance',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .EDITOR_PERFORMANCE_HISTORICAL_PERMISSIONS
        .listRoute
    }
  },
  {
    path: 'performance_weights',
    component: () => import('../../components/kpi/WeightsSettings'),
    name: 'performance_weights',
    meta: {
      description: 'Performance weights settings',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .EDITOR_PERFORMANCE_WEIGHTS_PERMISSIONS
        .listRoute
    }
  },
  {
    path: 'performance_kpi_settings',
    component: () => import('../../components/kpi/KpiSettings'),
    name: 'performance_kpi_settings',
    meta: {
      description: 'Performance KPI settings',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .EDITOR_PERFORMANCE_KPI_SETTINGS_PERMISSIONS
        .listRoute
    }
  }
]

export default routes
