export default {
  SET_CONFIG (state, config) {
    state.config = config
  },
  TOGGLE_LOADING (state, callingApi) {
    state.callingAPI = callingApi ?? !state.callingAPI
  },
  LOADING_ADD (state) {
    state.callingApiCount += 1
  },
  LOADING_REMOVE (state) {
    state.callingApiCount -= 1
  },
  LOADING_RESET (state) {
    state.callingApiCount = 0
  },
  SET_OFFLINE (state, offlineState) {
    state.isOffline = offlineState
  }
}
