const messages = {
  list: {
    id: 'Id',
    title: 'Názov',
    slug: 'Slug',
    title_title: 'Titul',
    publicationDate: 'Dátum vydania'
  },
  filter: {
    id: 'Id',
    title: 'Názov',
    title_title: 'Titul',
    search: 'Hľadať'
  },
  current: {
    info: 'Obrázky su cachované. Zmena sa prejaví až po expirácii cache.'
  },
  buttons: {
    add: 'Pridať',
    display_current: 'Zobraziť aktuálne',
    back_to_list: 'Späť na zoznam'
  },
  title: 'Názov',
  title_title: 'Titul',
  slug: 'Slug',
  publication_date: 'Dátum vydania',
  description: 'Popis',
  image: 'Obrázok'
}

export default messages
