export const HISTORY_PAGE_VIEWS = 'pageViews'
export const HISTORY_VIDEO_VIEWS = 'videoViews'
export const HISTORY_ONLINE_ARTICLES = 'onlineArticles'
export const HISTORY_P1_ARTICLES = 'p1Articles'
export const HISTORY_P2_ARTICLES = 'p2Articles'
export const HISTORY_PUBLISHED_VIDEOS = 'publishedVideos'
export const HISTORY_CREATED_VIDEOS = 'createdVideos'
export const HISTORY_IMAGES_IN_ARTICLES = 'imagesInArticles'

export const AVAILABLE_HISTORICAL_METRICS = [
  {
    id: HISTORY_PAGE_VIEWS,
    title: 'pageViews'
  },
  {
    id: HISTORY_VIDEO_VIEWS,
    title: 'videoViews'
  },
  {
    id: HISTORY_ONLINE_ARTICLES,
    title: 'onlineArticles'
  },
  {
    id: HISTORY_P1_ARTICLES,
    title: 'p1Articles'
  },
  {
    id: HISTORY_P2_ARTICLES,
    title: 'p2Articles'
  },
  {
    id: HISTORY_PUBLISHED_VIDEOS,
    title: 'publishedVideos'
  },
  {
    id: HISTORY_CREATED_VIDEOS,
    title: 'createdVideos'
  },
  {
    id: HISTORY_IMAGES_IN_ARTICLES,
    title: 'imagesInArticles'
  }
]
