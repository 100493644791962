const messages = {
  list: {
    id: 'Id',
    article_doc_id: 'Article Doc ID',
    article_id: 'Article ID',
    changed_at: 'Changed at',
    change_type: 'Change type',
    changed_by: 'Changed by'
  },
  filter: {
    id: 'Id',
    article_doc_id: 'Article Doc ID',
    article_id: 'Article ID',
    changed_at_from: 'Changed at from',
    changed_at_to: 'Changed at to',
    change_type: 'Change type',
    changed_by: 'Changed by',
    search: 'Search'
  }
}

export default messages
