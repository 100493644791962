const buildFilterQuery = function (filter) {
  var filterQuery = ''
  if (filter.site !== '0') {
    filterQuery += '&filter_eq[site]=' + filter.site
  }
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.identifier) {
    filterQuery += '&filter_contains[identifier]=' + filter.identifier
  }
  return filterQuery
}

const buildHpManagementFilterQuery = function (filter) {
  var filterQuery = ''
  if (filter.contentBlocks.length > 0) {
    filterQuery += '&filter_in[id]=' + filter.contentBlocks
  }
  if (filter.site) {
    filterQuery += '&filter_in[site]=' + filter.site
  }
  return filterQuery
}

export default {
  buildFilterQuery,
  buildHpManagementFilterQuery
}
