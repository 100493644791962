const messages = {
  header: 'EAGLE Admin',
  sign_in: 'Přihlásit se',
  username: 'Uživatelské jméno',
  password: 'Heslo',
  log_in: 'Přihlásit',
  forgot_password: 'Obnovit zapomenuté heslo',
  error_message: 'Vámi zadané jméno nebo heslo není správné. Zkuste se zde odhlásit a poté znovu přihlásit.',
  logout: 'Odhlásit',
  version: 'Verze'
}

export default messages
