const messages = {
  source: 'Zdroj',
  firstName: 'Meno',
  lastName: 'Priezvisko',
  customText: 'Custom text',
  user: 'Užívateľ',
  url: 'URL',
  shortcut: 'Skratka',
  sex: 'Pohlavie',
  sexMale: 'muž',
  sexFemale: 'žena',
  function: 'Funkcia',
  typeAuthor: 'Autor',
  typeEditor: 'Editor',
  typeSportEditor: 'Športový redaktor',
  typeReaderReporter: 'Čitateľ reportér',
  typePhotographer: 'Fotograf/Video',
  typeExternalAuthor: 'Externý autor',
  phone: 'Telefón',
  email: 'E-mail',
  showContactForm: 'Zobraziť kontaktný formulár',
  defaultSite: 'Stránka',
  sites: 'Servery',
  editorSites: 'Redaktor stránok',
  editorInChiefSites: 'Šéfredaktor stránok',
  otherFunction: 'Ďalšia funkcia',
  hiddenOnPage: 'Skryť autora na webe',
  hiddenOnPage_short: 'Skryť na webe',
  disabled: 'Neaktívne',
  authorImage: 'Fotografia autora',
  showCompleteProfile: 'Profil autora úplný',
  briefProfile: 'Stručný profil autora',
  completeProfile: 'Úplný profil autora',
  twitter: 'URL Twitter profil',
  linkedin: 'URL LinkedIn profil',
  facebook: 'URL Facebook profil',
  instagram: 'URL Instagram profil',
  pinterest: 'URL Pinterest profil',
  warningMessages: {
    duplicateEntryInServers: 'Pole Servery obsahuje duplicitný záznam s aktuálnou stránkou. Po uložení bude duplikát odstránený.'
  }
}

export default messages
