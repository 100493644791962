import { dateTimeNow } from '../../services/DateTimeService'

export default {
  id: null,
  username: '',
  email: '',
  enabled: true,
  salt: '',
  plainPassword: '',
  lastLogin: null,
  roles: [],
  firstName: '',
  lastName: '',
  permissions: [],
  subscriptions: [],
  facebookId: '',
  googleId: '',
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  createdBy: 0,
  modifiedBy: 0,
  masterSite: '',
  enabledSites: []
}
