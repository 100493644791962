import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_OTHER_FUNCTION_PERMISSIONS

const routes = [
  {
    path: 'otherFunction/new',
    component: () => import('../../views/otherFunction/OtherFunctionNewView'),
    name: 'otherFunction_new',
    meta: {
      description: 'Other Function new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'otherFunction/:id/edit',
    component: () => import('../../views/otherFunction/OtherFunctionEditView'),
    name: 'otherFunction_edit',
    meta: {
      description: 'Other Function edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'otherFunction/:id',
    component: () => import('../../views/otherFunction/OtherFunctionView'),
    name: 'otherFunction_detail',
    meta: {
      description: 'Other Function detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'otherFunction',
    component: () => import('../../views/otherFunction/OtherFunctionListView'),
    name: 'otherFunction_list',
    meta: {
      description: 'Other Function list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
