const messages = {
  id: 'ID',
  title: 'Titulok',
  native: 'Natívny',
  quality: 'Kvalita',
  source: 'Zdroj',
  type: 'Typ',
  sharedUsers: 'Autorský podiel',
  publication: 'Publikácia',
  save: 'Ulož',
  saveAndAddNew: 'Ulož a pridaj nový',
  yes: 'Áno',
  no: 'Nie',
  filter: {
    search: 'Hľadať',
    reset_filter: 'Zrušiť filter'
  },
  error: {
    required_fields: 'Prosím, vyplňte všetky povinné polia (Nadpis, Autorský podiel)'
  },
  message: {
    updated_record: 'Záznam bol zmenený!',
    deleted_record: 'Záznam bol vymazaný!'
  },
  orderDate: 'Dátum publikovania'
}

export default messages
