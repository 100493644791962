const messages = {
  list: {
    id: 'Id',
    title: 'Title',
    identifier: 'Identifier',
    site: 'Site'
  },
  filter: {
    id: 'Id',
    title: 'Title',
    identifier: 'Identifier',
    site: 'Site',
    search: 'Search'
  },
  site: 'Site',
  title: 'Title',
  identifier: 'Identifier',
  poll: 'Poll',
  identifier_validation: 'Must be unique'
}

export default messages
