<template>
  <h1 class="login__heading">
    <LogoVlmFull v-if="vlm" class="login__logo" />
    <span v-else><strong class="login__heading--strong">EAGLE</strong> Admin</span>
  </h1>
</template>

<script>
import { mapGetters } from 'vuex'
import LogoVlmFull from '@/assets/img/svg/vlm/aqua_logo_full.svg?inline'

export default {
  name: 'LoginLogo',
  components: {
    LogoVlmFull
  },
  computed: {
    ...mapGetters(['vlm'])
  }
}
</script>

<style scoped lang="scss">
  .login {
    &__logo {
      width: 13rem;
      height: auto;
      fill: #0C8CCD;
      position: relative;
      top: .65rem;
    }
    &__heading {
      @include font(500 34px "Roboto");
      @include margin(_ _ 30px);
      text-align: center;
      &--strong {
        font-weight: 700;
        color: #6599fe;
      }
    }
  }
</style>
