const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.year) {
    filterQuery += '&filter_gte[dateFrom]=' + filter.year + '-01-01'
    filterQuery += '&filter_lte[dateTo]=' + filter.year + '-12-31'
  }
  if (filter.product) {
    filterQuery += '&filter_eq[product]=' + filter.product
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
