export const DISPLAY_TYPE_PERCENTAGE = 'percentage'
export const DISPLAY_TYPE_NUMERIC = 'numeric'

export const DISPLAY_TYPE_MAP = {
  percentage: 'Percenta',
  numeric: 'Čísla'
}

export const AVAILABLE_VALUES = [
  {
    id: DISPLAY_TYPE_PERCENTAGE,
    title: 'Percenta'
  },
  {
    id: DISPLAY_TYPE_NUMERIC,
    title: 'Čísla'
  }
]
