export default {
  id: 0,
  date: null,
  field: {
    title: '',
    subTitle: '',
    shortTitle: '',
    firstParagraph: '',
    listingTeaser: '',
    bodyText: '',
    videoType: 'none',
    video: '',
    source: '',
    pageTitles: [],
    printCategory: ''
  },
  gallery: [],
  expanded: {
    gallery: []
  },
  articles: [],
  sharedUser: null
}
