const messages = {
  id: 'WoodWing ID',
  create_article: 'Transfer article',
  export: {
    category: 'Category',
    header: 'Export WoodWing',
    info: 'Are you willing to export article into system WoodWing? <b>This operation is irreversible!</b><br>Click "Export" for continue.<br>Article will be exported into <b>{brand}</b>',
    print: 'Print publication date',
    open: 'Export into WoodWing',
    save: 'Export',
    error: 'Article was not exported',
    success: 'Article was exported into WoodWing'
  },
  issue: 'Issue',
  status: {
    article_export: 'Export status ID of article into WoodWing',
    article_import: 'Import status ID of article from WoodWing',
    image_export: 'Export status ID of image into WoodWing',
    image_import: 'Import status ID of image from WoodWing'
  }
}

export default messages
