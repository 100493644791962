import PermissionService from '@/services/PermissionService'

export const DEFAULT_ROUTE_NAME = 'dashboard' // see 'alias' below
/**
 * Alternative default route in case user does not have permission for the default route.
 */
export const DEFAULT_ROUTE_ALTERNATIVE_NAME = 'article_list'

const routes = [
  {
    path: 'dashboard',
    alias: '', // this redirects empty route '/' to dashboard
    component: () => import('../../views/dashboard/DashboardViewNew'),
    name: 'dashboard',
    meta: {
      description: 'Dashboard',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .DASHBOARD_REALTIME_STATS_PERMISSIONS
        .listRoute
    }
  },
  {
    path: 'dashboardOld',
    component: () => import('../../views/dashboard/DashboardViewOld'),
    name: 'dashboard_old',
    meta: {
      description: 'Dashboard (old)',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .DASHBOARD_REALTIME_STATS_PERMISSIONS
        .listRoute
    }
  }
]

export default routes
