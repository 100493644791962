import cz from './cz/messages'
import en from './en/messages'
import sk from './sk/messages'

const messages = {
  cz: cz,
  en: en,
  sk: sk
}

export default messages
