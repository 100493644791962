import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.VIDEO_PLUS_TV_CATEGORY_PERMISSIONS

const routes = [
  {
    path: 'videoCategory/:id/edit',
    component: () => import('../../views/video/VideoCategoryEditView'),
    name: 'videoCategory_edit',
    meta: {
      description: 'Video category edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'videoCategory/new',
    component: () => import('../../views/video/VideoCategoryNewView'),
    name: 'videoCategory_new',
    meta: {
      description: 'Video category new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'videoCategory',
    component: () => import('../../views/video/VideoCategoryListView'),
    name: 'videoCategory_list',
    meta: {
      description: 'Video category list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
