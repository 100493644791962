export default {
  id: '',
  default: false,
  productId: '',
  departmentId: '',
  polymorphId: '',
  userId: '',
  month: 0,
  year: 0,
  name: '',
  printQuality: 0.0,
  pageViews: 0.0,
  engagedPageView: 0.0,
  subscriptions: 0.0,
  disqus: 0.0,
  fbShares: 0.0,
  videoViews: 0.0,
  videoPlaythrough: 0.0,
  articlesQuantity: 0.0,
  videoQuantity: 0.0,
  pageViewsNormalized: 0.0,
  avgArticleCompletion: 0.0,
  avgDailyUsers: 0.0,
  avgGalleryCompletion: 0.0,
  avgTimeSpentPerUserArticle: 0.0,
  avgTimeSpentPerUserGallery: 0.0,
  avgDailyUsersOrganic: 0.0
}
