export const PERMISSION_VIEW = 'view'
export const PERMISSION_CREATE = 'create'
export const PERMISSION_EDIT = 'edit'
export const PERMISSION_DELETE = 'delete'
export const PERMISSION_PUBLISH = 'publish'
export const PERMISSION_UPLOAD = 'upload'
export const PERMISSION_EXPORT = 'export'
export const PERMISSION_TRANSFER_ASSIGN = 'transfer-assign'
export const PERMISSION_POLL_SHOW_VOTES = 'poll-show-votes'
/**
 * For user management.
 * 'superadmin' does not have access to user management,
 * only 'superuser' does.
 */
export const PERMISSION_SUPERUSER = 'superuser'
export const ONE_SIGNAL_PERMISSIONS = {
  VIEW: 'onesignal_view',
  CREATE: 'onesignal_create',
  EDIT: 'onesignal_edit',
  DELETE: 'onesignal_delete'
}
