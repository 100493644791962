import { dataHubNativeCampaignPerfPredictionApi } from '@/api/datahub'
import moment from 'moment'
import { sortDate } from '@/helpers/date'

const API_NAME_NATIVE_FORECAST = '/v1/native/clicks-forecast'

const state = {
  error: null,
  data: {
    forecastOverall: {
      dailyTotal: [],
      total: {}
    },
    campaignReservedClicks: {
      daily: {
        data: [],
        dates: []
      },
      dailyTotal: [],
      total: {}
    }
  }
}

const mutations = {
  storeForecastOverall (state, responseData) {
    state.data.forecastOverall = responseData
  },
  storeCampaignReservedClicks (state, responseData) {
    state.data.campaignReservedClicks = responseData
  }
}

const actions = {
  async fetchForecastOverall (store) {
    const url = API_NAME_NATIVE_FORECAST + '/forecast' + '?fromDate=' + moment.utc().format('YYYY-MM-DD') + '&toDate=' + moment.utc().add(30, 'days').format('YYYY-MM-DD')
    await dataHubNativeCampaignPerfPredictionApi().get(url)
      .then(res => {
        store.commit('storeForecastOverall', { ...res.data, dailyTotal: res.data.dailyTotal.sort(sortDate) })
      })
      .catch(error => {
        console.log(error)
      })
  },
  async fetchCampaignReservedClicks (store, payload) {
    const dateFrom = payload.dateFrom && payload.dateFrom !== '' ? moment.utc(payload.dateFrom) : moment.utc()
    await dataHubNativeCampaignPerfPredictionApi().post(API_NAME_NATIVE_FORECAST + '/campaign-reserved-clicks', {
      fromDate: dateFrom.format('YYYY-MM-DD'),
      toDate: dateFrom.add(7, 'days').format('YYYY-MM-DD'),
      campaignIds: payload.campaignIds
    })
      .then(res => {
        store.commit('storeCampaignReservedClicks', res.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
}

const getters = {
  forecastOverall (state) {
    return state.data.forecastOverall
  },
  campaignReservedClicks (state) {
    return state.data.campaignReservedClicks
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
