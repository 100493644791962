const messages = {
  id: 'ID',
  created_at: 'Vytvorené',
  created_at_from: 'Vytvorené od',
  created_at_to: 'Vytvorené do',
  created_by: 'Vytvoril',
  modified_at: 'Zmenené',
  modified_by: 'Úpravil',
  email: 'Email',
  variableSymbol: 'VS',
  name: 'Názov',
  price: 'Cena',
  product: 'Produkt',
  payment: 'Spôsob platby',
  paymentResult: 'Výsledok platby',
  cardNumber: 'Číslo študentskej karty',
  count: 'Počet',
  sameDeliveryAddress: 'Rovnaká doručovacia adresa',
  invoiceAddress: 'Fakturačná adresa',
  deliveryAddress: 'Doručovacia adresa',
  notice: 'Poznámka',
  giftSubscription: 'Darčekové predplatné',
  aboStartEdition: 'Od vydania',
  systemInfo: 'Systémové informácie',
  history: 'História',
  historyType: 'Typ',
  historyDescription: 'Popis',
  payments: 'Platby',
  paymentStatusType: 'Typ',
  create_new: 'Vytvor novú',
  save: 'Uložiť',
  address: {
    type: 'Typ',
    name: 'Meno',
    surname: 'Priezvisko',
    company: 'Spoločnosť',
    ico: 'IČO',
    dic: 'DIČ',
    icDph: 'IČ DPH',
    street: 'Ulica',
    city: 'Mesto',
    zip: 'PSČ',
    country: 'Krajina'
  },
  abo: {
    title: 'ABO',
    idObj: 'ID objednávky',
    pcZakaz: 'PC zákazníka'
  },
  aboPeriodik: {
    title: 'Periodik ABO',
    orderId: 'ID objednávky',
    customerId: 'ID zákazníka'
  },
  subscriptionStatus: {
    mailSent: 'Odoslaný mail',
    aboExported: 'Exportovaná do ABO',
    activated: 'Aktivované predplatné'
  },
  feUser: 'Používateľ',
  filter: {
    export: 'Exportovať',
    search: 'Hľadať',
    reset_filter: 'Zrušiť filter'
  },
  button: {
    changePaymentResult: 'Zmeň výsledok platby',
    more: 'Viac'
  },
  modal: {
    changePaymentResultHeader: 'Zmeniť výsledok platby?',
    changePaymentResultText: '',
    changePaymentResultCancelBtn: 'Nie',
    changePaymentResultConfirmBtn: 'Áno, zmeň'
  },
  error: {
    required_fields: 'Prosím, vyplňte všetky povinné polia'
  },
  message: {
    updated_record: 'Záznam bol zmenený!',
    deleted_record: 'Záznam bol vymazaný!'
  }
}

export default messages
