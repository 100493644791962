/* eslint-disable */
const clean = (content) => {
  content = content.replace('/?position', '/position')
  return content
}

const customTrim = (text) => {
  return text.replace(/\&nbsp;/g, '').trim();
}

const parseAll = (content) => {
  let promises = []
  let embedsToReplace = []

  let contentNode = document.createElement('div')
  contentNode.innerHTML = content

  let imageEmbeds = contentNode.getElementsByClassName('snippet-image')
  let pollEmbeds = contentNode.getElementsByClassName('snippet-poll')
  let infoboxEmbeds = contentNode.getElementsByClassName('snippet-infobox')
  let articleEmbeds = contentNode.getElementsByClassName('snippet-articles')
  let diseaseEmbeds = contentNode.getElementsByClassName('snippet-disease-articles')
  let htmlEmbeds = contentNode.getElementsByClassName('snippet-code')
  let pageBreaks = contentNode.getElementsByClassName('snippet-page-break')
  let paidContentBreaks = contentNode.getElementsByClassName('snippet-paid-content-break')
  let galleryLinks = contentNode.getElementsByClassName('snippet-gallery-link')
  let quoteEmbeds = contentNode.getElementsByClassName('snippet-quote')
  let youtubeEmbeds = contentNode.getElementsByClassName('snippet-youtube')
  let sportTableEmbeds = contentNode.getElementsByClassName('snippet-sport-table')
  let sportOnlineEmbeds = contentNode.getElementsByClassName('snippet-sport-online')
  let carTableEmbeds = contentNode.getElementsByClassName('snippet-car-table')
  let ooyalaEmbeds = contentNode.getElementsByClassName('snippet-ooyala')
  let liveboxEmbeds = contentNode.getElementsByClassName('snippet-livebox')

  // images
  for (let i = 0; i < imageEmbeds.length; i++) {
    promises.push(
      parseEmbeddedImage(imageEmbeds[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // Infoboxes
  for (let i = 0; i < infoboxEmbeds.length; i++) {
    promises.push(
      parseEmbeddedInfobox(infoboxEmbeds[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // polls
  for (let i = 0; i < pollEmbeds.length; i++) {
    promises.push(
      parseEmbeddedPoll(pollEmbeds[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // article
  for (let i = 0; i < articleEmbeds.length; i++) {
    promises.push(
      parseEmbeddedArticle(articleEmbeds[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // disease article
  for (let i = 0; i < diseaseEmbeds.length; i++) {
    promises.push(
      parseEmbeddedDiseaseArticle(diseaseEmbeds[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // html
  for (let i = 0; i < htmlEmbeds.length; i++) {
    promises.push(
      parseEmbeddedHtml(htmlEmbeds[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // pageBreaks
  for (let i = 0; i < pageBreaks.length; i++) {
    promises.push(
      parsePageBreak(pageBreaks[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // paidContentBreaks
  for (let i = 0; i < paidContentBreaks.length; i++) {
    promises.push(
      parsePaidContentBreaks(paidContentBreaks[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // galleryLinks
  for (let i = 0; i < galleryLinks.length; i++) {
    promises.push(
      parseGalleryLinks(galleryLinks[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // quotes
  for (let i = 0; i < quoteEmbeds.length; i++) {
    promises.push(
      parseQuotes(quoteEmbeds[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // youtube videos
  for (let i = 0; i < youtubeEmbeds.length; i++) {
    promises.push(
      parseEmbeddedYoutubeVideo(youtubeEmbeds[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // sport tables
  for (let i = 0; i < sportTableEmbeds.length; i++) {
    promises.push(
      parseEmbeddedSportTable(sportTableEmbeds[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // sport onlines
  for (let i = 0; i < sportOnlineEmbeds.length; i++) {
    promises.push(
      parseEmbeddedSportOnline(sportOnlineEmbeds[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // car tables
  for (let i = 0; i < carTableEmbeds.length; i++) {
    promises.push(
      parseEmbeddedCarTable(carTableEmbeds[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // ooyala videos
  for (let i = 0; i < ooyalaEmbeds.length; i++) {
    promises.push(
      parseEmbeddedOoyalaVideo(ooyalaEmbeds[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  // livebox videos
  for (let i = 0; i < liveboxEmbeds.length; i++) {
    promises.push(
      parseEmbeddedLiveboxVideo(liveboxEmbeds[i])
        .then(response => {
          embedsToReplace.push(response)
        })
    )
  }

  return new Promise((resolve, reject) => {
    return Promise.all(promises)
      .then(() => {
        for (let i = 0; i < embedsToReplace.length; i++) {
          content = clean(content)
          let snippetContent = clean(embedsToReplace[i].itemElement.outerHTML)
          content = content.replace(snippetContent, embedsToReplace[i].template)
        }
        resolve(content)
      })
      .catch(error => {
        console.log(error)
      })
  })
}

// image
const parseEmbeddedImage = (itemElem) => {
  return new Promise((resolve, reject) => {
    let mediaId = parseInt(jQuery(itemElem).find('img').attr('data-media-id'))
    let template = ''
    if (mediaId > 0) {
      template = `###{EMBED_MEDIA_WIDEN:${mediaId}}###`
    }
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

// infobox
const parseEmbeddedInfobox = (itemElem) => {
  return new Promise((resolve, reject) => {
    let infoboxId = parseInt(jQuery(itemElem).find('.inner').attr('data-infobox-id'))
    let template = ''
    if (infoboxId > 0) {
      template = `###{EMBED_INFOBOX:${infoboxId}}###`
    }
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

// poll
const parseEmbeddedPoll = (itemElem) => {
  return new Promise((resolve, reject) => {
    let pollId = parseInt(jQuery(itemElem).find('.inner').attr('data-poll-id'))
    let template = ''
    if (pollId > 0) {
      template = `###{EMBED_POLL:${pollId}}###`
    }
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

// article
const parseEmbeddedArticle = (itemElem) => {
  return new Promise((resolve, reject) => {
    let template = ''
    let articleDocumentId = customTrim(jQuery(itemElem).find('.inner').attr('data-article-id'))
    let articleDocumentTag = customTrim(jQuery(itemElem).find('.inner').attr('data-article-tag'))
    if (articleDocumentId !== '' && articleDocumentTag !== '') {
      template = `###{EMBED_ARTICLE:${articleDocumentId}|${articleDocumentTag}}###`
    } else if (articleDocumentId !== '') {
      template = `###{EMBED_ARTICLE:${articleDocumentId}}###`
    }
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

// disease article
const parseEmbeddedDiseaseArticle = (itemElem) => {
  return new Promise((resolve, reject) => {
    let template = ''
    let articleDocumentId = customTrim(jQuery(itemElem).find('.inner').attr('data-article-id'))
    let articleDocumentTag = customTrim(jQuery(itemElem).find('.inner').attr('data-article-tag'))
    if (articleDocumentId !== '' && articleDocumentTag !== '') {
      template = `###{EMBED_DISEASE_ARTICLE:${articleDocumentId}|${articleDocumentTag}}###`
    } else if (articleDocumentId !== '') {
      template = `###{EMBED_DISEASE_ARTICLE:${articleDocumentId}}###`
    }
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

// HTML
const parseEmbeddedHtml = (itemElem) => {
  return new Promise((resolve, reject) => {
    let embedContent = jQuery(itemElem).find('.embed-name').attr('data-embed-content')
    let template = ''
    if (customTrim(embedContent) !== '') {
      template = `###{EMBED_HTML:${embedContent}}###`
    }
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

// page break
const parsePageBreak = (itemElem) => {
  return new Promise((resolve) => {
    resolve({
      'itemElement': itemElem,
      'template': `###{PAGEBREAK}###`
    })
  })
}

// paid content break
const parsePaidContentBreaks = (itemElem) => {
  return new Promise((resolve) => {
    resolve({
      'itemElement': itemElem,
      'template': `###{PAIDCONTENTBREAK}###`
    })
  })
}

// gallery links
const parseGalleryLinks = (itemElem) => {
  return new Promise((resolve) => {
    let linkText = jQuery(itemElem).find('.link-text').html()
    let pageNumber = jQuery(itemElem).find('.page-number').html()
    let template = ''
    if (customTrim(linkText) !== '' && parseInt(pageNumber) > 0) {
      template = `###{EMBED_GALLERY_LINK:${pageNumber}|${linkText}}###`
    }
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

// quotes
const parseQuotes = (itemElem) => {
  return new Promise((resolve) => {
    let quoteText = jQuery(itemElem).find('.quoted-text').html()
    let quoteAuthor = jQuery(itemElem).find('.author-name').html()
    let template = `<blockquote class="quote"><span>${quoteText}</span><footer>${quoteAuthor}</footer></blockquote>`
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

// youtube
const parseEmbeddedYoutubeVideo = (itemElem) => {
  return new Promise((resolve) => {
    let link = jQuery(itemElem).find('.video-link').text()
    let template = ''
    if (customTrim(link) !== '') {
      template = `###{EMBED_YOUTUBE:${link}}###`
    }
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

// sport table
const parseEmbeddedSportTable = (itemElem) => {
  return new Promise((resolve) => {
    let seasonId = jQuery(itemElem).find('.season-id').text()
    let activeTab = jQuery(itemElem).find('.active-tab').text()
    let template = ''
    if (parseInt(seasonId) > 0 && parseInt(activeTab) > 0) {
      template = `###{EMBED_SPORT_TABLE:${seasonId}|${activeTab}}###`
    }
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

// sport online
const parseEmbeddedSportOnline = (itemElem) => {
  return new Promise((resolve) => {
    let matchId = jQuery(itemElem).find('.match-id').text()
    let category = jQuery(itemElem).find('.match-category').text()
    let template = ''
    if (customTrim(matchId) !== '' && customTrim(category) !== '') {
      template = `###{EMBED_SPORT_ONLINE:${matchId}|${category}}###`
    }
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

// car table
const parseEmbeddedCarTable = (itemElem) => {
  return new Promise((resolve) => {
    let carIds = jQuery(itemElem).find('.car-ids').text()
    let template = ''
    if (customTrim(carIds) !== '') {
      template = `###{EMBED_CAR_TABLE:${carIds}}###`
    }
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

// ooyala
const parseEmbeddedOoyalaVideo = (itemElem) => {
  return new Promise((resolve) => {
    let link = jQuery(itemElem).find('.video-link').text()
    let template = ''
    if (customTrim(link) !== '') {
      template = `###{EMBED_OOYALA:${link}}###`
    }
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

// livebox
const parseEmbeddedLiveboxVideo = (itemElem) => {
  return new Promise((resolve) => {
    let link = jQuery(itemElem).find('.video-link').text()
    let template = ''
    if (customTrim(link) !== '') {
      template = `###{EMBED_LIVEBOX:${link}}###`
    }
    resolve({
      'itemElement': itemElem,
      'template': template
    })
  })
}

export default {
  parseAll
}
