const messages = {
  id: 'Id',
  name: 'Název',
  site: 'Stránka',
  managers: 'Manažeři',
  search: 'Hledej',
  created_at: 'Vytvořeno',
  modified_at: 'Upraveno',
  created_by: 'Vytvořil'
}

export default messages
