import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ARTICLE_OFFER_WOOD_WING_PERMISSIONS

const routes = [
  {
    path: 'woodWing/:id',
    component: () => import('../../components/woodWing/WoodWing'),
    name: 'woodWing_detail',
    meta: {
      description: 'WoodWing article detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'woodWing',
    component: () => import('../../components/woodWing/WoodWingList'),
    name: 'woodWing_list',
    meta: {
      description: 'WoodWing article list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
