import { dateTimeNow } from '../services/DateTimeService'

export default {
  id: null,
  status: 0,
  title: '',
  url: '',
  agency: '',
  client: '',
  bonusClicks: 0,
  bonusClicksLimit: 0,
  clicks: 0,
  clicksLimit: 0,
  clickPrice: 0,
  eagleDocumentId: '',
  damImageId: null,
  damImageFileName: null,
  articleAlternatives: [],
  nativePerfViews: 0,
  publishedSince: dateTimeNow(),
  publishedUntil: dateTimeNow(),
  category: null,
  dividedByDays: false,
  views: 0,
  duplicate: false,
  alternativesEnabled: false,
  schedulingEnabled: false,
  schedulingConfig: {},
  algorithmOverridden: false,
  paidCampaign: true
}
