const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.site !== null && filter.site !== 0 && filter.site !== '0') {
    filterQuery += '&filter_eq[site]=' + filter.site
  }
  if (filter.category !== null && filter.category !== 0) {
    filterQuery += '&filter_eq[category]=' + filter.category
  }
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.slug) {
    filterQuery += '&filter_contains[slug]=' + filter.slug
  }
  return filterQuery
}
const buildUsagesQuery = function (topicId) {
  return '&filter_memberOf[selectedTopics]=' + topicId
}
const buildArticleTopicsQuery = function (data) {
  let filterQuery = ''
  if (data.site !== null && data.site !== 0 && data.site !== '0') {
    filterQuery += '&filter_eq[site]=' + data.site
  }
  if (data.title) {
    filterQuery += '&filter_contains[title]=' + data.title
  }
  if (data.tagsIds.length > 0) {
    filterQuery += '&filter_custom[topic.tags]=' + data.tagsIds.join(',')
  }
  return filterQuery
}
export default {
  buildFilterQuery,
  buildUsagesQuery,
  buildArticleTopicsQuery
}
