const messages = {
  list: {
    id: 'ID',
    message: 'Správa',
    site: 'Denník',
    from: 'Od',
    to: 'Do',
    published: 'Publikované'
  },
  form: {
    title_label: 'Titulok',
    title_placeholder: 'Titulok správy',
    message_text_label: 'Text správy',
    message_text_placeholder: 'Text správy',
    link: 'Odkaz',
    link_placeholder: 'Odkaz na stránku (https://...)'
  },
  filter: {
    text: 'Správa',
    site: 'Denníky',
    published: 'Publikované',
    search: 'Filtrovať'
  },
  type_value: {
    published: 'Aktívne',
    unpublished: 'Neaktívne'
  },
  inactive: 'Neaktívne',
  active: 'Aktívne',
  publish_news_label: 'Publikovať správu'
}

export default messages
