<template>
  <h2 class="dash-title" @click="clickHandler" :style="{ '--enviromentColor': environmentVisualSettings.color }">
    <span v-if="!dashCompact" class="dash-title__text">
      <LogoVlmFull
        v-if="vlm"
        class="dash-title__logo-full"
      />
      <strong v-else class="dash-title__strong">
        EAGLE Admin
      </strong>
      <strong v-if="environmentVisualSettings.name" class="dash-title__enviroment-mark">
        {{ environmentVisualSettings.name }}
        <br />
        <span class="dash-title__enviroment-mark__api-url">
          {{ environmentVisualSettings.coreApiUrl }}
        </span>
      </strong>
    </span>
    <span v-if="dashCompact && !vlm" class="dash-title__text-compact">
      E
    </span>
    <LogoVlmShort v-if="vlm && dashCompact" class="dash-title__logo-short" />
  </h2>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import EnvironmentService from '@/services/EnvironmentService'
import LogoVlmFull from '@/assets/img/svg/vlm/aqua_logo_full.svg?inline'
import LogoVlmShort from '@/assets/img/svg/vlm/aqua_logo_short.svg?inline'

export default {
  name: 'DashTitle',
  components: {
    LogoVlmFull,
    LogoVlmShort
  },
  data () {
    return {
      environmentVisualSettings: {
        color: '',
        name: ''
      }
    }
  },
  computed: {
    ...mapState({
      dashCompact: state => state.dash.compact,
      dashSidebarMobileOpen: state => state.dash.sidebar.isOpen,
      siteWidth: state => state.site.width
    }),
    ...mapGetters(['vlm'])
  },
  methods: {
    clickHandler () {
      if (this.siteWidth >= 1200) {
        this.$store.commit('dash/setCompactness', !this.dashCompact)
      }
      if (this.siteWidth < 768) {
        this.$store.commit('dash/setSidebarOpen', !this.dashSidebarMobileOpen)
      }
    }
  },
  created () {
    this.environmentVisualSettings = EnvironmentService.getEnvironmentVisualSettings()
  }
}
</script>

<style scoped lang="scss">
  .dash-title {
    cursor: pointer;
    direction: ltr;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    @include bp(7) {
      @include size(240px 88px);
      @include fixed(0 _ _ 0);
    }
    .dash--compact & {
      @include size(60px 60px);
      background: var(--enviromentColor, #6599fe);
    }
    &__logo-full {
      width: 8rem;
      height: auto;
      fill: var(--enviromentColor, #0C8CCD);
      position: relative;
      top: .45rem;
    }
    &__logo-short {
      fill: #fff;
      padding: .7rem;
    }
    &__text {
      @include font(400 24px "Roboto");
      color: #465674;
      pointer-events: none;
    }
    &__text-compact {
      @include font(600 34px "Roboto");
      color: #fff;
      pointer-events: none;
    }
    &::after {
      .dash:not(.dash--compact) & {
        @include absolute(_ _ 0 20px, 1);
        @include size(calc(100% - 40px) 1px);
        content: '';
        background: #d1dbe4;
      }
    }
    &__strong {
      @include margin(_ 5px _ _);
      font-weight: 700;
      color: var(--enviromentColor, #6599fe);
    }

    &__enviroment-mark {
      display: block;
      font-size: 1rem;
      color: var(--enviromentColor, #6599fe);
      &__api-url {
        font-size: rem(12px);
      }
    }
  }
</style>
