const messages = {
  text: 'Text',
  text_placeholder: 'Text panelu',
  link: 'Odkaz',
  link_placeholder: 'Odkaz na stránku (https://...)',
  panel_positions: 'Pozícia panelu',
  created_date: 'Dátum vytvorenia',
  changed_date: 'Dátum zmeny',
  sites_label: 'Publikovat na Deníky (přepíše aktuálně publikované panely na dané pozici)',
  image_label: 'Obrázok panelu',
  list: {
    id: 'ID',
    text: 'Text',
    position: 'Pozícia',
    from: 'Od',
    to: 'Do',
    state: 'Stav'
  },
  filter: {
    select_site_label: 'Vybrať Deník'
  },
  create_panel_label: 'Vytvoriť panel',
  switch: {
    graphics_label: 'Grafické',
    text_label: 'Textové'
  },
  drag_label: 'Presunúť',
  state_active: 'Aktívne',
  state_inactive: 'Neaktívne',
  active_panels: 'Aktívne panely',
  inactive_panels: 'Neaktívne panely'
}

export default messages
