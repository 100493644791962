import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ARTICLE_TEST_CAR_PERMISSIONS

const routes = [
  {
    path: 'articleTest/car/new',
    component: () => import('../../views/article/ArticleTestCarNewView'),
    name: 'car_new',
    meta: {
      description: 'Car new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'articleTest/car/:id/edit',
    component: () => import('../../views/article/ArticleTestCarEditView'),
    name: 'car_edit',
    meta: {
      description: 'Car edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'articleTest/car/:id',
    component: () => import('../../views/article/ArticleTestCarView'),
    name: 'car_detail',
    meta: {
      description: 'Car detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'articleTest/car',
    component: () => import('../../views/article/ArticleTestCarListView'),
    name: 'car_list',
    meta: {
      description: 'Car list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
