const messages = {
  siteName: 'Server',
  appId: 'AppId',
  apiKey: 'ApiKey',
  utm: 'Utm',
  limit: 'Limit',
  limit_placeholder: 'Info: 0 pre neobmedzený denný limit',
  reset: 'Reset',
  active: 'Aktívny',
  addSite: 'Pridať server',
  no_results_found_for_this_gps: 'Nenašli sa žiadne výsledky pre tieto GPS',
  error_fetching_address_from_gps: 'Chyba pri získavaní adresy z GPS',
  invalid_gps_coordinates: 'Neplatné GPS súradnice',
  vlm_valid_GPS_format: 'požadovaný tvar GPS: 50.05649280076552,14.375121168745256'
}

export default messages
