export default {
  status: '',
  from: '',
  to: '',
  url: '',
  salesman: '',
  agency: '',
  client: '',
  title: '',
  isFree: true
}
