const state = {
  currentTab: null,
  currentTabTitle: null
}

const mutations = {
  SET_CURRENT_TAB (state, tab) {
    state.currentTab = tab.id
    state.currentTabTitle = tab.title
  }
}

const actions = {
  setCurrentTab ({ commit }, tab) {
    commit('SET_CURRENT_TAB', tab)
  }
}

const getters = {
  currentTab: state => state.currentTab,
  currentTabTitle: state => state.currentTabTitle
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
