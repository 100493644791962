const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.publication) {
    filterQuery += '&filter_eq[publication]=' + filter.publication.id
  }
  if (filter.printTitle) {
    filterQuery += '&filter_custom[printTitle]=' + filter.printTitle
  }
  if (filter.quality) {
    filterQuery += '&filter_eq[quality]=' + filter.quality
  }
  if (filter.native === 0 || filter.native === 1) {
    filterQuery += '&filter_eq[native]=' + filter.native
  }
  if (filter.type) {
    filterQuery += '&filter_eq[type]=' + filter.type
  }
  if (filter.department && (!filter.user)) {
    filterQuery += '&filter_custom[department]=' + filter.department
  }
  if (filter.department && filter.user) {
    filterQuery += '&filter_memberOf[sharedUsers]=' + filter.user
  }
  if (filter.publicationWeek) {
    filterQuery += '&filter_custom[publication.week]=' + filter.publicationWeek
  }

  return filterQuery
}

export default {
  buildFilterQuery
}
