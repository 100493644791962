import EdonApi from '../../api/edon'

const API_NAME = '/department-cluster'

const state = {
  loaded: false,
  clusters: []
}

const mutations = {
  storeClusters (state, responseData) {
    state.clusters = responseData
  },
  setLoaded (state, loaded) {
    state.loaded = loaded
  }
}

const actions = {
  async fetchAll (store) {
    return await EdonApi().get(API_NAME)
      .then(response => {
        store.commit('storeClusters', response.data)
        store.commit('setLoaded', true)
      })
      .catch(error => console.log(error))
  }
}

const getters = {
  all (state) {
    return state.clusters
  },
  loaded (state) {
    return state.loaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
