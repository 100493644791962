import CoreApi from '../../../api/core'
import NativeArticle from '../../../model/DonNativePerfArticle'
import config from '../../../config'
import _ from 'lodash'
import NativePerfArticleFilter from '../../../model/NativePerfArticleFilter'
import DonFilterService from '../../../services/don/DonFilterService'

const API_NAME = '/don/nativePerf/article'

const state = {
  error: null,
  detail: NativeArticle,
  list: [],
  refreshUrl: null,
  pagination: {
    currentPage: 1,
    totalCount: 0,
    currentPageCount: 0,
    firstItemId: null,
    lastItemId: null
  },
  filter: _.cloneDeep(NativePerfArticleFilter),
  articlesLoaded: false
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.articles
    state.pagination.totalCount = responseData.totalCount
    state.pagination.firstItemId = responseData.firstItemId
    state.pagination.lastItemId = responseData.lastItemId
    state.pagination.currentPageCount = state.list.length
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setRefreshUrl (state, url) {
    state.refreshUrl = url
  },
  setError (state, message) {
    state.error = message
  },
  setCurrentPage (state, currentPage) {
    state.pagination.currentPage = currentPage
  },
  resetList (state) {
    state.list = []
  },
  resetPagination (state) {
    state.list = []
    state.pagination.totalCount = 0
    state.pagination.firstItemId = null
    state.pagination.lastItemId = null
    state.pagination.currentPageCount = 0
    state.pagination.currentPage = 1
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(NativePerfArticleFilter)
  },
  setArticlesLoaded (state, articlesLoaded) {
    state.articlesLoaded = articlesLoaded
  }
}

const actions = {
  async fetch (store, type = 'init') {
    store.commit('TOGGLE_LOADING', null, { root: true })

    let url = API_NAME +
      '?limit=' + config.defaults.list.limit
    if (type === 'next') {
      url = url + '&lastItemId=' + store.state.pagination.lastItemId
    } else if (type === 'prev') {
      url = url + '&firstItemId=' + store.state.pagination.firstItemId
    } else if (type === 'refresh') {
      url = store.state.refreshUrl
    }

    url += DonFilterService.buildFilterQuery(store.state.filter)

    store.commit('setRefreshUrl', url)
    await CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },

  async refresh (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const url = store.state.refreshUrl
    await CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },

  async fetchOne (store, id) {
    return await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },

  async create (store, record) {
    return await CoreApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          store.commit('setError', error.response.data)
        } else if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },

  async update (store, record) {
    return await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          store.commit('setError', error.response.data)
        } else if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },

  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },

  async getCopy (store, id) {
    return await CoreApi().get(API_NAME + '/' + id + '/copy')
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  }
}

const getters = {
  detail (state, getters, rootState, rootGetters) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  error (state) {
    return state.error
  },
  pagination (state) {
    return state.pagination
  },
  filter (state) {
    return state.filter
  },
  isArticlesLoaded (state) {
    return state.articlesLoaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
