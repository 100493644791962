const messages = {
  list: {
    id: 'Id',
    name: 'Název',
    external: 'Externí',
    external_group_mapping_tooltip: 'Pokud je skupina uzamčena, je přiřazena k externí systémové skupině a nelze ji vymazat.'
  },
  name: 'Název',
  capabilities: 'Oprávnění skupiny'
}

export default messages
