const messages = {
  id: 'ID',
  activated_at: 'Activated at',
  activated_at_from: 'Activated at from',
  activated_at_to: 'Activated at to',
  cancelled_at: 'Cancelled',
  cancelled_at_from: 'Cancelled at from',
  cancelled_at_to: 'Cancelled at to',
  activeSince: 'Active from',
  activeUntil: 'Active to',
  email: 'Email',
  code: 'Code',
  feUser: 'FE user',
  product: 'Product',
  firstOrder: 'First order',
  filter: {
    export: 'Export',
    search: 'Search',
    reset_filter: 'Reset filter',
    unique_email: 'Unique email'
  }
}

export default messages
