import _ from 'lodash'
import ssoApi from '../../../api/sso'
import BeUserModel from '../../../model/beUser/BeUser.js'
import ErrorHandlingService from '../../../services/ErrorHandlingService'
import BeUserFilterModel from '../../../model/beUser/BeUserFilter'
import BeUserFilterService from '../../../services/beUser/BeUserFilterService'
import UserService from '@/services/user/UserService'

const API_NAME = '/Users'

const state = {
  error: null,
  detail: BeUserModel,
  list: [],
  all: [],
  totalCount: 0,
  page: 1,
  filterQuery: '',
  filter: _.cloneDeep(BeUserFilterModel)
}

const mapUsers = (apiUser) => ({
  ...apiUser,
  id: apiUser.userId,
  firstName: apiUser.givenName,
  lastName: apiUser.familyName,
  username: apiUser.email
})

const mutations = {
  storeAll (state, responseData) {
    state.all = responseData.users.map(mapUsers)
  },
  storeList (state, responseData) {
    state.list = responseData.users.map(mapUsers)
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setFilterQuery (state, filterQuery) {
    state.filterQuery = filterQuery
  }
}

const actions = {
  async fetchAll ({ commit }) {
    const url = `${API_NAME}/simple`
    await ssoApi().get(url)
      .then(res => {
        commit('storeAll', res.data)
      })
      .catch(error => console.error(error))
  },
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const filterQuery = BeUserFilterService.buildFilterQuery(store.state.filter)
    const url = `${API_NAME}?page=${store.state.page}${filterQuery}`
    ssoApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
      })
      .catch(error => {
        console.error(error)
      }).finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async fetchOne (store, id) {
    await ssoApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => {
        console.error(error)
      })
  },
  async update (store, user) {
    return await ssoApi().put(API_NAME + '/' + user.userId, JSON.stringify(user))
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
          return null // server does not return anything
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        ErrorHandlingService.handleError(store, error)
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  all (state) {
    return state.all
  },
  list (state) {
    return state.list
  },
  userNameById (state) {
    return id => {
      if (!id) {
        return ''
      }
      const user = state.all.find(user => [user.userId, user.remoteUserId].includes(id))
      return user ? UserService.getUserInfo(user) : id
    }
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  filterQuery (state) {
    return state.filterQuery
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
