const buildFilterQuery = function (filter) {
  var filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[seasonId]=' + filter.id
  }
  if (filter.nationName) {
    filterQuery += '&filter_custom[season.nationName]=' + filter.nationName
  }
  if (filter.fortunaSportName) {
    filterQuery += '&filter_custom[season.fortunaSportName]=' + filter.fortunaSportName
  }
  if (filter.competitionName) {
    filterQuery += '&filter_custom[season.competitionName]=' + filter.competitionName
  }
  if (filter.fortunaCatName) {
    filterQuery += '&filter_contains[fortunaCatName]=' + filter.fortunaCatName
  }
  if (filter.year) {
    filterQuery += '&filter_eq[details.year]=' + filter.year
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
