const messages = {
  list: {
    id: 'Id',
    title: 'Titulek',
    sites: 'Stránky',
    voting: 'Hlasování',
    published_since: 'Publikováno od',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil'
  },
  filter: {
    title: 'Titulek',
    id: 'Id',
    bodytext: 'BodyText',
    search: 'Hledat'
  },
  id: 'Id',
  title: 'Titulek',
  title_placeholder: 'Napište nadpis',
  bodytext: 'Text vtipu',
  sites: 'Stránky',
  voting: 'Povolit hlasování',
  author: 'Author',
  published_since: 'Datum publikování',
  published_since_placeholder: 'Vyberte datum publikování na webu',
  published_until: 'Publikované do',
  published_until_placeholder: 'Vyberte datum'
}

export default messages
