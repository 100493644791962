import { dateTimeNow } from '../services/DateTimeService'

export default {
  id: 0,
  service: '',
  category: 0,
  headline: '',
  slugline: '',
  author: '',
  contentCreated: dateTimeNow(),
  versionCreated: dateTimeNow(),
  content: '',
  source: '',
  raw: '',
  status: '',
  urgency: 0,
  articles: []
}
