const messages = {
  list: {
    id: 'Id',
    server: 'Server',
    title: 'Titulok ankety',
    startOfVoting: 'Začiatok ankety',
    endOfVoting: 'Koniec ankety',
    loggedInUsersOnly: 'Len prihlásení'
  },
  filter: {
    id: 'Id',
    title: 'Titulok ankety',
    server: 'Server'
  },
  server: 'Server',
  title: 'Titulok ankety',
  startOfVoting: 'Začiatok ankety',
  endOfVoting: 'Koniec ankety',
  displayType: 'Zobraziť',
  template: 'Šablóna',
  restrictionTypes: 'Pravidlá',
  image: 'Obrázok ankety',
  loggedInUsersOnlyLabel: 'Len pre prihlásených užívateľov',
  loggedInUsersOnlyLabelFalse: 'Nie',
  loggedInUsersOnlyLabelTrue: 'Áno',
  displayResultsBeforeVoting: 'Výsledky pred hlasovaním',
  addOption: 'Pridať možnosť',
  hidden: 'Skrytý',
  enabledVoting: 'Povoliť hlasovanie',
  minimumOptionsError: 'Anketa musí obsahovať aspoň dve možnosti',
  yesNoTemplateMaxOptionsError: 'Anketa so šablónou áno/nie môže obsahovať iba dve možnosti',
  options: {
    answer: 'Odpoveď',
    image: 'Obrázok odpovede',
    voteCount: 'Počet hlasov'
  },
  displayTypesOptions: {
    votes: 'Hlasy',
    percentage: 'Percenta'
  },
  templateOptions: {
    default: 'Východzí',
    yesNo: 'Áno nie',
    basic: 'Základný'
  },
  restrictionTypeOptions: {
    fiveMinutes: '1 x za 5 minút',
    fifteenMinutes: '1 x za 15 minút',
    thirtyMinutes: '1 x za 30 minút',
    sixtyMinutes: '1 x za 60 minút',
    oneDay: '1 x za Deň',
    oneMonth: '1 x za Mesiac',
    onlyOnce: '1 x za celú dobu trvania'
  }
}

export default messages
