import axios from 'axios'
import appConfig from '../config'
import Store from '../store/'

const ssoApi = function (timeout = appConfig.sso.apiTimeout, baseURL = appConfig.sso.apiUrl()) {
  const _client = axios.create({
    baseURL,
    timeout: timeout * 1000,
    headers: {
      Authorization: localStorage.getItem('access_token'),
      'Content-Type': 'application/json'
    }
  })

  /*
   * Axios Request Interceptor.
   * Before each request, use the values in local storage to set new headers.
   */
  _client.interceptors.request.use(
    config => {
      if (config.baseURL === appConfig.sso.apiUrl()) {
        config.headers.Authorization = localStorage.getItem('access_token')
      }
      return config
    },
    error => Promise.reject(error)
  )

  /*
   * Handle 401 response, redirect to login page
   */
  _client.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response.status === 401) {
        Store.dispatch('user/logout')
          .then(() => {
            window.location.href = '/login'
          })
          .catch((error) => {
            console.log(error.response)
          })
      }
      return Promise.reject(error)
    }
  )

  return _client
}

export default ssoApi
