export default {
  tags: 'Přidání tagů',
  isActive: 'Je aktivní',
  select_isActive: 'Aktivní',
  select_isInactive: 'Neaktivní',
  select_articlesCount: 'Počet článků',
  select_display_type: 'Typ zobrazení',
  select_display_type_cards: 'Karty',
  select_display_type_links: 'Odkazy',
  name: 'Název',
  id: 'Id',
  save_btn: 'Uložit',
  select_btn: 'Vybrat',
  cancelSelected_btn: 'Zrušit vybrané',
  specials: {
    conflict_modal: 'Článek musí obsahovat alespoň jeden z těchto tagů:',
    select_modal_header: 'Vyberte speciál',
    heroImage: 'Hlavní obrázek',
    listingImage: 'Obrázek v seznamu',
    header: 'Speciály',
    table_title: 'Název speciálu',
    selectLabel: 'Speciál',
    allSitesCheckboxLabel: 'Pro všechny weby denik.cz',
    sitesInputLable: 'Weby',
    name: 'Název',
    title: 'Titul',
    description: 'Popis',
    slug: 'Slug',
    validTo: 'Datum platnosti speciálu',
    metaHeader: 'Meta údaje',
    meta: {
      title: 'Meta titul',
      description: 'Meta popis',
      keywords: 'Meta klíčová slova'
    }
  },
  theme: {
    select_modal_header: 'Vyberte téma',
    header: 'Témata',
    table_title: 'Název tématu',
    selectLabel: 'Téma',
    title: ''
  },
  series: {
    select_modal_header: 'Vyberte seriál',
    header: 'Seriály',
    table_title: 'Název seriálu',
    selectLabel: 'Seriál',
    title: ''
  }
}
