const messages = {
  list: {
    id: 'Id',
    title: 'Název',
    slug: 'Slug',
    title_title: 'Titul',
    publicationDate: 'Datum vydání'
  },
  filter: {
    id: 'Id',
    title: 'Název',
    title_title: 'Titul',
    search: 'Hledat'
  },
  current: {
    info: 'Obrázky jsou cachovány. Změna se projeví až po expiraci cache.'
  },
  buttons: {
    add: 'Přidat',
    display_current: 'Zobrazit aktuální',
    back_to_list: 'Zpět na seznam'
  },
  title: 'Název',
  title_title: 'Titul',
  slug: 'Slug',
  publication_date: 'Datum vydání',
  description: 'Popis',
  image: 'Obrázek'
}

export default messages
