const messages = {
  filter: {
    complete: 'Complete',
    fulltext_search: 'Search',
    fulltext_search_placeholder: 'Fulltext (caption, description, headline, keywords)',
    created_since: 'Uploaded from',
    created_until: 'Uploaded to',
    description: 'Description (displayed description on web)',
    keywords: 'Keywords',
    author: 'Author',
    brand: 'Uploaded by brand',
    id: 'Id',
    reset_filter: 'Reset filter',
    upload_source: 'Upload source',
    orientation: 'Orientation',
    orientation_HRZN: 'Landscape',
    orientation_VRTC: 'Portrait',
    orientation_SQRE: 'Square',
    size: 'Size',
    size_gt: 'Minimum size {size}MB',
    resolution: 'Resolution',
    resolution_gt: 'Mininum resolution {resolution} Mpx',
    created_by: 'Uploaded by',
    show_by: 'Display by',
    show_by_upload_date: 'DAM upload date',
    show_by_original_date: 'Capture date',
    search: 'Search',
    restricted_sites_placeholder: 'Restriction to web sites',
    restricted_sites: 'Restricted sites',
    show_thumbnail_placeholder: 'First page preview',
    person_in_image: 'Person in photo',
    taken_since: 'Captured from',
    taken_until: 'Captured to',
    photo_series: 'Photo series',
    pdf: {
      taken_since: 'Created from',
      taken_until: 'Created to'
    }
  },
  selected_photos_count: 'Selected photos count',
  selected: 'Selected:',
  display_type_compact: 'Compact view',
  display_type_full: 'Full view',
  load_more: 'Load more',
  asset_image_info: 'Photo detail',
  asset_pdf_info: 'PDF detail',
  dam_generic_info: 'DAM Generic info',
  update_asset_metadata: 'Update metadata',
  system_info: 'System info',
  article_reference: 'Article usage',
  metadata_bulk_edit: 'Metadata bulk edit',
  id: 'Id',
  filename: 'Filename',
  file_location: 'File location',
  created: 'Uploaded to DAM',
  upload_source: 'Upload_source',
  restricted_sites: 'Restricted sites',
  person_in_image: 'Person in image',
  size: 'Size',
  dimensions: 'Dimensions',
  datetime_original: 'Capture date',
  datetime_original_pdf: 'Creation date',
  category: 'Category',
  published: 'Published',
  brand_safety: 'Brand safety',
  location_show: 'Location show',
  metadata: 'Metadata',
  headline: 'Headline',
  headline_seo_info: '<b>Images</b><br>The title of the image should describe in one sentence what the image actually shows.',
  author_exif_metadata_info: '<b>Author</b> is loaded from the file\'s EXIF metadata in this order:<br> <b>\'By-line\'</b> or<br> <b>\'Author\'</b> or<br> <b>\'Creator\'</b> or<br> <b>\'Artist\'</b> or<br> <b>\'Credit\'</b>',
  existingImage: {
    title: 'This image already exists, do you want to keep the original image information?',
    warningAndInfo: 'Some files failed to upload and some uploaded files already exist. In the next step, you will be able to choose between the new and original information of the uploaded files.',
    yesButton: 'Yes, keep the original information',
    noButton: 'No, load the new information (from uploaded image or entered by me)'
  },
  author: 'Author',
  caption: 'Caption',
  content: 'Content',
  content_load: 'Load content',
  copy_id: 'Copy ID',
  caption_writer: 'Caption writer',
  city: 'City',
  country: 'Country',
  country_code: 'Country code',
  description: 'Description (displayed description on web)',
  description_short: 'Description',
  imageAltText: 'Alternative text',
  keywords: 'Keywords',
  credit: 'Credit',
  rights: 'Rights',
  source: 'Source',
  photo_album: 'Photo album',
  added_to_album: 'Added to photo album',
  image_was_added_to_album: 'The image "{fileName}" was automatically added to the photo album "{albumName}"',
  delete_modal_header: 'Delete this {{recordTypeName}}?',
  delete_more_modal_image: 'images',
  delete_more_modal_documents: 'documents',
  delete_more_modal_header: 'Do you really want to delete all selected {{recordTypeName}}? This action cannot be undone.',
  delete_more_modal_body: '<p>Naozaj si želáte vymazať {{recordTypeName}} z Eagle CMS? Táto operácia je nezvratná a {{recordTypeName}} budú vymazané z DAM a zo všetkých článkov, kde sú použité.</p>' +
    '<p><strong>Táto operácia sa dotkne týchto článkov.</strong> V rámci korektnosti, prosím, informujte autorov článkov, že dané {{recordTypeName}} boli zmazané!</p>',
  delete_modal_close: 'Close',
  delete_modal_yes: 'Yes, delete this record',
  cannot_delete: 'Cannot delete an image that is used somewhere.',
  crop_modal_header: 'Crop photo',
  info_modal_header: 'Primary information',
  crop_modal_face_detect: 'Face detect',
  crop_modal_save: 'Save ROI/crop',
  crop_modal_was_set: 'ROI/crop was set.',
  image_check_info_max_size: 'Obrázok presahuje maximálne povolenú veľkosť 99 MB',
  image_check_info_low_quality: 'Pridaná fotografia má nízku kvalitu.',
  drop_files_here: 'Drop files here',
  rotate_plus_90: 'Rotate +90°',
  rotate_minus_90: 'Rotate -90°',
  rotate_error: 'Rotate error.',
  also_update_dam_asset_metadata: 'Save also metadata for the image in DAM',
  aspect_ratio_will_be_used: '{aspectRatio} aspect ratio will be used',
  sensitive_content: 'Sensitive content',
  download_original: 'Download original',
  download_successful: 'Original successfully downloaded',
  insert_into_article_hero: 'Insert as main image',
  insert_into_article_listing: 'Insert into HP / rubric',
  insert_into_article_body_block: 'Insert into article body',
  insert_into_gallery: 'Insert into gallery',
  create_gallery_insert_into_article: 'Create gallery and insert into article',
  asset_exists_in_gallery: 'Asset {title} already exists in gallery and will not be added',
  public_info: 'Public gallery is accessible for whole NMH after joined article is published, non-public is accessible for the concrete brand.'
}

export default messages
