import { dateTimeNow } from '../../services/DateTimeService'

export default {
  id: null,
  variableSymbol: '',
  email: '',
  price: '',
  product: '',
  payment: '',
  paymentResult: '',
  notice: '',
  cardNumber: '',
  count: '',
  sameDeliveryAddress: '',
  feUser: '',
  feUserExpanded: {},
  subscriptionStatus: {
    mailSent: 0
  },
  address: {
    type: '',
    name: '',
    surname: '',
    company: '',
    ico: '',
    dic: '',
    icDph: '',
    street: '',
    city: '',
    zip: '',
    country: ''
  },
  deliveryAddress: {
    type: '',
    name: '',
    surname: '',
    company: '',
    ico: '',
    dic: '',
    icDph: '',
    street: '',
    city: '',
    zip: '',
    country: ''
  },
  aboPeriodik: {
    customerId: ''
  },
  abo: {
    idObj: '',
    pcZakaz: ''
  },
  giftSubscription: 0,
  aboStartEdition: 0,
  latestHistory: [],
  latestPayments: [],
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  createdBy: 0,
  modifiedBy: 0
}
