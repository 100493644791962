export default {
  feUser: '',
  subscription: '',
  createdAtFrom: '',
  createdAtTo: '',
  cancelledAtFrom: '',
  cancelledAtTo: '',
  subscriptionActiveSince: '',
  subscriptionActiveUntil: '',
  product: '',
  subscriptionCode: '',
  userEmail: '',
  id: '',
  firstOrder: 'none',
  uniqueEmail: 'none'
}
