const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.name) {
    filterQuery += '&filter_contains[name]=' + filter.name
  }
  return filterQuery
}
const buildUsagesQuery = function (testId) {
  return '&filter_eq[articleTest]=' + testId
}
export default {
  buildFilterQuery,
  buildUsagesQuery
}
