const messages = {
  universal: {
    yesOrNoArea: {
      yes: 'Áno',
      no: 'Nie'
    }
  }
}

export default messages
