const messages = {
  list: {
    id: 'Id',
    server: 'Server',
    title: 'Název kvízu',
    type: 'Pravidla',
    loggedInUsersOnly: 'Pouze přihlášení'
  },
  filter: {
    id: 'Id',
    server: 'Server',
    title: 'Název kvízu'
  },
  id: 'Id',
  server: 'Server',
  title: 'Název kvízu',
  description: 'Text kvízu',
  type: 'Pravidla',
  image: 'Obrázek kvízu',
  resultsHeader: 'Hodnocení',
  resultTitle: 'Text hodnocení',
  addResult: 'Přidat hodnocení',
  resultScore: 'Body',
  displayCorrectAnswersImmediatelyLabel: 'Kdy zobrazit správné odpovědi?',
  displayCorrectAnswersImmediatelyLabelFalse: 'Po dokončení',
  displayCorrectAnswersImmediatelyLabelTrue: 'Ihned',
  loggedInUsersOnlyLabel: 'Pouze pro přihlášené uživatele',
  loggedInUsersOnlyLabelFalse: 'Ne',
  loggedInUsersOnlyLabelTrue: 'Ano',
  enabled: 'Aktivovat',
  questionsEmptyError: 'Kvíz musí mít alespoň 1 otázku',
  quizType: {
    correctAnswer: 'Položka může být správná nebo chybná',
    points: 'Každá položka má bodovou hodnotu'
  },
  question: {
    add: 'Přidat otázku',
    header: 'Otázky',
    title: 'Text otázky',
    image: 'Obrázek otázky',
    article: 'Článek s nápovědou'
  },
  answer: {
    add: 'Přidat odpověď',
    header: 'Odpovědi',
    title: 'Text odpovědi',
    points: 'Body',
    pointsTrue: 'pravda',
    pointsFalse: 'nepravda'
  },
  articleModal: {
    selectButton: 'Vybrat článek',
    header: 'Vybrat článek'
  }
}

export default messages
