const buildFilterQuery = function (filter) {
  var filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.site && filter.site > 0) {
    filterQuery += '&filter_eq[site]=' + filter.site
  }
  if (filter.sourceUrl) {
    filterQuery += '&filter_contains[sourceUrl]=' + filter.sourceUrl
  }
  if (filter.destinationUrl) {
    filterQuery += '&filter_contains[destinationUrl]=' + filter.destinationUrl
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
