import PermissionService from '@/services/PermissionService'
import Config from '@/config'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.POLL_PERMISSIONS

const routes = [
  {
    path: 'poll/new',
    component: () => Config.isVlm()
      ? import('../../views/poll/vlm/PollNewViewVlm')
      : import('../../views/poll/PollNewView'),
    name: 'poll_new',
    meta: {
      description: 'Poll new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'poll/:id/edit',
    component: () => Config.isVlm()
      ? import('../../views/poll/vlm/PollEditViewVlm')
      : import('../../views/poll/PollEditView'),
    name: 'poll_edit',
    meta: {
      description: 'Poll edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'poll/:id',
    component: () => Config.isVlm()
      ? import('../../views/poll/vlm/PollViewVlm')
      : import('../../views/poll/PollView'),
    name: 'poll_detail',
    meta: {
      description: 'Poll detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'poll',
    component: () => Config.isVlm()
      ? import('../../views/poll/vlm/PollListViewVlm')
      : import('../../views/poll/PollListView'),
    name: 'poll_list',
    meta: {
      description: 'Poll list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  },
  {
    path: 'pollManagement',
    component: () => import('../../views/poll/PollManagementView'),
    name: 'poll_management',
    meta: {
      description: 'Poll management',
      requiresAuth: true,
      requiredPermissionModules: PermissionService.REQUIRED_PERMISSIONS.POLL_MANAGEMENT_PERMISSIONS.listRoute
    }
  }
]

export default routes
