export default {
  id: null,
  title: '',
  description: '',
  articleCount: 0,
  galleryCount: 0,
  slug: '',
  type: '',
  additionalInfo: {
    event: {
      startDate: null,
      endDate: null,
      geo: null,
      region: null,
      district: null,
      country: null,
      address: null
    },
    location: {
      type: null,
      geo: null,
      population: null,
      region: null,
      district: null,
      country: null,
      address: null
    },
    organisation: {
      alternateName: null,
      region: null,
      district: null,
      country: null,
      address: null
    },
    person: {
      hasOccupation: null,
      givenName: null,
      birthDate: null,
      birthPlace: null,
      familyName: null,
      deathDate: null,
      deathPlace: null
    },
    product: {
      manufacturer: null,
      model: null
    }
  },
  routes: []
}
