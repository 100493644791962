import { cloneDeep } from 'lodash'
import { KpiData } from './Kpi'

export default {
  target: cloneDeep(KpiData),
  current: cloneDeep(KpiData),
  completion: cloneDeep(KpiData),
  median: cloneDeep(KpiData),
  medianCompletion: cloneDeep(KpiData)
}
