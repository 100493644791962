import UserLogout from '../../views/app/AppLogoutView'

const routes = [
  {
    path: 'userLogout',
    component: UserLogout,
    name: 'userLogout',
    meta: {
      description: 'User logout',
      requiresAuth: true
    }
  }
]

export default routes
