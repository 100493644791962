const messages = {
  filter: {
    id: 'Id',
    title: 'Titulok',
    name: 'Názov'
  },
  list: {
    id: 'Id',
    title: 'Titulok',
    name: 'Názov'
  },
  id: 'Id',
  title: 'Titulok',
  name: 'Názov',
  description: 'Popis',
  address: 'Adresa',
  rating: 'Hodnotenie',
  stars: 'Počet hviezd',
  yearOfReview: 'Rok recenzie',
  image: 'Obrázok',
  phone: 'Telefón',
  website: 'Web',
  priceLevel: 'Cenová úroveň',
  pros: 'Plusy',
  cons: 'Mínusy'
}

export default messages
