const messages = {
  title: 'Nastavenie váh',
  add: 'Pridať zobrazenie',
  save: 'Uložiť',
  group: 'Skupina',
  name: 'Názov',
  printQuality: 'Kvalita Printu',
  online: 'Kvalita Online',
  subscriptions: 'subscriptions',
  socialEngagement: 'socialEngagement',
  videoQuality: 'Kvalita Videa',
  articlesQuality: 'articlesQuality',
  videoQuantity: 'Kvantita Videa',
  tableListing: {
    printQuality: {
      printQualityA: 'Kvalita Printu A',
      printQualityB: 'Kvalita Printu B',
      printQualityC: 'Kvalita Printu C'
    },
    online: {
      pageViews: 'Page Views',
      engagedPageView: 'Engaged Page Views'
    },
    subscriptions: {
      subscriptions: 'Subscriptions'
    },
    socialEngagement: {
      disqus: 'Dsiqus',
      fbShares: 'Facebook Shares'
    },
    videoQuality: {
      videoViews: 'Video Views',
      videoPlaythrough: 'Video Playthrough'
    },
    articlesQuality: {
      printQuantityP1Author: 'printQuantityP1Author',
      printQuantityP1Agency: 'printQuantityP1Agency',
      printQuantityP2Author: 'printQuantityP2Author',
      printQuantityP2Agency: 'printQuantityP2Agency',
      onlineAuthor: 'onlineAuthor',
      fromPrint: 'From Print',
      agency: 'Agenturny',
      republished: 'Prepublikovany',
      quiz: 'Quiz',
      photostory: 'Fotostory'
    },
    videoQuantity: {
      videoInternal: 'Video - interne',
      videoSlideshow: 'Video - slideshow',
      videoSocial: 'Video - social',
      videoAgency: 'Video - agenturne'
    }
  }
}

export default messages
