export default {
  productId: '',
  month: 0,
  year: 0,
  name: '',
  printQualityA: 0.0,
  printQualityB: 0.0,
  printQualityC: 0.0,
  pageViews: 0.0,
  engagedPageView: 0.0,
  subscriptions: 0.0,
  disqus: 0.0,
  fbShares: 0.0,
  videoViews: 0.0,
  videoPlaythrough: 0.0,
  printQuantityP1Author: 0.0,
  printQuantityP1Agency: 0.0,
  printQuantityP2Author: 0.0,
  printQuantityP2Agency: 0.0,
  onlineAuthor: 0.0,
  fromPrint: 0.0,
  agency: 0.0,
  republished: 0.0,
  quiz: 0.0,
  photostory: 0.0,
  videoInternal: 0.0,
  videoSlideshow: 0.0,
  videoSocial: 0.0,
  videoAgency: 0.0
}
