const messages = {
  byAverage: 'PV a VV podľa priemeru',
  kpi_settings: 'Nastavenie KPI',
  kpi_settings_department: 'Nastavenie KPI pre stredisko',
  kpi_basic_view: 'Porovnanie s cieľom',
  kpi_averages_view: 'Porovnanie s priemerom',
  kpi_users_view: 'Zobrazenie strediska podľa autorov',
  kpi_type: 'Typ KPI',
  vs_kpi: 'vs. KPI',
  department_overall: 'Spolu',
  department: 'Oddelenie',
  product: 'Produkt',
  comparison_week: 'Týždeň',
  kpi_current: 'Reálne plnenie',
  kpi_target: 'Nastavene KPI',
  kpi_percentage: 'Percentuálne plnenie KPI',
  kpi_median: 'Median odd.',
  kpi_fulfilment: 'Plnenie KPI',
  articles_breakdown: 'Typy článkov',
  viewsStructure: 'Štruktúra PV - Typ obsahu',
  print_articles: 'Printové články',
  digital_articles: 'Online články',
  videos: 'Videá',
  video_types: 'Typy Videí',
  performance: 'Výkonnosť',
  weights_type: 'Typ váh',
  product_performance: 'Výkonnosť produktu',
  department_performance: 'Výkonnosť oddelenia',
  base_data: 'Podkladové dáta',
  user_data_not_found: 'Pre daného autora vo vybratom čase neexistujú údaje.',
  number_of_records: 'Počet záznamov:',
  info_mark: '*normalizované PV - prepočet PV podľa rozdielu monetizačného potenciálu medzi článkovým a galériovým PV, v pomere: <b>1 článkové PV= 14 galériových PV </b>',
  groupPerformance: {
    orderNumber: 'Poradie',
    userName: 'Autor',
    departmentName: 'Oddelenie',
    printQuality: 'Kvalita Print článkov',
    onlineQuality: 'Kvalita Online článkov',
    subscriptions: 'Subscriptions',
    socialEngagement: 'Social engagement',
    videoQuality: 'Video kvalita',
    articlesQuantity: 'Kvantita článkov',
    videoQuantity: 'Video kvantita',
    nativeQuantity: 'Native quantity',
    total: 'Výkonnosť'
  },
  kpi_parameters_tooltip: {
    articles: 'Počet vypublikovaných článkov za vybrané obdobie',
    photostory: '',
    quiz: '',
    images: '',
    videos: '',
    pageViews: '',
    pageViewsNormalized: 'Počet PV na článkoch, ktoré boli vypublikované v zvolenom období, nie PV z historických a nečlánkových šablón',
    videoViews: 'Počet VV na videách v článkoch, ktoré boli vypublikované v zvolenom období, nie VV z historických a nečlánkových šablón',
    videoArticles: '',
    avgDailyUsers: '',
    avgTimeSpentPerUserArticle: '',
    avgArticleCompletion: '',
    avgTimeSpentPerUserGallery: '',
    avgGalleryCompletion: '',
    avgDailyUsersOrganic: ''
  },
  kpi_parameters: {
    articles: 'Počet článkov spolu',
    photostory: 'Počet fotostory',
    quiz: 'Počet kvízov',
    images: 'Počet obrázkov',
    videos: 'Počet uploadnutých videí',
    pageViews: 'Zobrazenia sumár (PVs)',
    pageViewsNormalized: 'Zobrazenia PV (*normalizované)',
    videoViews: 'Zobrazenia videí (VVs)',
    videoArticles: 'Pokrytie článkov videom',
    avgDailyUsers: 'Priemerný počet užívateľov (RUs)',
    avgTimeSpentPerUserArticle: 'Priemerný čas strávený v článku',
    avgArticleCompletion: 'Priemerné dočítanie článku',
    avgTimeSpentPerUserGallery: 'Priemerný čas strávený v galérii',
    avgGalleryCompletion: 'Priemerná dopozeranosť galérie',
    avgDailyUsersOrganic: 'Priemerná denná organická návštevnosť'

  },
  realTimeStats: {
    pageViews: 'PageViews',
    realUsers: 'Real Users',
    videoViews: 'VideoViews ',
    avgTimeSpent: 'Priemerne strávený čas (ATS)'
  },
  performanceItems: {
    pageViewCnt: 'Zobrazenia stránky',
    imagesCnt: 'Obrázky',
    videosCnt: 'Videá',
    fbShares: 'FB zdielania',
    disqusComments: 'Disqus komentar',
    videoViewCnt: 'Zobrazenia videí',
    photostoryCnt: 'Fotostory',
    quizCnt: 'Kvíz',
    videoCnt: 'Video',
    internalCnt: 'Interné',
    externalCnt: 'Externé',
    nativeCnt: 'Natívne',
    prCnt: 'PR',
    editorialCnt: 'Redakčné',
    galleryViewCnt: 'Zobrazenia galérií',
    viewCnt: 'Zobrazenia sumár',
    engagedTime: 'Engaged time',
    articleCnt: 'Články',
    articleEditCnt: 'Úpravy článkov'
  },
  toolTip: {
    clusterDesc: '<b>Klaster</b> je kategorizovaný súbor titulov',
    weekSelect: '<b>Výber kalendárneho týždňa</b><br>Vyberte dátum v týždni pre ktorý sa má zobraziť štatistika. Vyberie sa vždy kalendárny týždeň ohraničený prvým, alebo posledným dňom mesiaca pokiaľ sa takýto deň v týždni nachádza.',
    orderNumber: 'Poradie je definované stĺpcom výkonnosť',
    printQuality: 'Rozdelenie print článkov na 3 kategórie: A/B/C',
    onlineQuality:
      `<b>Kombinácia základných metrík:</b><br>
      Celkové normalizované PV<br>
      Počet užívateľov<br>
      Čas strávený v článku<br>
      Dočítanosť článku<br>
      Čas strávený v galérii<br>
      Dopozeranosť galérie<br>
      Organická návštevnosť`,
    subscriptions: 'Počet/hodnota nákupov predplatného online (zatiaľ iba Trend)',
    socialEngagement:
      `<b>Kombinácia základných metrík:</b><br>
      Počet komentárov,<br>
      Počet zdieľaní na FB`,
    videoQuality:
      `<b>Kombinácia základných metrík:</b><br>
      Počet prehratí videa,<br>
      Prehratia nad polovicu dĺžky videa`,
    articlesQuantity: 'P1/P2 autorský a agentúrny, Online autorský, preklopený z printu, agentúrny, kópia z iného titulu, Quiz a Fotostory',
    videoQuantity: 'Počet videí typu: Autorské, Slideshow, Social, Agentúrne',
    total: 'Hodnotenie celkovej výkonnosti autora',
    pageViewsCnt: 'Počet zobrazení stránky (PageViews s GalleryViews)',
    videosCnt: 'Počet videí v článku',
    printType: 'P1/P2 autorský, P1/P2 agentúrny',
    actionArticleDetail: 'Zobrazenie detailu článku',
    suggestedValue: 'Hodnota je vypočítaná na základe premieru za okno predchádzajúcich 12 mesiacov',
    nmh_kpi_october_2024_adjustment_message: `Z dôvodu rekonštrukčných prác na Eagle Admin CMS sa<br>
      <b>NMH KPIs za Október 2024 nezarátavajú do ročného hodnotenia.</b><br>
      Budget KPIs sú plošne znížené o 30% a majú iba informatívny charakter.<br>`
  },
  weightsSelect: 'Výber nastavenia váh',
  timeGroupType: 'Typ reportu',
  month: 'Mesiac',
  week: 'Týždeň',
  monthly: 'Mesačný',
  weekly: 'Týždenný',
  language: 'sk',
  save: 'Uložiť',
  sumError: 'Súčet hodnôt editorov musí byť rovný hodnote za celé oddelenie',
  difference: 'Rozdiel',
  add: 'Pridať',
  remove: 'Odobrať',
  user: 'Používateľ',
  author: 'Autor',
  viewsStructureByDepartment: 'Štruktúra PV - po oddeleniach',
  cluster: 'Klaster',
  dateFrom: 'Od',
  dateTo: 'Do',
  average: 'PRIEMER',
  department_average: 'Priemer oddelenia',
  notAvailable: 'N/A',
  weekShort: 't',
  gcpData: 'GCP Data',
  historicalPerformanceButton: {
    pageViews: 'PVs na reportéra',
    videoViews: 'VVs na reportéra',
    onlineArticles: 'Autorské články na reportéra',
    p1Articles: 'Print P1 na reportéra',
    p2Articles: 'Print P2 na reportéra',
    publishedVideos: 'Vypublikované videá na reportéra',
    createdVideos: 'Uploadnuté videá na reportéra',
    imagesInArticles: 'Použité obrázky na reportéra'
  },
  clusterViewMetricsDescription: {
    pageViews: 'Priemer počtu zobrazení stránky na reportéra',
    videoViews: 'Priemer počtu prehratí Videa na reportéra',
    onlineArticles: 'Priemer počtu vypublikovaných Autorských článkov na reportéra',
    p1Articles: 'Priemer počtu P1 Printových článkov na reportéra',
    p2Articles: 'Priemer počtu P2 Printových článkov na reportéra',
    publishedVideos: 'Priemer počtu publikovaných videí na reportéra',
    createdVideos: 'Priemer počtu vytvorených videí na reportéra',
    imagesInArticles: 'Priemer počtu obrázkov použitých v článkoch na reportéra'
  },
  departmentViewMetricsDescription: {
    pageViews: 'Počet zobrazení stránky na reportéra',
    videoViews: 'Počet prehratí Videa na reportéra',
    onlineArticles: 'Počet vypublikovaných Autorských článkov na reportéra',
    p1Articles: 'Počet P1 Printových článkov na reportéra',
    p2Articles: 'Počet P2 Printových článkov na reportéra',
    publishedVideos: 'Počet publikovaných videí na reportéra',
    createdVideos: 'Počet vytvorených videí na reportéra',
    imagesInArticles: 'Počet obrázkov použitých v článkoch na reportéra'
  },
  source: 'Zdroj: Eagle-DON',
  productArticlesComposition: 'Zloženie článkov po produktoch',
  galleries: 'Galérie',
  articles: 'Články',
  period: 'Obdobie',
  weightsSelection: 'Výber nastavania váh',
  suggestedValue: 'Navrhovaná hodnota',
  allProducts: 'Celé {company}',
  longLoadingWarning: 'Načítanie dát môže trvať až 5 minút. Počas načítavania neodchádzajte zo stránky.',
  ai: {
    articleCountTitle: 'Počet článkov - Asistencia AI',
    pageViewsCountTitle: 'Štruktúra PV - Asistencia AI',
    articleSourceTitle: 'Zdroj článkov - Asistencia AI',
    withAi: 'S AI',
    withoutAi: 'Bez AI',
    articleSourceAuthor: 'Interný autor pre web',
    articleSourceAuthorAi: 'Interný autor pre web s AI',
    articleSourceAgency: 'Agentúrny',
    articleSourceAgencyAi: 'Agentúrny s AI',
    articleSourceFromPrint: 'Preklopené z printu',
    articleSourceFromPrintAi: 'Preklopené z printu s AI',
    articleSourceTransferred: 'Prebratý',
    articleSourceTransferredAi: 'Prebratý s AI'
  }
}

export default messages
