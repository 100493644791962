const messages = {
  text: 'Text',
  text_placeholder: 'Write title',
  link: 'Link',
  link_placeholder: 'Link to page (https://...)',
  panel_positions: 'Panel positions',
  created_date: 'Created date',
  changed_date: 'Changed date',
  sites_label: 'Publish on Journals (overwrites currently published panels at given position)',
  image_label: 'Panel image',
  list: {
    id: 'ID',
    text: 'Text',
    position: 'Position',
    from: 'From',
    to: 'To',
    state: 'State'
  },
  filter: {
    select_site_label: 'Select journal'
  },
  create_panel_label: 'Create panel',
  switch: {
    graphics_label: 'Graphics',
    text_label: 'Text'
  },
  drag_label: 'Drag',
  state_active: 'Active',
  state_inactive: 'Inactive',
  active_panels: 'Active panels',
  inactive_panels: 'Inactive panels'
}

export default messages
