const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.poll) {
    filterQuery += '&filter_contains[poll]=' + filter.poll
  }
  if (filter.pollOption) {
    filterQuery += '&filter_contains[pollOption]=' + filter.pollOption
  }
  if (filter.feUser) {
    filterQuery += '&filter_eq[feUser]=' + filter.feUser
  }
  if (filter.feUser_email) {
    filterQuery += '&filter_custom[feUser.email]=' + filter.feUser_email
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
