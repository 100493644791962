const messages = {
  filter: {
    complete: 'Dokončené',
    fulltext_search: 'Vyhľadávanie',
    fulltext_search_placeholder: 'Hľadať všade (titulok, popis, kľúčové slová)',
    created_since: 'Nahrané od',
    created_until: 'Nahrané do',
    description: 'Popis (zobrazený popis na webe)',
    keywords: 'Kľúčové slová',
    author: 'Autor',
    id: 'Id',
    reset_filter: 'Zrušiť filter',
    upload_source: 'Zdroj nahratia',
    orientation: 'Orientácia',
    orientation_HRZN: 'Na šírku',
    orientation_VRTC: 'Na výšku',
    orientation_SQRE: 'Štvorcové',
    size: 'Veľkosť',
    size_gt: 'Väčšie ako {size}MB',
    resolution: 'Rozlíšenie',
    resolution_gt: 'Väčšie ako {resolution} Mpx',
    created_by: 'Nahral',
    show_by: 'Zobraziť podľa',
    show_by_upload_date: 'Dátum nahratia do DAM',
    show_by_original_date: 'Dátum nasnímania',
    search: 'Hľadať',
    restricted_sites_placeholder: 'Obmedzenie na weby',
    restricted_sites: 'Povolené použitie',
    show_thumbnail_placeholder: 'Zapnúť náhľad prvej stránky',
    person_in_image: 'Človek na obrázku',
    taken_since: 'Nasnímané od',
    taken_until: 'Nasnímané do',
    photo_series: 'Séria fotografií',
    pdf: {
      taken_since: 'Vytvorené od',
      taken_until: 'Vytvorené do'
    }
  },
  selected_photos_count: 'Počet vybraných fotiek',
  selected: 'Vybrané:',
  display_type_compact: 'Kompaktné zobrazenie',
  display_type_full: 'Plné zobrazenie',
  load_more: 'Načítať viac',
  asset_image_info: 'Detail fotky',
  asset_pdf_info: 'Detail PDF',
  dam_generic_info: 'DAM Všeobecné informácie',
  update_asset_metadata: 'Upraviť informácie',
  system_info: 'Systémové informácie',
  article_reference: 'Použitie v článkoch',
  metadata_bulk_edit: 'Hromadná editácia metadát',
  id: 'Id',
  filename: 'Meno súboru',
  file_location: 'Umiestnenie súboru',
  created: 'Nahrané do DAM',
  upload_source: 'Zdroj nahratia',
  restricted_sites: 'Povolené použitie',
  person_in_image: 'Človek na obrázku',
  size: 'Veľkosť',
  dimensions: 'Rozmery',
  datetime_original: 'Dátum nasnímania',
  datetime_original_pdf: 'Dátum vytvorenia',
  location_show: 'Zobrazenie polohy',
  category: 'Kategória',
  published: 'Publikované',
  brand_safety: 'Brand safety',
  metadata: 'Metadata',
  headline: 'Titulok',
  headline_seo_info: '<b>Obrázky</b><br>Názov obrázku by mal jednou vetou popisovať to, čo obrázok reálne zobrazuje.',
  author_exif_metadata_info: '<b>Autor</b> sa načíta zo súboru z EXIF metadát v tomto poradí:<br> <b>\'By-line\'</b> alebo<br> <b>\'Author\'</b> alebo<br> <b>\'Creator\'</b> alebo<br> <b>\'Artist\'</b> alebo<br> <b>\'Credit\'</b>',
  existingImage: {
    title: 'Obrázok už existuje, chcete ponechať pôvodné informácie o obrázku?',
    warningAndInfo: 'Niektoré súbory sa nepodarilo nahrať a niektoré nahrané súbory už existujú. V ďalšom kroku budete môcť vybrať medzi novými a pôvodnými informáciami nahraných súborov.',
    yesButton: 'Áno, ponechať pôvodné informácie',
    noButton: 'Nie, načítať nové informácie (z nahrávaného obrázku alebo mnou zadané)'
  },
  author: 'Autor',
  caption: 'Nadpis',
  content: 'Obsah',
  content_load: 'Načítať obsah',
  copy_id: 'Kopírovať ID',
  caption_writer: 'Autor titulku',
  city: 'Mesto',
  country: 'Krajina',
  country_code: 'Kód krajiny',
  description: 'Popis (zobrazený popis na webe)',
  description_short: 'Popis',
  imageAltText: 'Alternatívny text',
  keywords: 'Kľúčové slová',
  credit: 'Kredit',
  rights: 'Práva',
  source: 'Zdroj',
  photo_album: 'Séria fotografií',
  added_to_album: 'Pridané do série fotografií',
  image_was_added_to_album: 'Obrázok "{fileName}" bol automaticky pridaný do série fotografií "{albumName}"',
  delete_modal_header: 'Odstrániť tento záznam?',
  delete_more_modal_image: 'fotky',
  delete_more_modal_documents: 'dokumenty',
  delete_more_modal_header: 'Vymazať {recordTypeName}',
  delete_more_modal_body: 'Naozaj si želáte vymazať {recordTypeName} z Eagle CMS? Táto operácia je nezvratná a {recordTypeName} budú vymazané z DAM a zo všetkých článkov, kde sú použité.<br>' +
    '<b>Táto operácia sa dotkne týchto článkov.</b> V rámci korektnosti, prosím, informujte autorov článkov, že dané {recordTypeName} boli zmazané!',
  delete_modal_close: 'Nie',
  delete_modal_yes: 'Áno',
  cannot_delete: 'Nie je možné vymazať obrázok, ktorý je niekde použitý.',
  info_modal_header: 'Základné informácie',
  crop_modal_header: 'Orezať fotku',
  crop_modal_face_detect: 'Detekcia tváre',
  crop_modal_save: 'Uložiť bod záujmu/orez',
  crop_modal_was_set: 'Bod záujmu/orez bol nastavený.',
  image_check_info_max_size: 'Obrázok presahuje maximálne povolenú veľkosť 99 MB.',
  image_check_info_low_quality: 'Fotografia má nízku kvalitu.',
  drop_files_here: 'Drop files here',
  rotate_plus_90: 'Otočiť o +90°',
  rotate_minus_90: 'Otočiť o -90°',
  rotate_error: 'Chyba pri otáčaní.',
  also_update_dam_asset_metadata: 'Uložiť metadáta aj k obrázku v DAM',
  aspect_ratio_will_be_used: 'Bude použitý rozmer {aspectRatio}',
  sensitive_content: 'Citlivý obsah',
  download_original: 'Stiahnuť originál',
  download_successful: 'Originál úspešne stiahnutý',
  insert_into_article_hero: 'Vložiť ako hlavný obrázok',
  insert_into_article_listing: 'Vložiť do HP / rubriky',
  insert_into_article_body_block: 'Vložiť do tela článku',
  insert_into_gallery: 'Vložiť do galerie',
  create_gallery_insert_into_article: 'Vytvoriť galériu a vložiť do článku',
  asset_exists_in_gallery: 'Fotka {title} sa uz v galerii nachádza a nebude pridaná.',
  public_info: 'Galéria bude po vypublikovaní článku dostupná pre celé vydavateľstvo NMH (do vypublikovania článku je neverejná). V prípade ak sa rozhodnete nezverejniť galériu, bude dostupná len pre redaktorov z vášho Titulu.'
}

export default messages
