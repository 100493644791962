const messages = {
  alternative_title: 'Alternativní titulek pro Seznam',
  title_seznam: 'Titulek pro Seznam',
  generateHtmlFromPrArticle: {
    btn: 'Generovať HTML štruktúru článku',
    modalTitle: 'HTML článku',
    btnCopy: 'Zkopírovat obsah'
  },
  article: 'Článek',
  foundAnError: 'Našla se chyba',
  articleListError: 'Nastala chyba při načítání článků',
  id: 'Id',
  editorSwitchBtn: {
    oldEditor: 'Přepnout na nový editor',
    newEditor: 'Přepnout na starý editor'
  },
  readAlso: 'Přečtěte si také',
  commentOnTheTopic: 'Komentář k tématu',
  comment: 'Komentář',
  relatedArticle: 'Související článek',
  review: 'Recenze',
  usedInArticle: 'Použité v článku',
  notUsedInAnyArticle: 'Nenachází se v žádném článku.',
  moreOnTheTopic: 'Více k tématu',
  document_id: 'Document Id',
  hidden: 'Skrytý',
  status: 'Stav článku',
  main_content: 'Hlavní obsah',
  article_type_title: 'Typ článku',
  internal: 'Interní',
  external: 'Externí',
  video: 'Video',
  site: 'Stránka',
  layout: 'Dizajn',
  main_rubric: 'Hlavní rubrika',
  title: 'Titulek',
  short_title: 'Titulek pro URL',
  sub_title: 'SEO titulek',
  first_paragraph: 'Perex',
  multi_drag: 'Hromadný přesun obrázků',
  show_listing_teaser: 'Jiný perex na HP / v rubrice',
  listing_teaser: 'Perex na HP / v rubrice',
  ooyala_id: 'Ooyala #ID',
  video_id: 'Video #ID',
  remove_ooyala_id: 'Vymazat Ooyala #ID',
  body_text: 'Text článku',
  body_text_seo_info: '<b>Text článku</b><br>Tělo článku by mělo být rozděleno na relativně krátké odstavce, které by měly obsahovat 5-7 řádků. Doporučujeme používat odrážky, zvýrazňovat hlavní myšlenky článku boldem, pracovat s velikostmi mezititulků/podnadpisů (H2, H3). V každém článku je podle možnosti vhodné umístit alespoň 4-5 prolinků na relevantní články z produkce NMH, označením relevantní fráze/věty/slovního spojení a vytvořením prolinku (hypertextového odkazu).',
  hero_image: 'Hlavní obrázek',
  hero_image_display_type: 'Způsob zobrazení',
  hero_image_display_type_big: 'Veľký',
  hero_image_display_type_small: 'Malý',
  hero_video: 'Hlavní video',
  hero_video_dynamic_preview: 'Dynamic preview',
  another_image_in_the_list: 'Jiný obrázek na HP / v rubrice',
  listing_image: 'Obrázek na HP / v rubrice',
  gallery: 'Galérie',
  related_articles: 'Související články',
  related_disease_articles: 'Související onemocnění',
  main_settings: 'Hlavní nastavení',
  other_rubrics: 'Rubriky',
  order_date: 'Datum publikování na webu',
  set_order_date_to_now: 'Nastav na teď',
  set_published_until: 'Nastavit vypnutí z webu',
  published_until: 'Publikovat do',
  postpone: 'Odložit změnu zveřejnění na webu',
  published_since: 'Publikovat od',
  hiddenOnHp: 'Nepublikovat na HP',
  author: 'Autor',
  authors: 'Autoři zobrazení na webu',
  main_author: 'Hlavní autor',
  safety_topics: 'Téma (brand safety)',
  selected_topics: 'Vybraná témata',
  selected_topics_no_keywords: 'Pro přiřazení témat je nutné zadat klíčová slova',
  owners: 'Vlastníci',
  sharedUsers: 'Autorský podíl',
  url: 'Url',
  urls: 'URLs',
  meta_info: 'Další nastavení',
  page_titles: 'Názvy stránek stránkovaného článku:',
  page_titles_car_test_generated_info: 'Názvy stránek pro test vozidla jsou vygenerovány automaticky: 1: Exteriér, 2: Interiér, 3: Pohon, 4: Podvozek, 5: Cena, 6: Závěr',
  page_title_add: 'Přidat název stránky',
  quiz_id: 'Quiz #ID',
  recipe_id: 'Recipe #ID',
  test_id: 'Test #ID',
  test_title: 'Test title',
  quiz_title: 'Quiz title',
  recipe_title: 'Recipe title',
  print_publication: 'Printové vydání',
  print_publication_category: 'Kategorie printového vydání',
  tags: 'Klíčová slova',
  tags_geneea: 'Geneea klíčová slova',
  tags_geneea_empty: 'Zatím žádná klíčová slova',
  tags_geneea_generate: '+ vygenerovat klíčová slova',
  tags_geneea_regenerate: '+ přegenerovat klíčová slova',
  tags_editorial: 'Redakční tagy',
  create_new_tag: 'Vytvořit nové',
  create_new_tag_message: 'Nové klíčové slovo bylo vytvořeno.',
  flags: 'Značky na webu',
  image_gallery: 'Galérie',
  exclusive: 'Exkluzivní',
  commentary: 'Komentář',
  interview: 'Rozhovor',
  online: 'Online',
  updated: 'Aktualizováno',
  updated_until_datetime: 'Aktualizováno zobrazit do',
  just_now: 'Právě se stalo',
  just_now_until_datetime: 'Právě se stalo zobrazit do',
  poll: 'Anketa',
  photostory: 'Fotostory',
  quiz: 'Kvíz',
  recipe: 'Recept',
  test: 'Test',
  longform: 'Longform',
  like_dislike: 'Líbí / Nelíbí',
  settings: 'Nastavení',
  unlocked: 'Odemčené',
  disable_comments: 'Zrušit diskusi',
  disable_transfer: 'Vyloučení z nabídky obsahu',
  pr: 'PR článek',
  native: 'Nativní článek',
  editorial_article: 'Redakční článek',
  copied_from_print: 'Překlopeno z printu',
  copyId: 'Zkopírovat ID do schránky',
  paid_content: 'Zamčený/Placený obsah',
  proofread_status: 'Proofread status',
  categories: 'Obsahové kategorie',
  video_type: 'Typ videa',
  system_settings: 'Systémová nastavení',
  article_history: 'Historie článku',
  article_history_not_found: 'Bez historie.',
  time: 'Čas',
  type: 'Typ',
  user: 'Uživatel',
  first_published_date: 'Publikováno',
  migrated_system: 'Migrated system',
  migrated_id: 'Migrated id',
  migrated_remote_id: 'Migrated remote id',
  unlock_for_edit: 'Odemknout úpravy',
  edit: 'Upravit',
  created_at: 'Vytvořeno',
  created_by: 'Vytvořil',
  modified_at: 'Změněno',
  modified_by: 'Úpravil',
  external_url: 'URL',
  external_author: 'Autor',
  external_site_name: 'Jméno stránky',
  external_site_url: 'URL adresa stránky',
  external_rubric_name: 'Název rubriky',
  external_rubric_url: 'URL adresa rubriky',
  last_modified: 'Poslední editace',
  version_info_published: 'Článek má také publikovanou verzi. Otevřít',
  version_info_draft: 'Článek má také verzi v konceptu. Otevřít',
  url_link: 'URL',
  url_gallery_link: 'Galerie',
  last_change_info_edited: 'upravil',
  activity_is_editing: 'upravuje článek!',
  antares_info: 'Statistiky',
  antares_visits: 'Zobrazení článku',
  antares_info_not_available: 'Statistika není k dispozici',
  title_is_too_short: 'Titulek je krátký',
  title_is_too_long: 'Titulek je příliš dlouhý',
  short_title_is_too_short: 'Titulek pro URL je krátký',
  short_title_is_too_long: 'Titulek pro URL je příliš dlouhý',
  seo_title_invalid: 'Titulek musí obsahovat {min} až {max} znaků',
  seo_title_seo_info: '<b>SEO Titulek</b><br>SEO titulek by měl mít délku {min}-{max} znaků, začínat by měl velkým písmenem primárního klíčového slova nebo kombinace slov (například. Módní trendy 2020, Fico na dovolené). V SEO titulcích je třeba se vyhýbat přívlastkům jako Neuvěřitelné, Šokující - zkrátka slovům, která nemají výpovědní hodnotu. SEO titulek by měl krátce a výstižně popisovat, o čem je daný článek. ',
  first_paragraph_is_too_short: 'Perex je krátký',
  first_paragraph_is_too_long: 'Perex je příliš dlouhý',
  first_paragraph_seo_info: '<b>Perex - popis článku</b><br>Délka ideální do 160 znaků. Nemá přímý efekt na SEO, ale výrazně zvyšuje míru prokliku na stránku – zobrazuje se totiž u našich článků ve výsledcích vyhledávačů. Tak by také měl být koncipován, nabádat čtenáře ke kliknutí.',
  status_draft: 'Koncept',
  status_review: 'Ke schválení',
  status_ready: 'Naplánován',
  status_published: 'Publikován',
  status_expired: 'Expirován',
  status_draft_create: 'Nový koncept',
  status_draft_update: 'Úprava konceptu',
  status_draft_delete: 'Koncept smazán',
  status_draft_to_review: 'Poslán ke kontrole',
  status_review_to_ready: 'Poslán k publikování',
  status_review_to_draft: 'Stažen do konceptu',
  status_ready_to_published: 'Vypublikován',
  status_ready_to_draft: 'Stažen do konceptu',
  status_published_to_draft: 'Odpublikován do konceptu',
  nature_of_the_article: 'Povaha článku',
  source_type: 'Zdroj článku',
  source_type_internal_author: 'Interní autor pro web',
  source_type_agency_article: 'Agenturní',
  source_type_external_author: 'Externí autor pro web',
  source_type_transfered_article: 'Převzatý článek',
  source_type_ai_assisted: '🤖 S asistencí AI',
  ai_assisted_tooltip: 'Označte 1 nebo více nástrojů, které jste při tvorbě článku použili. Pokud jste použitý nástroj nenašli v seznamu, vepište jej do položky "Jiné". <br>Využití funkcí s AI, které jsou integrovány přímo v CMS, je zaznamenáváno automaticky.',
  auto_logout_text: 'Vaše práce byla uložena a byli jste odhlášeni z článku',
  doctors: 'Odborní garanti/lékaři',
  gardeners: 'Odborní garanti/zahrádkáři',
  flag: {
    counseling_title: 'Článek má poradnu'
  },
  ai_service: {
    status_ai_recommended: 'DOPORUČENÉ',
    generate_from_ai: 'Generovat',
    generate_from_ai_again: 'Zavřít a generovat znovu',
    ai_generated: 'Doporučená klíčová slova. Prosím, vyberte:',
    show_generated: 'Zobrazit vygenerované návrhy',
    select_generated: 'Vyberte 1 z doporučených perexů (později jej můžete upravit)',
    replace: 'Použít jako',
    add_after: 'Přidat za',
    fill_in_the_title: 'Vyplnte pole Titulek',
    not_enough_chars: 'Přidejte ještě {charsLeft} znaků do Textu článku',
    no_generated_results: 'Nepodařilo se vygenerovat žádné výsledky.',
    recommended_articles_error_title: 'Je nám líto, ale momentálně nemůžeme zobrazit související články.',
    recommended_articles_error_body: 'Text článku je příliš krátký na to, abychom mohli poskytnout relevantní doporučení. Prosím, zkuste článek rozšířit nebo přidat více obsahu, abychom vám mohli nabídnout vhodné návrhy.',
    recommended_articles_service_unavailable: 'Je nám líto, ale momentálně nemůžeme zobrazit související články. Zkuste to znovu později.',
    ai_modal: {
      modal_title_heading: 'A/B/C testování titulků',
      modal_perex_heading: 'Perex',
      modal_keyword_heading: 'Klíčová slova',
      modal_title_hint: 'Vyberte verze B a C titulku',
      modal_perex_hint: 'Vyberte 1 z doporučených perexů (později jej můžete upravit)',
      modal_keyword_hint: 'Vyberte vhodná klíčová slova',
      modal_use_button: 'Použít v článku',
      modal_input_err_min: 'Klíčové slovo musí obsahovat min. 2 znaky.',
      modal_input_err_duplicate: 'Pole obsahuje klíčové slovo identické s jiným polem.',
      modal_related_modal_tooltip: 'Článek je doporučen algoritmem na báze umělé inteligence, který hledá nejvyšší shodu v textu mezi Vaším článkem a ostatními nedávno publikovanými články.',
      modal_socialMediaPost_heading: 'Příspěvek na sociální sítě',
      modal_socialMediaPost_hint: 'Vyberte nebo upravte příspěvek pro sociální síť.',
      modal_socialMediaPost_copy: 'Text byl zkopírován'
    },
    social_media_generator: {
      card_title: 'Sociální sítě',
      card_title_tooltip: 'Tato funkce vytvoří doporučený text pro sdílení článku na sociálních sítích. Návrh je generován z pole Titulek, takže může pracovat pouze s informacemi obsaženými v titulku článku.',
      generate_btn: 'Generovat status',
      add_perex_to_prompt: 'Zohlednit také perex článku',
      post_length: {
        title: 'Délka příspěvku',
        title_tooltip: 'Přibližný počet znaků: <br><br>' +
          'Krátký = 100 <br>' +
          'Střední = 150 <br>' +
          'Dlouhý = 200',
        short: 'Krátký',
        medium: 'Střední',
        long: 'Dlouhý'
      },
      type_of_interaction: {
        title: 'Typ interakce',
        title_tooltip: 'Diskuse<br> vybízí k zanechání komentáře<br><br>' +
          'Přečíst<br> vybízí k přečtení článku<br><br>' +
          'Sdílet<br> vybízí ke sdílení článku',
        discussion: 'Diskuse',
        reading: 'Čtení',
        sharing: 'Sdílení'
      }
    }
  },
  ab_test: {
    title: 'A/B/C Testing',
    variant: 'Verze',
    winner: 'výherní',
    impressions_count: 'Počet zobrazení',
    clicks_count: 'Počet kliknutí',
    ctr: 'CTR (klikanost)',
    duration: 'Trvání',
    info: {
      title: 'Aktivací A/B/C tetsingu se automatický po vypublikování článku zapne testování. Tento proces je plně automatický a po vyhodnocení se nastaví výherní verze Titulku a Fotky. Testuje se na všech stránkách NMH a všech šablonách',
      image: 'V případě nenastavení obrázku se použije obrázek z varianty A resp. Hlavní obrázek.'
    }
  },
  list: {
    type: 'Typ',
    title: 'Titulek',
    site: 'Stránka',
    main_rubric: 'Hlavní rubrika',
    author: 'Autor',
    order_date: 'Datum publikování',
    last_modified: 'Poslední editace',
    status: 'Stav článku'
  },
  filter: {
    site: 'Stránka',
    rubric: 'Rubrika',
    search_in: 'Hledat',
    title: 'Titulek',
    fulltext: 'Všude',
    id_url: 'Hledat podle UUID / URL',
    published_since: 'Publikováno od',
    published_until: 'Publikováno do',
    type: 'Typ článku',
    authors: 'Autoři',
    status: 'Stav článku',
    tags: 'Klíčová slova',
    categories: 'Kategorie',
    users: 'Vlastníci',
    search: 'Hledat',
    reset_filter: 'Zrušit filtr',
    my_last_modified: 'Poslední mnou upraveno',
    my_drafts: 'Mé koncepty',
    my_articles: 'Mé články',
    all: 'Všechny',
    all_drafts: 'Všechny koncepty',
    to_review: 'Ke schválení',
    waiting_to_publish: 'Čeká na publikování',
    published: 'Publikováno',
    just_edited: 'Právě editováno'
  },
  transfer_to_export: {
    title: 'Převzít článek',
    transfer: 'Převzít',
    transferred_state: 'Převzatý článek',
    open_original: 'Článek byl převzatý. Otevřít originál'
  },
  modal: {
    related_articles_header: 'Vyber související články',
    related_articles_header_tag: 'Nastav jmenovku',
    related_disease_articles_header: 'Vyber související onemocnění',
    related_disease_articles_header_tag: 'Nastav jmenovku',
    quiz_header: 'Vyber kvíz',
    recipe_header: 'Vyber recept',
    test_header: 'Vyber test',
    print_publication_header: 'Vyber printové vydání',
    article_history_header: 'Historie článku',
    create_new_modal_header: 'Vytvořit nový článek',
    create_new_modal_create: 'Vytvořit',
    refresh_url_header: 'Chcete přegenerovat URL článku?',
    before_close_header_1: 'Varování! Máte neuložené změny.',
    before_close_header_2: 'Chcete odejít?',
    before_close_yes: 'Ano',
    before_close_no: 'Ne',
    set_hero_image_header: 'Opravdu chcete nastavit Hlavní obrázek?',
    yes: 'Ano',
    no: 'Ne',
    insert_all_ai_generated_related_articles: 'Označit vše doporučené',
    select: 'Vybrat',
    insert: 'Vložit',
    youtube_header: 'Vložit youtube video',
    youtube_url: 'Youtube URL adresa / ID videa',
    youtube_url_warning: 'Vybrané video je momentálně nedostupné, jste si jisti?',
    youtube_url_unauthorized: 'Autor videa zakázal vkládání tohoto videa na webové stránky.',
    ooyala_header: 'Vložit ooyala video',
    ooyala_id: 'Ooyala ID',
    ooyala_id_status_warning: 'Video není ve stavu live, chcete ho přesto přidat?',
    ooyala_id_preview_warning: 'Video nemá náhledový obrázek !',
    ooyala_id_warning: 'Nesprávně ooyala video id!',
    infobox_header: 'Vložit Infobox',
    infobox_create: 'Vytvořit nový Infobox',
    infobox_select: 'Vybrat ze stávajících Infoboxů',
    infobox_create_header: 'Vložit Infobox',
    infobox_title: 'Titulek',
    infobox_heading: 'Nadpis',
    infobox_bodytext: 'Obsah infoboxu',
    poll_header: 'Vložit anketu',
    poll_update_votes: 'Upravit hlasy',
    poll_create: 'Vytvořit novou anketu',
    poll_select: 'Vybrat ze stávajících anket',
    poll_type: 'Typ',
    poll_question: 'Otázka',
    poll_display_type: 'Způsob zobrazení',
    poll_restriction_type: 'Omezení hlasování',
    poll_end_of_voting: 'Ukončení hlasování',
    poll_order_date: 'Datum zobrazení na webu',
    poll_published_until: 'Publikováno do',
    poll_show_published_since: 'Odložit změnu',
    poll_published_since: 'Publikováno od',
    poll_hidden: 'Skrytý',
    poll_enabled_voting: 'Povolit hlasování',
    poll_options: 'Možnosti',
    poll_option: 'Možnost',
    poll_option_title: 'Titulek *',
    poll_add_option: 'Přidat možnost',
    quote_header: 'Vložit citát',
    quote_title: 'Obsah citátu',
    quote_author: 'Autor citátu',
    sport_table_header: 'Vložit sportovní tabulku',
    sport_table_seasonId: 'Id tabulky',
    sport_table_activeTab: 'Aktivní tab (1 = první)',
    sport_online_header: 'Vložit online zápas',
    sport_online_matchId: 'ID zápasu',
    sport_online_category: 'Kategorie',
    gallery_link_header: 'Vložit odkaz na galerii',
    gallery_link_pageNumber: 'Číslo stránky',
    gallery_link_linkText: 'Text odkazu',
    html_header: 'Vložit externí objekt',
    html_code: 'Kód externího objektu',
    html_code_placeholder: 'Vložte kód sem',
    unsaved_version: {
      header: 'Automatické uložení článku',
      body: 'Systém automaticky uložil rozpracovaný článek. Chcete pokračovat od poslední změny?',
      footer: 'Poslední změna {lastModifiedText} od {username}',
      lastModifiedText: '{date} v {time}',
      today: 'dnes',
      yesterday: 'včera',
      offline_saved_version: 'Zdá se, že jste offline. Poslední uložená verze {lastModifiedText}. Jakékoliv další změny nemusí být uloženy.',
      offline_no_saved_version: 'Zdá se, že jste offline. Nebyla nalezena žádná automaticky uložená verze. Jakékoliv další změny nemusí být uloženy.',
      save_log_error: 'Při automatickém ukládání článku došlo k chybě. Poslední uložená verze {lastModifiedText}. Jakékoliv další změny nemusí být uloženy.',
      loading_log_success: 'Automaticky uložené změny byly obnoveny',
      loading_log_error: 'Automaticky uložené změny se nepodařilo obnovit',
      yes: 'Ano',
      no: 'Ne'
    },
    save_failed: {
      header: 'Koncept se neuložil',
      body: 'Koncept se neuložil. Zkuste to znovu nebo obnovte poslední verzi: {lastModifiedText}.',
      save: 'Uložit',
      restore: 'Obnovit poslední verzi'
    }
  },
  preview: {
    article_preview: 'Náhled článku',
    next: 'Další',
    prev: 'Předchozí',
    page_no: 'Strana č.',
    preview_error: 'Chyba při vytváření náhledu článku.',
    preview_expired: 'Náhled expiroval. Pro vygenerování nového náhledu je potřeba otevřít Úpravy článku a stisknout tlačítko:'
  },
  notify: {
    please_fill_all_required_fields: 'Prosím vyplňte správně všechna povinná pole!',
    new_draft_created: 'Nový koncept byl vytvořen',
    new_draft_created_from_published: 'Byl vytvořen nový koncept z publikovaného článku',
    draft_was_saved: 'Koncept byl uložen',
    draft_save_error_500: 'Chyba při ukládání článku',
    draft_save_error_loader_stuck: 'Chyba při ukládání článku. Ukládání trvalo déle než 60 sekund a bylo přerušeno.',
    draft_save_error: 'Chyba při ukládání článku. Prosím zkontrolujte si, zda nemáte tento článek otevřený v jiném tabu.',
    draft_load_error: 'Chyba při načítání článku. Zavřete a zkuste to znovu.',
    article_status_changed_to_draft: 'Stav článku byl změněn do stavu KONCEPT',
    article_status_changed_to_review: 'Stav článku byl změněn do stavu NA KONTROLU',
    article_status_changed_to_ready: 'Stav článku byl změněn do stavu ČEKÁ NA PUBLIKOVÁNÍ',
    article_status_changed_to_ready_forbidden: 'Nemáte oprávnění ke změně stavu do ČEKÁ NA PUBLIKOVÁNÍ',
    article_status_changed_to_draft_forbidden: 'Nemáte oprávnění ke změně stavu do stavu KONCEPT',
    article_in_draft_exists: 'Článek už má verzi v konceptu!',
    article_in_review_exists: 'Článek již má verzi ke schválení!',
    article_in_ready_exists: 'Článek už má naplánovanou verzi!',
    entity_has_redirect: 'Článek nelze odpublikovat, protože má aktivní Přesměrování!',
    article_url_refresh_success: 'URL článku byla změněna',
    article_url_refresh_error: 'Chyba, URL článku nebyla změněna!',
    infobox_created: 'Infobox byl vytvořen',
    poll_created: 'Anketa byla vytvořena',
    youtube_thumbnail_error: 'Nastal problém s dotažením nahledového obrázku Youtube videa.',
    paidContentEmbedMissing: 'Článek je nastaven jako zamčený/placený, ale chybí mu bod uzamčení.',
    paidContentNotChecked: 'Článek má bod uzamčení, ale není nastaven jako zamčený/placený.',
    paidContentEmbedMultiple: 'Máte nastaveno více bodů uzamčení, maximum je 1.',
    pageTitlesPageBreaksCount: 'Počet zalomení nesedí s počtem názvů stránek stránkovaného článku',
    missingTags: 'Geneea nebo redakční tagy'
  },
  article_type: {
    article: 'Interní',
    external: 'Externí',
    video: 'Video',
    quiz: 'Kvíz',
    photostory: 'Fotostory',
    test: 'Test',
    longform: 'Longform',
    recipe: 'Recept',
    disease: 'Onemocnění',
    encyclopedy: 'Encyklopedie',
    onlineReportage: 'Reportáž'
  },
  article_layout: {
    layout_0: 'default',
    layout_1: 'layout 1',
    layout_2: 'layout 2',
    layout_3: 'layout 3'
  },
  test_discriminator: {
    title: 'Typ',
    car: 'Vozidlo',
    restaurant: 'Restaurace',
    hotel: 'Hotel'
  },
  notificationService: {
    notification: 'Notifikace',
    notificationPostponed: 'Publikovat zpožděnou notifikaci',
    oneSignalNotified: 'Publikovat notifikaci',
    remainingFromLimit: '{remaining} volné / {limit} limit'
  },
  hp_topic: {
    add: '+ Přidat další',
    remove: '- Odstranit',
    approved: 'Schváleno vedoucím',
    date: 'Datum publikování na Homepage',
    offer: 'Homepage nabídka článků',
    slot: 'Časový slot (v hod.)'
  }
}

export default messages
