import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_SAFETY_TOPIC_PERMISSIONS

const routes = [
  {
    path: 'safetyTopic/:id/edit',
    component: () => import('../../views/topic/TopicSafetyEditView'),
    name: 'safetyTopic_edit',
    meta: {
      description: 'Safety topic edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'safetyTopic/new',
    component: () => import('../../views/topic/TopicSafetyNewView'),
    name: 'safetyTopic_new',
    meta: {
      description: 'Safety topic new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'safetyTopic',
    component: () => import('../../views/topic/TopicSafetyListView'),
    name: 'safetyTopic_list',
    meta: {
      description: 'Safety topic list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
