const messages = {
  list: {
    id: 'Id',
    title: 'Title',
    created_at: 'Created at',
    modified_at: 'Modified at',
    created_by: 'Created by'
  },
  filter: {
    title: 'Title',
    id: 'Id',
    search: 'Search'
  },
  id: 'Id',
  title: 'Title'
}

export default messages
