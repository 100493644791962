import CoreApi from '../../api/core'

const API_NAME = '/district'

const state = {
  error: null,
  list: [],
  totalCount: 0
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  setError (state, message) {
    state.error = message
  }
}

const actions = {
  fetchAll (store, regionId) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const url = API_NAME +
      '?limit=1000' +
      '&order[title]=asc' +
      '&filter_eq[region]=' + regionId
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  }
}

const getters = {
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  error (state) {
    return state.error
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
