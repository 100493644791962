import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ESHOP_SUBSCRIBER_PERMISSIONS

const routes = [
  {
    path: 'shop/subscriber',
    component: () => import('../../../components/eshop/SubscriberList'),
    name: 'eshopSubscriber_list',
    meta: {
      description: 'Subscriber list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
