const buildFilterQuery = function (filter) {
  var filterQuery = ''
  if (filter.id.length > 0) {
    filterQuery += '&filter_equals[id]=' + filter.id
  }
  if (filter.title.length > 0) {
    filterQuery += '&filter_regex[title]=' + filter.title
  }

  return filterQuery
}

export default {
  buildFilterQuery
}
