import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_TAG_PERMISSIONS

const routes = [
  // ----------- TAG (old) -----------
  // * list
  {
    path: 'tag',
    component: () => import('@/views/tag/TagListView'),
    name: 'tag_list',
    meta: {
      description: 'Tag list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  },
  // * edit
  {
    path: 'tag/:id/edit',
    component: () => import('@/views/tag/TagEditView'),
    name: 'tag_edit',
    meta: {
      description: 'Tag edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  // * new
  {
    path: 'tag/new',
    component: () => import('@/views/tag/TagNewView'),
    name: 'tag_new',
    meta: {
      description: 'Tag new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  // ----------- COMMON TAGS (new) -----------
  // * list
  {
    path: 'commonTags',
    component: () => import('@/views/tag/commonTags/CommonTagsListView.vue'),
    name: 'commonTags_list',
    meta: {
      description: 'Common tags list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  },
  // ----------- GENEEA TAGS -----------
  // * list
  {
    path: 'geneeaTags',
    component: () => import('@/views/tag/geneeaTags/GeneeaTagsListView'),
    name: 'geneeaTags_list',
    meta: {
      description: 'Geneea tags list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  },
  // * edit
  {
    path: 'geneeaTag/:id/edit',
    component: () => import('@/views/tag/geneeaTags/GeneeaTagEditView'),
    name: 'geneeaTag_edit',
    meta: {
      description: 'Geneea tag edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  // * detail
  {
    path: 'geneeaTag/:id',
    component: () => import('@/views/tag/geneeaTags/GeneeaTagView'),
    name: 'geneeaTag_detail',
    meta: {
      description: 'Geneea tag detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  },
  // ----------- EDITORIAL TAGS -----------
  // * list
  {
    path: 'editorialTags',
    component: () => import('@/views/tag/editorialTags/EditorialTagsListView'),
    name: 'editorialTags_list',
    meta: {
      description: 'Editorial tags list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  },
  // * edit
  {
    path: 'editorialTag/:id/edit',
    component: () => import('@/views/tag/editorialTags/EditorialTagEditView'),
    name: 'editorialTag_edit',
    meta: {
      description: 'Editorial tag edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  // * new
  {
    path: 'editorialTag/new',
    component: () => import('@/views/tag/editorialTags/EditorialTagsNewView'),
    name: 'editorialTag_new',
    meta: {
      description: 'Editorial tag new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  // * detail
  {
    path: 'editorialTags/:id',
    component: () => import('../../views/tag/editorialTags/EditorialTagView.vue'),
    name: 'editorialTag_detail',
    meta: {
      description: 'Editorial tag detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  }
]

export default routes
