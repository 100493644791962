import { QUIZ_TYPE_DEFAULT_VLM } from '@/components/mixins/valueObject/QuizTypeMixin'
import { TYPE_IMAGE_ANSWERS } from '@/model/ValueObject/QuizLayout'

const DEFAULT_QUESTION_PER_PAGE = 1

export default {
  id: null,
  site: null,
  type: QUIZ_TYPE_DEFAULT_VLM, // rules
  title: '',
  description: '',
  enabled: true,
  displayCorrectAnswersImmediately: true,
  loggedInUsersOnly: true,
  image: null,
  imageInfo: null,
  setting: {
    layout: TYPE_IMAGE_ANSWERS,
    questionsPerPage: DEFAULT_QUESTION_PER_PAGE,
    showImage: true
  },
  questions: [],
  results: [],
  categories: []
}
