const messages = {
  id: 'ID',
  created_at: 'Vytvořeno',
  created_at_from: 'Vytvořeno od',
  created_at_to: 'Vytvořeno do',
  modified_at: 'Změněno',
  email: 'Email',
  phone: 'Mobil',
  variableSymbol: 'VS',
  price: 'Cena',
  product: 'Produkt',
  payment: 'Způsob platby',
  paymentResult: 'Výsledek platby',
  nextPaymentAt: 'Následující platba',
  nextSmsPaymentAt: 'Následující SMS platba',
  nextComfortPayPaymentAt: 'Následující ComfortPay platba',
  order: 'Objednávka',
  status: 'Pořadí platby',
  comfortPaySmsStatus: 'Stav',
  subscriptionStatus: {
    aboExported: 'Abo',
    aboPeriodikExported: 'Periodik Abo'
  },
  filter: {
    export: 'Exportovat',
    search: 'Hledat',
    reset_filter: 'Zrušit filtr'
  },
  modal: {
    defaults: {
      title: 'Upravit',
      body: 'Potvrdit změnu',
      message: 'Změna byla úspěšná',
      error: 'Při změně nastala chyba',
      button: {
        show: 'Změnit',
        cancel: 'Zrušit',
        confirm: 'Potvrdit'
      }
    },
    aboExport: {
      title: 'ABO',
      confirmation: 'Potrvďte prosím exportování platby ID',
      button: 'Exportovat',
      success: 'Platba byla exportována.',
      error: 'Vyskytla se chyba.'
    },
    aboPeriodikExport: {
      title: 'Export do Periodik ABO',
      confirmation: 'Potrvďte prosím export',
      button: 'Periodik Export',
      success: 'Platba byla exportována.',
      error: 'Vyskytla se chyba.'
    },
    changePaymentResult: {
      title: 'Změna výsledku platby',
      confirmation: 'Potrvďte prosím změnu platby',
      button: 'Změnit výsledek',
      success: 'Platba byla změněna',
      error: 'Vyskytla se chyba'
    },
    activatePayment: {
      title: 'Aktivovat opakování platby',
      confirmation: 'Potrvďte prosím aktivaci platby',
      button: 'Aktivovat',
      success: 'Platba byla aktivována',
      error: 'Vyskytla se chyba'
    },
    changeNextPaymentAt: {
      title: 'Změnit datum příští platby',
      confirmation: 'Potrvďte prosím změnu následující platby',
      button: 'Následující platba',
      success: 'Platba byla změněna',
      error: 'Vyskytla se chyba'
    },
    cancelPayment: {
      title: 'Zrušení následující platby',
      confirmation: 'Potvrďte zrušení příští platby',
      button: 'Deaktivovat',
      success: 'Následující platba zrušena.',
      error: 'Vyskytla se chyba.'
    }
  },
  message: {
    updated_record: 'Záznam byl změněn!'
  }
}

export default messages
