export const CATEGORY_OTHER = 'other'
export const CATEGORY_EMMA_HOT_OR_NOT = 'emmaHotOrNot'
export const CATEGORY_POLOVNICTVO_LIKE_DISLIKE = 'polovnictvoLikeDislike'
export const CATEGORY_POLOVNICTVO_READER_CATCH = 'polovnictvoReaderCatch'
export const CATEGORY_PLUS_1_PHOTO_OF_THE_DAY = 'plus1PhotoOfTheDay'
export const CATEGORY_SARM_FASHION_POLICE = 'sarmFashionPolice'
export const CATEGORY_PLUS_7_PLUS_MINUS = 'plus7PlusMinus'
export const CATEGORY_SPORT24_CLAP_WHISTLE = 'sport24ClapWhistle'
export const CATEGORY_AUTOBILD_START_STOP = 'autobildStartStop'

export const CATEGORY_MAP = {
  other: 'Iná',
  emmaHotOrNot: 'Emma - Hot or Not',
  polovnictvoLikeDislike: 'Poľovníctvo - Páči nepáči',
  polovnictvoReaderCatch: 'Poľovníctvo - Vaše úlovky',
  plus1PhotoOfTheDay: 'Plus JEDEN DEŇ - Foto dňa',
  sarmFashionPolice: 'Šarm - Módna polícia',
  plus7PlusMinus: 'Plus 7 dní - Plus mínus',
  sport24ClapWhistle: 'Šport24.sk - Tlieskame pískame',
  autobildStartStop: 'Autobild - Štart Stop'
}

export const AVAILABLE_VALUES = [
  {
    id: CATEGORY_OTHER,
    title: 'Iná'
  },
  {
    id: CATEGORY_EMMA_HOT_OR_NOT,
    title: 'Emma - Hot or Not'
  },
  {
    id: CATEGORY_POLOVNICTVO_LIKE_DISLIKE,
    title: 'Poľovníctvo - Páči nepáči'
  },
  {
    id: CATEGORY_POLOVNICTVO_READER_CATCH,
    title: 'Poľovníctvo - Vaše úlovky'
  },
  {
    id: CATEGORY_PLUS_1_PHOTO_OF_THE_DAY,
    title: 'Plus JEDEN DEŇ - Foto dňa'
  },
  {
    id: CATEGORY_SARM_FASHION_POLICE,
    title: 'Šarm - Módna polícia'
  },
  {
    id: CATEGORY_PLUS_7_PLUS_MINUS,
    title: 'Plus 7 dní - Plus mínus'
  },
  {
    id: CATEGORY_SPORT24_CLAP_WHISTLE,
    title: 'Šport24.sk - Tlieskame pískame'
  },
  {
    id: CATEGORY_AUTOBILD_START_STOP,
    title: 'Autobild - Štart Stop'
  }
]
