const messages = {
  id: 'ID',
  created_at: 'Vytvorené',
  created_at_from: 'Vytvorené od',
  created_at_to: 'Vytvorené do',
  modified_at: 'Zmenené',
  email: 'Email',
  phone: 'Mobil',
  variableSymbol: 'VS',
  price: 'Cena',
  product: 'Produkt',
  payment: 'Spôsob platby',
  paymentResult: 'Výsledok platby',
  nextPaymentAt: 'Nasledujúca platba',
  nextSmsPaymentAt: 'Nasledujúca SMS platba',
  nextComfortPayPaymentAt: 'Nasledujúca ComfortPay platba',
  order: 'Objednávka',
  status: 'Poradie platby',
  comfortPaySmsStatus: 'Stav',
  subscriptionStatus: {
    aboExported: 'Abo',
    aboPeriodikExported: 'Periodik Abo'
  },
  filter: {
    export: 'Exportovať',
    search: 'Hľadať',
    reset_filter: 'Zrušiť filter'
  },
  modal: {
    defaults: {
      title: 'Upraviť',
      body: 'Potvrdiť zmenu',
      message: 'Zmena bola úspešná',
      error: 'Pri zmene nastala chyba',
      button: {
        show: 'Zmeniť',
        cancel: 'Zrušiť',
        confirm: 'Potvrdiť'
      }
    },
    aboExport: {
      title: 'ABO',
      confirmation: 'Potrvďte prosím exportovanie platby ID',
      button: 'Exportovať',
      success: 'Platba bola exportovaná.',
      error: 'Vyskytla sa chyba.'
    },
    aboPeriodikExport: {
      title: 'Export do Periodik ABO',
      confirmation: 'Potrvďte prosím export',
      button: 'Periodik Export',
      success: 'Platba bola exportovaná.',
      error: 'Vyskytla sa chyba.'
    },
    changePaymentResult: {
      title: 'Zmena výsledku platby',
      confirmation: 'Potrvďte prosím zmenu platby',
      button: 'Zmeniť výsledok',
      success: 'Platba bola zmenená',
      error: 'Vyskytla sa chyba'
    },
    activatePayment: {
      title: 'Aktivovať opakovanie platby',
      confirmation: 'Potrvďte prosím aktiváciu platby',
      button: 'Aktivovať',
      success: 'Platba bola aktivovaná',
      error: 'Vyskytla sa chyba'
    },
    changeNextPaymentAt: {
      title: 'Zmeniť dátum nasledujúcej platby',
      confirmation: 'Potrvďte prosím zmenu následujúcej platby',
      button: 'Nasledujúca platba',
      success: 'Platba bola zmenená',
      error: 'Vyskytla sa chyba'
    },
    cancelPayment: {
      title: 'Zrušenie nasledujúcej platby',
      confirmation: 'Potvrďte zrušenie nasledujúcej platby',
      button: 'Deaktivovať',
      success: 'Nasledujúca platba zrušená.',
      error: 'Vyskytla sa chyba.'
    }
  },
  message: {
    updated_record: 'Záznam bol zmenený!'
  }
}

export default messages
