import { dateTimeNow } from '../../services/DateTimeService'

export default {
  type: 'date_interval',
  payment: '',
  code: '',
  accounts: 1,
  activeSince: '',
  activeUntil: '',
  activeDays: '',
  abo: {
    cisDo: '',
    username: ''
  },
  admin: '',
  setting: {
    marketing: 0,
    admin: 0
  },
  digitalServices: [1],
  subscribers: [],
  expirationAt: '',
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  id: ''
}
