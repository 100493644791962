const messages = {
  list: {
    id: 'Id',
    title: 'Titulok',
    sites: 'Stránky',
    voting: 'Hlasovanie',
    published_since: 'Publikované od',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril'
  },
  filter: {
    title: 'Titulok',
    id: 'Id',
    bodytext: 'BodyText',
    search: 'Hľadať'
  },
  id: 'Id',
  title: 'Titulok',
  title_placeholder: 'Napište nadpis',
  bodytext: 'Text vtipu',
  sites: 'Stránky',
  voting: 'Povoliť hlasovanie',
  author: 'Author',
  published_since: 'Dátum publikovania',
  published_since_placeholder: 'Vyberte dátum publikovania na webe',
  published_until: 'Publikované do',
  published_until_placeholder: 'Vyberte dátum'
}

export default messages
