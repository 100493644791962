import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'shop/top-product-setting',
    component: () => import('@/components/eshop/TopProductSetting'),
    name: 'eshopTopProductSetting_edit',
    meta: {
      description: 'TOP Product Setting edit',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS.ESHOP_TOP_PRODUCT_PERMISSIONS.listRoute
    }
  }
]

export default routes
