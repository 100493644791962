import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.JOKE_PERMISSIONS

const routes = [
  {
    path: 'joke/new',
    component: () => import('../../views/joke/JokeNewView'),
    name: 'joke_new',
    meta: {
      description: 'Joke new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'joke/:id/edit',
    component: () => import('../../views/joke/JokeEditView'),
    name: 'joke_edit',
    meta: {
      description: 'Joke edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'joke/:id',
    component: () => import('../../views/joke/JokeView'),
    name: 'joke_detail',
    meta: {
      description: 'Joke detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'joke',
    component: () => import('../../views/joke/JokeListView'),
    name: 'joke_list',
    meta: {
      description: 'Joke list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
