export default {
  id: '',
  variableSymbol: '',
  order: '',
  price: '',
  order_product: '',
  order_payment: '',
  paymentResult: '',
  order_email: '',
  order_phone: '',
  status: '',
  createdAtFrom: '',
  createdAtTo: '',
  nextSmsPaymentAt: '',
  nextComfortPayPaymentAt: ''
}
