import moment from 'moment'

const buildFilterQuery = function (filter) {
  var filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.category) {
    filterQuery += '&filter_in[category]=' + filter.category.map(category => category.id)
  }
  if (filter.service) {
    filterQuery += '&filter_eq[service]=' + filter.service
  }
  if (filter.headline) {
    filterQuery += '&filter_contains[headline]=' + filter.headline
  }
  if (filter.contentCreatedFrom) {
    const date = moment(filter.contentCreatedFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[contentCreated]=' + date
  }
  if (filter.contentCreatedTo) {
    const date = moment(filter.contentCreatedTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[contentCreated]=' + date
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
