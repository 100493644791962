const messages = {
  filter: {
    id: 'Id',
    title: 'Titulek',
    name: 'Název'
  },
  list: {
    id: 'Id',
    title: 'Titulek',
    name: 'Název'
  },
  id: 'Id',
  title: 'Titulek',
  name: 'Název',
  description: 'Popis',
  address: 'Adresa',
  rating: 'Hodnocení',
  stars: 'Počet hvězd',
  yearOfReview: 'Rok recenze',
  image: 'Obrázek',
  phone: 'Telefon',
  website: 'Web',
  priceLevel: 'Cenová úroveň',
  pros: 'Plusy',
  cons: 'Minusy'
}

export default messages
