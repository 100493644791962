const messages = {
  npa: {
    status: 'Stav',
    title: 'Titulok',
    agency: 'Agentúra',
    client: 'Klient',
    interval: 'Trvanie',
    days: 'Aktívne zobrazovanie (dni)',
    impressions: 'Impresie',
    visits: 'Videnia',
    clicks: 'Kliknutia',
    total_price: 'Total price',
    ctr: 'CTR in-view',
    clicks_limit: 'Zaplatené kliknutia',
    click_price: 'Click price (€)',
    clicks_total_price: 'Clicks total price (€)',
    bonus_clicks_limit: 'Nárok na bonusové kliknutia',
    bonus_clicks: 'Aktuálne bonusové kliknutia',
    views: 'Zobrazenia',
    progress: 'Plnenie',
    bonus: 'Bonus',
    actions: 'Akcie',
    inactive: 'Inactive',
    active: 'Active',
    done: 'Done',
    paused: 'Paused',
    category: 'Campaign Category',
    category_selection: 'Category Selection',
    divided_by_days: 'Divided by days',
    divided_by_days_shortname: 'DBD',
    url: 'URL stránky',
    image: 'Obrázok',
    published_since: 'Platnosť od',
    published_until: 'Platnosť do',
    native_perf_count: 'Počet článkov v boxe',
    total_items_count: 'Počet všetkých článkov',
    total_pages_count: 'Počet všetkých strán',
    alternating_campaigns: 'Alternating campaigns',
    alternative_title: 'Title for alternative ',
    alternative_url: 'URL of alternative ',
    alternative_image: 'Image for alternative ',
    alternative: 'Alternative',
    alternative_in_views_count: 'In-Views Count',
    alternative_clicks_count: 'Clicks Count',
    alternative_ctr: 'CTR',
    enable_scheduling: 'Enable daily scheduling',
    algorithm_overridden: 'Always show',
    paid_campaign: 'Paid campaign',
    campaign_delivery_day: 'Delivery day / days',
    campaign_delivered_from: 'Delivery time from',
    campaign_delivered_to: 'Delivery time to',
    buttons: {
      add: 'Pridať',
      settings: 'Nastavenia',
      duplicate: 'Duplicate',
      add_alternative: '+ Add alternative',
      remove_alternative: 'Remove alternative'
    },
    statuses: {
      inactive: 'Inactive',
      active: 'Active',
      done: 'Done',
      paused: 'Paused'
    },
    original_daily_calculation: 'Original Daily Calculation',
    first_day_estimate: '1st Day',
    second_day_estimate: '2nd Day',
    third_day_estimate: '3rd Day',
    fourth_day_estimate: '4th Day',
    fifth_day_estimate: '5th Day',
    sixth_day_estimate: '6th Day',
    seventh_day_estimate: '7th Day',
    total_daily_campaign_performance: 'Total Daily Campaign Performance',
    predicted_estimate_by_campaigns: 'NMH Estimate',
    daily_stats_chart: 'Campaign Progress',
    daily_stats_table: 'Campaign Progress by Brand',
    of_in_views: 'in views',
    of_clicks: 'clicks',
    validation: {
      main_image_check: 'The main image must be set.',
      alternative_b_check: 'Either of a title or image for the B alternative must be set.',
      alternative_c_check: 'Either of a title or image for the C alternative must be set.',
      duplicate_exists: 'There is already an existing duplicate for the given campaign.',
      delivery_time_check: 'Campaign`s delivery end time must be later than the beginning time.'
    }
  }
}

export default messages
