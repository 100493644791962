const getUserInfo = function (user) {
  if (user.firstName && user.lastName) {
    return user.firstName + ' ' + user.lastName
  }
  if (user.username) {
    return user.username
  }
}

export default {
  getUserInfo
}
