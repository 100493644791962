import _ from 'lodash'
import EshopApi from '../../../api/eshop'
import HistoryFilterService from '../../../services/eshop/HistoryFilterService'
import HistoryFilterModel from '../../../model/eshop/HistoryFilter'
import config from '../../../config'

const API_NAME = '/history'
const EXPORT_LIMIT = 10000

const state = {
  error: null,
  list: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(HistoryFilterModel)
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * config.defaults.list.limit) - config.defaults.list.limit
    const url = API_NAME + '?view=full&limit=' + config.defaults.list.limit + '&offset=' + offset + '&order[id]=desc' +
      HistoryFilterService.buildFilterQuery(store.state.filter)
    EshopApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async export (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const url = API_NAME + '.csv?limit=' + EXPORT_LIMIT + '&order[id]=desc' + HistoryFilterService.buildFilterQuery(store.state.filter)
    return await EshopApi().get(url)
      .finally(() => {
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  }
}

const getters = {
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
