const messages = {
  id: 'Id',
  name: 'Názov',
  site: 'Stránka',
  scaleGroup: 'Skupina',
  groupValue: 'Skupinová hodnota',
  value: 'Hodnota',
  search: 'Hľadaj'
}

export default messages
