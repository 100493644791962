const messages = {
  list: {
    id: 'Id',
    title: 'Titulok',
    published_since: 'Publikované od',
    author: 'Autor',
    image: 'Obrázok',
    site: 'Stránka',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril'
  },
  filter: {
    title: 'Titulok',
    id: 'Id',
    bodytext: 'BodyText',
    site: 'Stránka',
    search: 'Hľadať'
  },
  id: 'Id',
  title: 'Titulok',
  title_placeholder: 'Napište nadpis',
  bodytext: 'Text myšlienky',
  published_since: 'Dátum publikovania',
  published_since_placeholder: 'Vyberte dátum publikovania na webe',
  published_until: 'Publikované do',
  published_until_placeholder: 'Vyberte dátum',
  author: 'Autor',
  image: 'Fotka k myšlienke',
  site: 'Stránka'
}

export default messages
