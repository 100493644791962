const messages = {
  id: 'ID',
  activated_at: 'Aktivováno',
  activated_at_from: 'Aktivováno od',
  activated_at_to: 'Aktivováno do',
  cancelled_at: 'Zrušeno',
  cancelled_at_from: 'Zrušeno od',
  cancelled_at_to: 'Zrušeno do',
  activeSince: 'Aktivní od',
  activeUntil: 'Aktivní do',
  email: 'Email',
  code: 'Kód',
  feUser: 'Uživatel',
  product: 'Produkt',
  firstOrder: 'První objednávka',
  filter: {
    export: 'Exportovat',
    search: 'Hledat',
    reset_filter: 'Zrušit filtr',
    unique_email: 'Unikátní email'
  }
}

export default messages
