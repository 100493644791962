import CoreApi from '../../../api/core'

const API_NAME = '/don/nativePerf/analytics'

const state = {
  data: null
}

const mutations = {
  storeData (state, responseData) {
    state.data = responseData
  }
}

const actions = {
  async getData (store, id) {
    return await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeData', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
}

const getters = {
  data (state) {
    return state.data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
