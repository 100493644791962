const messages = {
  nativeCampaign: {
    status: 'Stav',
    agency: 'Agentúra',
    client: 'Klient',
    title: 'Titulok',
    interval: 'Trvanie',
    alternatives: 'Alter- natívy',
    days: 'Aktívne zobrazenie (dni)',
    daysColumn: 'Aktívne dni',
    daysTooltip: 'Počet dní kedy je kampaň aktívna',
    visits: 'Videnia (inview impresie)',
    visitsColumn: 'Videnia',
    visitsTooltip: 'Inview impresie',
    displays: 'Zobrazenia (impresie)',
    clicks: 'Kliknutia',
    clicksTooltip: 'Počet kliknutí / Počet zaplatených kliknutí',
    sumPrice: 'Minutá suma',
    ctr: 'CTR In-View',
    ctrColumn: 'CTR',
    ctrTooltip: 'In-View',
    clicksLimit: 'Zaplatené kliknutia',
    budget: 'Rozpočet',
    clickPrice: 'Cena za klik (€)',
    clickPriceColumn: 'CPC',
    clickPriceTooltip: 'Cena za klik',
    budgetFulfillment: 'Plnenie rozpočtu',
    fulfillment: 'Plnenie',
    bonusFulfillment: 'Aktuálne bonusové kliknutia',
    bonusFulfillmentColumn: 'Bonus',
    bonusFulfillmentTooltip: 'Aktuálne bonusové kliknutia',
    rrk: 'RRK',
    rrkTooltip: 'Rovnomerné rozloženie klikov',
    dividedByDays: 'Rovnomerné rozloženie klikov', // RRK
    note: 'Poznámka',
    salesman: 'Obchodník',
    category: 'Kategória kampane',
    pageUrl: 'URL stránky',
    orderId: 'Číslo objednávky',
    addAlternative: 'Pridať alternatívu',
    mainAlternative: 'Hlavná alternatíva',
    alternativeName: 'Alternatíva {name}',
    image: 'Obrázok',
    loadImageFromArticle: 'Načítať obrázok z článku',
    publishedFrom: 'Platnosť od',
    publishedTo: 'Platnosť do',
    segments: 'Segmenty',
    pause: 'Pozastaviť kampaň',
    bonusClicks: 'Nárok na bonusové kliknutia',
    resetCampaign: 'Resetovať kampaň',
    resetCampaignDisabled: 'Kampaň je stále platná',
    publishedIsInPast: 'Platnosť nemôže byť v minulosti.',
    brands: 'Výber webov pre zobrazenie reklamy',
    allNMHBrandOption: 'Celé NMH',
    alternativesEnabled: 'Alternovanie kampaní',
    paidCampaign: 'Platená kampaň',
    createNewLabel: 'Nová kampaň',
    filter: {
      status: 'Stav',
      agency: 'Agentúra',
      client: 'Klient',
      title: 'Titulok',
      from: 'Dátum od',
      to: 'Dátum do',
      url: 'URL',
      salesman: 'Obchodník',
      orderId: 'Objednávka č.'
    },
    alternative: {
      ctr: 'CTR',
      visitsCount: 'Počet videní (inview impresie)',
      displaysCount: 'Počet zobrazení (impresie)',
      clickCount: 'Počet kliknutí'
    },
    brandsTable: {
      title: 'Vývoj kampane podľa brandu',
      brands: 'Brandy',
      visits: 'Videnia (inview impresie)',
      clicks: 'Kliky',
      ctr: 'CTR'
    }
  },
  prediction: {
    clicks: 'Reálne kliknutia',
    clicksForecast: 'Predpoveď kliknutí',
    clicksReserved: 'Rezervované kliknutia',
    sold: 'Vypredané',
    clicksForecastTooltip: 'Tento údaj / os hovorí akým reklamným priestorom disponuje NMH v prípade natívnej reklamy donboxu.',
    clicksReservedTooltip: 'Tento údaj / os hovorí o sumárnych rezervovaných klikoch kampaní v prípade natívnej reklamy donboxu.' +
      'Teda počet klikov, ktoré si klienti zaplatili vrámci kampaní. ',
    soldTooltip: '% podiel medzi z “klikov k dispozícii” a “predaných klikov”.',
    clicksTooltip: 'Tento údaj / os hovorí o reálnom počte klikov, ktoré budú vykonané v prípade natívnej reklamy donboxu'
  }
}

export default messages
