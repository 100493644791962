const messages = {
  messages: {
    error: 'Nebyla specifikována zpráva, prosím přidejte ji do konfigurace',
    0: 'Chybí A/B Testing. Zapněte A/B Testing!',
    1: 'Žádný obrázek v článku. Přidejte obrázky! (Optimální počet obrázků: {optimalValue})',
    2: 'Málo obrázků v článku. Přidejte více obrázků! (Optimální počet obrázků: {optimalValue})',
    3: 'Žádné video v článku. Přidejte videa! (Optimální počet videí: {optimalValue})',
    4: 'Nízký počet videí v článku. Přidejte více videí! (Optimální počet videí: {optimalValue})',
    5: 'Velmi nízká návštěvnost z Google vyhledávače. (Skutečná hodnota: {realValue}% Optimální hodnota: {optimalValue}%)',
    6: 'Nízká návštěvnost z Google vyhledávače. (Skutečná hodnota: {realValue}% Optimální hodnota: {optimalValue}%)',
    7: 'Velmi nízká návštěvnost z Facebooku. Podpořte to postem. (Skutečná hodnota: {realValue}% Optimální hodnota: {optimalValue}%)',
    8: 'Nízká návštěvnost z Facebooku. Podpořte to postem. (Skutečná hodnota: {realValue}% Optimální hodnota: {optimalValue}%)',
    9: 'Velmi nízká návštěvnost z NMH kanálů. Článek není dostatečně promován na ostatních značkách. (Skutečná hodnota: {realValue}% Optimální hodnota: {optimalValue}%)',
    10: 'Nízká návštěvnost z NMH kanálů. Článek není dostatečně promován na ostatních značkách. (Skutečná hodnota: {realValue}% Optimální hodnota: {optimalValue}%)',
    11: 'Uživatelé tráví v článku extrémně krátkou dobu. (Skutečná hodnota: {realValue} sekund Optimální hodnota: {optimalValue} sekund)',
    12: 'Uživatelé tráví v článku krátkou dobu. (Skutečná hodnota: {realValue} sekund Optimální hodnota: {optimalValue} sekund)',
    13: 'Uživatelé tráví v galerii extrémně krátkou dobu. (Skutečná hodnota: {realValue} sekund Optimální hodnota: {optimalValue} sekund)',
    14: 'Uživatelé tráví v galerii krátkou dobu. (Skutečná hodnota: {realValue} sekund Optimální hodnota: {optimalValue} sekund)'
  }
}

export default messages
