const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.slug) {
    filterQuery += '&filter_contains[slug]=' + filter.slug
  }
  if (filter.site && filter.site > 0) {
    filterQuery += '&filter_eq[site]=' + filter.site
  }
  if (filter.parent && filter.parent > 0) {
    filterQuery += '&filter_eq[parent]=' + filter.parent
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
